import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';

import { AnelRotaService } from './../../_services/anel-rota.service';
import { MessageService } from './../../_services/message.service';

import { Anel } from './../../_models/anel';
import { Porto } from './../../_models/porto';

import { MessageComponent } from './../../shared/message/message.component';

import { Operacao } from 'app/_enums/operacao';
import { SentidoRota } from 'app/_enums/sentido-rota.enum';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'app-anel-rota',
  templateUrl: './anel-rota.component.html',
  styleUrls: ['./anel-rota.component.scss']
})
export class AnelRotaComponent implements OnInit {

  public aneis: Anel[];
  public portos: Porto[];
  public loading: boolean = false;

  @ViewChild(MessageComponent , { static: true}) mensagem: MessageComponent;

  constructor(private _msgService: MessageService,
    private _anelRotaService: AnelRotaService) {

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ngOnInit() {
    this.ObterAneis();
    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
      this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao);
  }

  public ObterAneis() {
    this.loading = true;
    this._anelRotaService.getAneis()
      .subscribe(p => {
        this.aneis = p;
        this.loading = false;
      }
      );
  }

  public DeletarAnel(anelID: number) {
    swal({
      title: 'Confirmação',
      text: 'Deseja excluir o Anel e suas rotas? A exclusão de um Anel impactará diretamente na Programação Completa de Navios.',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(success => {

      this.loading = true;
      this._anelRotaService.deletarAnel(anelID)
        .subscribe(result => {
          this.loading = false;

          if (result.IsValid) {
            this._msgService.addMessage('Anéis e Rotas', result.Message, "success");
            this.ObterAneis();
          }
          else {
            var msgErr: string[] = [];
            result.Erros.forEach(element => msgErr.push(element.Message));
            this._msgService.addMessageArray("Anéis e Rotas", msgErr, "error", LogFrontFuncionalidade.AneisERotas, Operacao.Exclusao, anelID);
          }
        });
    }, dismiss => {
      // console.log(dismiss);
    });
  }

  public obterRotasSul(anel: Anel){
    return anel.rotas.filter(rota => rota.sentido == SentidoRota.Sul);
  }

  public obterRotasNorte(anel: Anel){
    return anel.rotas.filter(rota => rota.sentido == SentidoRota.Norte);
  }

}
