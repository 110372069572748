import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ViewChildren } from '@angular/core';

import { NavioViagem } from '../../../_models/navioViagem';
import { DoctoFatura } from './../../../_models/doctoFatura';
import { DoctoFaturaRequest } from './../../../_models/doctoFatura-request';

import { AppService } from 'app/_services/app.service';
import { SharedService } from '../../../_services/shared.service';
import { DatepickerService } from '../../../_services/datepicker.service';
import { GenericOrderbyService } from "app/_services/generic-orderby.service";

import { CnpjDropdownComponent } from 'app/shared/cnpj-dropdown/cnpj-dropdown.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";
import { DateModel, DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";

@Component({
  selector: 'filtro-documentacao',
  templateUrl: './filtro-documentacao.component.html',
  styleUrls: ['./filtro-documentacao.component.scss']
})
export class FiltroDocumentacaoComponent implements OnInit {

  loading: boolean;
  viagens: NavioViagem[];
  viagensSelecionadas: number[];
  comboViagens: FilterComboConfiguration;
  cssClassAdmin = '';
  filtroGenerico = null;
  eAdmin: boolean = this._appService.isAdmin();
  eCliente: boolean = this._appService.isCliente();
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();

  optionsInicio: DatePickerOptions;
  optionsFim: DatePickerOptions;
  dataFim: DateModel;
  dataInicio: DateModel;

  @ViewChild(CnpjDropdownComponent, { static: true }) cnpjdropdown: CnpjDropdownComponent;
  @Output() clickPesquisar: EventEmitter<DoctoFaturaRequest> = new EventEmitter<DoctoFaturaRequest>();
  @Output() cnpjAlteradoEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _datePickerService: DatepickerService,
    private _appService: AppService,
    private _genericOrderbyService: GenericOrderbyService,
    private _sharedService: SharedService) {

    this.optionsInicio = this._datePickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), -7));
    this.optionsFim = this._datePickerService.GenerateOptionsConfig(new Date());

    this.comboViagens = new FilterComboConfiguration('Navio/Viagem...');
    this.comboViagens.settings = {
      checkedStyle: 'glyphicon',
      selectionLimit: 1,
      closeOnSelect: true,
      showCheckAll: false,
      showUncheckAll: false,
      //displayAllSelectedText: true,
      //autoUnselect: true,
      dynamicTitleMaxItems: 1,
      enableSearch: true
    };
  }

  ngOnInit() {
    this.cssClassAdmin = this.eAdmin || (this.eCliente && this.maisDeUmCNPJ) ? "" : " customer-profile";
  }

  ConfiguraNavioViagens(viagens: NavioViagem[], listaDoctos: DoctoFatura[]) {
    this.viagens = viagens;
    this.comboViagens.options = [];

    if (this.viagens != null) {
      this.viagens.forEach(viagem => {
        if (listaDoctos.filter(f => f.NavioViagem == viagem.descViagemNavio).length > 0) {
          this.comboViagens.options.push({ id: viagem.idViagemNavio, name: viagem.descViagemNavio });
        }
      });
    }

    this.comboViagens.options = this._genericOrderbyService.ordenarPorCampo(this.comboViagens.options, 'name', false);
    this.loading = false;
  }

  Pesquisar() {
    var dataInicio = this._datePickerService.ObtemData(this.dataInicio);
    var dataFim = this._datePickerService.ObtemData(this.dataFim);
    var consulta = new DoctoFaturaRequest();
    var cnpj = (this._appService.isAdmin()) ? this.cnpjdropdown.CNPJFiltro :
      (this.cnpjdropdown.cnpjsSelecionados) ? this.cnpjdropdown.cnpjsSelecionados.join(";") :
        this.cnpjdropdown.cnpjsGrupos ? this.cnpjdropdown.cnpjsGrupos : '';

    consulta.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
    consulta.dataEmissaoIni = dataInicio;
    consulta.dataEmissaoFim = dataFim;
    consulta.cnpjCli = cnpj;
    consulta.filtroGenerico = this.filtroGenerico;
    consulta.idNavioViagem = (this.viagensSelecionadas && this.viagensSelecionadas.length > 0) ? this.viagensSelecionadas[0] : null;

    this.clickPesquisar.emit(consulta);
  }

  cnpjAlterado(cnpj: string) {
    this.cnpjAlteradoEvent.emit(cnpj);
  }
}
