import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { CtmNf } from 'app/_models/ctmNf';
import { BaseComponent } from 'app/shared/base.component';

@Component({
  selector: 'chave-nfe',
  templateUrl: './chave-nfe.component.html',
  styleUrls: ['./chave-nfe.component.scss']
})

export class ChaveNfeComponent extends BaseComponent implements OnInit {
  @Input() nfe: CtmNf;
  @Input() index: number;
  @Input() exibirExcluir: boolean;

  @Output() excluirNfe: EventEmitter<CtmNf> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  private excluir() {
    this.excluirNfe.emit(this.nfe);
  }
}
