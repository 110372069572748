
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { DetalhesContainerComponent } from './detalhes-container.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot()
    ],
    exports: [DetalhesContainerComponent],
    declarations: [DetalhesContainerComponent]
})
export class DetalhesContainerModule { }
