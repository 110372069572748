import { Component, OnInit, ViewChild } from '@angular/core';

import { TipoServico } from './../../../_models/tipo-servico';

import { MessageService } from './../../../_services/message.service';
import { ParametroService } from './../../../_services/parametro.service';

import { BaseComponent } from './../../../shared/base.component';
import { MessageComponent } from './../../../shared/message/message.component';
import { PaginacaoComponent } from './../../../shared/paginacao/paginacao.component';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'app-tipos-servicos',
  templateUrl: './tipos-servicos.component.html',
  styleUrls: ['./tipos-servicos.component.scss']
})

export class TiposServicosComponent extends BaseComponent implements OnInit {

  tipoServicos: TipoServico[]; // array of all items to be paged
  loading: boolean = false;
  filtro: string = "";

  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  constructor(private _msgService: MessageService,
    private _parametroService: ParametroService) {
    super();
    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // After the view is initialized, child component (paginacao) will be available
    this.ObterTipoServicos();
  }

  ObterTipoServicos() {
    this._msgService.clearMessage();
    this.loading = true;
    this._parametroService.getTipoServicosAdmin(this.filtro)
      .subscribe(n => {
        this.tipoServicos = n;
        this.paginacao.setAllData(this.tipoServicos);
        this.paginacao.setPage(1);
        this.loading = false;
      });
  }

  Salvar() {
    this.loading = true;
    this._parametroService.salvarTipoServico(this.tipoServicos)
      .subscribe(result => {
        this.loading = false;

        if (result.IsValid) {
          this._msgService.addMessage('Serviços', result.Message, "success");
          this.ObterTipoServicos();
        }
        else {
          var msgErr: string[] = [];
          result.Erros.forEach(element => msgErr.push(element.Message));             
          this._msgService.addMessageArray("Serviços", msgErr, "error", LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.tipoServicos);   
        }
      });
  }

  Filtrar() {
    this.ObterTipoServicos();
  }

}
