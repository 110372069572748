import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Cliente } from './../../_models/cliente';
import { UsuarioCNPJ } from './../../_models/usuario-cnpj';
import { PerfilUsuario } from 'app/_enums/perfil-usuario.enum';
import { ClienteRequest } from './../../_models/cliente-request';

import { AppService } from 'app/_services/app.service';
import { BaseService } from 'app/_services/base.service';
import { SharedService } from 'app/_services/shared.service';
import { GrupoEconomicoService } from 'app/_services/grupo-economico.service';
import { GenericOrderbyService } from './../../_services/generic-orderby.service';

import { BaseComponent } from '../base.component';
import { IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from './../multiselect-dropdown/multiselect-dropdown.component';

@Component({
  selector: 'cnpjdropdown',
  templateUrl: './cnpj-dropdown.component.html',
  styleUrls: ['./cnpj-dropdown.component.scss']
})
export class CnpjDropdownComponent extends BaseComponent implements OnInit {

  loading: boolean = false;
  loadingCNPJs: boolean = false;
  loadingClientes: boolean = false;
  modoEdicao: boolean = false;
  modoBloqueado: boolean = false;
  isUsuarioAdmin = false;
  MaisDe1Cnpj: boolean = false;
  CNPJFiltroCSS: string = 'form-control';
  SelecionaMultiplos: boolean = true;
  clientes: Cliente[];
  clientesInscricoes: Cliente[];
  filtroCliente: ClienteRequest = new ClienteRequest;
  inscrEstadualSelecionada: string;
  razaoSocial: string = '';
  pesquisaEspecial: boolean = false;
  cnpjsGrupos: string = '';

  constructor(private _appService: AppService,
              private _baseService: BaseService,
              private _grupoService: GrupoEconomicoService,
              private _genericOrderbyService: GenericOrderbyService,
              private _sharedService: SharedService) {
    super();
  }
  
  @Input() habilitaValidacaoCNPJ: boolean = false;
  @Input() habilitaValidacaoIE: boolean = false;
  @Output() cnpjAlterado: EventEmitter<string> = new EventEmitter<string>();
  @Output() clienteSelecionado: EventEmitter<Cliente> = new EventEmitter<Cliente>();
  @Output() clienteNaoEncontrado: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  get CNPJs() {
    return this.cnpjsSelecionados;
  }
  set CNPJs(val) {
    this.cnpjsSelecionados = val;
    //this.cnpjAlterado.emit(this.cnpjsSelecionados.join(";"));
  }

  @Input()
  get CNPJFiltro() {
    return this.CNPJDigitado;
  }
  set CNPJFiltro(val) {
    this.CNPJDigitado = val;
    //this.cnpjAlterado.emit(this.CNPJDigitado);
  }

  CNPJDigitado: string;
  cnpjsSelecionados: any[];
  cnpjs: UsuarioCNPJ[];

  mySettings: IMultiSelectSettings = {
    pullRight: false,
    enableSearch: true,
    checkedStyle: 'glyphicon',
    buttonClasses: 'btn btn-default btn-secondary',
    selectionLimit: 0,
    closeOnSelect: false,
    autoUnselect: false,
    showCheckAll: true,
    showUncheckAll: true,
    fixedTitle: false,
    dynamicTitleMaxItems: 1,
    maxHeight: '300px',
  };

  myTexts: IMultiSelectTexts = {
    checkAll: 'Selecionar Todos',
    uncheckAll: 'Desmarcar Todos',
    checked: 'item selecionado',
    checkedPlural: 'itens selecionados',
    searchPlaceholder: 'Pesquisar',
    defaultTitle: 'CNPJ...',
    allSelected: 'Todos selecionados',
  };

  myOptions: IMultiSelectOption[] = [];

  ngOnInit() {
    if (this._appService.UsuarioLogado == null || this._appService.UsuarioLogado == undefined) {
      this._baseService.handleError({ status: 401});
      return;
    }

    // Obtem Cód. ASC Conforme String Retornada "T" = 84 (Administrador)
    this.isUsuarioAdmin = (this._appService.UsuarioLogado.perfilUsuario.toString().charCodeAt(0) == PerfilUsuario.Administrador);

    if (!this.isUsuarioAdmin){

      if (!this.pesquisaEspecial) 
        this.ObterCNPJs();
      else
        this.ObterCNPJsFiltrados();

      this.SelecionaCliente();
    }
    else
      this.cnpjsGrupos = localStorage.getItem('cnpjGrupos');

    this._grupoService.grupoEconomicoSelecionadoEvent.subscribe((cnpjs: string) => {
        this.cnpjsGrupos = cnpjs;
    });
  }

  private SelecionaCliente() {
    this.MaisDe1Cnpj = (this._appService.UsuarioLogado.cnpjs.length > 1);
    if (this._appService.UsuarioLogado.cnpjs.length == 1){
      this.cnpjsSelecionados = this._appService.UsuarioLogado.cnpjs;
      this.inscrEstadualSelecionada = this._appService.UsuarioLogado.inscricaoEstadual;

      this.filtroCliente.cnpj = this._appService.UsuarioLogado.cnpjs[0];
      this.filtroCliente.inscricaoEstadual  = this._appService.UsuarioLogado.inscricaoEstadual;
      this.ObterClientes();
    }
  }

  private ObterCNPJs() {
    this.loading = true;

    var ordenados: string[] = this._appService.UsuarioLogado.cnpjs.sort((n1, n2) => {
        if (n1 > n2) 
            return 1;
        if (n1 < n2) 
            return -1;
        return 0;
    });
    this.myOptions = [];
    ordenados.forEach(cnpj => {
      if (this.myOptions.filter(f => f.id == cnpj).length == 0)
        this.myOptions.push({ id: cnpj, name: cnpj });
    });
    this.loading = false;
  }

  private ObterCNPJsFiltrados() {
    this.loadingCNPJs = true;

    this._sharedService.getClientes(this.filtroCliente, this.pesquisaEspecial)
      .subscribe(lista => {
        
        this.clientes = this._genericOrderbyService.ordenarPorCampo(lista, 'CNPJ');
        
        this.MaisDe1Cnpj = (lista.length > 1);
        this.myOptions = [];
        this.clientes.forEach(c => {
          if (this.myOptions.filter(x => x.id == c.cnpj).length == 0)
            this.myOptions.push({ id: c.cnpj, name: c.cnpj });
        });

        if ((lista != undefined) && (lista.length == 1)) {
            this.inscrEstadualSelecionada = lista[0].inscricaoEstadual;
            this.razaoSocial = lista[0].nome;
            this.clienteSelecionado.emit(lista[0]);
        }
        else {
            this.inscrEstadualSelecionada = '';
            this.razaoSocial = '';
            this.clienteSelecionado.emit(null);
        }

        this.loadingCNPJs = false;
    });
  }

  public ObterClientes() {
    if (this.clientes != undefined && this.pesquisaEspecial && !this.isUsuarioAdmin) {
      this.clientesInscricoes = this.clientes.filter(c => c.cnpj == this.filtroCliente.cnpj);

      if (this.filtroCliente.inscricaoEstadual)
        this.clientesInscricoes = this.clientesInscricoes.filter(c => c.inscricaoEstadual == this.filtroCliente.inscricaoEstadual);

      if ((this.clientesInscricoes != undefined) && (this.clientesInscricoes.length == 1)) {
        this.inscrEstadualSelecionada = this.clientesInscricoes[0].inscricaoEstadual;
        this.razaoSocial = this.clientesInscricoes[0].nome;
        this.clienteSelecionado.emit(this.clientesInscricoes[0]);
      }
      else {
          this.inscrEstadualSelecionada = '';
          this.razaoSocial = '';
          this.clienteSelecionado.emit(null);
      }
    }
    else {
      this.loadingClientes = true;
      var cnpjSemFormatacao = this._sharedService.replaceAll(this.filtroCliente.cnpj, '.', '');
      var cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '/', '');
      var cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '-', '');
      this.filtroCliente.cnpj = cnpjSemFormatacao;
      
      this._sharedService.getClientes(this.filtroCliente, this.pesquisaEspecial)
        .subscribe(c => {
          if (c == null){
            //Não há CNPJs para este cliente ou não há propostas ativas para nenhum dos CNPJs do cliente.
            this.clienteNaoEncontrado.emit('Não há CNPJs para este cliente ou não há propostas ativas para nenhum dos CNPJs do cliente.');
            return;
          }
          
          this.clientes = c;

          //if (this.isUsuarioAdmin)
            this.clientes = c.filter(c => c.cnpj == cnpjSemFormatacao);
          //this.clientesInscricoes = c;
          this.clientesInscricoes = this.clientes.filter(c => c.cnpj == cnpjSemFormatacao);
          if ((this.clientesInscricoes != undefined) && (this.clientesInscricoes.length == 1)) {
              this.inscrEstadualSelecionada = this.clientesInscricoes[0].inscricaoEstadual;
              this.razaoSocial = this.clientesInscricoes[0].nome;
              this.clienteSelecionado.emit(this.clientesInscricoes[0]);
          }
          else {
              this.inscrEstadualSelecionada = '';
              this.razaoSocial = '';
              this.clienteSelecionado.emit(null);
          }

          this.loadingClientes = false;
        });
    }
  }

  public FiltraCliente() {
    if (this.CNPJFiltro != undefined && this.CNPJFiltro != '')
      this.filtroCliente.cnpj = this.CNPJFiltro;
    else 
      if (this.cnpjsSelecionados != undefined)
        this.filtroCliente.cnpj = (this.cnpjsSelecionados instanceof Array) ? this.cnpjsSelecionados.join(';') : this.cnpjsSelecionados;

    this.filtroCliente.inscricaoEstadual = '';

    this.ObterClientes();
  }

  private ExibeNomeCliente(ie: string) {
    this.filtroCliente.inscricaoEstadual = ie;
    var cli: Cliente = this.clientes.find( c => c.cnpj == this.filtroCliente.cnpj && c.inscricaoEstadual == this.filtroCliente.inscricaoEstadual);

    if (cli != undefined) {
      this.razaoSocial = cli.nome;
      this.clienteSelecionado.emit(cli);
    }
    else {
      this.razaoSocial = '';
      this.clienteSelecionado.emit(null);
    }
  }

  private blurCnpjAlterado(){
    this.cnpjAlterado.emit(this.CNPJFiltro);
  }

  validaCNPJnoGrupo(): boolean {
    this.cnpjsGrupos = localStorage.getItem('cnpjGrupos');

    if (!this.CNPJDigitado || !this.cnpjsGrupos)
      return true;

    var cnpjSemFormatacao = "";
    cnpjSemFormatacao = this._sharedService.replaceAll(this.CNPJDigitado, '.', '');
    cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '/', '');
    cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '-', '');

    return (this.cnpjsGrupos.indexOf(cnpjSemFormatacao) >= 0);
  }

}
