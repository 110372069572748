import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from 'app/shared/shared.module';
import { DatePickerModule } from "app/shared/ng2-datepicker/ng2-datepicker.module";
import { MultiselectDropdownModule } from './../../shared/multiselect-dropdown/multiselect-dropdown.module';
import { ProvedorModule } from 'app/provedor/provedor.module';

import { DatepickerService } from 'app/_services/datepicker.service';
import { MessageService } from 'app/_services/message.service';

import { VincularNfComponent } from './vincular-nf.component';
import { ChaveNfeComponent } from './chave-nfe/chave-nfe.component';
import { ChaveCteComponent } from './chave-cte/chave-cte.component';
import { VincularNfRoutingModule } from './vincular-nf.routing.module';
import { GroupbyService } from 'app/_services/groupby.service';
import { OrdemServicoService } from 'app/_services/ordem-servico.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DatePickerModule,
        MultiselectDropdownModule,
        ModalModule.forRoot(),
        SharedModule,
        ProvedorModule,
        VincularNfRoutingModule
    ],
    declarations: [
        VincularNfComponent,
        ChaveNfeComponent,
        ChaveCteComponent
    ],
    exports: [
        SharedModule,
        ProvedorModule,
        ChaveNfeComponent,
        ChaveCteComponent
    ],
    providers: [
        OrdemServicoService,
        DatepickerService,
        MessageService,
        GroupbyService
    ]
})

export class VincularNfModule { }
