import { ResponsavelDesova } from './responsavelDesova';
import { ResponsaveisDesova } from 'app/_enums/responsaveisDesova.enum';
import { ResponsavelOva } from './responsavelOva';
import { ResponsaveisOva } from 'app/_enums/responsaveisOva.enum';
import { TipoContainer } from "app/_models/tipo-container";
import { ArquivoUpload } from './arquivo-upload';
import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root'
})
export class Cotacao {

    idCotacao: number = 0;
    idUsuario: number = 0;
    idTrecho: number = 0;
    numCotacao: number = 0;
    qtdeContainer: number = 0;
    cargaOverAltura: number = 0;
    cargaOverEsquerda: number = 0;
    cargaOverDireita: number = 0;
    cargaOverFrontal: number = 0;
    cargaOverTraseiro: number = 0;
    tempCargaRefrigerada: number = 0;
    tempoHrEstimadodesova: number = 0;
    tempoHrEstimadoOva: number = 0;
    qtdeAjudanteOva: number = 0;
    tamanhoContainer: number = 0;
    idCategoriaContainer: number = 0;
    qtdeAjudantedesova: number = 0;
    valEquipeServEspDes: number = 0;
    idPortoDes: number = 0;
    idMunicEntrega: number = 0;
    idPortoOri: number = 0;
    idMunicColeta: number = 0;
    idFamiliaProduto: number = 0;
    idMunicDes: number = 0;
    idMunicOri: number = 0;
    numProposta: number = 0;

    // valEquipeIndicaOri: number = 0;
    // valEquipeIndicaDes: number = 0;
    metrosCubicosDes: number = 0;
    valMercadoria: number = 0;
    valEquipeServEspOri: number = 0;
    metrosCubicosOri: number = 0;
    valCustoPrecificacaoOri: number = 0;
    valCustoPrecificacaoDes: number = 0;
    pesoContainer: number = 0;
    valFreteOTM: number = 0;

    cnpjCli: string = '';
    ieCli: string = '';
    nomeCli: string = '';
    nomeFantasiaCli: string = '';
    tipo: string = '';
    periodicidade: string = '';
    modal: string = '';
    descCategoriaContainer: string = '';
    responsavelEstufagem: string = '';
    materialEspecialOva: string = '';
    tipoEmbalagemCarga: string = '';
    respEstufagemDes: string = '';
    materialEspecialDesova: string = '';
    descMaterialPeacao: string = '';
    nomePortoDestino: string = '';
    municEntrega: string = '';
    ufPortoDestino: string = '';
    ufEntrega: string = '';
    nomePortoOrigem: string = '';
    ufPortoOrigem: string = '';
    municColeta: string = '';
    ufColeta: string = '';
    descFamiliaProduto: string = '';
    numeroUNO: string = '';
    classe: string = '';
    sequencia: string = '';
    properShippingName: string = '';
    obsCargaPerigosa: string = '';
    obsCargaRefrigerada: string = '';
    obsCargaOver: string = '';
    obsGeral: string = '';
    tipoOperacaoComercial: string = '';
    statusCotacao: string = '';
    obsComplementar: string = '';
    obsEcargo: string = '';
    dhUltimaAltProposta: string = '';
    seguro: string = '';
    veiculoEspecialDes: string = '';
    veiculoEspecialOri: string = '';
    outrosProdutos: string = '';
    strValFreteOTM: string = '';

    tipoContainer: string = '';
    categoriaContainer: string = '';
    municOri: string = '';
    ufMunicOri: string = '';
    portoOri: string = '';
    ufPortoOri: string = '';
    municDes: string = '';
    ufMunicDes: string = '';
    portoDes: string = '';
    ufPortoDes: string = '';

    flagEquipeIndicaCliOri: string = '';
    flagEquipeIndicaCliDes: string = '';
    flagEquipePropriaOri: string = '';
    flagEquipePropriaDes: string = '';
    flagConferenteOri: string = '';
    flagConferenteDes: string = '';
    flagEstruturaOvaContainer: string = '';
    flagShipperOwnContainer: string = '';
    flagOvaTerminal: string = '';
    //flagEquipeClienteOva: string = '';
    flagDesovaTerminal: string = '';
    //flagEquipeClienteDesova: string = '';
    flagMaterialPeacao: string = '';
    flagCargaRefrigerada: string = '';
    flagCargaOver: string = '';
    flagCargaPerigosa: string = '';
    flagEstruturaDesovaContainer: string = '';
    flagOvaDesova: string = '';
    flagConferente: string = '';
    flagGensetOri: string = '';
    flagGensetDes: string = '';

    flagMaterialEspecialDesova: string = '';
    flagMaterialEspecialOva: string = '';

    valEquipeIndicaOri: string = '';
    valEquipeIndicaDes: string = '';
    flagEquipeClienteOva: string = '';
    flagEstufagemTerminal: string = '';
    flagOvaEmbarcador: string = '';
    flagDesovaRecebedor: string = '';
    flagEscoltaOri: string = '';
    flagEscoltaDes: string = '';
    adValorem: number = 0;

    status: string = '';
    retorno: string = '';
    loading: boolean;

    tipoDeContainer: TipoContainer;
    detalheDaCotacao: Cotacao;
    Arquivos: ArquivoUpload[];

    //-------------------------
    //DAQUI PRA BAIXO SÃO PROPRIEDADES/FUNÇÕES CUSTOMIZADAS
    private _oriPorto: boolean = true;
    get oriPorto(): boolean {
        if ((this.modal == undefined) || (this.modal == "") || (this.modal.startsWith('/')))
            return undefined;
        this._oriPorto = this.modal.startsWith("PO");

        return this._oriPorto;
    }
    set oriPorto(value: boolean) {
        this._oriPorto = value;
        this.atualizaModal('ori');
    }

    private _desPorto: boolean = true;
    get desPorto(): boolean {
        if ((this.modal == undefined) || (this.modal == "") || (this.modal.endsWith('/')))
            return undefined;

        this._desPorto = this.modal.endsWith("PO");
        return this._desPorto;
    }
    set desPorto(value: boolean) {
        this._desPorto = value;
        this.atualizaModal('des');
    }

    get escoltaOrigem(): boolean{
        return this.flagEscoltaOri == 'S';
    }
    set escoltaOrigem(value:boolean){
        this.flagEscoltaOri = value ? 'S' : 'N';
    }

    get escoltaDestino(): boolean{
        return this.flagEscoltaDes == 'S';
    }
    set escoltaDestino(value:boolean){
        this.flagEscoltaDes = value ? 'S' : 'N';
    }

    private atualizaModal(tipo: string) {
        //PO/PO
        if (tipo == 'ori') {
            if (this.desPorto != undefined) {
                this.modal = (this._oriPorto ? "PO" : "PA") + "/" + (this._desPorto ? "PO" : "PA");
            }
            else
                this.modal = (this._oriPorto ? "PO" : "PA") + "/";
        }
        else {
            if (this.oriPorto != undefined)
                this.modal = (this._oriPorto ? "PO" : "PA") + "/" + (this._desPorto ? "PO" : "PA");
            else
                this.modal = "/" + (this._desPorto ? "PO" : "PA");
        }

        if ((this.modal != undefined) && (this.modal != ""))
            this.modal = this.modal.replace("//", "/");

    }

    private _cargaPerigosa: boolean = false;
    get cargaPerigosa(): boolean {
        if ((this.flagCargaPerigosa == undefined) || (this.flagCargaPerigosa == ""))
            return undefined;
        this._cargaPerigosa = this.flagCargaPerigosa == 'S';
        return this._cargaPerigosa;
    }
    set cargaPerigosa(value: boolean) {
        this._cargaPerigosa = value;
        this.flagCargaPerigosa = this._cargaPerigosa ? 'S' : 'N';
    }

    private _cargaRefrigerada: boolean = false;
    get cargaRefrigerada(): boolean {
        if ((this.flagCargaRefrigerada == undefined) || (this.flagCargaRefrigerada == ""))
            return undefined;
        this._cargaRefrigerada = this.flagCargaRefrigerada == 'S';
        return this._cargaRefrigerada;
    }
    set cargaRefrigerada(value: boolean) {
        this._cargaRefrigerada = value;
        this.flagCargaRefrigerada = this._cargaRefrigerada ? 'S' : 'N';
    }

    private _cargaOver: boolean = false;
    get cargaOver(): boolean {
        if ((this.flagCargaOver == undefined) || (this.flagCargaOver == ""))
            return undefined;
        this._cargaOver = this.flagCargaOver == 'S';
        return this._cargaOver;
    }
    set cargaOver(value: boolean) {
        this._cargaOver = value;
        this.flagCargaOver = this._cargaOver ? 'S' : 'N';
    }

    private _estruturaOvaContainer: boolean = false;
    get estruturaOvaContainer(): boolean {
        if ((this.flagEstruturaOvaContainer == undefined) || (this.flagEstruturaOvaContainer == ""))
            return undefined;
        this._estruturaOvaContainer = this.flagEstruturaOvaContainer == 'S';
        return this._estruturaOvaContainer;
    }
    set estruturaOvaContainer(value: boolean) {
        this._estruturaOvaContainer = value;
        this.flagEstruturaOvaContainer = this._estruturaOvaContainer ? 'S' : 'N';
    }

    private _ovaTerminal: boolean = false;
    get ovaTerminal(): boolean {
        if ((this.flagOvaTerminal == undefined) || (this.flagOvaTerminal == ''))
            return undefined;
        this._ovaTerminal = this.flagOvaTerminal == 'S';
        return this._ovaTerminal;
    }
    set ovaTerminal(value: boolean) {
        this._ovaTerminal = value;
        this.flagOvaTerminal = this._ovaTerminal ? 'S' : 'N';
    }

    private _materialPeacao: boolean = false;
    get materialPeacao(): boolean {
        if ((this.flagMaterialPeacao == undefined) || (this.flagMaterialPeacao == ''))
            return undefined;
        this._materialPeacao = this.flagMaterialPeacao == 'S';
        return this._materialPeacao;
    }
    set materialPeacao(value: boolean) {
        this._materialPeacao = value;
        this.flagMaterialPeacao = this._materialPeacao ? 'S' : 'N';
    }

    private _equipeIndicaCliOri: boolean = false;
    get equipeIndicaCliOri(): boolean {
        if ((this.flagEquipeIndicaCliOri == undefined) || (this.flagEquipeIndicaCliOri == '' || 'SN'.indexOf(this.flagEquipeIndicaCliOri) === -1))
            return undefined;
        this._equipeIndicaCliOri = this.flagEquipeIndicaCliOri == 'S';
        return this._equipeIndicaCliOri;
    }
    set equipeIndicaCliOri(value: boolean) {
        this._equipeIndicaCliOri = value;
        this.flagEquipeIndicaCliOri = this._equipeIndicaCliOri ? 'S' : 'N';
    }

    private _estruturaDesovaContainer: boolean = false;
    get estruturaDesovaContainer(): boolean {
        if ((this.flagEstruturaDesovaContainer == undefined) || (this.flagEstruturaDesovaContainer == '' || 'SN'.indexOf(this.flagEstruturaDesovaContainer) === -1))
            return undefined;
        this._estruturaDesovaContainer = this.flagEstruturaDesovaContainer == 'S';
        return this._estruturaDesovaContainer;
    }
    set estruturaDesovaContainer(value: boolean) {
        this._estruturaDesovaContainer = value;
        this.flagEstruturaDesovaContainer = this._estruturaDesovaContainer ? 'S' : 'N';
    }

    private _desovaTerminal: boolean = false;
    get desovaTerminal(): boolean {
        if ((this.flagDesovaTerminal == undefined) || (this.flagDesovaTerminal == '' || 'SN'.indexOf(this.flagDesovaTerminal) === -1))
            return undefined;
        this._desovaTerminal = this.flagDesovaTerminal == 'S';
        return this._desovaTerminal;
    }
    set desovaTerminal(value: boolean) {
        this._desovaTerminal = value;
        this.flagDesovaTerminal = this._desovaTerminal ? 'S' : 'N';
    }

    private _materialEspecialOva: boolean = false;
    get materialEspecialOvaFlag(): boolean {
        if ((this.flagMaterialEspecialOva == undefined) || (this.flagMaterialEspecialOva == ''))
            return undefined;
        this._materialEspecialOva = this.flagMaterialEspecialOva == 'S';
        return this._materialEspecialOva;
    }
    set materialEspecialOvaFlag(value: boolean) {
        this._materialEspecialOva = value;
        this.flagMaterialEspecialOva = this._materialEspecialOva ? 'S' : 'N';
    }

    private _materialEspecialDesova: boolean = false;
    get materialEspecialDesovaFlag(): boolean {
        if ((this.flagMaterialEspecialDesova == undefined) || (this.flagMaterialEspecialDesova == ''))
            return undefined;
        this._materialEspecialDesova = this.flagMaterialEspecialDesova == 'S';
        return this._materialEspecialDesova;
    }
    set materialEspecialDesovaFlag(value: boolean) {
        this._materialEspecialDesova = value;
        this.flagMaterialEspecialDesova = this._materialEspecialDesova ? 'S' : 'N';
    }

    private _equipeIndicaCliDes: boolean = false;
    get equipeIndicaCliDes(): boolean {
        if ((this.flagEquipeIndicaCliDes == undefined) || (this.flagEquipeIndicaCliDes == ''))
            return undefined;
        this._equipeIndicaCliDes = this.flagEquipeIndicaCliDes == 'S';
        return this._equipeIndicaCliDes;
    }
    set equipeIndicaCliDes(value: boolean) {
        this._equipeIndicaCliDes = value;
        this.flagEquipeIndicaCliDes = this._equipeIndicaCliDes ? 'S' : 'N';
    }

    private _conferenteOri: boolean = false;
    get conferenteOri(): boolean {
        if ((this.flagConferenteOri == undefined) || (this.flagConferenteOri == ''))
            return undefined;
        this._conferenteOri = this.flagConferenteOri == 'S';
        return this._conferenteOri;
    }
    set conferenteOri(value: boolean) {
        this._conferenteOri = value;
        this.flagConferenteOri = this._conferenteOri ? 'S' : 'N';
    }

    private _conferenteDes: boolean = false;
    get conferenteDes(): boolean {
        if ((this.flagConferenteDes == undefined) || (this.flagConferenteDes == ''))
            return undefined;
        this._conferenteDes = this.flagConferenteDes == 'S';
        return this._conferenteDes;
    }
    set conferenteDes(value: boolean) {
        this._conferenteDes = value;
        this.flagConferenteDes = this._conferenteDes ? 'S' : 'N';
    }

    private _gensetOri: boolean = false;
    get gensetOri(): boolean {
        if (this.flagGensetOri == undefined) 
            return undefined;
        this._gensetOri = this.flagGensetOri == 'S';
        return this._gensetOri;
    }
    set gensetOri(value: boolean) {
        this._gensetOri = value;
        this.flagGensetOri = this._gensetOri ? 'S' : 'N';
    }

    private _gensetDes: boolean = false;
    get gensetDes(): boolean {
        if (this.flagGensetDes == undefined) 
            return undefined;
        this._gensetDes = this.flagGensetDes == 'S';
        return this._gensetDes;
    }
    set gensetDes(value: boolean) {
        this._gensetDes = value;
        this.flagGensetDes = this._gensetDes ? 'S' : 'N';
    }

    private _shipper: boolean = false;
    get shipperOwnContainer(): boolean {
        if ((this.flagShipperOwnContainer == undefined) || (this.flagShipperOwnContainer == '' || 'SN'.indexOf(this.flagShipperOwnContainer) === -1))
            return undefined;
        this._shipper = this.flagShipperOwnContainer == 'S';
        return this._shipper;
    }
    set shipperOwnContainer(value: boolean) {
        this._shipper = value;
        this.flagShipperOwnContainer = this._shipper ? 'S' : 'N';
    }

    public responsaveisOva: ResponsavelOva[] = new Array();
    public responsaveisDesova: ResponsavelDesova[] = new Array();

    get respOva(): ResponsavelOva {
        return this.responsaveisOva.find(x => x.descricao == this.responsavelEstufagem);
    }
    set respOva(value: ResponsavelOva) {
        this.responsavelEstufagem = this.responsaveisOva.find(x => x.codigo == value.codigo).descricao;
    }

    get respDesova(): ResponsavelDesova {
        return this.responsaveisDesova.find(x => x.descricao == this.respEstufagemDes);
    }
    set respDesova(value: ResponsavelDesova) {
        this.respEstufagemDes = this.responsaveisDesova.find(x => x.codigo == value.codigo).descricao;
    }

    private _flagEmbalagemEOutros: boolean = undefined;
    get flagEmbalagemEOutros(): boolean {
        if (this.tipoEmbalagemCarga == undefined)
            return undefined;

        return this._flagEmbalagemEOutros;
    }
    set flagEmbalagemEOutros(value: boolean) {
        this._flagEmbalagemEOutros = (this.tipoEmbalagemCarga == 'Outros');
    }

    get embalagem(): string {
        return this.tipoEmbalagemCarga;
    }
    set embalagem(value: string) {
        if (value == 'Outros') {
            //if (!this._flagEmbalagemEOutros)
            if (!this.flagEmbalagemEOutros && this.flagEmbalagemEOutros != undefined)
                this.tipoEmbalagemCarga = '';

            this._flagEmbalagemEOutros = true;
        }
        else {
            this.tipoEmbalagemCarga = value;
            this._flagEmbalagemEOutros = false;
        }
    }

    private _flagVeiculoOriEOutros: boolean = undefined;
    get flagVeiculoOriEOutros(): boolean {
        if (this.veiculoEspecialOri == undefined)
            return undefined;

        return this._flagVeiculoOriEOutros;
    }
    set flagVeiculoOriEOutros(value: boolean) {
        this._flagVeiculoOriEOutros = (this.veiculoEspecialOri == 'Outros');
    }

    get veiculoOrigem(): string {
        return this.veiculoEspecialOri;
    }
    set veiculoOrigem(value: string) {
        if (value == 'Outros') {
            if (!this.flagVeiculoOriEOutros && this.flagVeiculoOriEOutros != undefined)
                this.veiculoEspecialOri = '';

            this._flagVeiculoOriEOutros = true;
        }
        else {
            this.veiculoEspecialOri = value;
            this._flagVeiculoOriEOutros = false;
        }
    }

    private _flagVeiculoDesEOutros: boolean = undefined;
    get flagVeiculoDesEOutros(): boolean {
        if (this.veiculoEspecialDes == undefined)
            return undefined;

        return this._flagVeiculoDesEOutros;
    }
    set flagVeiculoDesEOutros(value: boolean) {
        this._flagVeiculoDesEOutros = (this.veiculoEspecialDes == 'Outros');
    }

    get veiculoDestino(): string {
        return this.veiculoEspecialDes;
    }
    set veiculoDestino(value: string) {
        if (value == 'Outros') {
            if (!this.flagVeiculoDesEOutros && this.flagVeiculoDesEOutros != undefined)
                this.veiculoEspecialDes = '';

            this._flagVeiculoDesEOutros = true;
        }
        else {
            this.veiculoEspecialDes = value;
            this._flagVeiculoDesEOutros = false;
        }
    }

    setFlagsEnvio() {
        if (this.flagEquipeIndicaCliOri == '' || 'SN'.indexOf(this.flagEquipeIndicaCliOri) === -1)
            this.flagEquipeIndicaCliOri = (this.equipeIndicaCliOri == undefined) ? 'N' : (!this.equipeIndicaCliOri) ? 'N' : 'S';

        if (this.flagEquipeIndicaCliDes == '' || 'SN'.indexOf(this.flagEquipeIndicaCliDes) === -1)
            this.flagEquipeIndicaCliDes = (this.equipeIndicaCliDes == undefined) ? 'N' : (!this.equipeIndicaCliDes) ? 'N' : 'S';

        if (this.flagConferenteOri == '' || 'SN'.indexOf(this.flagConferenteOri) === -1)
            this.flagConferenteOri = (this.conferenteOri == undefined) ? 'N' : (!this.conferenteOri) ? 'N' : 'S';

        if (this.flagConferenteDes == '' || 'SN'.indexOf(this.flagConferenteDes) === -1)
            this.flagConferenteDes = (this.conferenteDes == undefined) ? 'N' : (!this.conferenteDes) ? 'N' : 'S';

        if (this.flagEstruturaOvaContainer == '' || 'SN'.indexOf(this.flagEstruturaOvaContainer) === -1)
            this.flagEstruturaOvaContainer = (this.estruturaOvaContainer == undefined) ? 'N' : (!this.estruturaOvaContainer) ? 'N' : 'S';

        if (this.flagEstruturaDesovaContainer == '' || 'SN'.indexOf(this.flagEstruturaDesovaContainer) === -1)
            this.flagEstruturaDesovaContainer = (this.estruturaDesovaContainer == undefined) ? 'N' : (!this.estruturaDesovaContainer) ? 'N' : 'S';

        if (this.flagShipperOwnContainer == '' || 'SN'.indexOf(this.flagShipperOwnContainer) === -1)
            this.flagShipperOwnContainer = (this.shipperOwnContainer == undefined) ? 'N' : (!this.shipperOwnContainer) ? 'N' : 'S';

        if (this.flagOvaTerminal == '' || 'SN'.indexOf(this.flagOvaTerminal) === -1)
            this.flagOvaTerminal = (this.ovaTerminal == undefined) ? 'N' : (!this.ovaTerminal) ? 'N' : 'S';

        if (this.flagDesovaTerminal == '' || 'SN'.indexOf(this.flagDesovaTerminal) === -1)
            this.flagDesovaTerminal = (this.desovaTerminal == undefined) ? 'N' : (!this.desovaTerminal) ? 'N' : 'S';

        if (this.flagMaterialPeacao == '' || 'SN'.indexOf(this.flagMaterialPeacao) === -1)
            this.flagMaterialPeacao = (this.materialPeacao == undefined) ? 'N' : (!this.materialPeacao) ? 'N' : 'S';

        if (this.flagCargaRefrigerada == '' || 'SN'.indexOf(this.flagCargaRefrigerada) === -1)
            this.flagCargaRefrigerada = (this.cargaRefrigerada == undefined) ? 'N' : (!this.cargaRefrigerada) ? 'N' : 'S';

        if (this.flagCargaOver == '' || 'SN'.indexOf(this.flagCargaOver) === -1)
            this.flagCargaOver = (this.cargaOver == undefined) ? 'N' : (!this.cargaOver) ? 'N' : 'S';

        if (this.flagCargaPerigosa == '' || 'SN'.indexOf(this.flagCargaPerigosa) === -1)
            this.flagCargaPerigosa = (this.cargaPerigosa == undefined) ? 'N' : (!this.cargaPerigosa) ? 'N' : 'S';

        if (this.flagGensetOri == '' || 'SN'.indexOf(this.flagGensetOri) === -1)
            this.flagGensetOri = (this.gensetOri == undefined) ? 'N' : (!this.gensetOri) ? 'N' : 'S';

        if (this.flagGensetDes == '' || 'SN'.indexOf(this.flagGensetDes) === -1)
            this.flagGensetDes = (this.gensetDes == undefined) ? 'N' : (!this.gensetDes) ? 'N' : 'S';

        if (this.flagMaterialEspecialDesova == '' || 'SN'.indexOf(this.flagMaterialEspecialDesova) === -1)
            this.flagMaterialEspecialDesova = (this.materialEspecialOvaFlag == undefined) ? 'N' : (!this.materialEspecialOvaFlag) ? 'N' : 'S';

        if (this.flagMaterialEspecialOva == '' || 'SN'.indexOf(this.flagMaterialEspecialOva) === -1)
            this.flagMaterialEspecialOva = (this.materialEspecialOvaFlag == undefined) ? 'N' : (!this.materialEspecialOvaFlag) ? 'N' : 'S';
    }
}
