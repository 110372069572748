import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Perfil } from './../../../_models/perfil';
import { Usuario } from './../../../_models/usuario';
import { Municipio } from './../../../_models/municipio';
import { UsuarioCNPJ } from './../../../_models/usuario-cnpj';
import { Funcionalidade } from './../../../_models/funcionalidade';

import { Operacao } from 'app/_enums/operacao';
import { PerfilUsuario } from "app/_enums/perfil-usuario.enum";
import { StatusUsuario } from 'app/_enums/status-usuario.enum';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

import { SharedService } from 'app/_services/shared.service';
import { PerfilService } from './../../../_services/perfil.service';
import { UsuarioService } from './../../../_services/usuario.service';
import { MessageService } from './../../../_services/message.service';

import { BaseComponent } from './../../../shared/base.component';
import { MessageComponent } from './../../../shared/message/message.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-usuario-detalhe',
  templateUrl: './usuario-detalhe.component.html',
  styleUrls: ['./usuario-detalhe.component.scss']
})

export class UsuarioDetalheComponent extends BaseComponent implements OnInit {

  inscricaoGet: Subscription;
  inscricaoFuncs: Subscription;
  inscricaoPost: Subscription;

  public titulo: string = 'Novo Usuario de Acesso';
  public desabilitado: boolean = true;
  public loading: boolean = false;
  public loadingMunicipios: boolean = false;
  public usuario: Usuario = new Usuario();
  public mostraAprovacao: boolean = true;
  public id: number = 0;
  public perfis: Perfil[];
  public cliente: boolean = false;
  public provedor: boolean = false;
  public administrador: boolean = false;
  public municipio: Municipio;
  public municipios: Municipio[] = new Array();
  public novoStatus: StatusUsuario;
  public funcionalidadesDoPerfil: Funcionalidade[] = [];
  public perfisBasico: string[] = [];

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  constructor(
    private _route: ActivatedRoute,
    private _usuarioService: UsuarioService,
    private _msgService: MessageService,
    private _sharedService: SharedService,
    private _router: Router,
    private _perfilService: PerfilService,
    private _sanitizer: DomSanitizer) {

    super();
    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  autocompleListFormatter = (data: any) => {
    let html = `<span>${data.descricaoCompleta}/${data.uf}</span>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
    //return html;
  }

  ngOnInit(): void {
    this.ObterMunicipios();
    this.inscricaoGet = this._route.params.subscribe(
      (params: any) => {
        this.id = params['id'];
        if (this.id) {
          this.titulo = 'Editar Usuário';

          this.desabilitado = true;
          this.loading = true;
          this._usuarioService.getUsuario(this.id)
            .subscribe(usuario => {
              this.usuario = usuario;

              if (this.municipios) {
                this.municipio = this.municipios.find(f => f.codigo.toString() == usuario.municipio);
              }

              this.loading = false;
              this.novoStatus = (usuario.status == StatusUsuario.AguardandoAprovacao) ? StatusUsuario.Aprovado : usuario.status;
              this.AddNovoCNPJ();
              this.cliente = (usuario.perfilUsuario == PerfilUsuario.Ambos || usuario.perfilUsuario == PerfilUsuario.Cliente);
              this.provedor = (usuario.perfilUsuario == PerfilUsuario.Ambos || usuario.perfilUsuario == PerfilUsuario.Provedor);
              this.administrador = usuario.perfilUsuario == PerfilUsuario.Administrador;
              this.getFuncionalidadePorPerfil(usuario.perfilID);
            });
        }
        else {
          this.desabilitado = false;
          this.titulo = 'Novo Usuário';
          this.usuario = new Usuario();
          this.usuario.status = StatusUsuario.AguardandoAprovacao;
          this.novoStatus = StatusUsuario.Aprovado;
          this.AddNovoCNPJ();
        }
      }
    );

    this._perfilService.getPerfisAtivos()
      .subscribe(lista => {
        this.perfis = lista;
      });
  }

  public ObterMunicipios() {
    this.loadingMunicipios = true;
    this._sharedService.getMunicipios()
      .subscribe(m => {
        this.municipios = m;

        if (this.usuario) {
          this.municipio = m.find(f => f.codigo.toString() == this.usuario.municipio);
        }

        this.loadingMunicipios = false;
      });
  }

  AddNovoCNPJ() {
    if (this.usuario.cnpjs && this.usuario.cnpjs.length > 0) {
      var cnpj = this.usuario.cnpjs[this.usuario.cnpjs.length - 1].cnpj;
      if (!(cnpj.length == 14 || cnpj.length == 8)) {
        this._msgService.addMessage("CNPJ inválido", "Digite um CNPJ válido", "warning", LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, cnpj);
        return;
      }

      if (this.usuario.cnpjs.filter(u => u.cnpj == cnpj).length > 1) {
        this._msgService.addMessage("CNPJ já cadastrado", "O CNPJ " + cnpj + " já foi cadastrado para este usuário", "warning", LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.usuario);
        return;
      }

      this.usuario.cnpjs.forEach(a => a.novo = false);
    }
    else
      this.usuario.cnpjs = [];

    let userCNPJ = new UsuarioCNPJ();
    userCNPJ.cnpj = '';
    userCNPJ.usuarioCNPJID = 0;
    userCNPJ.usuarioID = 0;
    userCNPJ.novo = true;

    this.usuario.cnpjs.push(userCNPJ);
  }

  removeCNPJ(index) {
    this.usuario.cnpjs.splice(index, 1);
  }

  ngOnDestroy() {
    if (this.inscricaoGet)
      this.inscricaoGet.unsubscribe();
    if (this.inscricaoPost)
      this.inscricaoPost.unsubscribe();
    if (this.inscricaoFuncs)
      this.inscricaoFuncs.unsubscribe();
  }

  SalvarUsuario() {
    this._msgService.clearMessage();

    if (!this.cliente && !this.provedor && !this.administrador) {
      this._msgService.addMessage('Usuario', "Informe o perfil e-Cargo!", "error", LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.usuario);
      return;
    }

    if (!this._sharedService.validaTelefone(this.usuario.telefone)) {
      this._msgService.addMessage('Usuario', 'Telefone inválido', 'danger', LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.usuario);
      return;
    }

    if (!this._sharedService.validaCEP(this.usuario.cep)) {
      this._msgService.addMessage('Registre-se', 'CEP inválido', 'danger', LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.usuario);
      return;
    }

    if (!this.municipio || !this.municipio.codigo){
      this._msgService.addMessage('Registre-se', 'Informe o Município', 'danger', LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.municipio);
      return;
    }
    else {
      this.usuario.municipio = this.municipio.codigo.toString();
      this.usuario.uf = this.municipio.uf
    }

    this.loading = true;
    this.usuario.cnpjs = this.usuario.cnpjs.filter(function (itm) { return itm.cnpj != '' });

    if (this.administrador)
      this.usuario.perfilUsuario = PerfilUsuario.Administrador;
    else {
      if (this.cliente && this.provedor)
        this.usuario.perfilUsuario = PerfilUsuario.Ambos;
      if (this.cliente && !this.provedor)
        this.usuario.perfilUsuario = PerfilUsuario.Cliente;
      if (!this.cliente && this.provedor)
        this.usuario.perfilUsuario = PerfilUsuario.Provedor;
    }

    //if (this.usuario.Status != StatusUsuario.Reprovado) {
      //if (this.aprovado) {
        if (this.novoStatus == StatusUsuario.Aprovado) {
        //this.usuario.Status = StatusUsuario.Aprovado;
        if (this.usuario.perfilID == null) {
          this._msgService.addMessage('Usuario', 'Selecione o perfil de acesso', "error", LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.usuario);
          this.loading = false;
          return;
        }
      }
      else {
        //this.usuario.Status = StatusUsuario.Reprovado;
        if (this.usuario.motivoRecusa.trim() == '') {
          this._msgService.addMessage('Usuario', 'Motivo da recusa é obrigatório.', "error", LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.usuario);
          this.loading = false;
          return;
        }
      }
    //}

    this.usuario.status = this.novoStatus;
    //this.usuario.PerfilUsuario = this.getComposicaoPerfil(this.usuario.PerfilID);

    this.usuario.email = (!this.usuario.email) ? this.usuario.email : this.usuario.email.toLowerCase();

    this._usuarioService.salvarUsuario(this.usuario)
      .subscribe(result => {
        this.loading = false;
        if (result.IsValid) {
          this._msgService.addMessageRedirect('Usuario', result.Message, "success");
          this._router.navigate(['usuario']);
        }
        else {
          this.AddNovoCNPJ();

          var msgErr: string[] = [];
          result.Erros.forEach(element => msgErr.push(element.Message));
          this._msgService.addMessageArray("Usuario", msgErr, "error", LogFrontFuncionalidade.Usuarios, Operacao.Alteracao, this.usuario);
          this.loading = false;
          //    this._router.events.filter(event => event instanceof NavigationEnd).subscribe(() => {
          //          window.scrollTo(0, 0);
          //    });
        }
      });
  }

  AtualizaStatus(value) {
    switch (this.usuario.status) {
      case StatusUsuario.AguardandoAprovacao:
          this.novoStatus = value ? StatusUsuario.Aprovado : StatusUsuario.Reprovado; // aprovar
      break;
      case StatusUsuario.Aprovado:
          this.novoStatus = value ? StatusUsuario.Aprovado : StatusUsuario.Inativado; // inativar
      break;
      case StatusUsuario.Reprovado:
          this.novoStatus = value ? StatusUsuario.Aprovado : StatusUsuario.Reprovado; // aprovar
      break;
      case StatusUsuario.Inativado:
          this.novoStatus = value ? StatusUsuario.Aprovado : StatusUsuario.Inativado; // ativar
        break;
      default:
        break;
    }
  }

  getLabelStatus(): string {
    switch (this.usuario.status) {
      case StatusUsuario.AguardandoAprovacao:
          return (this.novoStatus == StatusUsuario.Aprovado) ? "Aprovado" : "Reprovado";
      case StatusUsuario.Aprovado:
          return (this.novoStatus == StatusUsuario.Inativado) ? "Inativo" : "Ativo";
      case StatusUsuario.Reprovado:
          return (this.novoStatus == StatusUsuario.Aprovado) ? "Aprovado" : "Reprovado";
      case StatusUsuario.Inativado:
          return (this.novoStatus == StatusUsuario.Inativado) ? "Inativo" : "Ativo";
      default:
        break;
    }
  }

  getTitleStatus(): string {
    switch (this.usuario.status) {
      case StatusUsuario.AguardandoAprovacao:
          return "Aprovar / Reprovar";
      case StatusUsuario.Aprovado:
          return "Ativar / Inativar";
      case StatusUsuario.Reprovado:
          return "Aprovar / Reprovar";
      case StatusUsuario.Inativado:
          return "Ativar / Inativar";
      default:
        break;
    }
  }

  obtemPerfisUsuarioDistintos(funcionalidades: Funcionalidade[]): PerfilUsuario []{
    let perfisUsuario : PerfilUsuario[] = [];
    funcionalidades.forEach(x => {
        if (!perfisUsuario.find(y => y == x.perfilUsuario))
          perfisUsuario.push(x.perfilUsuario);
    });
    return perfisUsuario;
  }

  getFuncionalidadePorPerfil(idPerfil: number): void{
    var perfil = this._perfilService.getPerfil(idPerfil).subscribe(lista => {
        this.funcionalidadesDoPerfil = lista.funcionalidades;
        this.perfisBasico = [];
        this.obtemPerfisUsuarioDistintos(this.funcionalidadesDoPerfil).forEach(x => this.perfisBasico.push(PerfilUsuario[x]));

        this.cliente = (this.perfisBasico.filter(p => p == "Cliente").length > 0);
        this.provedor = (this.perfisBasico.filter(p => p == "Provedor").length > 0);
        this.administrador = (this.perfisBasico.filter(p => p == "Administrador").length > 0);
    });
  }
}
