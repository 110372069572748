import { Component, OnInit, ViewChild } from '@angular/core';

import { PerfilService } from '../../_services/perfil.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from './../../_services/message.service';
import { UsuarioService } from './../../_services/usuario.service';
import { GenericOrderbyService } from 'app/_services/generic-orderby.service';

import { Perfil } from 'app/_models/perfil';
import { Usuario } from './../../_models/usuario';
import { UsuarioPesquisa } from './../../_models/usuario-pesquisa';
import { BaseComponent } from 'app/shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";

import { StatusUsuario } from 'app/_enums/status-usuario.enum';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { DatepickerService } from 'app/_services/datepicker.service';
import { DatePickerOptions, DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import * as moment from 'moment-timezone';
import { UsuarioPesquisaRequest } from 'app/_models/usuario-pesquisa-request';

@Component({
    selector: 'app-usuario',
    templateUrl: './usuario.component.html',
    styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent extends BaseComponent implements OnInit {

    @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
    @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

    filtro: UsuarioPesquisa = new UsuarioPesquisa();
    request: UsuarioPesquisaRequest = new UsuarioPesquisaRequest();
    lista: Usuario[];
    loading: boolean = false;
    loadingPerfis: boolean = false;
    comboStatus: FilterComboConfiguration;
    comboPerfis: FilterComboConfiguration;
    statusSelecionados: number[];
    perfisSelecionados: number[];
    perfis: Perfil[];
    pageSize: number = 30;
    downloading: boolean = false;
    deOptions: DatePickerOptions;
    ateOptions: DatePickerOptions;


    constructor(private _sharedService: SharedService,
        private _msgService: MessageService,
        private _usuarioService: UsuarioService,
        private _perfilService: PerfilService,
        private _genericOrderbyService: GenericOrderbyService,
        private _datepickerService: DatepickerService
    ) {

        super();
        this.comboStatus = new FilterComboConfiguration("Status...");
        this.comboStatus.settings = {
          checkedStyle: 'glyphicon',
          dynamicTitleMaxItems: 2,
          showCheckAll: true,
          showUncheckAll: true
        };

        this.comboStatus.options.push({ id: 0, name: 'Aguardando Aprovação' });
        this.comboStatus.options.push({ id: 1, name: 'Aprovado' });
        this.comboStatus.options.push({ id: 2, name: 'Reprovado' });
        this.comboStatus.options.push({ id: 3, name: 'Inativado' });

        this.comboPerfis = new FilterComboConfiguration("Perfis...");
        this.comboPerfis.settings = {
          checkedStyle: 'glyphicon',
          dynamicTitleMaxItems: 2,
          showCheckAll: true,
          showUncheckAll: true
        };

        this._msgService.emitirMensagem.subscribe(
            msg => {
                this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
            }
        );
    }

    ngOnInit() {
        this.deOptions = this._datepickerService.GenerateOptionsConfig(null);
        this.ateOptions = this._datepickerService.GenerateOptionsConfig(null);
        this.loadingPerfis = true;

        //this.ObterLista();

        this._perfilService.getPerfisAtivos()
          .subscribe(lista => {
            this.perfis = lista;


            if (this.perfis != null) {
                this.perfis = this._genericOrderbyService.ordenarPorCampo(this.perfis, 'Descricao', false);
                this.perfis.forEach(perfil => {
                  this.comboPerfis.options.push({ id: perfil.perfilID, name: perfil.descricao });
                });
            }

            this.loadingPerfis = false;
        });


        let msg = this._msgService.getMessageRedirect();
        if (msg.show)
            this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Usuarios, Operacao.Consulta, this.filtro);
    }

    private ObterLista() {
        this.loading = true;
        this._usuarioService.getUsuarios()
            .subscribe(lista => {
                this.lista = lista;
                this.paginacao.setAllData(this.lista);
                this.paginacao.setPage(1);
                this.loading = false;
            }, (err) => {
            });
    }

    Pesquisar() {
        this.loading = true;
        this.atribuiPeriodoInicialEFinal();
        this.request.status = this.statusSelecionados ? this.statusSelecionados.join(";") : "";
        this.request.perfil = this.perfisSelecionados ? this.perfisSelecionados.join(";") : "";

        if (this.filtro.cnpj) {
            var cnpjSemFormatacao = "";
            cnpjSemFormatacao = this._sharedService.replaceAll(this.request.cnpj, '.', '');
            cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '/', '');
            cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '-', '');
            this.request.cnpj = cnpjSemFormatacao;
        }

        this._usuarioService.getUsuariosByFiltro(this.request)
            .subscribe(lista => {
                this.lista = lista;
                this.setPageSize(this.pageSize);
                this.loading = false;
            }, (err) => {
                this.loading = false;
            });
    }

    exportarRelatorioCSV() {
      this.downloading = true;
      this._usuarioService.exportarRelatorioCSV(this.lista).subscribe(s =>
            this.downloading = false
        );
    }

    public getClasse(status: StatusUsuario): string {
        return this._usuarioService.getClasse(status);
    }

    public getString(status: StatusUsuario): string {
        return this._usuarioService.getString(status);
    }

    getNomePerfilECargo(perfil: any): string {
        return this._usuarioService.getNomePerfilECargo(perfil);
    }

    setPageSize(value: number) {
        this.pageSize = value;
        //if (this.paginacao != )
        this.paginacao.setAllData(this.lista);
        this.paginacao.setPageSize(this.pageSize);
        this.paginacao.setPage(1);
      }

      atribuiPeriodoInicialEFinal(): void {
        this.request.nome = this.filtro.nome;
        this.request.email = this.filtro.email;
        this.request.razaoSocial = this.filtro.razaoSocial;
        this.request.perfil = this.filtro.perfil;
        this.request.status = this.filtro.status;
        this.request.cnpj = this.filtro.cnpj;
        let dataInicio = this.filtro.cadastradosDe? this._datepickerService.ObtemData(this.filtro.cadastradosDe, 'DD/MM/YYYY'): null;
        let dataFim = this.filtro.cadastradosAte? this._datepickerService.ObtemData(this.filtro.cadastradosAte, 'DD/MM/YYYY'): null;
        this.request.cadastradosDe = dataInicio;
        this.request.cadastradosAte = dataFim
      }
}
