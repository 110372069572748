export enum Operacao
{
    Invalida,
    Inclusao,
    Exclusao,
    Alteracao,
    Consulta,
    Email,
    Login,
    Download,
    Upload
}