import { PerfilUsuario } from "app/_enums/perfil-usuario.enum";

export class UsuarioRegistrar {
    Nome: string;
    RazaoSocial: string;
    NomeFantasia: string;
    CNPJ: string;
    InscricaoEstadual: string;
    Logradouro: string;
    Complemento: string;
    Numero: string;
    Bairro: string;
    Municipio: string;
    UF: string;
    CEP: string;
    DDD: string;
    Telefone: string;
    Email: string;
    Cliente: boolean;
    Provedor: boolean;
    PerfilUsuario: PerfilUsuario;
    DataAceiteTermo: Date;
}