import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaleConoscoComponent } from './fale-conosco.component';
import { FaleConoscoRoutingModule } from './fale-conosco.routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';



@NgModule({
    imports: [ 
        CommonModule, 
        FormsModule, 
        SharedModule,
        FaleConoscoRoutingModule ],
    exports: [],
    declarations: [ FaleConoscoComponent ],
    providers: [],
})
export class FaleConoscoModule {  }