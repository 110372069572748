
import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';


import { BaseService } from './base.service';
import { MessageService } from 'app/_services/message.service';

import { Operacao } from '../_enums/operacao';
import { GrupoEconomico } from 'app/_models/grupo-economico';
import { ValidationResult } from './../_models/validation-result';
import { GrupoEconomicoRequest } from 'app/_models/grupo-economico-request';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';

@Injectable({
    providedIn: 'root'
})
export class GrupoEconomicoService {

  grupoEconomicoSelecionadoEvent = new EventEmitter<string>();

  constructor(private baseService: BaseService, 
              private _msgService: MessageService) { }

  
  getGruposEconomicos(filtro: GrupoEconomicoRequest): Observable<GrupoEconomico[]> {
      return this.baseService.Post('grupoeconomico', filtro).pipe(
          map((response: ValidationResult) => {
              let retorno: ValidationResult = response;

              if (retorno.IsValid)
                  return retorno.Data as GrupoEconomico[];
              else {
                  var erros: string[] = [];
                  erros.push("Erro ao consultar Grupos Econômicos! Contate o administrador!");
                  retorno.Erros.forEach(element => {
                      erros.push(element.ErrorCode + " - " + element.Message);
                  });
                  this._msgService.addMessageArray('Grupo Econômico', erros, "error", LogFrontFuncionalidade.GrupoEconomico, Operacao.Consulta, filtro);           
                  return null;
              }
          }),catchError(e => {
              return this.baseService.handleError(e);
      }),);
  }
}
