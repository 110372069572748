import { ServicoProposta } from './servico-proposta';
import { TipoContainer } from "app/_models/tipo-container";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PropostaComercial {
    idProposta: number = 0;
    idTrecho: number = 0;
    numProposta: number = 0;
    idMunicOrigem: number = 0;
    idMunicDestino: number = 0;
    idPortolOrigem: number = 0;
    idPortoDestino: number = 0;
    tamanhoContainer: number = 0;
    idCategoriaContainer: number = 0;
    qtdeContainer: number = 0;
    tempCargaRefrigerada: number = 0;
    CargaOverEsquerda: number = 0;
    CargaOverDireita: number = 0;
    CargaOverTraseiro: number = 0;
    CargaOverFrontal: number = 0;
    CargaOverAltura: number = 0;
    idFamiliaProduto: number = 0;
    idUsuario: number = 0;
    idMunicipioEmb: number = 0;

    flagConferente: string = '';
    flagShipperOwnContainer: string = '';
    flagAjudanteOvaDesova: string = '';
    flagTremBalsa: string = '';
    flagCargaPerigosa: string = '';
    flagCargaRefrigerada: string = '';
    obsCargaRefrigeradaa: string = '';
    flagCargaOver: string = '';

    modal: string = '';
    tipo: string = '';
    tipoCarga: string = '';
    tipoOperacaoComercial: string = '';
    nomeCli: string = '';
    cnpjCli: string = '';
    ieCli: string = '';
    nomeEmb: string = '';
    cnpjEmb: string = '';
    ieEmb: string = '';
    cepEmb: string = '';
    logradouroEmb: string = '';
    complementoEmb: string = '';
    numeroEndEmb: string = '';
    bairroEmb: string = '';
    municipioEmb: string = '';
    ufEmb: string = '';
    contatoEmb: string = '';
    emailEmb: string = '';
    seguro: string = '';
    municOrigem: string = '';
    ufOrigem: string = '';
    municDestino: string = '';
    ufDestino: string = '';
    nomePortoOrigem: string = '';
    ufPortoOrigem: string = '';
    nomePortoDestino: string = '';
    ufPortoDestino: string = '';
    terminalInterior: string = '';
    recebimentoTerminal: string = '';
    descCategoriaContainer: string = '';
    descFamiliaProduto: string = '';
    numeroUNO: string = '';
    classe: string = '';
    sequencia: string = '';
    psn: string = '';
    obsCargaPerigosa: string = '';
    obsCargaRefrigerada: string = '';
    obsCargaOver: string = '';
    obsGeral: string = '';
    status: string = '';

    flagEscoltaOri: string = '';
    flagEscoltaDes: string = '';
    flagConferenteOri: string = '';
    flagConferenteDes: string = '';
    flagEquipeIndicaCliOri: string = '';
    flagEquipeIndicaCliDes: string = '';
    flagEquipePropriaOri: string = '';
    flagEquipePropriaDes: string = '';

    strValMercadoria: string = '';
    
    valMercadoria: number = 0;
    valFreteOTM: number = 0;
    pesoContainer: number = 0;

    tipoDeContainer: TipoContainer;
    servicosDaProposta: ServicoProposta[];
    ListaTipoServicoOri: string = '';
    ListaTipoServicoDes: string = '';

    contatoCli: string = '';
    adValorem: number = 0;
    flagGensetOri: string = '';
    flagGensetDes: string = '';
    flagEstufagemTerminal: string = '';
    flagOvaTerminal: string = '';
    flagOvaEmbarcador: string = '';
    flagDesovaTerminal: string = '';
    flagDesovaRecebedor: string = '';

    numPropostaEidTrecho: string = '';

    indicPagtoAVista: string = '';

    get vlrAdValoren(): number{
        return this.adValorem;
      }

    private _shipperOwnContainer: boolean = false;
    get shipperOwnContainer(): boolean {
        this._shipperOwnContainer = this.flagShipperOwnContainer == 'S';
        return this._shipperOwnContainer;
    }
    set shipperOwnContainer(value: boolean) {
        this._shipperOwnContainer = value;
        this.flagShipperOwnContainer = this._shipperOwnContainer ? 'S' : 'N';
    }

    private _cargaPerigosa: boolean = false;
    get cargaPerigosa(): boolean {
        this._cargaPerigosa = this.flagCargaPerigosa == 'S';
        return this._cargaPerigosa;
    }
    set cargaPerigosa(value: boolean) {
        this._cargaPerigosa = value;
        this.flagCargaPerigosa = this._cargaPerigosa ? 'S' : 'N';
    }

    private _cargaRefrigerada: boolean = false;
    get cargaRefrigerada(): boolean {
        this._cargaRefrigerada = this.flagCargaRefrigerada == 'S';
        return this._cargaRefrigerada;
    }
    set cargaRefrigerada(value: boolean) {
        this._cargaRefrigerada = value;
        this.flagCargaRefrigerada = this._cargaRefrigerada ? 'S' : 'N';
    }    

    private _cargaOver: boolean = false;
    get cargaOver(): boolean {
        this._cargaOver = this.flagCargaOver == 'S';
        return this._cargaOver;
    }
    set cargaOver(value: boolean) {
        this._cargaOver = value;
        this.flagCargaOver = this._cargaOver ? 'S' : 'N';
    }       

    // getters
    private _oriPorto: boolean = true;
    get oriPorto(): boolean {
        if ((this.modal == undefined) || (this.modal == "") || (this.modal.startsWith('/')))
            return undefined;
        this._oriPorto = this.modal.startsWith("PO");

        return this._oriPorto;
    }        

    private _desPorto: boolean = true;
    get desPorto(): boolean {
        if ((this.modal == undefined) || (this.modal == "") || (this.modal.endsWith('/')))
            return undefined;

        this._desPorto = this.modal.endsWith("PO");
        return this._desPorto;
    }    
}
