import { CtmNf } from "app/_models/ctmNf";
import { StatusOS } from "app/_enums/status-os.enum";
import { Incidente } from 'app/_models/incidente';
import { DateModel } from './../shared/ng2-datepicker/ng2-datepicker.component';

export class OrdemServico {
	idOs: number;
	idCategoriaContainer: number;
	solicitContainerId: number;
	tamanhoContainer: number;
	tara: number;

	numOs: string;
	numBooking: string;
	numContainer: string;
	numFatura: string;
	numFaturaOrdenacao: number;
	statusOs: string;
	statusPortal: string;
	descNavioViagem: string;
	descTipoServico: string;
	nomeEmbDest: string;
	portoOriDes: string;
	municOriDes: string;
	ufOriDes: string;
	cnpjProvedor: string;
	ieProvedor: string;
	nomeProvedor: string;
	tipo: string;
	dhProgramacao: string;
	nomeColEnt: string;
	enderecoCompletoColEnt: string;
	cnpjColEnt: string;
	ieColEnt: string;
	cnpjCliente: string;
	ieCliente: string;
	nomeCliente: string;
	lacre1: string;
	lacre2: string;
	lacre3: string;
	placa: string;
	observacao: string;
	dtRetiradaTerminal: Date;
	hrRetiradaTerminal: string;
	dtInicioExecucao: Date;
	hrInicioExecucao: string;
	dtFimExecucao: Date;
	hrFimExecucao: string;
	dtEntregaTerminal: Date;
	hrEntregaTerminal: string;
	dhChegadaTerminal: Date;
  dhSaidaCliente: Date;
  indicAvulsa: boolean;

	dataRetiradaTerminal: DateModel;
	dataInicioExecucao: DateModel;
	dataFimExecucao: DateModel;
	dataEntregaTerminal: DateModel;

	descCategoriaContainer: string;
	dhChegadaCliente: Date;
	dhRetiradaTerminal: Date;
	flagNFValidada: string;
	flagPossuiCTe: string;
	flagPossuiCTM: string;
	flagPossuiNF: string;
	idMunicOriDes: number;
	idNavioViagem: number;
	idPortoOriDes: number;
	idTipoServico: number;
	indicTipo: string;
	numCte: string;
	ordem_servico_id: number;

	serieCtm: string;

	selecionado: boolean;
	ctmNF: CtmNf;

	strProgramacao: string;
	strdtRetiradaTerminal: string;
	strInicioExecucao: string;
	strFimExecucao: string;
	strEntregaTerminal: string;
	strdhRetiradaTerminal: string;
	strChegadaTerminal: string;
	strChegadaClientedhChegadaCliente: string;
	strSaidaCliente: string;

	incidentes: Incidente[];

	numAverbacao: string;
	numApolice: string;
	numBL: string;

	private _status: StatusOS;
  idTipoContainer: number;
	public get status(): StatusOS {
		return this._status;
	}
	public set status(value: StatusOS) {
		this._status = value;
	}
}
