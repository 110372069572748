import {
  Component,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";

import { BaseComponent } from "./../../../../../shared/base.component";
import { EventoTracking } from "../../../../../_models/evento-tracking";
import { TrackingLista } from "app/_models/tracking-lista";

@Component({
  selector: "item-tracking",
  templateUrl: "./item-tracking.component.html",
  styleUrls: ["./item-tracking.component.scss"],
})
export class ItemTrackingComponent
  extends BaseComponent
  implements AfterViewInit {
  @Output() componentLoaded: EventEmitter<any> = new EventEmitter();
  @Input() eventoTracking: EventoTracking;
  @Input() trackingLista: TrackingLista;

  constructor() {
    super();
  }
  ngAfterViewInit(): void {
    this.componentLoaded.emit(null);
  }

  get viagem(): string {
    if (!this.trackingLista) return;

    return this.eventoTracking.IsNavegando
      ? this.trackingLista.viagemNavio
      : this.eventoTracking.viagemNavio;
  }

  get exibeViagemNavio(): boolean {
    return (
      (this.eventoTracking.IsNavegando || this.eventoTracking.IsTransbordo) &&
      this.viagem != undefined &&
      this.viagem != ""
    );
  }

  get ocultaDatas(): boolean {
    return this.eventoTracking.OcultaDatasTracking;
  }

  // get textoSaidaCliente(): string {
  //   return "Saída do Cliente:";
  // }

  // get textoRetiradaTerminal(): string {
  //   return "Retirada do Vazio:";
  // }

  // get dataRetiradaTerminal(): string {
  //   return this.eventoTracking && this.eventoTracking.IsEmColeta
  //     ? this.eventoTracking.strDataRetiradaTerminal
  //     : undefined;
  // }

  get eventoRealizadoLinha(): string {
    return this.eventoTracking.ExibeComoRealizado ? "line ok" : "line";
  }

  get eventoRealizadoCirculo(): string {
    return this.eventoTracking.ExibeComoRealizado ? "circle-success" : "circle";
  }

  get atrasoEvento(): string {
    return this.eventoTracking.IsAtrasoEvento
      ? "atrasado"
      : this.eventoTracking.IsAtrasoEvento == null
      ? ""
      : "realizado";
  }

  // get dataRealizada(): string {
  //   return this.eventoTracking
  //     ? this.eventoTracking.IsEmColeta || this.eventoTracking.IsRealizada
  //       ? this.eventoTracking.strDataIniExecucao
  //       : this.eventoTracking.IsBookingConfirmado || this.eventoTracking.IsDepositada || this.eventoTracking.IsEmEntrega
  //       ? this.eventoTracking.strDataRealizada
  //       : this.eventoTracking.IsRetiradaCheio
  //       ? this.eventoTracking.strDataRetiradaCheio
  //       : this.eventoTracking.IsDevolucaoVazio
  //       ? this.eventoTracking.strDataDevolucaoVazio
  //       : this.eventoTracking.strDataETAConf
  //       : undefined;
  // }

  // get dataSaidaCliente(): string {
  //   return this.eventoTracking
  //     ? this.eventoTracking.strDataFimExecucao
  //     : undefined;
  // }

  // get dataPrevista(): string {
  //   return this.eventoTracking &&
  //     (this.eventoTracking.IsEmColeta ||
  //       this.eventoTracking.IsRealizada ||
  //       this.eventoTracking.IsBookingConfirmado)
  //     ? this.eventoTracking.strDataPrevista
  //     : this.eventoTracking.strDataETAPrev;
  // }

  // get textoPrevisto(): string {
  //   return this.eventoTracking.IsEmColeta ||
  //     this.eventoTracking.IsRealizada ||
  //     this.eventoTracking.IsBookingConfirmado
  //     ? "Solicitado:"
  //     : "Chegada Prevista:";
  // }

  // get textoRealizado(): string {
  //   return this.eventoTracking.IsBookingConfirmado
  //     ? "Confirmado:"
  //     : this.eventoTracking.IsEmEntrega
  //     ? "Saída Terminal:"
  //     : this.eventoTracking.IsDepositada
  //     ? "Chegada Terminal:"
  //     : this.eventoTracking.IsEmColeta || this.eventoTracking.IsRealizada
  //     || this.eventoTracking.IsRetiradaCheio || this.eventoTracking.IsDevolucaoVazio
  //     ? "Realizado:"
  //     : "Chegada Realizada:";
  // }

  get ExibeDescarregada(): boolean {
    return this.eventoTracking.ExibeDataDescarregada;
  }

  get isModalPOInOrigin(): boolean {
    return this.trackingLista.modal.toLocaleLowerCase().startsWith("po");
  }

  get isColetaPA() {
    return this.eventoTracking.IsEmColeta && !this.isModalPOInOrigin;
  }

// TEXTOS ////////////////////////////////////////////
  get textoSolicitado(): string {
    return "Solicitado:"
  }
  get textoConfirmado(): string {
    return "Confirmado:"
  }
  get textoRetiradadoVazio(): string {
    return "Retirada do Vazio:";
  }
  get textoChegadaTerminal(): string {
    return "Chegada Terminal:";
  }
  get textoChegadaPrevista(): string {
    return "Chegada Prevista:";
  }
  get textoChegadaRealizada(): string {
    return "Chegada Realizada:";
  }
  get textoSaidaTerminal(): string {
    return "Saída Terminal:";
  }
  get texto_Realizado(): string {
    return "Realizado:";
  }
  get textoSaidadoCliente(): string {
    return "Saída do Cliente:";
  }
  get textoSaidaRealizada(): string {
    return "Saída Realizada:";
  }
  get textoEmbarcada(): string {
    return "Embarcada:";
  }
  get textoDescarregada(): string {
    return "Descarregada:";
  }


// DATAS ///////////////////////////////////////////
  get dataSolicitado(): string {
    return this.eventoTracking.strDataPrevista;
  }
  get dataConfirmado(): string {
    return this.eventoTracking.strDataRealizada;
  }
  get dataRetiradadoVazio(): string {
    return this.eventoTracking.strDataRetiradaVazio;
  }
  get dataChegadaTerminal(): string {
    return this.eventoTracking.strDataRealizada;
  }
  get dataChegadaPrevista(): string {
    return this.eventoTracking.strDataETAPrev;
  }
  get dataChegadaRealizada(): string {
    return this.eventoTracking.strDataETAConf;
  }
  get dataSaidaTerminal(): string {
    return this.eventoTracking.strDataRealizada;
  }
  get dataRealizado(): string {
    return this.eventoTracking.IsEmColeta || this.eventoTracking.IsRealizada
        ? this.eventoTracking.strDataIniExecucao
        : this.eventoTracking.IsRetiradaCheio
        ? this.eventoTracking.strDataRetiradaCheio
        : this.eventoTracking.IsDevolucaoVazio
        ? this.eventoTracking.strDataDevolucaoVazio
        : undefined
  }
  get dataSaidadoCliente(): string {
    return this.eventoTracking.strDataFimExecucao;
  }
  get dataSaidaRealizada(): string {
    return this.eventoTracking.strDataRealizada;
  }
  get dataEmbarcada(): string {
    return this.eventoTracking.strDataEmbarque;
  }
  get dataDescarregada(): string {
    return this.eventoTracking.strDataDesembarque;
  }
}
