import { Endereco } from "app/_models/endereco";

export class Cliente
{
    CNPJ: string;
    InscricaoEstadual: string;
    Nome: string;
    NomeFantasia: string;
    Contato: string;
    Email: string;
    DDD: string;
    Fone: string;

    Enderecos: Endereco[];
}