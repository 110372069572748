import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { Component, Compiler, ViewChild, ElementRef, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import * as moment from 'moment/moment';

import { AppService } from './_services/app.service';
import { ConfigService } from './_services/config.service';
import { GrupoEconomicoService } from './_services/grupo-economico.service';
import { AuthenticationService } from './_services/authentication.service';

import { GrupoEconomico } from 'app/_models/grupo-economico';
import { GrupoEconomicoRequest } from './_models/grupo-economico-request';
import { FilterComboConfiguration } from './_models/filterComboConfiguration';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from './_enums/analytics-tela';
import { AnalyticsOperacao } from './_enums/analytics-operacao';
import { GoogleConfig } from './_models/google-config';

declare var ocultarControleMenu: any;
declare var ocultarSwalOverlay: any;
declare var removeSwal: any;
declare var isIe: boolean;
declare var loadMaps: any;
declare var loadAnalytics: any;
declare var browser_versao: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  @ViewChild('closeModal', {static: true}) private closeModal: ElementRef;
  comboGrupoEconomico: FilterComboConfiguration;
  mensagemErro: string;
  username: string = '';
  logado: boolean = false;
  loadingGrupos: boolean = false;
  grupos: GrupoEconomico[] = [];
  gruposSelecionados: number[];
  labelGrupo: string;
  eAdmin: boolean = this._appService.isAdmin();
  cssClassAdmin = '';
  loadingRouter: boolean = false;

  constructor(public _router: Router,
    public _authenticationService: AuthenticationService,
    public _appService: AppService,
    private _compiler: Compiler,
    private _configService: ConfigService,
    private _grupoService: GrupoEconomicoService,
    private _analyticsService: AnalyticsService) {
    
    this._compiler.clearCache();

    Date.prototype.toJSON = function(){ return moment(this).format(); }

    this.loadGoogleConfig(_configService._googleConfig);
    this._appService.configInfo = _configService._config;
    this._appService.apiUrl = this._appService.configInfo.apiUrl;
    localStorage.setItem('browser_versao', JSON.stringify(browser_versao));
    localStorage.setItem('apiUrl', JSON.stringify(_configService._config));

    this.PopularUsuario();
    this._authenticationService.emitirLogin.subscribe(
      modal => {
        this.PopularUsuario();
        if(modal)
          this.showModal();
      }
    );

    this._appService.emitirLogin.subscribe(
      modal => {
        this.PopularUsuario();
        if(modal) {
          this._appService.limpaStorage();
          this.showModal();
        }
      }
    );
    
    this.comboGrupoEconomico = new FilterComboConfiguration('Grupo(s) Econômico(s)...');
    this.comboGrupoEconomico.settings = {
      checkedStyle: 'glyphicon',
      showCheckAll: false,
      showUncheckAll: true,
      dynamicTitleMaxItems: 0,
      enableSearch: true
    };
    
    if (isIe){
      removeSwal();
      
      swal({
        title: 'Atenção',
        text: 'Este site é melhor visualizado no navegador Google Chrome',
        type: 'warning',
      }).then(success => {  
        ocultarControleMenu();
        ocultarSwalOverlay();
      }, dismiss => {
        removeSwal();
        // console.log(dismiss);
      });
    }
  }

  private loadGoogleConfig(cfgGoogle: GoogleConfig){
    var cfgAnalytics = cfgGoogle.Analytics;
    this._appService.configAnalytics = cfgAnalytics;
    this._appService.configMaps = cfgGoogle.Maps;
    //localStorage.setItem('google-config', JSON.stringify(cfgGoogle));
    loadMaps(cfgGoogle.Maps.ApiKey);

    if (cfgAnalytics.Habilitado)
      loadAnalytics(cfgAnalytics.GtagID);
  }
 
  ngOnInit(): void {
    this.labelGrupo = this._appService.getLocalStorage('labelGrupo'); //this._appService.getLocalStorage('cnpjGrupos');
    if (this.labelGrupo)
      this.gruposSelecionados = this.getGrupoStorage();

    this.cssClassAdmin = this.eAdmin  ? "peril-admin" : "";
    this._router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
          this.loadingRouter = true;
      } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouter = false;
      }
    });
  }

  PopularUsuario(): void {
    if (this._appService.UsuarioLogado != null) {
      this.username = this._appService.UsuarioLogado.Nome;
      this.logado = true;
      this._analyticsService.RegistrarOperacao(AnalyticsTela.Home, AnalyticsOperacao.LoginRealizado);
    }
    else {
      this.username = '';
      this.logado = false;
      this.labelGrupo = '';
      //this._appService.tokenExpirado = true;
    }
  }

  public showModal(): void {
    this._authenticationService.showModal();
  }

  public logout(): void {
    removeSwal();

    swal({
      title: 'Sair',
      text: 'Deseja realmente sair do sistema?',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(success => {
      this.labelGrupo = '';
      this.gruposSelecionados = [];
      this._authenticationService.logout();
      this._appService.tokenExpirado = false;
      this._router.navigate(['/home']);

      ocultarControleMenu();
      ocultarSwalOverlay();
    }, dismiss => {
      removeSwal();
      // console.log(dismiss);
    });
  }

  ObterGrupos() {
    if (!this._appService.UsuarioLogado || this.grupos.length > 0)       
      return;
   
    this.loadingGrupos = true;
    var request: GrupoEconomicoRequest = new GrupoEconomicoRequest()
    request.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;

    this._grupoService.getGruposEconomicos(request)
      .subscribe(m => {
        this.grupos = m;
        
        if (this.grupos != null) {
          this.comboGrupoEconomico.options = [];
          this.grupos.forEach(grp => {
            this.comboGrupoEconomico.options.push({ id: grp.idGrupo, name: grp.descGrupo });
          });
          
        }
        else // em caso de erro (ex.: sessão expirada)
          this.closeModal.nativeElement.click();
        this.loadingGrupos = false;
        setTimeout(() => {
          this.gruposSelecionados = this.getGrupoStorage();
        }, 10);   
      });
  }

  ocultarMensagem() {
    this.mensagemErro = '';
  }

  aplicarGrupo() {
      var cnpjs = "";
      var label = "";
      var total = 0;
      if (this.gruposSelecionados) {
        this.gruposSelecionados.forEach(g => {
          var grp: GrupoEconomico = this.grupos.find(f => f.idGrupo == g);
          cnpjs += grp.listaCNPJCliente + ";"
          if (label == "") {
            label = (grp.descGrupo.indexOf(' ') > 0) ? ": " + grp.descGrupo.substring(0, grp.descGrupo.indexOf(' ')) : ": " + grp.descGrupo;
          }
          total += 1;
        });
      }
      if (label && total >1)
        label += " + " + (total-1).toString();

      if (cnpjs)
        cnpjs = cnpjs.substring(0, cnpjs.length-1);

      if (cnpjs.length > 4000)
      {
        this.mensagemErro = "Muitos CNPJs selecionados. Selecionar no máximo 255 CNPJs.";
        return;
      }
      
      this.labelGrupo = label;
      label = "";
      this._appService.setLocalStorage('labelGrupo', this.labelGrupo);
      this._appService.setLocalStorage('cnpjGrupos', cnpjs);
      this._appService.setLocalStorageJson('idsGruposSelecionados', this.gruposSelecionados);
      this._grupoService.grupoEconomicoSelecionadoEvent.emit(cnpjs);
      this.closeModal.nativeElement.click();
  }

  cancelarGrupo() {
    this.gruposSelecionados = this.getGrupoStorage();
    this.closeModal.nativeElement.click();
  }

  getGrupoStorage(): any {
    return this._appService.getLocalStorageJson('idsGruposSelecionados');
  }
}
