
import {catchError, map} from 'rxjs/operators';
import { Assunto } from './../_models/assunto';
import { Injectable, EventEmitter } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';



import { FaleConosco } from './../_models/faleconosco';
import { ValidationResult } from './../_models/validation-result';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class CaixaEntradaService {

    constructor(private baseService: BaseService) { }

    emitirModal = new EventEmitter<boolean>();

    public showModal(): void {
        this.emitirModal.emit(true);
    }

    public hideModal(): void {
        this.emitirModal.emit(false);
    }

    getEmails(filtro: string): Observable<FaleConosco[]> {
        return this.baseService.Get('faleconosco/' + filtro).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                if (retorno.IsValid)
                    return retorno.Data as FaleConosco[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getAssuntos(): Observable<Assunto[]> {
        return this.baseService.Get('faleconosco/assuntos').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                if (retorno.IsValid)
                    return retorno.Data as Assunto[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }
}
