
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';

import { ValidationResult } from 'app/_models/validation-result';
import { ConfigUpload } from 'app/_models/config-upload';
import { ConfigPortal, ConfigViaGreen } from 'app/_models/config-portal';
import { UsuarioLogado } from 'app/_models/usuariologado';
import { GoogleConfig } from 'app/_models/google-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public _config: ConfigPortal;
  public _googleConfig: GoogleConfig;
  public _viaGreenConfig: ConfigViaGreen;

  constructor(private _http: HttpClient) { }

  loadConfig() {
    return new Promise((resolve, reject) => {
      this._http.get('_config/config.json').pipe(
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        }))
        .subscribe((data) => {
          this._config = data as ConfigPortal;
          resolve(true);
        });
    });
  }

  loadGoogleConfig() {
    return new Promise((resolve, reject) => {
      this._http.get('_config/google-config.json').pipe(
        map(res => JSON.parse(JSON.stringify(res))),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        }))
        .subscribe((data: GoogleConfig) => {
          this._googleConfig = data;
          resolve(true);
        });
    });
  }

  loadViaGreenConfig() {
    return new Promise((resolve, reject) => {
      this._http.get('_config/via-green-config.json').pipe(
        map(res => JSON.parse(JSON.stringify(res))),
        catchError((error: any) => {
          return observableThrowError(error.error || 'Server error');
        }))
        .subscribe((data) => {
          this._viaGreenConfig = data;
          resolve(true);
        });
    });
  }

  private getOptionsArgs(usuarioLogado: UsuarioLogado): any {
    // let headers = new Headers();
    // if (usuarioLogado != null)
    //   headers = new Headers({ 'Authorization': 'Bearer ' + usuarioLogado.Token });
    // return new RequestOptions({ headers: headers });
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + usuarioLogado.token, 'Content-Type': 'application/json' });

    let options = {
      headers: headers
    };
    return options;
  }

  public getConfigUpload(baseUrl: string, usuarioLogado: UsuarioLogado): Observable<ConfigUpload> {
    return this._http.get(baseUrl + 'upload/config', this.getOptionsArgs(usuarioLogado)).pipe(
      map((response: any) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as ConfigUpload;
        else
          return new ConfigUpload();
      }));
  }
}
