
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';


import { BaseService } from './base.service';
import { Perfil } from './../_models/perfil';
import { ValidationResult } from './../_models/validation-result';

@Injectable({
    providedIn: 'root'
})
export class PerfilService {

    constructor(private baseService: BaseService) { }

    salvarPerfil(perfil: Perfil): Observable<ValidationResult> {
        if (!perfil.PerfilID || perfil.PerfilID == 0)
            return this.baseService.Post('perfil', perfil).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
        else
            return this.baseService.Put('perfil/' + perfil.PerfilID, perfil).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
    }

    deletarPerfil(id: number): Observable<ValidationResult> {
        return this.baseService.Delete('perfil/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getPerfis(): Observable<Perfil[]> {
        return this.get('perfil');
    }

    getPerfisAtivos(): Observable<Perfil[]> {
        return this.get('perfil/GetAtivos');
    }

    private get(path: string): Observable<Perfil[]> {
        return this.baseService.Get(path).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Perfil[];
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getPerfil(id: number): Observable<Perfil> {
        return this.baseService.Get('perfil/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Perfil;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

}
