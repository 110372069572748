export class Endereco
{
    Logradouro: string;
    Complemento: string;
    Numero: string;
    Bairro: string;
    CEP: string;
    Municipio: string;
    UF: string;
    IdMunicipio: number;
}