import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GroupbyService {

  constructor() { }

  public AgruparPorCampo(lista: Array<any>, campo: string): Array<any>{
    var listaAgrupada: Array<any> = [];
    if (!lista) return [];
    lista.forEach(x => {
      if (!listaAgrupada.find(y => y[campo] == x[campo]))
        listaAgrupada.push(x);   
    });

    return listaAgrupada;
  }
}