import { Navio } from "./navio";
import { Porto } from "./porto";

export class Prioridade {
    PrioridadeID: number;
    PortoOrigem: Porto;
    PortoDestino: Porto;
    Navios: Navio[];
    FiltrarProgramacaoNavio: boolean;

    PortosOrigemID: number[]; //para o combo-multiselect
    PortosDestinoID: number[]; //para o combo-multiselect
    NaviosID: number[]; //para o combo-multiselect

    constructor() {
      this.PrioridadeID = 0;
      this.PortoOrigem = new Porto();
      this.PortoDestino = new Porto();
      this.Navios = [];
      this.FiltrarProgramacaoNavio = false;

      this.PortosOrigemID = [];
      this.PortosDestinoID = [];
      this.NaviosID = [];
    }
}
