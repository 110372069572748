
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';

import * as moment from 'moment/moment';

import { MessageService } from './message.service';
import { Municipio } from './../_models/municipio';
import { Mercadoria } from './../_models/mercadoria';
import { CargaIMO } from './../_models/cargaIMO';
import { TipoContainer } from './../_models/tipo-container';
import { ValidationResult } from './../_models/validation-result';
import { BaseService } from './base.service';
import { Porto } from './../_models/porto';
import { TipoServico } from './../_models/tipo-servico';
import { Navio } from './../_models/navio';
import { ClienteRequest } from './../_models/cliente-request';
import { Cliente } from './../_models/cliente';
import { Feriado } from 'app/_models/feriado';
import { DateModel } from '../shared/ng2-datepicker/ng2-datepicker.module';
import { Operacao } from '../_enums/operacao';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private baseService: BaseService,
              private _msgService: MessageService) { }

  copyProperties(from: any, to: any): void {
    for (var key in from)
      if (to.hasOwnProperty(key))
        to[key] = from[key];
  }

  addDays(theDate, days) {
    if (!theDate || days == undefined)
      return undefined;
    return new Date(theDate.getTime() + (days * 24 * 60 * 60 * 1000));
  }

  addHours(theDate, hours) {
    if (!theDate || hours == undefined)
      return undefined;
    return new Date(theDate.getTime() + (hours * 60 *60 * 1000));
  }

  CalculaDataMinimaAgendamento(feriados: Feriado[], dataReferencia: Date, parametroHoras: number): Date {
    var fds = false;
    var dataBase = new Date(dataReferencia.getTime());
    while (this.isFeriadoFds(feriados, dataBase)){
      dataBase = this.addDays(dataBase, 1);
      fds = true;
    }
    if (fds)
    dataBase = new Date(dataBase.getFullYear(), dataBase.getMonth(), dataBase.getDate(), 0, 1, 0);

    return this.CalculaProximoDiaUtil(feriados, dataBase, parametroHoras);
  }

  CalculaProximoDiaUtil(feriados: Feriado[], dataReferencia: Date, parametroHoras: number): Date {
    var diaUtil = dataReferencia;
    var dias = Math.floor(parametroHoras/24);

    for (let i=0; i<dias; i++) {
      diaUtil = this.addDays(diaUtil, 1);
      while (this.isFeriadoFds(feriados, diaUtil))
        diaUtil = this.addDays(diaUtil, 1);
    }

    var resto = parametroHoras - (dias * 24);
    if (resto > 0) {
      diaUtil = this.addHours(diaUtil, resto);
      while (this.isFeriadoFds(feriados, diaUtil))
        diaUtil = this.addDays(diaUtil, 1);
    }

    return diaUtil;
  }

  isFeriadoFds(feriados: Feriado[], data: Date): boolean {
    if (!feriados || feriados.length == 0)
      return this.isFds(data);

    return feriados.some(feriado => { return feriado.Data ? (this.corrigeData(feriado.Data).toDateString() == data.toDateString()) || this.isFds(data) : false; });
  }

  isFds(data: Date){
    return (data.getDay() == 0) || (data.getDay() == 6);
  }

  getTipoContainers(idFamiliaProduto: number): Observable<TipoContainer[]> {
    return this.baseService.Get('cotacao/containers/' + idFamiliaProduto).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as TipoContainer[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getCargaPerigosa(): Observable<CargaIMO[]> {
    return this.baseService.Get('cotacao/cargaimo').pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as CargaIMO[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getCargaPerigosaPorClasse(classe: string): Observable<CargaIMO[]> {
    return this.baseService.Get('cotacao/cargaimo/' + classe).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as CargaIMO[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getMercadorias(): Observable<Mercadoria[]> {
    return this.baseService.Get('cotacao/mercadorias').pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Mercadoria[];
        else {
          var erros: string[] = [];
          erros.push("Erro ao buscar mercadorias! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Mercadorias', erros, "error", LogFrontFuncionalidade.Cotacao, Operacao.Consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getMunicipios(): Observable<Municipio[]> {
    return this.baseService.Get('schedule/municipios').pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Municipio[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getNavios(): Observable<Navio[]> {
    return this.baseService.Get('navio').pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Navio[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getTipoServicos(): Observable<TipoServico[]> {
    return this.baseService.Get('tipoServico').pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as TipoServico[];
        else
          return null;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getPortosTodos(): Observable<Porto[]> {
    return this.getPortosInterno('parametro/portos/todos');
  }

  getPortos(): Observable<Porto[]> {
    return this.getPortosInterno('parametro/portos');
  }

  private getPortosInterno(url: string): Observable<Porto[]> {
    return this.baseService.Get(url).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;
        if (retorno.IsValid)
          return retorno.Data as Porto[];
        else {
          var erros: string[] = [];
          erros.push("Erro ao buscar portos! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Portos', erros, "error", LogFrontFuncionalidade.ProgramacaoDeNavios, Operacao.Consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getClientes(cliente: ClienteRequest, especial: boolean = false): Observable<Cliente[]> {
    this._msgService.clearMessage();
    var url = (especial) ? 'shared/clientes/proposta' : 'shared/clientes';
    return this.baseService.Post(url, cliente).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;
        if (retorno.IsValid){
          return retorno.Data as Cliente[];
        } else {
          var erros: string[] = [];
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getFeriados(portoId: number): Observable<Feriado[]> {
    return this.baseService.Get('shared/porto/feriado/' + portoId).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;
        if (retorno.IsValid){
          return retorno.Data as Feriado[];
        } else {
          var erros: string[] = [];
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getVersaoAPI(filtro: string): Observable<string> {
    return this.baseService.Get('versao/' + filtro).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;
        if (retorno.IsValid)
          return retorno.Data as string;
        else {
          var erros: string[] = [];
          erros.push("Erro ao buscar versão! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Versão', erros, "error", LogFrontFuncionalidade.Sobre, Operacao.Consulta, filtro);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  clonaDateModel(dt: DateModel): DateModel{
    var clone = new DateModel();
    clone.day = dt.day;
    clone.month = dt.month;
    clone.year = dt.year;
    clone.formatted = dt.formatted;
    if (dt.momentObj)
      clone.momentObj = dt.momentObj.clone();
    return clone;
  }

  formataDataCSV(valor: any): string {
      if (valor) {
          var data: Date = this.corrigeData(valor); //new Date(valor);

          return this.formataDataHoraBR(data, true); //data.toLocaleDateString('pt-br') + " " + data.toLocaleTimeString('pt-br');
      }
  }

  formataCNPJ(cnpj: string): string{
      return cnpj ? cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5") : "";
  }

  replaceAll(target, search, replacement) {
    return target ? target.split(search).join(replacement) : null;
  }

  corrigeData(data: any): Date{
    if (!data)
      return undefined;
    return moment(data.toString(), "YYYY-MM-DD hh:mm:ss").toDate();
  }

  obtemDDDdoTelefone(telefone: string): string {
    if (telefone) {
      telefone = telefone.replace(/\D/g, '');
      if (telefone && telefone.length > 2) {
        return telefone.substr(0, 2);
      }
      return '';
    }
  }

  obtemTelefoneSemDDD(telefone: string): string {
    telefone = telefone.replace(/\D/g, '');
    if (telefone && telefone.length > 9) {
      const tamanhoTelefone = telefone.length;
      const numTelefone = telefone.substr(2, telefone.length - 1);

      return numTelefone;
    }
    return telefone;
  }

  validaTelefone(telefone): boolean {
    if (!telefone)
      return false;

    var tamanhoTelefone = telefone ? telefone.length : 0;
    return (! (!tamanhoTelefone || tamanhoTelefone < 10 || tamanhoTelefone > 11));
  }

  validaInteiro(valor: number): boolean {
    if ((valor == undefined) || (valor == null))
      return true;

    return (valor != undefined) &&  (valor != null) && (valor % 1 === 0) && (valor.toString().indexOf('.') < 0);
  }

  validaCEP(cep): boolean {
    if (!cep)
      return false;

    var strCEP: string = cep.toString();
    var tamanhoCEP = cep ? cep.length : 0;

    return ((strCEP.indexOf('-') >= 0) && (tamanhoCEP == 9)) || ((strCEP.indexOf('-') < 0) && (tamanhoCEP == 8));
  }

  toCamelCase(str) {
    // Lower cases the string
    str = ' ' + str;
    return str.toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, ' ')
      // Removes any non alphanumeric characters
      // .replace(/[^\w\s]/g, '')
      // Uppercases the first character in each group immediately following a space
      // (delimited by spaces)
      .replace(/ (.)/g, function ($1) { return $1.toUpperCase(); });
      // Removes spaces
      //.replace(/ /g, '');
  }

  padLeft(text:string, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
  }

  formataDataHoraBR(data: Date, comHora: boolean): string {
    if (comHora)
      return this.padLeft(data.getDate().toString(), '0', 2) + "/" +
              this.padLeft((data.getMonth()+1).toString(), '0', 2) + "/" +
              data.getFullYear() + " " +
              this.padLeft(data.getHours().toString(), '0', 2) + ":" +
              this.padLeft(data.getMinutes().toString(), '0', 2) + ":" +
              this.padLeft(data.getMilliseconds().toString(), '0', 2);
    else
      return this.padLeft(data.getDate().toString(), '0', 2) + "/" +
              this.padLeft((data.getMonth()+1).toString(), '0', 2) + "/" +
              data.getFullYear();
  }

  setTimeZero(data: Date): Date {
		data.setHours(0);
		data.setMinutes(0);
		data.setSeconds(0);
		data.setMilliseconds(0);
    return data;
  }

  formatNumeroMoeda(numero: number, places, symbol, thousand, decimal):string {
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var negative = numero < 0 ? "-" : "";
    var i = parseInt(Math.abs(+numero || 0).toFixed(places), 10);
    var j = (j = i.toString().length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.toString().substr(0, j) + thousand : "") + i.toString().substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
    (places ? decimal + Math.abs(numero - i).toFixed(places).slice(2) : "");
  };

  validaMailFormat(email: string){
    if (!email)
      return false;

    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return (!(email != "" && (email.length <= 5 || !EMAIL_REGEXP.test(email))));
  }

  validaDigitoContainer(numContainer: string): boolean {

    if (!numContainer)
      return false;

      let valores: { [id: string]: number; } = {
        "A": 10, "B": 12, "C": 13, "D": 14, "E": 15, "F": 16, "G": 17, "H": 18,
        "I": 19, "J": 20, "K": 21, "L": 23, "M": 24, "N": 25, "O": 26, "P": 27,
        "Q": 28, "R": 29, "S": 30, "T": 31, "U": 32, "V": 34, "W": 35, "X": 36,
        "Y": 37, "Z": 38
      };

      let soma: number = 0;

      for (let i=0; i< numContainer.length-1; i++) {
        let caracter = numContainer.substr(i, 1);
        let letraValor = valores[caracter];
        let valorCaracter: number = 0;

        //calcula o valor do caracter onde "i" é o "peso"
        // se não encontrou a letra, significa que é numero
        valorCaracter = (!letraValor) ?  Number(caracter) : Number(letraValor);

        if (i>0)
          valorCaracter =  Number(valorCaracter*Math.pow(2, i));

        soma += valorCaracter;
      }

      let dv_calculado = ((soma%11) != 10) ? (soma%11) : 0;
      let dv_digitado = numContainer.substr(numContainer.length-1, 1);
      return (dv_calculado.toString() == dv_digitado.toString());
  }
}
