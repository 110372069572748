import { Booking } from 'app/_models/booking';
import { TrackingLista } from 'app/_models/tracking-lista';
import { FiltrosLaterais } from './../_models/filtros-laterais';
import { ValidationError } from 'app/_models/validation-error';
import { TipoEventoTracking } from 'app/_models/tipoEventoTracking';

export class RastreamentoCargasVM {
    trackings: TrackingLista[];
    tiposEventos: TipoEventoTracking[];
    erros: ValidationError[];
    FiltrosLateraisAtivos: FiltrosLaterais = new FiltrosLaterais();
}