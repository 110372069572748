export class Endereco
{
    logradouro: string;
    complemento: string;
    numero: string;
    bairro: string;
    cep: string;
    municipio: string;
    uf: string;
    idMunicipio: number;
}