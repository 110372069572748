import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';
import { SubTributaria } from '../_models/sub-tributaria';
import { ValidationResult } from '../_models/validation-result';

@Injectable({
  providedIn: 'root'
})
export class SubTributariaService {
  urlPath: string;

  constructor(
    private baseService: BaseService,
    private _msgService: MessageService) { }


  salvar(subStribGrupoEcon: SubTributaria): Observable<ValidationResult> {
    return this.baseService.Post('subTributaria', subStribGrupoEcon).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Substituição Tributário - Grupo Economico - Salvar', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Inclusao, SubTributaria);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getSubTributaria(id: number): Observable<SubTributaria> {
    return this.baseService.Get('subTributaria/' + id).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;

            if (retorno.IsValid)
                return retorno.Data as SubTributaria;
            else
                return null;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
}

  update(subTributaria: SubTributaria[]): Observable<ValidationResult> {
    return this.baseService.Put('subTributaria', SubTributaria).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Substituição Tributário - Grupo Economico - Atualização', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Alteracao, SubTributaria);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


  delete(id: number): Observable<ValidationResult> {
    return this.baseService.Delete('subTributaria/' + id).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Substituição Tributário - Grupo Economico - Exclusão', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Exclusao, id);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


obterSubTributarias(): Observable<SubTributaria[]> {
  return this.baseService.Get('subTributaria').pipe(
    map((response: ValidationResult) => {
      let retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._msgService.addMessageValidationError('Substituição Tributário - Grupo Economico', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Consulta, null);
      }

      return retorno.Data as SubTributaria[];

    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
}

exportarRelatorioCSV(SubTributariaes: SubTributaria[]): Observable<any> {
  if (!SubTributariaes || SubTributariaes.length === 0) {
    return;
  };


  const titulos: any[] = [
    'ID',
    'E-mail',
    'Nome',
    'Razão Social',
    'CNPJ',
    'Nome Fantasia',
    'Inscrição Estadual',
    'Logradouro',
    'Número',
    'Complemento',
    'Bairro',
    'UF',
    'Município',
    'CEP',
    'Telefone',
    'Perfil Portal',
    'Perfil E-cargo',
    'CNPJ(s) Cadastrados',
    'Status do Usuário',
    'Data de Solicitação',
    'Data de Aprovação',
    'Data de Reprovação',
    'Data Última Alteração de Senha',
    'Situação Senha Login'];

      this.baseService.DownloadXLSX(SubTributariaes, titulos, 'TaxasEsporadicas.xlsx');
};

}
