import { Component, OnInit, ViewChild } from '@angular/core';

import { SharedService } from 'app/_services/shared.service';
import { MessageService } from './../../_services/message.service';
import { UsuarioService } from './../../_services/usuario.service';
import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { Perfil } from 'app/_models/perfil';
import { UsuarioPesquisa } from './../../_models/usuario-pesquisa';
import { BaseComponent } from 'app/shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";

import { StatusUsuario } from 'app/_enums/status-usuario.enum';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { DatepickerService } from 'app/_services/datepicker.service';
import { DatePickerOptions, DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import { UsuarioPesquisaRequest } from 'app/_models/usuario-pesquisa-request';
import { SporadicTaxService } from 'app/_services/sporadic-tax.service';
import { SporadicTax } from 'app/_models/sporadic-tax';
import { DataService } from "./../../_services/data.service";
import { ListItem } from "./../../_models/listItem";
import { parse } from 'path';
import { BaseService } from 'app/_services/base.service';
import {TipoTaxaEsporadicaService} from './../../_services/tipo-taxa-esporadica.service'
import { TipoTaxaEsporadica } from 'app/_models/tipo-taxa-esporadica';

@Component({
    selector: 'app-tipo-taxa-esporadica',
    templateUrl: './tipo-taxa-esporadica.component.html',
    styleUrls: ['./tipo-taxa-esporadica.component.scss']
})
export class TipoTaxaEsporadicaComponent extends BaseComponent implements OnInit {

    @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
    @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

    filtro: UsuarioPesquisa = new UsuarioPesquisa();
    request: UsuarioPesquisaRequest = new UsuarioPesquisaRequest();

    public loadingSporadicTaxes: boolean = false;

    loading: boolean = false;
    comboStatus: FilterComboConfiguration;
    comboPerfis: FilterComboConfiguration;
    statusSelecionados: number[];
    perfisSelecionados: number[];
    pageSize: number = 50;
    downloading: boolean = false;

    tipoTaxaEsporadicaLista: TipoTaxaEsporadica[] = new Array();
    sporadicTaxTypeId: number;
    isEnabled: number;


    constructor(
        private baseService: BaseService,
        private _dataService: DataService,
        private _sharedService: SharedService,
        private _msgService: MessageService,
        private _usuarioService: UsuarioService,
        private _genericOrderbyService: GenericOrderbyService,
        private _datepickerService: DatepickerService,
        private _tipoTaxaEsporadicaService: TipoTaxaEsporadicaService ) {

          super();

        this._msgService.emitirMensagem.subscribe(
            msg => {
                this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
            }
        );

        this.ObterDataFields();
    }

    ngOnInit() {
        this.getTipoTaxaEsporadica(0, 0);

        let msg = this._msgService.getMessageRedirect();
        if (msg.show)
            this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Usuarios, Operacao.Consulta, this.filtro);
    }

    private getTipoTaxaEsporadica(sporadicTaxTypeId: number, isEnabled: number) {
        this.loading = true;
        this._tipoTaxaEsporadicaService.getAll()
            .subscribe(lista => {
                this.tipoTaxaEsporadicaLista = lista;
                console.log(this.tipoTaxaEsporadicaLista);
                this.paginacao.setAllData(this.tipoTaxaEsporadicaLista);
                this.paginacao.setPage(1);
                this.loading = false;
            }, (err) => {
            });

            this.loading = false;
    }


    Pesquisar() {
        console.log(this.sporadicTaxTypeId);
        console.log(this.isEnabled  );

        if (this.sporadicTaxTypeId == undefined)
          this.sporadicTaxTypeId=0;

          if (this.isEnabled == undefined)
          this.isEnabled=0;

      this.getTipoTaxaEsporadica(this.sporadicTaxTypeId, this.isEnabled);

    }

    public getClasse(status: StatusUsuario): string {
        return this._usuarioService.getClasse(status);
    }

    public getString(status: StatusUsuario): string {
        return this._usuarioService.getString(status);
    }

    getNomePerfilECargo(perfil: any): string {
        return this._usuarioService.getNomePerfilECargo(perfil);
    }

    setPageSize(value: number) {
        this.pageSize = value;
        //if (this.paginacao != )
        this.paginacao.setAllData(this.tipoTaxaEsporadicaLista);
        this.paginacao.setPageSize(this.pageSize);
        this.paginacao.setPage(1);
      }

      atribuiPeriodoInicialEFinal(): void {
        this.request.nome = this.filtro.nome;
        this.request.email = this.filtro.email;
        this.request.razaoSocial = this.filtro.razaoSocial;
        this.request.perfil = this.filtro.perfil;
        this.request.status = this.filtro.status;
        this.request.cnpj = this.filtro.cnpj;
        let dataInicio = this.filtro.cadastradosDe? this._datepickerService.ObtemData(this.filtro.cadastradosDe, 'DD/MM/YYYY'): null;
        let dataFim = this.filtro.cadastradosAte? this._datepickerService.ObtemData(this.filtro.cadastradosAte, 'DD/MM/YYYY'): null;
        this.request.cadastradosDe = dataInicio;
        this.request.cadastradosAte = dataFim
      }


      ObterDataFields() {


      }


      setHabilitado(isEnabled: boolean):string
      {
        return ((isEnabled == true) ? 'SIM' : 'NÃO');
      }

      exportarRelatorioCSV() {
        this.downloading = true;

        const titulos: any[] = [
          "id",
          "sporadicTaxName",
          "sporadicTaxTypeId",
          "originRegionId",
          "originModalId",
          "destinationRegionId",
          "destinationModalId",
          "containerType20Value",
          "containerType40Value",
          "startDate",
          "expirationDate",
          "createDate",
          "isEnabled"];


          const data: any[] = [];

          this.tipoTaxaEsporadicaLista.forEach(x => {
            data.push([
              x.isEnabled
            ]);
          });

        this.downloading = false;
        this.baseService.DownloadXLSX(data, titulos, 'TaxasEsporadicas.csv');

      }
}
