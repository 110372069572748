import { Navio } from "./navio";
import { Porto } from "./porto";

export class Prioridade {
    prioridadeID: number;
    portoOrigem: Porto;
    portoDestino: Porto;
    navios: Navio[];
    filtrarProgramacaoNavio: boolean;

    portosOrigemID: number[]; //para o combo-multiselect
    portosDestinoID: number[]; //para o combo-multiselect
    naviosID: number[]; //para o combo-multiselect

    constructor() {
      this.prioridadeID = 0;
      this.portoOrigem = new Porto();
      this.portoDestino = new Porto();
      this.navios = [];
      this.filtrarProgramacaoNavio = false;

      this.portosOrigemID = [];
      this.portosDestinoID = [];
      this.naviosID = [];
    }
}
