import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UsuarioComponent } from './usuario.component';
import { UsuarioDetalheComponent } from './usuario-detalhe/usuario-detalhe.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const usuariosRoutes: Routes = [
    { path: '', component: UsuarioComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe', component: UsuarioDetalheComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe/:id', component: UsuarioDetalheComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(usuariosRoutes)],
    exports: [RouterModule]
})
export class UsuarioRoutingModule {}