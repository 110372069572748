import { DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';

export class BaseFiltroTop {
  filtroGenerico: string;
  filtroGenerico2: string;
  municipiosOrigem: number[];
  portosOrigem: number[];
  municipiosDestino: number[];
  portosDestino: number[];  
  dataInicio: DateModel;
  dataFim: DateModel;
  tipoFiltroPeriodo: number;
}