import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewChildren,
} from "@angular/core";
import swal from "sweetalert2";

import { NavioViagem } from "./../../_models/navioViagem";
import { DoctoFatura } from "app/_models/doctoFatura";
import { DownloadArquivo } from "../../_models/downloadArquivo";
import { DoctoFaturaRequest } from "../../_models/doctoFatura-request";
import { NavioViagemRequest } from "../../_models/navioViagem-request";
import { DoctoFaturaArquivoRequest } from "../../_models/doctoFaturaArquivo-request";

import { AppService } from "app/_services/app.service";
import { SharedService } from "../../_services/shared.service";
import { MessageService } from "./../../_services/message.service";
import { DatepickerService } from "../../_services/datepicker.service";
import { NavioViagemService } from "../../_services/navio-viagem.service";
import { DocumentacaoService } from "../../_services/documentacao.service";
import { GrupoEconomicoService } from "app/_services/grupo-economico.service";

import { BaseComponent } from "./../../shared/base.component";
import { MessageComponent } from "app/shared/message/message.component";
import { ListaDocumentacaoComponent } from "app/cliente/documentacao/lista-documentacao/lista-documentacao.component";
import { FiltroDocumentacaoComponent } from "app/cliente/documentacao/filtro-documentacao/filtro-documentacao.component";
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { AnalyticsService } from "app/_services/analytics.service";
import { AnalyticsTela } from "../../_enums/analytics-tela";
import { AnalyticsDownload } from "../../_enums/analytics-download";
import { AnalyticsOperacao } from "../../_enums/analytics-operacao";

import { Operacao } from "app/_enums/operacao";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import { OkEntregaService } from "app/_services/ok-entrega.service";
import { ImagemNF } from "app/_models/imagemNF";
import { BaseService } from "app/_services/base.service";
import { OkEntregaConsultaLink } from "app/_models/ok-entrega-consulta-link";
import { ValidationResult } from "app/_models/validation-result";
import { DoctoFaturaArquivo } from "app/_models/doctoFaturaArquivo";
import { link } from "fs";

@Component({
  selector: "app-documentacao",
  templateUrl: "./documentacao.component.html",
  styleUrls: ["./documentacao.component.scss"],
})
export class DocumentacaoComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  cnpjs: string;
  downloading: boolean = false;
  sending: boolean = false;
  loading: boolean = false;
  noresult: boolean = false;
  consulta: DoctoFaturaRequest;
  listaDocumentacao: DoctoFatura[];
  empty: boolean = true;
  eAdmin: boolean = this._appService.isAdmin();
  eCliente: boolean = this._appService.isCliente();
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();
  clicouSelecionarTodos: boolean = false;
  cssClassAdmin = "";
  viagens: NavioViagem[];

  @ViewChild(FiltroDocumentacaoComponent, { static: true })
  filtro: FiltroDocumentacaoComponent;
  @ViewChild(ListaDocumentacaoComponent, { static: true })
  lista: ListaDocumentacaoComponent;
  @ViewChild(MessageComponent, { static: true }) mensagem: MessageComponent;

  constructor(
    private _documentacaoService: DocumentacaoService,
    private _grupoService: GrupoEconomicoService,
    private _navioViagemService: NavioViagemService,
    private _datePickerService: DatepickerService,
    private _sharedService: SharedService,
    private _msgService: MessageService,
    private _appService: AppService,
    private _analyticsService: AnalyticsService,
    private _okEntregaService: OkEntregaService,
    private _baseService: BaseService
  ) {
    super();

    this._msgService.emitirMensagem.subscribe((msg) => {
      this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
    });
  }

  ngOnInit() {
    this.cssClassAdmin =
      this.eAdmin || (this.eCliente && this.maisDeUmCNPJ)
        ? ""
        : " customer-profile";
    this.clicouSelecionarTodos = false;

    if (!this.eAdmin) {
      var usuario = this._appService.UsuarioLogado;
      this.cnpjs = usuario.cnpjs.join(";");
      this.cnpjAlterado(this.cnpjs);
      this.ObterDocumentacao();
    } else {
      this.filtro.cnpjdropdown.cnpjsGrupos = localStorage.getItem("cnpjGrupos");
      if (this.filtro.cnpjdropdown.cnpjsGrupos) {
        this.cnpjAlterado(this.filtro.cnpjdropdown.cnpjsGrupos);
        this.ObterDocumentacao();
      }
    }

    this._analyticsService.RegistrarAcesso(AnalyticsTela.Documentacao);
  }

  ngAfterViewInit(): void {
    this._grupoService.grupoEconomicoSelecionadoEvent.subscribe(
      (cnpjs: string) => {
        if (this.consulta && this.consulta.cnpjCli)
          if (cnpjs) this.clickPesquisar(this.consulta);
          else this.zeraPesquisa();
        else this.ObterDocumentacao();
      }
    );
  }

  clickEnviarArquivoEmail(dados: any) {
    this._msgService.clearMessage();
    this.sending = true;
    var arquivos = [];
    arquivos.push(dados.arquivo);
    this._analyticsService.RegistrarEmailDocumentacao(dados.arquivo);
    this._documentacaoService
      .enviaEmailArquivos(arquivos)
      .subscribe((sucesso) => {
        this.sending = false;
        if (sucesso) this.showSwal("E-mail enviado com sucesso!");
      });
  }

  clickEnviarTodosEmail(doctoFatura: DoctoFatura) {
    this._msgService.clearMessage();
    this.sending = true;
    this._analyticsService.RegistrarEmailDocumentacaoTodos();
    this._documentacaoService
      .enviaEmailArquivos(doctoFatura.arquivos)
      .subscribe((sucesso) => {
        this.sending = false;
        if (sucesso) this.showSwal("E-mail enviado com sucesso!");
      });
  }

  enviarSelecionados() {
    this._msgService.clearMessage();
    this.sending = true;
    var selecionados = this.lista.listaDoctoFatura.filter((d) => d.selecionado);

    if (this.clicouSelecionarTodos)
      this._analyticsService.RegistrarOperacao(
        AnalyticsTela.Documentacao,
        AnalyticsOperacao.EmailAposSelecionarTodos
      );
    else this._analyticsService.RegistrarEmailDocumentacaoTodos();

    this._documentacaoService
      .enviaEmailSelecionados(selecionados)
      .subscribe((sucesso) => {
        this.sending = false;
        if (sucesso) this.showSwal("E-mail enviado com sucesso!");
      });
  }

  clickDownloadArquivo(dados: any) {
    if (dados.arquivo.tipoArquivo === "PDF Canhoto") {
      let imagem = new ImagemNF(
        dados.arquivo.tipoArquivo,
        dados.arquivo.caminhoArquivo
      );

      this._okEntregaService.getPDFCanhotos([imagem]).subscribe((res) => {
        this._baseService.download(
          res[0],
          `canhoto-nf-${dados.arquivo.numNFe}.pdf`
        );
      });
    } else {
      this._msgService.clearMessage();
      this.downloading = true;
      this._analyticsService.RegistrarDownloadDocumentacao(dados.arquivo);
      this._documentacaoService
        .downloadArquivo(dados.arquivo)
        .subscribe((download) => {
          this.executarDownload(download);
        });
    }
  }

  clickDownloadTodosArquivos(doctoFatura: DoctoFatura) {
    this._msgService.clearMessage();
    this.downloading = true;
    this._analyticsService.RegistrarDownloadDocumentacaoTodos();
    this._documentacaoService
      .downloadTodos(doctoFatura.arquivos)
      .subscribe((download) => {
        let dados = doctoFatura.arquivos.find(
          (arquivo) => arquivo.tipoArquivo === "PDF Canhoto"
        );
        if (dados != null) {
          let imagem = new ImagemNF(dados.tipoArquivo, dados.caminhoArquivo);
          this._okEntregaService.getPDFCanhotos([imagem]).subscribe((res) => {
            this._baseService.addFileToZipAndDownload(
              this.b64toBlob(download.arquivo, "application/octet-stream", 512),
              res[0],
              `canhoto-nf-${dados.numNFe}.pdf`
            );
          });
        } else {
          this.executarDownload(download);
        }
        this.downloading = false;
      });
  }

  baixarCanhotos() {
    this._msgService.clearMessage();
    this.downloading = true;
    if (this.clicouSelecionarTodos)
      this._analyticsService.RegistrarOperacao(
        AnalyticsTela.Documentacao,
        AnalyticsOperacao.DownloadAposSelecionarTodos
      );
    else this._analyticsService.RegistrarDownloadDocumentacaoTodos();
    var selecionados = this.lista.listaDoctoFatura.filter((d) => d.selecionado);
    var consultas = selecionados.map(
      (selecionado) =>
        new OkEntregaConsultaLink(selecionado.numBooking, selecionado.numNFe)
    );
    this._okEntregaService
      .getLinkCanhotos(consultas)
      .subscribe((response: ValidationResult) => {
        this.validAndDownload(
          response,
          consultas.map((c) => c.numNF)
        );
      });
  }

  async baixarTodosSelecionados() {
    this._msgService.clearMessage();
    this.downloading = true;
    if (this.clicouSelecionarTodos)
      this._analyticsService.RegistrarOperacao(
        AnalyticsTela.Documentacao,
        AnalyticsOperacao.DownloadAposSelecionarTodos
      );
    else this._analyticsService.RegistrarDownloadDocumentacaoTodos();
    var selecionados = this.lista.listaDoctoFatura.filter((d) => d.selecionado);
    var consultas = selecionados.map(
      (selecionado) =>
        new OkEntregaConsultaLink(selecionado.numBooking, selecionado.numNFe)
    );
    const namesZips = [];
    let zips = [];

    var zipCo2 = await this.CertificadosCo2Zipados();
    if (zipCo2 != null) {
      zips.push(zipCo2);
      namesZips.push("certificados.zip");
    }

    var zipCanhotos = await this.canhotosZipados(consultas);
    if (zipCanhotos != null) {
      zips.push(zipCanhotos);
      namesZips.push("canhotos.zip");
    }

   this._documentacaoService
    .downloadSelecionados(selecionados)
    .subscribe((download) => {
      if(download != null){
        namesZips.push("documentos.zip");
        zips.push(
          this.b64toBlob(
            download.arquivo,
            "application/octet-stream",
            512
          )
        );
      }
      if(namesZips.length)
        this._baseService.zipAndDownload(
          zips,
          namesZips,
          "Documentacao.zip"
        );
      this.downloading = false;
    });

  }

  async canhotosZipados(consultas) {
    return await this._okEntregaService
      .getLinkCanhotos(consultas)
      .toPromise()
      .then(async (response: ValidationResult) => {
        let retorno: ValidationResult = response;
        if (retorno.IsValid) {
          let requests = Object.values(retorno.Data)
            .filter(
              (consulta: OkEntregaConsultaLink) =>
                consulta.linkCanhoto != null && consulta.linkCanhoto != ""
            )
            .map(
              (consulta: OkEntregaConsultaLink) =>
                new ImagemNF("PDF Canhoto", consulta.linkCanhoto)
            );
          return await this._okEntregaService.getPDFCanhotos(requests).toPromise().then((res) => {
            let names = res?.map(
              (r, index) => `canhoto-nf-${consultas[index].numNF}.pdf`
            );
            if (res == null || res.length == 0) return null;
            return this._baseService.zip(res, names);
          });
        }
      });
  }

  async CertificadosCo2Zipados() {
    var links = this.construirLinks();
    return await this._baseService
      .BaseGetAllBlobforkJoin(links)
      .toPromise()
      .then((res: Blob[]) => {
        res = res?.filter((x) => x?.type == "application/x-download");
        if (res == null || res?.length == 0) return null;
        return this._baseService.zip(
          res,
          this.getNamesCertificadosCo2(links)
        );
      });
  }

  validAndDownload(response: ValidationResult, nfNums, returnZip = false) {
    let retorno: ValidationResult = response;
    if (retorno.IsValid) {
      let requests = (Object.values(retorno.Data) as OkEntregaConsultaLink[])
        .filter(
          (consulta) =>
            consulta.linkCanhoto != null && consulta.linkCanhoto != ""
        )
        .map((consulta) => new ImagemNF("PDF Canhoto", consulta.linkCanhoto));
      this._okEntregaService.getPDFCanhotos(requests).subscribe((res) => {
        let names = res?.map((r, index) => `canhoto-nf-${nfNums[index]}.pdf`);
        if (returnZip) {
          return this._baseService.zip(res, names);
        } else {
          this._baseService.zipAndDownload(res, names, "canhotos.zip");
        }
      });
    } else {
      this._msgService.addMessageArray(
        "Documentacao",
        retorno.Erros.map((e) => e.Message),
        "error",
        LogFrontFuncionalidade.Documentacao,
        Operacao.Consulta
      );
    }
    this.downloading = false;
  }

  baixarCertificados() {
    this._msgService.clearMessage();
    this.downloading = true;
    if (this.clicouSelecionarTodos)
      this._analyticsService.RegistrarOperacao(
        AnalyticsTela.Documentacao,
        AnalyticsOperacao.DownloadAposSelecionarTodos
      );
    else this._analyticsService.RegistrarDownloadDocumentacaoTodos();
    const links = this.construirLinks();

    this._baseService.downloadAllAndZip(
      links,
      this.getNamesCertificadosCo2(links),
      "certificados.zip"
    );
    this.downloading = false;
  }

  private getNamesCertificadosCo2(links: string[]): string[] {
    return links.map((x, index) => "certificado-co2-" + index + ".pdf");
  }

  private construirLinks() {
    const selecionados = this.lista.listaDoctoFatura.filter(
      (d) =>
        d.selecionado &&
        d.indicEcoFrete &&
        d.idViagreen &&
        d.idViagreen.length > 0
    );
    const idsViaGreen = [];
    selecionados.map((x) =>
      x.idViagreen
        .split(";")
        .filter((x) => x != "")
        .forEach((x) => idsViaGreen.push(x))
    );

    const links = idsViaGreen.map((x) => {
      return (
        this._appService.configViaGreen.apiUrlDownloadCertificado +
        "/" +
        x +
        "?accessToken=" +
        this._appService.configViaGreen.token
      );
    });
    return links;
  }

  baixarSelecionados(returnB64 = false) {
    this._msgService.clearMessage();
    this.downloading = true;
    if (this.clicouSelecionarTodos)
      this._analyticsService.RegistrarOperacao(
        AnalyticsTela.Documentacao,
        AnalyticsOperacao.DownloadAposSelecionarTodos
      );
    else this._analyticsService.RegistrarDownloadDocumentacaoTodos();
    var selecionados = this.lista.listaDoctoFatura.filter((d) => d.selecionado);

    this._documentacaoService
      .downloadSelecionados(selecionados)
      .subscribe((download) => {
        if (returnB64)
          return this.b64toBlob(
            download.arquivo,
            "application/octet-stream",
            512
          );
        else this.executarDownload(download);
      });
  }

  selecionarTodos() {
    this._analyticsService.RegistrarOperacao(
      AnalyticsTela.Documentacao,
      AnalyticsOperacao.SelecionarTodos
    );
    this.clicouSelecionarTodos = true;
    this.lista.selecionarTodos();
  }

  alteradoPageSize(pageSize: number) {
    this.lista.alteradoPageSize(pageSize);
  }

  alteradaOrdenacao(ordenacao: string) {
    this.lista.alteradaOrdenacao(ordenacao);
  }

  buscarArquivos(doctoFatura: DoctoFatura) {
    var request = new DoctoFaturaArquivoRequest();
    request.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    request.numFatBol = doctoFatura.numFatBol;
    request.numNfeCte = doctoFatura.numNFeCte;
    request.numNFe = doctoFatura.numNFe;
    request.numBooking = doctoFatura.numBooking;
    request.doctoPagtoId = doctoFatura.doctoPagtoId;
    request.doctoTransporteId = doctoFatura.doctoTransporteId;
    this._documentacaoService
      .getListaArquivos(request)
      .subscribe((listaArquivos) => {
        doctoFatura.arquivos = listaArquivos;
      });
  }

  zeraPesquisa() {
    this.empty = true;
    this.noresult = false;
    this.loading = false;
    this.lista.listaDoctoFatura = [];
    if (this.lista.paginacao) {
      this.lista.paginacao.pagedItems = [];
      this.lista.paginacao.pager = {};
    }
  }

  ObterDocumentacao() {
    this.loading = true;

    var dataInicio = this.filtro.dataInicio
      ? this.ObterData(this.filtro.dataInicio, 0)
      : this.ObterData(this.filtro.dataInicio, -7);
    var dataFim = this.ObterData(this.filtro.dataFim, 0);

    this.consulta = new DoctoFaturaRequest();
    this.consulta.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    this.consulta.dataEmissaoIni = dataInicio;
    this.consulta.dataEmissaoFim = dataFim;

    this.clickPesquisar(this.consulta, false);
  }

  clickPesquisar(
    consulta: DoctoFaturaRequest,
    registrarAnalytics: boolean = true
  ) {
    this._msgService.clearMessage();
    if (
      this.eAdmin &&
      (this.filtro.cnpjdropdown.CNPJDigitado == undefined ||
        this.filtro.cnpjdropdown.CNPJDigitado == "") &&
      !this.filtro.cnpjdropdown.cnpjsGrupos
    ) {
      this._msgService.addMessage(
        "Documentacao",
        "Informe o CNPJ do Cliente ou selecione ao menos um Grupo Econômico.",
        "error",
        LogFrontFuncionalidade.Documentacao,
        Operacao.Consulta,
        consulta
      );
      this.zeraPesquisa();
      return;
    } else {
      if (this.eAdmin) {
        this.cnpjs = this.filtro.cnpjdropdown.cnpjsGrupos;
        if (
          this.filtro.cnpjdropdown.cnpjsGrupos &&
          this.filtro.cnpjdropdown.CNPJDigitado
        ) {
          if (!this.filtro.cnpjdropdown.validaCNPJnoGrupo()) {
            this._msgService.addMessage(
              "Documentacao",
              "CNPJ informado não pertence ao(s) Grupo(s) Econômico(s) selecionado(s)!",
              "error",
              LogFrontFuncionalidade.Documentacao,
              Operacao.Consulta,
              consulta
            );
            this.zeraPesquisa();
            return;
          }
        }
        this.cnpjs = this.filtro.cnpjdropdown.CNPJDigitado
          ? this.filtro.cnpjdropdown.CNPJDigitado
          : this.filtro.cnpjdropdown.cnpjsGrupos;
      }

      var ini = consulta.dataEmissaoIni.valueOf();
      var fim = consulta.dataEmissaoFim.valueOf();

      if (fim < ini) {
        this._msgService.addMessage(
          "Documentacao",
          "A data início não pode ser maior que a data fim.",
          "error",
          LogFrontFuncionalidade.Documentacao,
          Operacao.Consulta,
          consulta
        );
        return;
      }

      var diasDiff = Math.floor(
        (consulta.dataEmissaoFim.valueOf() -
          consulta.dataEmissaoIni.valueOf()) /
          (1000 * 60 * 60 * 24)
      );

      if (diasDiff > 30) {
        this._msgService.addMessage(
          "Documentacao",
          "O filtro por período de emissão não deve ser superior à 30 dias.",
          "error",
          LogFrontFuncionalidade.Documentacao,
          Operacao.Consulta,
          consulta
        );
        return;
      }

      //limpa mensagens de erros
      this._msgService.clearMessage();
      this.loading = true;
      this.filtro.loading = true;
      this.noresult = false;
      consulta.cnpjCli = this.cnpjs;

      this.cnpjAlterado(this.cnpjs);

      if (registrarAnalytics)
        this._analyticsService.RegistrarFiltroDocumentacao(consulta);

      this._documentacaoService
        .getListaDocumentacao(consulta)
        .subscribe((listaDocumentacao) => {
          this.empty = false;
          this.loading = false;
          this.noresult =
            listaDocumentacao == undefined || listaDocumentacao.length == 0;
          if (!listaDocumentacao) return;
          this.lista.carregaListaDocumentacao(listaDocumentacao);
          this.filtro.ConfiguraNavioViagens(this.viagens, listaDocumentacao);
        });
    }
  }

  ObterNaviosViagens(consulta: NavioViagemRequest) {
    this.filtro.loading = true;
    this._navioViagemService.getNavioViagem(consulta).subscribe((viagens) => {
      this.filtro.loading = false;
      //this.filtro.ConfiguraNavioViagens(viagens);
      this.viagens = viagens;
    });
  }

  cnpjAlterado(cnpj: string) {
    this.cnpjs = cnpj;
    //var usuario = this._appService.UsuarioLogado;
    var consulta = new NavioViagemRequest();
    consulta.CNPJCliente = cnpj; //usuario.CNPJs.join(";");
    consulta.CNPJProvedor = cnpj; //usuario.CNPJs.join(";");
    consulta.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    consulta.tipo = "BOOKING";
    consulta.dataIni = this.ObterData(this.filtro.dataInicio, -30);
    consulta.dataFim = this.ObterData(this.filtro.dataFim, 0);

    this.ObterNaviosViagens(consulta);
  }

  private executarDownload(download: DownloadArquivo) {
    this.downloading = false;
    if (!download || !download.arquivo) {
      this._msgService.addMessage(
        "Erro ao realizar download",
        "Arquivo não encontrado",
        "danger",
        LogFrontFuncionalidade.Documentacao,
        Operacao.Download,
        download
      );
      return;
    }
    var FileSaver = require("file-saver");
    var byteArray = this.b64toBlob(
      download.arquivo,
      "application/octet-stream",
      512
    );
    FileSaver.saveAs(byteArray, download.nomeArquivo);
  }

  private ObterData(dateModel: DateModel, dateOffset: number) {
    return dateModel != undefined
      ? this._datePickerService.ObtemData(dateModel)
      : this._sharedService.addDays(new Date(), dateOffset);
  }

  private showSwal(message: string) {
    swal({
      title: "Sucesso",
      text: message,
      type: "success",
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: "OK",
    }).then(
      (success) => {},
      (dismiss) => {
        // console.log(dismiss);
      }
    );
  }

  private b64toBlob(b64Data, contentType, sliceSize): Blob {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
