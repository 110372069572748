import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaleConoscoComponent } from './fale-conosco.component';

const faleConoscoRoutes: Routes = [
    { path: '', component: FaleConoscoComponent }
];

@NgModule({
    imports: [RouterModule.forChild(faleConoscoRoutes)],
    exports: [RouterModule]
})
export class FaleConoscoRoutingModule {}