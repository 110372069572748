import { filtrosLaterais } from './filtros-laterais';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CotacaoRequest extends filtrosLaterais {
    status: string;
    cotacaoID: number;    
    usuarioID: number;
    idTrecho: number;
    idsMunicDes: string; 
    idsMunicOri: string; 
    idsPortoOri: string; 
    idsPortoDes: string;
    cnpjCli: string;
}