import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigPortal, ConfigViaGreen } from 'app/_models/config-portal';
import { UsuarioLogado } from './../_models/usuariologado';
import { PerfilUsuario } from 'app/_enums/perfil-usuario.enum';
import { AnalyticsConfig } from '../_models/analytics-config';
import { ConfigUpload } from '../_models/config-upload';
import { ConfigService } from 'app/_services/config.service';
import { MapsConfig } from 'app/_models/maps-config';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  configuracoesCarregadas = new EventEmitter();
  emitirLogin = new EventEmitter<boolean>();
  private _usuarioLogado: UsuarioLogado = null;
  configUpload: ConfigUpload;
  configAnalytics: AnalyticsConfig;
  configMaps: MapsConfig;
  configViaGreen: ConfigViaGreen;
  apiUrl: string = '';
  tokenExpirado: boolean = false;
  versaoWebPortal: string = '2.0.6';

  constructor(private configService: ConfigService,
              private _router: Router) {
    this.UsuarioLogado = this.getLocalStorageJson('currentUser');
    this.configInfo = this.getLocalStorageJson('apiUrl');
    this.configUpload = this.getLocalStorageJson('configUpload');
    this.configViaGreen = configService._viaGreenConfig;
    if (!this.configUpload)
      this.configUpload = new ConfigUpload();
  }

  public setLocalStorage(key: string, item: any){
    localStorage.setItem(key, item);
  }

  public getLocalStorage(key: string): any {
    try { return localStorage.getItem(key); }
    catch (ex) { return null; }
  }

  public setLocalStorageJson(key: string, item: any){
    this.setLocalStorage(key, JSON.stringify(item));
  }

  public getLocalStorageJson(key: string): any {
    try { return JSON.parse(this.getLocalStorage(key)); }
    catch (ex) { return null; }
  }

  public clearLocalStorage(key: string, setVazio: boolean = true){
    localStorage.removeItem(key);
    if (setVazio)
      this.setLocalStorage(key, '');
  }

  _configInfo: ConfigPortal;

  get configInfo(): ConfigPortal {
    return this._configInfo;
  }

  set configInfo(configInfo: ConfigPortal) {
    this._configInfo = configInfo;
    if (configInfo)
      this.apiUrl = configInfo.apiUrl;
  }

  public getVersaoWebPortal() {
    return this.versaoWebPortal;
  }

  public getBrowserVersao() {
    return localStorage.getItem('browser_versao');
  }

  get UsuarioLogado(): UsuarioLogado {
    return this._usuarioLogado;
  }

  set UsuarioLogado(usuarioLogado: UsuarioLogado) {
    this._usuarioLogado = usuarioLogado;
  }

  public carregaConfiguracoesUpload(){
    this.configService.getConfigUpload(this.apiUrl, this.UsuarioLogado)
      .subscribe(configUpload => {
        this.configUpload = configUpload;
        this.setLocalStorageJson('configUpload', configUpload);
      });
  }

  public isAuthenticated(): boolean {
    return this._usuarioLogado != null;
  }

  public isAdmin(): boolean {
      return (this.UsuarioLogado != null) && (this.UsuarioLogado.PerfilUsuario.toString().charCodeAt(0) == PerfilUsuario.Administrador);
  }

  public isCliente(): boolean {
    return ((this.UsuarioLogado.PerfilUsuario.toString().charCodeAt(0) == PerfilUsuario.Cliente) ||
      (this.UsuarioLogado.PerfilUsuario.toString().charCodeAt(0) == PerfilUsuario.Ambos));
  }

  public isClienteEProvedor(): boolean {
    return (this.UsuarioLogado != null) &&
    this.UsuarioLogado.PerfilUsuario.toString().charCodeAt(0) == PerfilUsuario.Administrador &&
     (this.UsuarioLogado.Funcionalidades != null) && ((
      this.UsuarioLogado.Funcionalidades.length >= 11 // Todos os menus habilitados
    ));
  }

  public maisDeUmCNPJ(): boolean {
    return (this.UsuarioLogado.CNPJs.length > 1);
  }

  public hasPermission(path: string): boolean {
    if (!this.isAuthenticated() || !this._usuarioLogado.Funcionalidades)
      return false;

    return this._usuarioLogado.Funcionalidades.find(a => a == path) != null;
  }

  public acessoNegado() {
    this.UsuarioLogado = null;
    this.emitirLogin.emit(true);
    //this._authService.logout();
    this.tokenExpirado = true;
    this._router.navigate(['/home']);
  }

  public limpaStorage(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('labelGrupo');
    localStorage.removeItem('cnpjGrupos');
    localStorage.removeItem('idsGruposSelecionados');
    localStorage.setItem('labelGrupo', '');
    localStorage.setItem('cnpjGrupos', '');
    localStorage.setItem('idsGruposSelecionados', '');
  }
}
