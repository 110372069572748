import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetSenhaComponent } from './reset-senha.component';
import { ResetSenhaRoutingModule } from './reset-senha.routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';



@NgModule({
    imports: [ 
        CommonModule, 
        FormsModule, 
        SharedModule,
        ResetSenhaRoutingModule 
    ],
    exports: [],
    declarations: [ ResetSenhaComponent ],
    providers: [],
})
export class ResetSenhaModule {  }