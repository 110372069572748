
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';


import { BaseService } from './base.service';
import { ValidationResult } from './../_models/validation-result';
import { Municipio } from './../_models/municipio';
import { MunicipioRequest } from './../_models/municipio-request';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MunicipioService {

  constructor(private _baseService: BaseService, protected http: HttpClient) { }

  getMunicipios(uf: string = ''): Observable<Municipio[]> {
    return this._baseService.Get('municipio/' + uf).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Municipio[];
        else
          return null;
      }),
      catchError(e => {
        return this._baseService.handleError(e);
      }),)
      ;
  }

  getMunicipiosAutoComplete(descricao: string): Observable<Municipio[]> {
    return this._baseService.Get('municipio/filtro/' + descricao).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Municipio[];
        else
          return null;
      }),
      catchError(e => {
        return this._baseService.handleError(e);
      }),)
      ;
  }

  getMunicipiosEspecial(municipio: MunicipioRequest): Observable<Municipio[]> {
    return this._baseService.Post('municipio', municipio).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as Municipio[];
        else
          return null;
      }),
      catchError(e => {
        return this._baseService.handleError(e);
      }),)
      ;
  }
}
