
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmModule } from './../../shared/confirm/confirm.module';
import { SharedModule } from './../../shared/shared.module';

import { UsuarioRoutingModule } from './usuario.routing.module';
import { UsuarioComponent } from './usuario.component';
import { UsuarioDetalheComponent } from './usuario-detalhe/usuario-detalhe.component';
import { UsuarioService } from './../../_services/usuario.service';

import { FuncionalidadeService } from './../../_services/funcionalidade.service';
import { MascaraModule } from "app/shared/mascara/mascara.module";
import { PerfilService } from 'app/_services/perfil.service';
import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';
import { DatepickerService } from 'app/_services/datepicker.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        UsuarioRoutingModule,
        ModalModule.forRoot(),
        ConfirmModule,
        SharedModule,
        MascaraModule,
        DatePickerModule
    ],
    exports: [],
    declarations: [
        UsuarioComponent,
        UsuarioDetalheComponent
    ],
    providers: [
        PerfilService,
        UsuarioService,
        FuncionalidadeService,
        DatepickerService
    ]
})
export class UsuarioModule { }
