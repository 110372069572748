export class DoctoFaturaRequest {
    filtroGenerico: string;
    numRefCliente: string;
    numNFeCte: string;
    numNFe: string;
    numNFSe: string;
    numFatBol: string;
    tipoDocto: string;
    numBooking: string;
    
    cnpjDest: string;
    ieDest: string;
    numContainer: string;
    cnpjEmb: string;
    ieEmb: string;
    cnpjCli: string;
    ieCli: string;
    idUsuario: number;
    idNavioViagem: number;
    dataVenctoIni: Date;
    dataVenctoFim: Date;
    dataEmissaoIni: Date;
    dataEmissaoFim: Date;
}