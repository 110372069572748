

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/_guards/auth.guard';
import { DocumentacaoComponent } from './documentacao.component';


const documentacaoRoutes: Routes = [
    { path: '', component: DocumentacaoComponent, canActivate: [AuthGuard] }
];
@NgModule({
    imports: [RouterModule.forChild(documentacaoRoutes)],
    exports: [RouterModule]
})
export class DocumentacaoRoutingModule {}