import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Anel } from './../../../_models/anel';
import { Porto } from './../../../_models/porto';
import { SentidoRota } from 'app/_enums/sentido-rota.enum';

import { SharedService } from './../../../_services/shared.service';
import { MessageService } from './../../../_services/message.service';
import { AnelRotaService } from './../../../_services/anel-rota.service';

import { BaseComponent } from './../../../shared/base.component';
import { MessageComponent } from './../../../shared/message/message.component';
import { RotasAnelComponent } from "./rotas-anel/rotas-anel.component";

import { Operacao } from 'app/_enums/operacao';
import { SentidoRotaAware } from "app/_enums/sentido-rota-aware.decorator";
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'app-anel-rota-detalhe',
  templateUrl: './anel-rota-detalhe.component.html',
  styleUrls: ['./anel-rota-detalhe.component.scss']
})
@SentidoRotaAware
export class AnelRotaDetalheComponent extends BaseComponent implements OnInit {

  public portos: Porto[];
  public loading: boolean = false;
  public loadingPortos: boolean = false;
  public id: number = 0;
  public titulo: string = 'Novo Anel e Rotas';
  public anel: Anel = new Anel();

  inscricaoGet: Subscription;

  @ViewChild(MessageComponent, { static: true}) mensagem: MessageComponent;
  @ViewChild("rotasSul", { static: true}) rotasSul: RotasAnelComponent;
  @ViewChild("rotasNorte", { static: true}) rotasNorte: RotasAnelComponent;

  constructor(private _route: ActivatedRoute,
    private _msgService: MessageService,
    private _router: Router,
    private _anelRotaService: AnelRotaService,
    private _sharedService: SharedService) 
    { 
      super ();
      
      this._msgService.emitirMensagem.subscribe(
        msg => { 
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
        }
      );
    }

  ngOnInit(): void {
    this.inscricaoGet = this._route.params.subscribe(
      (params: any) => {
        this.id = params['id'];
        if (this.id) {
          this.titulo = 'Editar Anel e Rotas';
          this.loading = true;
          this._anelRotaService.getAnel(this.id)
            .subscribe(anel => {
              this.anel = anel;
              this.loading = false;
              this.SetUltimoNovo();
            });
        }
        else {
          this.titulo = 'Novo  Anel e Rotas';
          this.anel = new Anel();
          this.SetUltimoNovo();
        }
      }
    );
    this.ObterPortos();
  }

  public ObterPortos() {
    this.loadingPortos = true;
    this._sharedService.getPortosTodos()
      .subscribe(p => {
        this.portos = p;
        this.loadingPortos = false;
      });
  }

  public SalvarAnelRota() {
    //Preenche último porto se o usuário não apertou Adicionar (+)
    this.rotasSul.AdicionaRotaPreenchida();
    this.rotasNorte.AdicionaRotaPreenchida();

    if (this.ValidaAnel()) {
      this.loading = true;
      this.anel.Rotas = this.rotasSul.rotas.filter(rota => !rota.Novo);
      this.anel.Rotas.push.apply(this.anel.Rotas, this.rotasNorte.rotas.filter(rota => !rota.Novo));
      this._anelRotaService.salvarAnel(this.anel)
        .subscribe(result => {
          this.loading = false;

          if (result.IsValid) {
            this._msgService.addMessageRedirect(this.titulo, result.Message, "success");
            this._router.navigate(['anel-rota']);
          }
          else
          {
            //this.SetUltimoNovo();
            result.Erros.forEach(element => this._msgService.addMessage(this.titulo, element.Message, "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.anel) );
          }
        });
    }
  }

  public ValidaAnel(): boolean {
    if (!this.rotasSul.ContemRotas() || !this.rotasNorte.ContemRotas()) {
      this._msgService.addMessage('Adicionar Anel', 'O Anel deve conter ao menos uma rota em cada sentido!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.anel);
      return false;
    }

    if (this.anel.Nome.trim() == "") {
      this._msgService.addMessage('Adicionar Anel', 'O Nome do Anel é obrigatório!', "error", LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao, this.anel);
      return false;
    }

    return true;
  }

  private SetUltimoNovo(){
    if (this.anel.Rotas != undefined)
      this.rotasSul.CarregarRotas(this.anel.Rotas.filter(rota => rota.Sentido == SentidoRota.Sul));
    this.rotasSul.SetUltimoNovo(this.anel.AnelID);

    if (this.anel.Rotas != undefined)
      this.rotasNorte.CarregarRotas(this.anel.Rotas.filter(rota => rota.Sentido == SentidoRota.Norte));
    this.rotasNorte.SetUltimoNovo(this.anel.AnelID);
  }
}
