import { BaseComponent } from "app/shared/base.component";

export class BaseParametrosHoras extends BaseComponent {
    public descricoes: { [id: string]: string; } = {
        "QtdHrEntrega": "ENTREGA PORTA - Permitir agendamento antes do ETA, a partir de",
        "QtdHrAgendEntrega": "ENTREGA PORTA - Permitir Programação após ETS, a partir de",
        "QtdHrAgendColetaPA": "COLETA PORTA - Programar para antes do deadline, com máximo de",
        "QtdHrAgendColetaPO": "BOOKING PORTO - Solicitar com antecedência mínima de",
        "QtdHrMinimasAgendColeta": "COLETA PORTA - Solicitar com antecedência mínima de",
        "QtdHrMinimasAgendEntrega": "ENTREGA PORTA - Solicitar com antecedência mínima de"
    };
}

/**
        "QtdHrEntrega": "Disponível para Agendamento da Entrega (horas antes da previsão de chegada)",
        "QtdHrAgendEntrega": "Horas para entrega da carga após ETA",
        "QtdHrAgendColetaPA": "Disponível para Agendamento de Coleta (horas antes do deadline de entrega - Porta)",
        "QtdHrAgendColetaPO": "Disponível para Agendamento de Coleta (horas antes do deadline de entrega - Porto)",
        "QtdHrMinimasAgendColeta": "Quantidade mínima para agendamento de coleta",
        "QtdHrMinimasAgendEntrega": "Quantidade mínima para agendamento de entrega"
 * 
"Na tela Parametrizações (Definições de dias), que estamos ajustando para horas, pedimos ajustar também as labels:
Disponível para agendamento de coleta (dias antes do deadline de entrega - Porta)
Substituir label por: Programar para antes do dead line, com máximo de
                    
Quantidade mínima para agendamento de coleta
Substituir label por: Solicitar com antecedência mínima de
                   
Disponível para agendamento de Entrega (dias antes do da previsão de chegada)
Substituir label por: Permitir agendamento antes do ETA, a partir de
                   
Dias para entrega da carga após ETA
Substituir label por: Permitir Programação após ETS, a partir de
                   
Quantidade mínima para agendamento de entrega
Substituir label por: Solicitar com antecedência mínima de
                   
Disponível para agendamento de coleta (dias antes do deadline de entrega - PortO)
Substituir label por: Solicitar com antecedência mínima de"						
 */