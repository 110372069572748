
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';

import { ConfirmComponent } from './confirm.component';
import { ConfirmService } from './confirm.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ModalModule.forRoot()
    ],
    exports: [ConfirmComponent],
    declarations: [
        ConfirmComponent
    ],
    providers: [ConfirmService],
})
export class ConfirmModule { }
