import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CaixaEntradaComponent } from './caixa-entrada.component';
import { CaixaEntradaDetalheComponent } from './caixa-entrada-detalhe/caixa-entrada-detalhe.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const caixaEntradasRoutes: Routes = [
    { path: '', component: CaixaEntradaComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe', component: CaixaEntradaDetalheComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe/:id', component: CaixaEntradaDetalheComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(caixaEntradasRoutes)],
    exports: [RouterModule]
})
export class CaixaEntradaRoutingModule {}