import { Component, Input } from "@angular/core";
import { SilResponse } from "app/_models/info-sil";

@Component({
  selector: 'info-sil-modal',
  templateUrl: './info-sil-modal.component.html'
})
export class InfoSilModalComponent {

  @Input() infos: SilResponse;
  @Input() evento: string;
}
