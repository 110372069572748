import { DateModel, IDateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";

export class AgendamentoColeta {
    idBooking: number;
    idUsuario: number;
    tamanhoContainer: number;
    qtdeContainer: number;
    numAgendamento: number;
    idMunicipioCol: number;
    idAgendamento: number;
    valMercadoria: number;

    strValMercadoria: string;
    strDataColeta: string;

    peso: number;
    numRefCliente: string;
    dataColeta: Date;
    horaColeta: string;
    observacao: string;
    cnpjEmb: string;
    ieEmb: string;
    nomeEmb: string;
    logradouroCol: string;
    numeroEndCol: string;
    bairroCol: string;
    complementoCol: string;
    municipioCol: string;
    ufCol: string;
    contatoCol: string;
    dddCol: string;
    foneCol: string;
    cepCol: string;
    categoriaContainer: string;
    statusAgendamento: string;

    dataSelecionada: DateModel;

    get telefoneColetaComDDD(): string {
        return this.dddCol + this.foneCol;
    }
}