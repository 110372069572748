import { Component, OnInit, ViewChild } from '@angular/core';

import { PerfilService } from '../../_services/perfil.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from './../../_services/message.service';
import { UsuarioService } from './../../_services/usuario.service';
import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { Perfil } from 'app/_models/perfil';
import { Usuario } from './../../_models/usuario';
import { UsuarioPesquisa } from './../../_models/usuario-pesquisa';
import { BaseComponent } from 'app/shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";

import { StatusUsuario } from 'app/_enums/status-usuario.enum';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { DatepickerService } from 'app/_services/datepicker.service';
import { DatePickerOptions, DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import * as moment from 'moment-timezone';
import { UsuarioPesquisaRequest } from 'app/_models/usuario-pesquisa-request';
import { SporadicTaxService } from 'app/_services/sporadic-tax.service';
import { SporadicTax } from 'app/_models/sporadic-tax';
import { DataService } from "./../../_services/data.service";
import { ListItem } from "./../../_models/listItem";
import { parse } from 'path';
import { BaseService } from 'app/_services/base.service';
import { SubTributariaService } from 'app/_services/sub-tributaria.service';
import { SubTributaria } from 'app/_models/sub-tributaria';
import { CustomerInfoService } from 'app/_services/customer-info.service';
import { UF } from 'app/_models/uf';

@Component({
    selector: 'app-sub-tributaria',
    templateUrl: './sub-tributaria.component.html',
    styleUrls: ['./sub-tributaria.component.scss']
})
export class SubTributariaCompoent extends BaseComponent implements OnInit {

    @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
    @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

    filtro: UsuarioPesquisa = new UsuarioPesquisa();
    request: UsuarioPesquisaRequest = new UsuarioPesquisaRequest();

    public loadingSporadicTaxes: boolean = false;

    loading: boolean = false;
    comboStatus: FilterComboConfiguration;
    comboPerfis: FilterComboConfiguration;
    statusSelecionados: number[];
    perfisSelecionados: number[];
    perfis: Perfil[];
    pageSize: number = 50;
    downloading: boolean = false;

    listUfs: UF[] = new Array();
    tiposModais: ListItem[] = new Array();
    subTributariasList: SubTributaria[] = new Array();

    statusesList: ListItem[] = new Array();
    taxTypesList: ListItem[] = new Array();

    sporadicTaxTypeId: number;
    isEnabled: number;

    optionsInicio: DatePickerOptions = {
      format: 'DD-MM-YYYY',
      todayText: 'Hoje',
      style: 'big'
    };

    optionsFim: DatePickerOptions;
    dataInicio: DateModel;
    dataFim: DateModel;

    taxTypesFilterCboConfig: FilterComboConfiguration;
    statusesFilterCboConfig: FilterComboConfiguration;

    listGrupoEconomico: ListItem[] = new Array();

    constructor(
        private baseService: BaseService,
        private _dataService: DataService,
        private _sharedService: SharedService,
        private _msgService: MessageService,
        private _usuarioService: UsuarioService,
        private _genericOrderbyService: GenericOrderbyService,
        private _customerInfoService: CustomerInfoService,
        private _datepickerService: DatepickerService,
        private _subTriGrupoEconService: SubTributariaService ) {

          super();

        this.taxTypesFilterCboConfig = new FilterComboConfiguration("Selecione...");
        this.taxTypesFilterCboConfig.settings = {
          checkedStyle: 'glyphicon',
          dynamicTitleMaxItems: 1,
          enableSearch: false
        };

        this.statusesFilterCboConfig = new FilterComboConfiguration("Selecione...");
        this.statusesFilterCboConfig.settings = {
          checkedStyle: 'glyphicon',
          dynamicTitleMaxItems: 1,
          enableSearch: false
        };

        this.optionsInicio = this._datepickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), -30));
        this.optionsFim = this._datepickerService.GenerateOptionsConfig(new Date());

        this._msgService.emitirMensagem.subscribe(
            msg => {
                this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
            }
        );

        this.ObterDataFields();
    }

    ngOnInit() {
        this.listGrupoEmpresarial();
        this.getSubTributarias(0, 0);

        let msg = this._msgService.getMessageRedirect();
        if (msg.show)
            this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Usuarios, Operacao.Consulta, this.filtro);
    }

    private getSubTributarias(sporadicTaxTypeId: number, isEnabled: number) {
        this.loading = true;
        this._subTriGrupoEconService.obterSubTributarias()
            .subscribe(lista => {
                this.subTributariasList = lista;
                console.log(this.subTributariasList);
                this.paginacao.setAllData(this.subTributariasList);
                this.paginacao.setPage(1);
                this.loading = false;
            }, (err) => {
            });

            this.loading = false;
    }


    Pesquisar() {
        console.log(this.sporadicTaxTypeId);
        console.log(this.isEnabled  );

        if (this.sporadicTaxTypeId == undefined)
          this.sporadicTaxTypeId=0;

          if (this.isEnabled == undefined)
          this.isEnabled=0;

      this.getSubTributarias(this.sporadicTaxTypeId, this.isEnabled);

    }

    public getClasse(status: StatusUsuario): string {
        return this._usuarioService.getClasse(status);
    }

    public getString(status: StatusUsuario): string {
        return this._usuarioService.getString(status);
    }

    getNomePerfilECargo(perfil: any): string {
        return this._usuarioService.getNomePerfilECargo(perfil);
    }

    setPageSize(value: number) {
        this.pageSize = value;
        //if (this.paginacao != )
        this.paginacao.setAllData(this.subTributariasList);
        this.paginacao.setPageSize(this.pageSize);
        this.paginacao.setPage(1);
      }

      atribuiPeriodoInicialEFinal(): void {
        this.request.Nome = this.filtro.Nome;
        this.request.Email = this.filtro.Email;
        this.request.RazaoSocial = this.filtro.RazaoSocial;
        this.request.Perfil = this.filtro.Perfil;
        this.request.Status = this.filtro.Status;
        this.request.CNPJ = this.filtro.CNPJ;
        let dataInicio = this.filtro.CadastradosDe? this._datepickerService.ObtemData(this.filtro.CadastradosDe, 'DD/MM/YYYY'): null;
        let dataFim = this.filtro.CadastradosAte? this._datepickerService.ObtemData(this.filtro.CadastradosAte, 'DD/MM/YYYY'): null;
        this.request.CadastradosDe = dataInicio;
        this.request.CadastradosAte = dataFim
      }

      setTipoTaxa(tipoTaxaId: number):string
      {
        let item1 = this.taxTypesList.find(i => i.codigo === tipoTaxaId.toString());
        return item1.descricao;
      }

      setModal(modalId: number):string
      {
        let item1 = this.tiposModais.find(i => i.codigo === modalId.toString());
        return item1.descricao;
      }


      setUfs(uf: number):string
      {
        let item1 = this.listUfs.find(i => i.codigo === uf);
        return item1.sigla;
      }

      setGrupoEmpresarial(corporationId: string):string
      {
        if(corporationId != undefined)
        {
        let item1 = this.listGrupoEconomico.find(i => i.codigo === corporationId);
        return item1.descricao;
        }
        else{
          return'';
        }


      }


      listGrupoEmpresarial() {
        this._customerInfoService
          .listGrupoEmpresarial()
          .subscribe((lista) => {


            this.listGrupoEconomico = lista;

            this.loading = false;

          });
      }


      ObterDataFields() {

        this._dataService.getUFs().subscribe((lista) => {
          this.listUfs = lista;
        });

        this._dataService.getTipoModal().subscribe((lista) => {
          this.tiposModais = lista;
        });

        this._dataService.getSporadicTaxTypes().subscribe((lista) => {
          this.taxTypesList = lista;
        });

        this._dataService.getStatuses().subscribe((lista) => {
          this.statusesList = lista;
        });

        this._dataService.getUFs().subscribe((lista) => {
          this.listUfs = lista;
        });

        this._dataService.getTipoModal().subscribe((lista) => {
          this.tiposModais = lista;
        });

        this.listGrupoEmpresarial();

      }

      formatDate(data: Date): string
      {

        let dataTMP = data.toString().substring(0,10);

        let day: string = dataTMP.substr(8, 2);
        let month: string  = dataTMP.substr(5,2);
        let year: string  = dataTMP.substr(0,4);

        let formattedDate: string = day + "/" + month.toString() + "/" + year.toString();
        dataTMP = formattedDate;
        return dataTMP;

      }

      // habilitaEdicao(_sporadicTax: SporadicTax) : boolean     {
      //   var data = _sporadicTax.expirationDate.toString();

      //   let year: number = parseInt(data.substr(0, 4));
      //   let month: number = parseInt(data.toString().substr(5, 2));
      //   let day: number = parseInt(data.toString().substr(8, 2));

      //   let _date = new Date(year, month - 1, day);

      //   if(_date <= new Date())
      //     return false;

      //   if (_sporadicTax.isEnabled == false)
      //     return false;


      //   return true;
      // }

      exportarRelatorioCSV() {
        this.downloading = true;

        const titulos: any[] = [
          "id",
          "sporadicTaxName",
          "sporadicTaxTypeId",
          "originRegionId",
          "originModalId",
          "destinationRegionId",
          "destinationModalId",
          "containerType20Value",
          "containerType40Value",
          "startDate",
          "expirationDate",
          "createDate",
          "isEnabled"];


          const data: any[] = [];

          this.subTributariasList.forEach(x => {
            data.push([
              x.isEnabled
            ]);
          });

        this.downloading = false;
        this.baseService.DownloadXLSX(data, titulos, 'TaxasEsporadicas.csv');

      }
}
