import { Component, OnInit, ViewChild } from '@angular/core';

import { FiltroListaItem } from 'app/_models/filtro-lista-item';
import { CtmNf } from 'app/_models/ctmNf';
import { Porto } from 'app/_models/porto';
import { OrdemServico } from 'app/_models/ordemServico';
import { OrdemServicoRequest } from 'app/_models/ordemServicoRequest';
import { OrdemServicoVM } from 'app/_models/ordemServicoVM';

import { BaseComponent } from 'app/shared/base.component';
import { MessageComponent } from 'app/shared/message/message.component';
import { FiltroTopComponent } from 'app/provedor/filtro-top/filtro-top.component';
import { FiltroListaComponent } from 'app/provedor/filtro-lista/filtro-lista.component';
import { ListaProvedorComponent } from 'app/provedor/lista-provedor/lista-provedor.component';
import { OrdenacaoProvedorComponent } from 'app/provedor/ordenacao-provedor/ordenacao-provedor.component';
import { ChaveNfeComponent } from 'app/provedor/vincular-nf/chave-nfe/chave-nfe.component';

import { AppService } from 'app/_services/app.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';
import { GroupbyService } from 'app/_services/groupby.service';
import { OrdemServicoService } from 'app/_services/ordem-servico.service';
import { AnalyticsTela } from '../../_enums/analytics-tela';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsOperacao } from '../../_enums/analytics-operacao';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'vincular-nf',
  templateUrl: './vincular-nf.component.html',
  styleUrls: ['./vincular-nf.component.scss']
})

export class VincularNfComponent extends BaseComponent implements OnInit {
  @ViewChild('botaoCancelar', {static: true}) botaoCancelar;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(FiltroTopComponent, {static: true}) filtroTop: FiltroTopComponent;
  @ViewChild(FiltroListaComponent, {static: true}) filtroLista: FiltroListaComponent;
  @ViewChild(ListaProvedorComponent, {static: true}) listaProvedor: ListaProvedorComponent;
  @ViewChild(OrdenacaoProvedorComponent, {static: true}) ordenacaoProvedor: OrdenacaoProvedorComponent;
  @ViewChild(ChaveNfeComponent, {static: true}) chaveNfe: ChaveNfeComponent;

  loading = false;
  loadingChaves = false;
  loadingPortos = false;
  downloading: boolean = false;

  campoHabilitado = true;
  exibirAdicionar = true;
  exibirExcluir = true;

  exibirCte = true;
  exibirNfe = true;

  empty = true;
  noresult = false;

  portos: Porto[] = [];
  cnpjDigitado: string;

  eAdmin = this._appService.isAdmin();
  eCliente = this._appService.isCliente();
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();

  totais: FiltroListaItem[];

  filtroPesquisa: OrdemServicoRequest = new OrdemServicoRequest();
  ordemServicoVM: OrdemServicoVM = new OrdemServicoVM();

  exibirMensagemModal: boolean;
  textoMensagemModal: string;
  classeTipoMensagem: string;

  OS: OrdemServico = new OrdemServico();
  Nfe: CtmNf = new CtmNf();

  Ctes: string[] = [];
  Nfs: CtmNf[] = [];
  NfsToInsert: CtmNf[] = [];

  campoOrdenarPor: string = "dhProgramacao";
  camposOrdenacao = [
    { descricao: "Ordem de Serviço", id: "numOs" },
    { descricao: "Fatura", id: "numFaturaOrdenacao" },
    { descricao: "Tipo de Serviço", id: "descTipoServico" },
    { descricao: "Navio / Viagem", id: "descNavioViagem" },
    { descricao: "Porto", id: "portoOriDes" },
    { descricao: "Emb / Dest", id: "nomeEmbDest" },
    { descricao: "Município", id: "municOriDes" },
    { descricao: "Data da Programação", id: "dhProgramacao" },
    { descricao: "Contêiner", id: "numContainer" }
  ];

  constructor(
    private _ordemServicoService: OrdemServicoService,
    private _sharedService: SharedService,
    private _appService: AppService,
    private _messageService: MessageService,
    private _groupByService: GroupbyService,
    private _analyticsService: AnalyticsService) {
    super();

    this.totais = [
      FiltroListaItem.Create('step-pendentes', 'icon-clock', 'pendente', 'Pendente(s)', 0),
      FiltroListaItem.Create('step-a-vincular', 'icon-page-export', 'a vincular', 'A Vincular', 0),
      FiltroListaItem.Create('step-vinculadas', 'icon-check', 'vinculada', 'Vinculada(s)', 0),
      FiltroListaItem.Create('step-ctes-emitidos', 'icon-checklist', 'ct-e emitido', 'CT-e(s) Emitido(s)', 0)
    ];
  }

  ngOnInit() {
    this.filtroTop.classePrincipal = "vincular-nfs";
    this.filtroLista.classePrincipal = "vincular-nfs";
    this.listaProvedor.classePrincipal = "vincular-nfs";

    if (!this.eAdmin) {
      this.pesquisaInicial();
    }

    this.filtroTop.carregarFiltros.subscribe((filtro: OrdemServicoRequest) => {
      if (filtro) {
        this.pesquisar(filtro);
      }
    });

    this.listaProvedor.carregarFiltros.subscribe((filtro: OrdemServicoRequest) => {
      if (this.filtroPesquisa) {
        this.pesquisar(this.filtroPesquisa);
      }
      else {
        this.pesquisaInicial();
      }
    });

    // Exportar arquivo em formato CSV
    this.ordenacaoProvedor.exportarArquivo.subscribe((exportar: boolean) => {
      if (exportar) {
        this.exportarRelatorioCSV();
      }
    });

    // Ordenação da Lista do Tracking
    this.ordenacaoProvedor.ordenarLista.subscribe((campoDeOrdenacao: string) => {
      this.listaProvedor.obtemListaOrdensOrdenadaPorCampo(campoDeOrdenacao);
    });

    this.ordenacaoProvedor.quantidadePorPagina.subscribe((quantidadePorPagina: number) => {
      this.listaProvedor.setQuantidadeRegistrosPorPagina(quantidadePorPagina);
    });

    // Envia mensagens de erro conforme navegação e interação com a tela
    this._messageService.emitirMensagem.subscribe(msg => {
      this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
    });

    this._analyticsService.RegistrarAcesso(AnalyticsTela.VincularNF);
  }

  pesquisaInicial(): void {
    this.filtroPesquisa.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    this.filtroPesquisa.cnpjProvedor = this._appService.UsuarioLogado.cnpjs.join(';');
    this.filtroPesquisa.dataFim = new Date();
    this.filtroPesquisa.dataIni = this._sharedService.addDays(this.filtroPesquisa.dataFim, -14);

    this.pesquisar(this.filtroPesquisa);
  }

  pesquisar(consulta: OrdemServicoRequest): void {
    var diasDiff = Math.floor((consulta.dataFim.valueOf() - consulta.dataIni.valueOf()) / (1000 * 60 * 60 * 24)) + 1;
    if (diasDiff > 31) {
      this._messageService.addMessage("Vincular NF", "O filtro por período não deve ser superior à 31 dias.", "error", LogFrontFuncionalidade.VincularNF, Operacao.Consulta, consulta);
      return;
    }

    consulta.vincularNF = true;
    consulta.strTipoServico = '1'; // apenas transporte rodoviário.

    this.loading = true;
    this.filtroPesquisa = consulta;
    this.filtroTop.loading = true;
    this.filtroLista.zerarQuantidades();

    this._ordemServicoService.obtemOrdensDeServico(consulta, AnalyticsTela.VincularNF).subscribe(
      x => {
        if (x == null)
          return;

        this.ordemServicoVM = x;
        this.filtroTop.preencheTipos(x.TiposOS);
        this.filtroTop.preenchePortos(x.OSs);
        //this.filtroTop.filtrarNavioViagens(x.OSs);
        this.filtroTop.preencheNavioViagens(x.OSs);
        this.listaProvedor.carregaListaOrdens(x.OSs, this.campoOrdenarPor);
        this.listaProvedor.setTiposEventos(x.tiposEventosNF);
        this.filtroLista.carregaDadosEmComponente(x.tiposEventosNF);
        this.aplicarFiltro(this.filtroLista.stepAtivo);

        this.empty = false;
        this.noresult = (!this.ordemServicoVM.OSs || (this.ordemServicoVM.OSs.length === 0));
      },
      (errors) => {
        if (errors && errors.length > 0) {
          let mensagemAgrupada;
          errors.forEach(element => {
            mensagemAgrupada += element + '\n';
          });

          this.exibirMensagem(mensagemAgrupada);
        }

        this.filtroTop.loading = false;
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.filtroTop.loading = false;
      }
    );
  }

  ordenarPor(campoDeOrdenacao: string) {
    this.campoOrdenarPor = campoDeOrdenacao;
    this.listaProvedor.obtemListaOrdensOrdenadaPorCampo(campoDeOrdenacao);
  }

  exportarRelatorioCSV() {

    this.downloading = true;
    this._ordemServicoService.obtemOrdensDeServicoCSV(this.filtroPesquisa).subscribe(ordens => {
      this.downloading = false;
      this._ordemServicoService.exportarRelatorioCSV(ordens);
    });

    /*
    if (!this.listaProvedor.ordens || this.listaProvedor.ordens.length === 0) {
      return this.listaProvedor.ordens;
    };

    const data: any[] = [];
    const titulos: any[] = [
      'OS',
      'Tipo de OS',
      'Fatura',
      'Tipo de Serviço',
      'Navio / Viagem',
      'Origem/Destino',
      'Embarcador/Destinatário',
      'Município',
      'Data da Programação',
      'Contêiner',
      'Status',
      'Retirada do Terminal',
      'Início de Execução',
      'Fim de Execução',
      'Entrega no Terminal',
      'Status NF'
    ];

    this.listaProvedor.ordens.forEach(x => {
      var munic = (x.municOriDes) ? x.municOriDes.trim() + "/" + x.ufOriDes : "";
      data.push([
        x.numOs,
        x.tipo,
        x.numFatura,
        x.descTipoServico,
        x.descNavioViagem,
        x.portoOriDes,
        x.nomeEmbDest,
        munic,
        this._sharedService.formataDataCSV(x.dhProgramacao),
        x.numContainer,
        x.statusOs,
        this._sharedService.formataDataCSV(x.dtRetiradaTerminal),
        this._sharedService.formataDataCSV(x.dtInicioExecucao),
        this._sharedService.formataDataCSV(x.dtFimExecucao),
        this._sharedService.formataDataCSV(x.dtEntregaTerminal),
        x.statusPortal
      ]);
    });

    //this._ordemServicoService.exportarRelatorioCSV(data, titulos);
    */
  }

  aplicarFiltro(step: string) {
    var result = this._ordemServicoService.filtrarPorStep(this.ordemServicoVM.OSs, step);
    this.noresult = !result || result.length <= 0;

    this.listaProvedor.carregaListaOrdens(result, this.campoOrdenarPor);
  }

  vincularNF(OS: OrdemServico) {
    this.Nfe = new CtmNf();
    this.OS = OS;

    this.Ctes = [];
    this.Nfs = [];

    this.loadingChaves = true;
    this.ocultarMensagem();

    if (OS) {
      let filtro: OrdemServicoRequest = new OrdemServicoRequest();
      filtro.idOs = OS.idOs;
      filtro.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
      filtro.cnpjProvedor = OS.cnpjProvedor;

      if (OS.statusPortal.toLowerCase() === 'a vincular') {
        this.exibirAdicionar = true;
        this.exibirExcluir = true;
      }
      else if (OS.statusPortal.toLowerCase() === 'vinculada' && OS.flagNFValidada.toUpperCase() === 'N') {
        this.exibirAdicionar = true;
        this.exibirExcluir = true;
      }
      else {
        this.exibirAdicionar = false;
        this.exibirExcluir = false;
      }

      this._ordemServicoService.obtemCtmNfs(filtro).subscribe(result => {
        if (!result || result.length <= 0) {
          this.Nfs = [];

          this.exibirCte = false;
          this.loadingChaves = false;
        }
        else {
          result.forEach(item => {
            let nfe: CtmNf = new CtmNf();
            this._sharedService.copyProperties(item, nfe);

            nfe.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;

            if (nfe) {
              this.Nfs.push(nfe);
            }
          });

          if (OS.flagPossuiCTe === 'S' || OS.flagPossuiCTM === 'S') {
            var groupedByCtm = this._groupByService.AgruparPorCampo(result, 'chaveCtm');
            if (!groupedByCtm || groupedByCtm.length <= 0) {
              this.exibirCte = false;
            }
            else {
              groupedByCtm.forEach((element: CtmNf) => {
                if (!element.chaveCtm || element.chaveCtm === '') {
                  return;
                }

                this.Ctes.push(element.chaveCtm);
              });

              this.exibirCte = true;
            }
          }
          else {
            this.exibirCte = false;
          }

          if (this.Nfs.length > 0)
            this._analyticsService.RegistrarOperacao(AnalyticsTela.VincularNF, AnalyticsOperacao.VisualizacaoNF);
          if (this.Ctes.length > 0)
            this._analyticsService.RegistrarOperacao(AnalyticsTela.VincularNF, AnalyticsOperacao.VisualizacaoCTM);

          this.loadingChaves = false;
        }
      }, (errors) => {
        if (errors && errors.length > 0) {
          let mensagemAgrupada;
          errors.forEach(element => {
            mensagemAgrupada += element + '\n';
          });

          this.exibirMensagem(mensagemAgrupada);
        }

        this.loadingChaves = false;
      });
    }
  }

  adicionar(): void {
    if (!this._ordemServicoService.validarNfe(this.Nfe)) {
      this.exibirMensagem("Chave informada inválida.");
      return;
    }

    if (!this.NfsToInsert) {
      this.NfsToInsert = [];
    }

    if (this.Nfe && this.Nfs) {
      this.Nfe.idOs = this.OS.idOs;
      this.Nfe.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
      this.Nfe.numContainer = this.OS.numContainer;
      this.Nfe.acao = 1;

      if (this.Nfs && !this.Nfs.find(nf => nf.chaveNfe === this.Nfe.chaveNfe)) {
        this.NfsToInsert.push(this.Nfe);
        this.Nfs.push(this.Nfe);
      }

      this.Nfe = new CtmNf();
      return;
    }
    else {
      this.exibirMensagem("A nota informada não foi encontrada ou a lista de notas não existe.");
      return;
    }
  };

  excluir(nfe: CtmNf) {
    if (nfe) {
      if (!nfe.acao || nfe.acao !== 2) {
        let toInsertIndex = this.NfsToInsert.indexOf(nfe);
        let toInsert = this.NfsToInsert.find(nf => nf.chaveNfe === nfe.chaveNfe);
        let isToInsert = toInsert !== undefined && toInsert !== null;
        if (isToInsert && toInsertIndex > -1) {
          this.NfsToInsert.splice(toInsertIndex, 1);

          toInsertIndex = this.Nfs.indexOf(nfe);
          if (toInsertIndex > -1) {
            this.Nfs.splice(toInsertIndex, 1);
          }

          return;
        }

        nfe.idOs = this.OS.idOs;
        nfe.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
        nfe.numContainer = this.OS.numContainer;
        nfe.acao = 2;
      }
      else {
        nfe.idUsuario = null;
        nfe.acao = null;
      }
    }
    else {
      this.exibirMensagem("Chave informada não encontrada ou inválida para esta operação.");
      return;
    }
  }

  salvar() {
    if (this.Nfs && this.Nfs.length > 0) {
      this.loadingChaves = true;
      // this.Nfs.forEach(item => {
      //   if (item.acao === 0) {
      //     let itemIndex = this.Nfs.indexOf(item);
      //     if (itemIndex > -1) {
      //       this.Nfs.splice(itemIndex, 1);
      //     }
      //   }
      // });

      var houveAlteracao: boolean = false;
      this._analyticsService.RegistrarNFsVinculadas(this.Nfs);
      this._ordemServicoService.atualizarNotasFiscais(this.Nfs).subscribe(result => {
        this.Nfs.forEach(element => {
          if (element.acao === 2) {
            houveAlteracao = true;
            let itemIndex = this.Nfs.indexOf(element);
            if (itemIndex > -1) {
              this.Nfs.splice(itemIndex, 1);
            }
          }
          else if (element.acao === 1) {
            houveAlteracao = true;
            let elementIndex = this.NfsToInsert.indexOf(element);
            if (element && elementIndex > -1) {
              this.NfsToInsert.splice(elementIndex, 1);
            }

            element.acao = 0;
          }
        });

        this.loadingChaves = false;
        if (houveAlteracao)
          this._messageService.addMessage("Vincular NF", "Registro(s) alterado(s) com sucesso!", "success");
        else
          this._messageService.addMessage("Vincular NF", "Nenhum dado alterado!", "warning", LogFrontFuncionalidade.VincularNF, Operacao.Inclusao, this.Nfs);

        var acaoFecharModal = document.getElementById('botaoCancelar');
        if (acaoFecharModal) {
          acaoFecharModal.click();
          if (this.filtroPesquisa) {
            this.pesquisar(this.filtroPesquisa);
          }
        }
      }, (errors) => {
        if (errors && errors.length > 0) {
          let mensagemAgrupada = '';
          errors.forEach(element => {
            mensagemAgrupada += element.Message + '\n';
          });

          this.exibirMensagem(mensagemAgrupada);
        }

        this.loadingChaves = false;
      });
    }
    else {
      this.exibirMensagem("Nenhuma chave selecionada para ser salva.");
      return;
    }
  }

  exibirMensagem(texto: string, tipo: string = null) {
    if (texto && texto !== undefined && texto !== null && texto !== '') {
      this.textoMensagemModal = texto;
      this.exibirMensagemModal = true;
      this.classeTipoMensagem = tipo || 'danger';

      var element = document.getElementById('mensagemModal');
      if (element) {
        element.focus();
      }
    }
  }

  ocultarMensagem() {
    if (this.exibirMensagemModal) {
      this.textoMensagemModal = null;
      this.exibirMensagemModal = false;
    }
  }
}
