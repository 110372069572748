
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiselectDropdownModule } from './../../shared/multiselect-dropdown/multiselect-dropdown.module';

import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from './../../shared/shared.module';
import { ParametroComponent } from './parametro.component';
import { HorasComponent } from './horas/horas.component';
import { NaviosComponent } from './navios/navios.component';
import { ParamentroRoutingModule } from './parametro.routing.module';
import { ParametroService } from './../../_services/parametro.service';
import { TiposServicosComponent } from './tipos-servicos/tipos-servicos.component';
import { ParametroPortoEditComponent } from './horas/parametro-porto-edit/parametro-porto-edit.component';
import { ParametroPortoViewComponent } from './horas/parametro-porto-view/parametro-porto-view.component';
import { AnelRotaService } from 'app/_services/anel-rota.service';
import { TerminaisComponent } from './terminais/terminais.component';
import { TerminaisService } from 'app/_services/terminais.service';
import { PrioridadesModule } from './prioridades/prioridades.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ParamentroRoutingModule,
        MultiselectDropdownModule,
        SharedModule,
        ModalModule.forRoot(),
        PrioridadesModule
    ],
    exports: [],
    declarations: [
        ParametroComponent,
        HorasComponent,
        NaviosComponent,
        TiposServicosComponent,
        ParametroPortoEditComponent,
        ParametroPortoViewComponent,
        TerminaisComponent
    ],
    providers: [
        ParametroService,
        TerminaisService,
        AnelRotaService
    ],
})
export class ParametroModule { }
