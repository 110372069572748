export class ImagemNF{
  TipoImagem: string;
  URLImagem: string;
  NumNF: string;

  constructor(tipoImagem: string, url: string) {
    this.TipoImagem = tipoImagem;
    this.URLImagem = url;
  }
}
