export enum AnalyticsOperacao {
    FechamentoTotal = "Fechamento total",
    FechamentoParcial = "Fechamento parcial",
    VisualizacaoNF = "Visualização de NF",
    VisualizacaoCTM = "Visualização de CTM",
    NFsVinculadas = "NFs Vinculadas",
    AcessoLink1 = "Acesso ao Link 1",
    AcessoLink2 = "Acesso ao Link 2",
    AcessoLink3 = "Acesso ao Link 3",
    LoginRealizado = "Login Realizado",
    Duplicacao = "Duplicação",
    AcessoBooking = "Acesso ao Booking",
    ConsultaRastreamento = "Consulta ao rastreamento",
    AgendamentoRealizado = "Agendamento realizado",
    AgendamentoVisualizado = "Agendamento visualizado",
    SelecionarTodos = "Selecionar todos",
    DownloadAposSelecionarTodos = "Download após selecionar todos",
    EmailAposSelecionarTodos = "E-mail após selecionar todos"
}