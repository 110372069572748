import { FiltrosLaterais } from './filtros-laterais';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class BookingRequest extends FiltrosLaterais{
    idUsuario: number;
    idBooking: number;
    filtroGenerico: string;
    dataIni: Date;
    dataFim: Date;
    idsMunicOrigem: string;
    idsMunicDestino: string;
    idsPortoOrigem: string;
    idsPortoDestino: string
    cnpjCli: string;
    ieCli: string;
    cnpjEmb: string;
    ieEmb: string;
    numBooking: string;
    numProposta: string;
}