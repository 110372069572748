import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MapsConfig } from 'app/_models/maps-config';
import { Geolocalizacao } from 'app/_models/geolocalizacao';
import { TipoGeolocalizacao } from 'app/_enums/geolocalizacao-tipo';
import { StatusGeolocalizacao } from 'app/_enums/geolocalizacao-status';

declare var removeMapa: any;
declare var inicializaMapa: any;
declare var limpaMarcadores: any;
declare var adicionaMarcadoresMapa: any;

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  constructor(private baseService: BaseService) { }

  private mapaJaCarregado: boolean = false;

  public ConfiguraMapa(listaPontosGeograficos: Geolocalizacao[]){
    var startup = this.Config.Startup;
    if (!this.mapaJaCarregado)
      inicializaMapa(startup.Latitude, startup.Longitude, startup.Zoom, startup.MapType);

    var marcadores = [];
    listaPontosGeograficos.forEach(ponto => {
      var zCustomizado = 9;
      if (ponto.Tipo == TipoGeolocalizacao.Caminhao)
        zCustomizado = 18;
        marcadores.push({ Tipo: ponto.Tipo, Latitude: ponto.Latitude, Longitude: ponto.Longitude, Icone: this.ObterIcone(ponto), ZoomCustomizado: zCustomizado })
      });

    adicionaMarcadoresMapa(marcadores);
    this.mapaJaCarregado = true;
  }

  public LimpaMarcadores(){
    limpaMarcadores();
  }

  public RemoveMapaExistente(){
    removeMapa();
    this.mapaJaCarregado = false;
  }

  private ObterIcone(ponto: Geolocalizacao): string {
    var icones = this.Config.Icones;
    if(ponto.Tipo == TipoGeolocalizacao.TerminalComCarga)
      return icones.find(i => i.Nome == "TerminalComCarga").Icone;
    if (ponto.Tipo == TipoGeolocalizacao.Caminhao)
      return icones.find(i => i.Nome == "Caminhao").Icone;
    if (ponto.Tipo == TipoGeolocalizacao.Navio)
      return icones.find(i => i.Nome == "Navio").Icone;
    if (ponto.Tipo == TipoGeolocalizacao.Terminal && ponto.Status == StatusGeolocalizacao.Realizado)
      return icones.find(i => i.Nome == "TerminalVisitado").Icone;
    if (ponto.Tipo == TipoGeolocalizacao.Terminal && ponto.Status == StatusGeolocalizacao.Proximo)
      return icones.find(i => i.Nome == "TerminalProximo").Icone;
    if (ponto.Tipo == TipoGeolocalizacao.Terminal && ponto.Status == StatusGeolocalizacao.NaoRealizado)
      return icones.find(i => i.Nome == "TerminalNaoVisitado").Icone;
    return "";
  }

  private get Config(): MapsConfig{
    //Retorna as configurações do Maps carregadas (_config\google-config.json)
    return this.baseService.ConfigMaps;
  }

}
