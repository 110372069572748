import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CadastrarSenhaComponent } from './cadastrar-senha.component';

const cadastrarSenhaRoutes: Routes = [
    { path: ':id', component: CadastrarSenhaComponent }
];

@NgModule({
    imports: [RouterModule.forChild(cadastrarSenhaRoutes)],
    exports: [RouterModule]
})
export class CadastrarSenhaRoutingModule {}