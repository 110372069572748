import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { AppService } from 'app/_services/app.service';
import { SharedService } from '../../../../_services/shared.service';
import { BookingService } from '../../../../_services/booking.service';
import { MessageService } from '../../../../_services/message.service';
import { MunicipioService } from '../../../../_services/municipio.service';
import { DatepickerService } from "app/_services/datepicker.service";
import { GenericOrderbyService } from "app/_services/generic-orderby.service";
import { FiltrosLateraisService } from 'app/_services/filtros-laterais.service';
import { PropostaComercialService } from '../../../../_services/proposta-comercial.service';

import { Porto } from '../../../../_models/porto';
import { Municipio } from '../../../../_models/municipio';
import { Booking } from 'app/_models/booking';
import { MeusBookings } from "app/_models/meus-bookings";
import { BookingRequest } from '../../../../_models/booking-request';
import { FiltrosLaterais } from "app/_models/filtros-laterais";
import { ServicoProposta } from '../../../../_models/servico-proposta';
import { MunicipioRequest } from '../../../../_models/municipio-request';
import { PropostaComercial } from '../../../../_models/proposta-comercial';

import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { BaseComponent } from '../../../../shared/base.component';
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { MessageComponent } from '../../../../shared/message/message.component';
import { PaginacaoComponent } from '../../../../shared/paginacao/paginacao.component';
import { CnpjDropdownComponent } from '../../../../shared/cnpj-dropdown/cnpj-dropdown.component';
import { FiltrosLateraisComponent } from "../../../filtros-laterais/filtros-laterais.component";
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";
import { DetalhesContainerComponent } from '../../../../shared/detalhes-container/detalhes-container.component';

import { AnalyticsTela } from '../../../../_enums/analytics-tela';
import { AnalyticsService } from '../../../../_services/analytics.service';
import { AnalyticsDownload } from '../../../../_enums/analytics-download';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { GrupoEconomicoService } from 'app/_services/grupo-economico.service';
import { InfoPgtoAdicionalService } from 'app/_services/info-pgto-adicional.service';
import * as moment from 'moment';
import { InfoPgtoAdicional } from 'app/_models/InfoPgtoAdicional';
import { BaseService } from 'app/_services/base.service';


@Component({
  selector: 'pixreport',
  templateUrl: './pixreport.component.html',
  styleUrls: ['./pixreport.component.scss']
})
export class PixReportComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild(CnpjDropdownComponent, {static: true}) cnpjdropdown: CnpjDropdownComponent;
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(FiltrosLateraisComponent, {static: true}) filtroslaterais: FiltrosLateraisComponent;
  @ViewChild(DetalhesContainerComponent, {static: true}) detalhesContainer : DetalhesContainerComponent;

  loading: boolean = false;
  downloading: boolean = false;
  loadingPortos: boolean = false;
  loadingMunicipios: boolean = false;
  empty: boolean = true;
  noresult: boolean = false;
  listaStatus: string[];
  statusSelecionado: string;

  filtroMunicipio: MunicipioRequest = new MunicipioRequest();

  filtrosLaterais = new FiltrosLaterais();
  consulta: BookingRequest = new BookingRequest();
  meusBookings: MeusBookings = new MeusBookings(new FiltrosLaterais(), []);
  listaBookingsOriginal: Booking[];
  pageSize: number = 10;
  optionsInicio: DatePickerOptions;
  optionsFim: DatePickerOptions;
  startDateModel: DateModel;
  expirationDateModel: DateModel;
  cnpj: String = '';
  nomeCliente: String = '';

  filtroGenerico: string = "";
  portos: Porto[] = new Array();
  municipios: Municipio[] = new Array();

  municipiosOrigem: number[];
  portosOrigem: number[];
  municipiosDestino: number[];
  portosDestino: number[];
  listaInfoPgtoAdicional: InfoPgtoAdicional[];

  ordenarpor: string = 'idBooking';
  eAdmin: boolean = this._appService.isAdmin();
  eCliente: boolean = this._appService.isCliente();
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();
  cssClassAdmin = '';

  comboCidades: FilterComboConfiguration;
  comboPortos: FilterComboConfiguration;
  selecao: number[];
  dhInstantPayment: string = '';
  idBooking: String;
  idProposta: number;

  constructor(
    private _bookingService: BookingService,
    private _grupoService: GrupoEconomicoService,
    private _sharedService: SharedService,
    private _propostaService: PropostaComercialService,
    private _municipioService: MunicipioService,
    private _appService: AppService,
    private _msgService: MessageService,
    private _genericOrderbyService: GenericOrderbyService,
    private _datepickerService: DatepickerService,
    private _filtroslateraisService: FiltrosLateraisService,
    private _analyticsService: AnalyticsService,
    private _infoPgtoAdicionalService: InfoPgtoAdicionalService,
    private _baseService: BaseService
  ) {

    super();

    this.optionsInicio = this._datepickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), -30));
    this.optionsFim = this._datepickerService.GenerateOptionsConfig(new Date());

    this.comboCidades = new FilterComboConfiguration("Cidade(s)...");
    this.comboCidades.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      enableSearch: true
    };

    this.comboPortos = new FilterComboConfiguration("Porto(s)...");
    this.comboPortos.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      showCheckAll: true,
      showUncheckAll: true
    };

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      });
  }

  ngAfterViewInit(): void {
    this._grupoService.grupoEconomicoSelecionadoEvent.subscribe((cnpjs: string) => {
        if (cnpjs)
          this.Pesquisar();
        else
          this.zeraPesquisa();
      });
  }

  ngOnInit() {
    this.cssClassAdmin = this.eAdmin || (this.eCliente && this.maisDeUmCNPJ)  ? "" : " customer-profile";

    this.cnpjdropdown.SelecionaMultiplos = true;
    this.cnpjdropdown.modoEdicao = false;
    

    if (!this.eAdmin){

    }
    else {
      this.cnpjdropdown.cnpjsGrupos = localStorage.getItem('cnpjGrupos');
      if (this.cnpjdropdown.cnpjsGrupos)
        this.Pesquisar(false);
    }

    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
    this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Booking, Operacao.Consulta, this.consulta);

    this._analyticsService.RegistrarAcesso(AnalyticsTela.Booking);
  }

  zeraPesquisa() {
    this.empty = true;
    this.noresult = false;
    this.statusSelecionado = "";
    this.meusBookings = new MeusBookings(new FiltrosLaterais(), []);
    this.paginacao.pagedItems = [];
    this.paginacao.pager = {};
  }

  Pesquisar(registrarAnalytics: boolean = true) {
    this._msgService.clearMessage();
   
    this.getListaInfoPgtoAdicional();

      //limpa mensagens de erros
      this._msgService.clearMessage();

  }



  FiltrarPorStatus(status: string){
    if (!status)
      this.meusBookings.Bookings = this.listaBookingsOriginal;
    else
      this.meusBookings.Bookings = this.listaBookingsOriginal.filter(b => b.statusBooking == status);

    this.ordernarListaPorCampo(this.ordenarpor, true);
  }

  

  ordernarListaPorCampo(campo: string, desc: boolean): void {
    this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(this.meusBookings.Bookings, campo, desc));
  }


  getListaInfoPgtoAdicional() {
    this.loading = true;


    
    let startDate: String = this.formataDateToString(this.startDateModel);
    let expirationDate: String =  this.formataDateToString(this.expirationDateModel);

    // if (this.startDateModel)
    //   {
    //     var dddd = this.ObterData(this.startDateModel, 0);
    //   }
    // // else
    // // {
    // // startDate = this._sharedService.addDays(new Date(), -30).toString();
    // // }

    // if (this.expirationDateModel)
    //   {
    //     expirationDate = this.ObterData(this.expirationDateModel, 0);
    //   }
    // else
    // {
    //   endDate = new Date().toString();
    // }


    this._infoPgtoAdicionalService.getListaInfoPgtoAdicional(startDate, expirationDate, this.nomeCliente, this.cnpj, this.idBooking, this.idProposta ).subscribe(
      (lista) => {
        this.listaInfoPgtoAdicional = lista;
        this.paginacao.setAllData(lista);
        this.paginacao.setPage(1);
     },
      (err) => {}
    );

    this.loading = false;

  }

  private ObterData(dateModel: DateModel, dateOffset: number) {
		return (dateModel != undefined) ?
			this._datepickerService.ObtemData(dateModel) :
			this._sharedService.addDays(new Date(), dateOffset);
	}


   formataDateToString(dateModel: DateModel): String{
    let formattedDate: String ="";

    let day: String = "";
    let month: String= "";
    let year: String= "";
    

    if(  parseInt(dateModel.day) > 9){
      day = dateModel.day;
    }
    else{
      day = dateModel.day;
    }


    if(parseInt(dateModel.month) > 9){
      month = dateModel.month;
     }
     else{
      month = dateModel.month;
     }
  

      year = dateModel.year;
      

      formattedDate = year.toString() + '-' + month + '-' +  day;


      return formattedDate;
   }


   extraeData(data: String): String{


       return data.substring(0, 10);
   }
   



  // exportarRelatorioCSV() {
  //   let listaFiltrada = this._filtroslateraisService.filtrarDados(this.meusBookings.Bookings, this.filtrosLaterais);
  //   this._bookingService.exportarRelatorioCSV(listaFiltrada);
  //   this._analyticsService.RegistrarDownload(AnalyticsTela.Booking, AnalyticsDownload.ExportacaoCSV);
  //   this.downloading = false;
  // }

  exportarRelatorioCSV() {
    this.downloading = true;

    const titulos: any[] = [
      "CLIENTE",
      "CPF/CNPJ",
      "PROPOSTA",
      "NUM.BOOKING",
      "DATA CRIACAO",
      "VALOR TOTAL",
      "VALOR PAGO",
      "PAGO EM",
      "STATUS"];


      const data: any[] = [];

      this.listaInfoPgtoAdicional.forEach(x => {
        let status = this.ajustaStatus( x.paymentStatus )

        data.push([
          x.customerName,   // CLIENTE
          x.cpfCnpj,        // CPF/CNPJ
          x.idProposta,     // PROPOSTA
          x.idBooking,      // NUM.BOOKING
          x.createdOn,      // DATA CRIACAO
          x.vlrFreteTotal,  // VALOR TOTAL
          x.amountReceived, //  VALOR PAGO
          x.paidAt,         // PAGO EM
          status  // STATUS
        ]);
      });

    this._baseService.DownloadXLSX(data, titulos, 'PagamentosPix.xlsx');
    this.downloading = false;
    
  }

  ajustaStatus(status: String): String{
    let _status: String = '';
    if(status === 'COMPLETED'){
      _status = 'PAID';
    }

    return _status;

  }

}
