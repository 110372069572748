import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrarComponent } from './registrar.component';

const registrarRoutes: Routes = [
    { path: '', component: RegistrarComponent }
];

@NgModule({
    imports: [RouterModule.forChild(registrarRoutes)],
    exports: [RouterModule]
})
export class RegistrarRoutingModule {}