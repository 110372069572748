export class OrdemServicoRequest {
	filtroGenerico: string;
	numOs: string;
	numBooking: string;
	numContainer: string;
	statusOs: string;
	numFatura: string;
	strTipoServico: string;
	idUsuario: number;
	idOs: number;
	idNavioViagem: number;

	cnpjProvedor: string;
	dataIni: Date;
	dataFim: Date;
	tipo: string;
	strPortoOriDes: string;

	tipoFiltroPeriodo: number;

	vincularNF: boolean = false;
}
