
import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from "rxjs";

import { BaseService } from "app/_services/base.service";
import { MessageService } from "app/_services/message.service";

import { AgendaEntrega } from "app/_models/agenda-entrega";
import { ValidationResult } from "app/_models/validation-result";
import { AgendaEntregaSalvar } from "app/_models/agenda-entrega-salvar";

import { Operacao } from '../_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Injectable({
  providedIn: 'root'
})
export class AgendaEntregaService {

  constructor(private baseService: BaseService, private _msgService: MessageService) { }

  emitirModal = new EventEmitter<boolean>();

  public showModal(): void {
    this.emitirModal.emit(true);
  }

  public hideModal(): void {
    this.emitirModal.emit(false);
  }

  Consulta(consulta: AgendaEntrega): Observable<AgendaEntrega[]> {
    return this.baseService.Post('agendaentrega/consulta', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as AgendaEntrega[];
        else {
          var erros: string[] = [];
          erros.push("Erro ao consultar Agendas de Entregas! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Agendamento de Entrega', erros, 'error', LogFrontFuncionalidade.Rastreamento, Operacao.Consulta, consulta);
          return null;
        }
      }), catchError(e => {
        return this.baseService.handleError(e);
      }));
  }

  Salvar(agenda: AgendaEntregaSalvar): Observable<AgendaEntrega> {
    return this.baseService.Post('agendaentrega/salvar', agenda).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as AgendaEntrega;
        else {
          var erros: string[] = [];
          erros.push("Erro ao salvar Agendamento de Entregas! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Agendamento de Entrega', erros, "error", LogFrontFuncionalidade.Rastreamento, Operacao.Inclusao, agenda);
          return null;
        }
      }), catchError(e => {
        return this.baseService.handleError(e);
      }));
  }
}
