import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ValidationResult } from '../_models/validation-result';
import { CustomerInfo } from 'app/_models/customer-info';
import { ListItem } from 'app/_models/listItem';
import { BookingRequest } from 'app/_models/booking-request';
import { MeusBookings } from 'app/_models/meus-bookings';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';
import { Cliente } from 'app/_models/cliente';



@Injectable({
    providedIn: 'root'
})
export class CustomerInfoService {

  listaGrupoEmpresarial: ListItem[] = new Array();
  customerListInfo: CustomerInfo[] = new Array();


    constructor(private baseService: BaseService,
      private _msgService: MessageService,
      private _sharedService: SharedService
      ) { }
    salvarCustomerInfo(parametros: CustomerInfo[]): Observable<ValidationResult> {
        return this.baseService.Put('customerInfo/', parametros).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;
                return retorno;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    getCustomerInfo(ssn: string): Observable<Cliente> {
        return this.baseService.Get('customerInfo/findCustomerInfoByCnpj?ssn=' + ssn).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Cliente;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }


    listGrupoEmpresarial(): Observable<ListItem[]> {


      return this.baseService.Get('customerInfo/findAll').pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            //this._msgService.addMessageValidationError('Icms', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Consulta, null);
          }

          let lista: CustomerInfo[] = retorno.Data as CustomerInfo[];
          this.listaGrupoEmpresarial = new Array();

          const result = [];
          const map = new Map();
          for (const item of lista) {
              if(!map.has(item.corporationId)){
                  map.set(item.corporationId, true);
                  result.push({
                    corporationId: item.corporationId,
                      name: item.name
                  });
              }
            }


            let listItem: ListItem;
            for(const item of result){
              listItem = new ListItem();
              listItem.codigo = item.corporationId;
              listItem.descricao = item.name;

              this.listaGrupoEmpresarial.push(listItem);

            }


          return this.listaGrupoEmpresarial;
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }


    findAll(): Observable<CustomerInfo[]> {

      return this.baseService.Get('customerInfo/findAll').pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            //this._msgService.addMessageValidationError('Icms', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Consulta, null);
          }

          this.customerListInfo = retorno.Data as CustomerInfo[];

          return this.customerListInfo;
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }


    getBookings(consulta: BookingRequest): Observable<MeusBookings> {
      return this.baseService.Post('bookings', consulta).pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (retorno.IsValid)
            return retorno.Data as MeusBookings;
          else {
            var erros: string[] = [];
            erros.push("Erro ao consultar Bookings! Contate o administrador!");
            retorno.Erros.forEach(element => {
              erros.push(element.ErrorCode + " - " + element.Message);
            });
            this._msgService.addMessageArray('Meus Bookings', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, consulta);
            return null;
          }
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }


}
