import { Pipe, PipeTransform } from '@angular/core';
import { UF } from './../_models/uf';

@Pipe({
  name: 'orderby'
})
export class OrderbyPipe implements PipeTransform {
  transform(array: Array<UF>, args: string): Array<UF> {
    array.sort((a: UF, b: UF) => {
      if (a.sigla < b.sigla) {
        return -1;
      } else if (a.sigla > b.sigla) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
