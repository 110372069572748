import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PerfilComponent } from './perfil.component';
import { PerfilDetalheComponent } from './perfil-detalhe/perfil-detalhe.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const perfisRoutes: Routes = [
    { path: '', component: PerfilComponent, canActivate: [AuthGuard]  },
    // { path: 'detalhe', component: PerfilDetalheComponent, canActivate: [AuthGuard]  },
    // { path: 'detalhe/:id', component: PerfilDetalheComponent, canActivate: [AuthGuard]  }
];

@NgModule({
    imports: [RouterModule.forChild(perfisRoutes)],
    exports: [RouterModule]
})
export class PerfilRoutingModule {}