import { Injectable } from '@angular/core';



import { BaseService } from './base.service';
import { MessageService } from 'app/_services/message.service';
import { ValidationResult } from 'app/_models/validation-result';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from 'app/_enums/operacao';
import { ImagemNF } from 'app/_models/imagemNF';
import { Http, ResponseContentType } from '@angular/http';
import { forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OkEntregaConsultaLink } from 'app/_models/ok-entrega-consulta-link';
@Injectable({
  providedIn: 'root'
})
export class OkEntregaService {

  constructor(private baseService: BaseService,
    private _msgService: MessageService,
    private http: HttpClient) { }


  getPDFCanhotos(CanhotosDisponiveis: ImagemNF[]): any {
    let requests = []
    CanhotosDisponiveis.forEach(element => {
      requests.push(this.http.get(element.URLImagem, { responseType: 'blob' }));
    });
    return forkJoin<Blob>([...requests]);
  }

  openPDFNovaAba(response: any) {
    const retorno: ValidationResult = response;

    if (retorno && !retorno.IsValid) {
      this._msgService.addMessageValidationError('Ok Entrega', retorno.Erros, 'error', LogFrontFuncionalidade.OkEntregaConsulta, Operacao.Consulta);
    } else {
      const fileName = "canhotos.pdf";
      //      if (window.navigator && window.navigator.appVersion && window.navigator.appVersion.indexOf('.NET') > 0 && typeof window.navigator.msSaveBlob === 'function') {
      if (window.navigator && typeof window.navigator.msSaveBlob === 'function') {
        window.navigator.msSaveOrOpenBlob(new Blob(retorno.Data), fileName);
      } else {
        const b64toBlob = (base64, type = 'application/octet-stream') =>
          fetch(`data:${type};base64,${base64}`).then(res => res.blob())

        b64toBlob(retorno.Data, "application/pdf")
          .then(res => {
            let obj = URL.createObjectURL(res)
            window.open(obj, '_blank');
            URL.revokeObjectURL(obj)
          });
      }
    }
  }

  downloadPDFCanhoto(response: ValidationResult) {
    const retorno: ValidationResult = response;

    if (retorno && !retorno.IsValid) {
      this._msgService.addMessageValidationError('Ok Entrega', retorno.Erros, 'error', LogFrontFuncionalidade.OkEntregaConsulta, Operacao.Consulta);
    } else {
      const fileName = "canhotos.pdf";
      if (window.navigator && window.navigator.appVersion && window.navigator.appVersion.indexOf('.NET') > 0 && typeof window.navigator.msSaveBlob === 'function') {
        window.navigator.msSaveBlob(new Blob(retorno.Data), fileName);
      } else {
        const downloadLink = document.createElement("a");
        downloadLink.href = 'data:application/pdf;base64,' + retorno.Data;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }

  getLinkCanhotos(consultas: OkEntregaConsultaLink[]) {
    return this.baseService.Post("ok-entrega/canhoto-links", consultas);
  }
}
