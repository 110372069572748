export class PortoInstantPaymentRequest {
  id: number;
  code: string;
  description: string;
  city: string;
  state: string;
  region: number;
  createdBy: string;
  updatedBy: string;
  isEnabled: boolean;
  isNovo:boolean = true;
}
