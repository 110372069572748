import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ParametroPorto } from 'app/_models/parametro-porto';
import { BaseParametrosHoras } from 'app/admin/parametro/horas/base.parametros.horas';

@Component({
  selector: 'parametro-porto-view',
  templateUrl: './parametro-porto-view.component.html',
  styleUrls: ['./parametro-porto-view.component.scss']
})
export class ParametroPortoViewComponent extends BaseParametrosHoras implements OnInit {

  @Input() parametro: ParametroPorto;
  @Output() clickEditar: EventEmitter<ParametroPorto> = new EventEmitter<ParametroPorto>();
  @Output() clickExcluir: EventEmitter<ParametroPorto> = new EventEmitter<ParametroPorto>();

  constructor() { 
    super();
  }

  ngOnInit() {
  }

  clickBotaoEditar(){
    this.clickEditar.emit(this.parametro);
  }

  clickBotaoExcluir(){
    this.clickExcluir.emit(this.parametro);
  }

}
