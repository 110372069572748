import { TipoServico } from './tipo-servico';
import { OrdemServico } from 'app/_models/ordemServico';
import { TipoEventoTracking } from 'app/_models/tipoEventoTracking';
import { ValidationError } from "app/_models/validation-error";

export class OrdemServicoVM {
	OSs: OrdemServico[];
	TiposOS: string[] = [];
	TiposServicosOS: TipoServico[] = [];
	tiposEventosNF: TipoEventoTracking[];
	tiposEventosOS: TipoEventoTracking[];
}
