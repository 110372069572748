
import { Injectable } from '@angular/core';
import { Booking } from 'app/_models/booking';
import { ListItem } from 'app/_models/listItem';
import { TipoServico } from 'app/_models/tipo-servico';
import { BaseService } from "app/_services/base.service";
import { DataService } from 'app/_services/data.service';
import { MessageService } from "app/_services/message.service";
import jsPDF from 'jspdf';
import { InfoPgtoAdicional } from '../../../_models/InfoPgtoAdicional';
import * as moment from 'moment/moment';
import 'moment/locale/pt-br';



@Injectable({
  providedIn: 'root'
})
export class ImpresaoPixPdf {

  imgAliancaHeader: string = './../../../../../assets/img/logo-alianca.png';
  imgFundoCinza: string = './../../../../../assets/img/alianca-fundo-cinza.jpeg';
  imgPix: string = './../../../../../assets/img/pix.png';
  imagePixBytes: any;
  //Dados Para Deposito
  cnpj: string = '02.427.026/0001-46';
  bancoNome: string = 'JP MORGAN';
  bancoNumero: string = '376';
  agencia: string = '0001';
  contaDeposito: string = '104447-6';
  clienteClassificacoes: ListItem[] = new Array();
  tiposPagamentos: ListItem[] = new Array();

  tiposServicos: TipoServico[] = new Array();

  idTipoServico: number = 0;
  idClassificacaoCliente: number = 0;
  idTipoPagamento: number = 0;


  constructor(private baseService: BaseService,
    private _dataService: DataService,
    private _msgService: MessageService) {

    this.ObterDataFields();

  }


  printPDF(booking: Booking, infoPgtoAdicional: InfoPgtoAdicional) {

    const data = new Date();
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // Lembre-se que os meses começam em 0
    const ano = data.getFullYear();
    const hora = data.getHours();
    const min = data.getMinutes();
    let infoPgtoAdicionalPdf = new jsPDF('p', 'mm', 'a4');

    //const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${min}`;
    var dhReport = moment();

    const dataFormatada = dhReport.format("DD/MM/YYYY HH:mm:ss");// `${dia}/${mes}/${ano} ${hora}:${min}`;



    const nomeFonte: string = 'Courier';

    if (booking.municOri === null) {
      booking.municOri = "";
    }

    if (booking.municDes === null) {
      booking.municDes = "";
    }

    if (infoPgtoAdicional.ufPagadorFrete === null) {
      infoPgtoAdicional.ufPagadorFrete = "";
    }


    if (booking.ufEmb === null) {
      booking.ufEmb = "";
    }


    let localDestino: string = '';
    let rotuloOrigem: string = '';
    let rotuLoDestino: string = '';
    let rotuloData: string = '';
    let localOrigem: string = '';

    let fontSize: number = 12;
    let objHeight: number = 10;
    let objX: number = 10;
    let posLinhaConteudo: number = 16;
    let posLinhaHeader: number = 0;
    let lineHeight: number = 13;
    let adValoren: string = '0,00';

    let firstColPos: number = 10;
    let secondColPos: number = 80;
    let thirdColPos: number = 140;
    let objWidth: number = 200;



    if (booking.proposta.adValorem == null || booking.proposta.adValorem == undefined)
      adValoren = '0,00';
    else
      adValoren = booking.proposta.adValorem.toString();


    if (booking.modal.startsWith('PO')) {
      rotuloData = 'DIA DDL DOCUMENTAÇÃO';
    }
    else {
      rotuloData = 'DATA DA COLETA';
    }


    // Cria retangulo com fundo azul.
    infoPgtoAdicionalPdf.setDrawColor('#057DC8');
    infoPgtoAdicionalPdf.setLineWidth(lineHeight);
    infoPgtoAdicionalPdf.line(objX, posLinhaConteudo, objWidth, posLinhaConteudo);

    objX = firstColPos + 3; //Desloca a imagem para direita.
    posLinhaConteudo = posLinhaConteudo - 4;
    infoPgtoAdicionalPdf.addImage(this.imgAliancaHeader, "jpeg", objX, posLinhaConteudo, 22, 8);


    // Cria retangulo com fundo cinza.
    objHeight = 7;
    lineHeight = 7;
    objX = firstColPos;
    posLinhaConteudo = posLinhaConteudo + 13;

    infoPgtoAdicionalPdf.setLineWidth(lineHeight);
    infoPgtoAdicionalPdf.setDrawColor('#D3D3D3');
    infoPgtoAdicionalPdf.line(objX, posLinhaConteudo, objWidth, posLinhaConteudo);


    lineHeight = 0.5;
    fontSize = 9;
    objX = firstColPos + 3;
    posLinhaConteudo = posLinhaConteudo + 7;

    infoPgtoAdicionalPdf.setFontSize(fontSize);
    infoPgtoAdicionalPdf.setLineWidth(lineHeight);
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text("CALCULO DE FRETE - NUM. BOOKING:" + booking.numBooking, objX, posLinhaConteudo - 5);
    infoPgtoAdicionalPdf.text(dataFormatada, 160, posLinhaConteudo - 5);


    objX = firstColPos;
    posLinhaHeader = posLinhaConteudo + 5;
    posLinhaConteudo = posLinhaHeader + 1;

    infoPgtoAdicionalPdf.setDrawColor(0, 0, 0);
    infoPgtoAdicionalPdf.text("DADOS DA PROPOSTA", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setLineWidth(lineHeight);
    infoPgtoAdicionalPdf.line(objX, posLinhaConteudo, objWidth, posLinhaConteudo);


    // Linha 01
    objX = firstColPos;
    posLinhaHeader = posLinhaConteudo + 5;
    posLinhaConteudo = posLinhaHeader + 3;

    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("CLIENTE", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(booking.proposta.nomeCli, objX, posLinhaConteudo);


    objX = secondColPos + 20;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text(rotuloData, objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(infoPgtoAdicional.dataBaseCalculo, objX, posLinhaConteudo);



    // Linha 03
    objX = firstColPos;
    posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 5;
    posLinhaConteudo = posLinhaHeader + 3;


    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("QTD CONTAINERS", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(booking.qtdeContainer.toString(), objX, posLinhaConteudo);

    objX = secondColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("TIPO CONTAINER", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(booking.proposta.tipoDeContainer.descricao + " - " + booking.proposta.tipoDeContainer.desc_resumida, objX, posLinhaConteudo);

    objX = thirdColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("MODAL DE EMBARQUE", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(booking.modal, objX, posLinhaConteudo);


    // DADOS FISCAIS ************************************************************************************************************
    objX = firstColPos;
    posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 10;
    posLinhaConteudo = posLinhaHeader + 1;

    infoPgtoAdicionalPdf.text("DADOS FISCAIS", objX, posLinhaHeader);
    infoPgtoAdicionalPdf.line(objX, posLinhaConteudo, objWidth, posLinhaConteudo);

    // Linha 01
    objX = firstColPos;
    posLinhaHeader = posLinhaConteudo + 5;
    posLinhaConteudo = posLinhaHeader + 3;

    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("TIPO PAGAMENTO", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.getDescricao(infoPgtoAdicional.idTipoPagto, this.tiposPagamentos), objX, posLinhaConteudo);


    objX = secondColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("CLASSIFICAÇÃO", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.getDescricao(infoPgtoAdicional.idClassificacaoCli, this.clienteClassificacoes), objX, posLinhaConteudo);

/*
    // Linha 02
    objX = firstColPos;
    posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 5;
    posLinhaConteudo = posLinhaHeader + 3;

    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("SUBSTITUICAO TRIBUTÁRIA", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text("-----------", objX, posLinhaConteudo);
*/


    objX = firstColPos;
    posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 5;
    posLinhaConteudo = posLinhaHeader + 3;

    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("TIPO DE SERVIÇO", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.getDescricao(infoPgtoAdicional.idTipoServico, this.tiposServicos), objX, posLinhaConteudo);


    objX = secondColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("UF PAGADOR FRETE", objX, posLinhaHeader);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(infoPgtoAdicional.ufPagadorFrete, objX, posLinhaConteudo);


    if (booking.modal.startsWith('PA')) {
      // Linha 03
      objX = firstColPos;
      posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 5;
      posLinhaConteudo = posLinhaHeader + 3;

      infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
      infoPgtoAdicionalPdf.text("CIDADE DE ORIGEM", objX, posLinhaHeader);

      infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
      infoPgtoAdicionalPdf.text(booking.proposta.municOrigem + '/' + booking.proposta.ufOrigem, objX, posLinhaConteudo);

      objX = secondColPos;
      infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
      infoPgtoAdicionalPdf.text("PORTO DE ORIGEM", objX, posLinhaHeader);

      infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
      infoPgtoAdicionalPdf.text(booking.proposta.nomePortoOrigem + '/' + booking.proposta.ufPortoOrigem, objX, posLinhaConteudo);


      // Linha 04
      objX = firstColPos;
      posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 5;
      posLinhaConteudo = posLinhaHeader + 3;

      infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
      infoPgtoAdicionalPdf.text("PORTO DE DESTINO", objX, posLinhaHeader);

      infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
      infoPgtoAdicionalPdf.text(booking.proposta.nomePortoDestino + '/' + booking.proposta.ufPortoDestino, objX, posLinhaConteudo);


      if (booking.modal.endsWith('PA')) {
        objX = secondColPos;

        infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
        infoPgtoAdicionalPdf.text("CIDADE DE DESTINO", objX, posLinhaHeader);

        infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
        infoPgtoAdicionalPdf.text(booking.proposta.municDestino + '/' + booking.ufDes, objX, posLinhaConteudo);
      }
    }
    else {
      // Modal de Origem: PO
      objX = firstColPos;
      posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 5;
      posLinhaConteudo = posLinhaHeader + 3;

      infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
      infoPgtoAdicionalPdf.text("PORTO DE ORIGEM", objX, posLinhaHeader);

      infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
      infoPgtoAdicionalPdf.text(booking.proposta.nomePortoOrigem + '/' + booking.proposta.ufPortoOrigem, objX, posLinhaConteudo);

      if (booking.modal.endsWith('PO')) {

        objX = secondColPos;
        infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
        infoPgtoAdicionalPdf.text("PORTO DE DESTINO", objX, posLinhaHeader);

        infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
        infoPgtoAdicionalPdf.text(booking.proposta.nomePortoDestino + '/' + booking.proposta.ufPortoDestino, objX, posLinhaConteudo);

      }
      else {

        objX = firstColPos;
        // Muda para proxima linha
        posLinhaHeader = posLinhaConteudo + (fontSize / 2) + 5;
        posLinhaConteudo = posLinhaHeader + 3;

        infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
        infoPgtoAdicionalPdf.text("PORTO DE DESTINO", objX, posLinhaHeader);

        infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
        infoPgtoAdicionalPdf.text(booking.proposta.nomePortoDestino + '/' + booking.proposta.ufPortoDestino, objX, posLinhaConteudo);

        objX = secondColPos;
        infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
        infoPgtoAdicionalPdf.text("CIDADE DE DESTINO", objX, posLinhaHeader);

        infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
        infoPgtoAdicionalPdf.text(booking.proposta.municDestino + '/' + booking.ufDes, objX, posLinhaConteudo);
      }
    }


    // DADOS DO CALCULO *************************************************************************
    // Linha 01
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2) + 10;
    objX = firstColPos;
    infoPgtoAdicionalPdf.text("DADOS DO CALCULO", objX, posLinhaConteudo);
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2) - 3;
    infoPgtoAdicionalPdf.line(10, posLinhaConteudo, 200, posLinhaConteudo);


    // Linha 02
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);

    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("VALOR TOTAL NF (R$)", objX, posLinhaConteudo);

    objX = objX + 36;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.vlrTotalNota, 12), objX, posLinhaConteudo);



    objX = objX + 34;

    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("ALÍQUOTA AD-VALOREM ", objX, posLinhaConteudo);

    objX = objX + 52;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(adValoren, objX, posLinhaConteudo);


    objX = 150;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("ALÍQUOTA ICMS", objX, posLinhaConteudo);

    objX = objX + 40;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.percAliquotaIcms * 100), objX, posLinhaConteudo);


    // DEMONSTRATIVO DO CALCULO *************************************************************************
    objX = firstColPos;
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2) + 10;
    posLinhaHeader = posLinhaConteudo;

    infoPgtoAdicionalPdf.text("DEMONSTRATIVO DO CALCULO (R$)", objX, posLinhaConteudo);
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2) - 3;

    infoPgtoAdicionalPdf.line(10, posLinhaConteudo, 70, posLinhaConteudo);


    // Linha 01
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = firstColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("TOTAL FRETE NEG.", objX, posLinhaConteudo);


    objX = 42;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.vlrFreteNegociado, 15), objX, posLinhaConteudo, { align: "left" });

    // LINHA 02
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = firstColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("AD-VALOREM", objX, posLinhaConteudo);

    objX = 42;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.vlrAdValorem, 15), objX, posLinhaConteudo, { align: "left" });


    // Linha 03
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = firstColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("BUNKER", objX, posLinhaConteudo, { align: "left" });

    objX = 42;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.vlrBaf, 15), objX, posLinhaConteudo, { align: "left" });

    // LINHA 04
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = firstColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("TX ADICIONAL", objX, posLinhaConteudo, { align: "left" });

    objX = 42;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.vlrTaxaAdicional, 15), objX, posLinhaConteudo, { align: "left" });

    // LINHA 05
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = firstColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("ICMS", objX, posLinhaConteudo, { align: "left" });

    objX = 42;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.vlrIcms, 15), objX, posLinhaConteudo, { align: "left" });

    // LINHA 06
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = 10;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("VALOR A PAGAR", objX, posLinhaConteudo, { align: "left" });

    objX = 42;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.formatNumber(infoPgtoAdicional.vlrFreteTotal, 15), objX, posLinhaConteudo, { align: "left" });


    // DADOS PARA DEPOSITO ********************************************************************
    objX = secondColPos;
    posLinhaConteudo = posLinhaHeader;
    infoPgtoAdicionalPdf.text("DADOS PARA DEPÓSITO", objX, posLinhaConteudo);
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2) - 3;

    infoPgtoAdicionalPdf.line(objX, posLinhaConteudo, 140, posLinhaConteudo);
    // objYTemp =   objY  - 5;

    // Linha 01
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);

    objX = secondColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("CNPJ", objX, posLinhaConteudo);

    objX = 105;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.cnpj, objX, posLinhaConteudo);

    // LINHA 02
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = secondColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("BANCO", objX, posLinhaConteudo);

    objX = 105;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.bancoNumero, objX, posLinhaConteudo);


    // Linha 03
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = secondColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("AGÊNCIA", objX, posLinhaConteudo);

    objX = 105;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.agencia, objX, posLinhaConteudo);

    // LINHA 04
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2);
    objX = secondColPos;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "normal");
    infoPgtoAdicionalPdf.text("C/C", objX, posLinhaConteudo);

    objX = 105;
    infoPgtoAdicionalPdf.setFont(nomeFonte, "bold");
    infoPgtoAdicionalPdf.text(this.contaDeposito, objX, posLinhaConteudo);



    // IMAGE PIX ********************************************************************
    
    objX = 150;
    posLinhaConteudo = posLinhaHeader;
    infoPgtoAdicionalPdf.text("PAGAMENTO PIX", objX, posLinhaConteudo);
    posLinhaConteudo = posLinhaConteudo + (fontSize / 2) - 3;

    infoPgtoAdicionalPdf.line(objX, posLinhaConteudo, 200, posLinhaConteudo);


    objX = 155;
    posLinhaConteudo = posLinhaHeader + 5;


    var pixImage = 'data:image/jpeg;base64,' + infoPgtoAdicional.qrCode;


    infoPgtoAdicionalPdf.addImage(pixImage, "JPEG", objX, posLinhaConteudo, 39, 39);
    



    // RODAPÉ ********************************************************************
    posLinhaConteudo = posLinhaConteudo + 50;

    fontSize = 8;
    infoPgtoAdicionalPdf.setFontSize(fontSize);
    infoPgtoAdicionalPdf.line(10, posLinhaConteudo, 200, posLinhaConteudo);
    objX = 10;
    posLinhaConteudo = posLinhaConteudo + 5;
    infoPgtoAdicionalPdf.text("* A liberação do booking está sujeita a confirmação do pagamento.", objX, posLinhaConteudo);
    posLinhaConteudo = posLinhaConteudo + 5;
    infoPgtoAdicionalPdf.text("* O valor da mercadoria deverá ser o mesmo informado na Nota Fiscal para que não haja divergência no cálculo.", objX, posLinhaConteudo);
    posLinhaConteudo = posLinhaConteudo + 5;
    infoPgtoAdicionalPdf.text("* O frete negociado não sofrerá alteração, entretanto as demais taxas poderão sofrer variações.", objX, posLinhaConteudo);
    posLinhaConteudo = posLinhaConteudo + 5;
    infoPgtoAdicionalPdf.text("* Atenção: Este QR Code é válido por 4 horas a partir da geração. Por favor, efetue o pagamento dentro deste prazo.", objX, posLinhaConteudo);
    
    infoPgtoAdicionalPdf.save('PC' + booking.proposta.numProposta + " - BKG" + booking.numBooking + ".pdf");

  }



  ObterDataFields() {
    this._dataService.getClienteClassificacao()
      .subscribe(lista => {
        this.clienteClassificacoes = lista;
      });

    this._dataService.getTipoPagamento()
      .subscribe(lista => {
        this.tiposPagamentos = lista;
      });

    this._dataService.getTipoServico()
      .subscribe(lista => {
        this.tiposServicos = lista;
      });

  }

  formatNumber(valor: number, tamanho?: number): string {
    let formatado: string;
    let valorTmp = valor.toFixed(2).toString();

    valorTmp = valorTmp.replace('.', ',');
    formatado = valorTmp.padStart(tamanho, ' ');

    return formatado;

  }



  getDescricao(valor: number, lista: any): string {
    let descricao: string = '';

    for (let index = 0; index < lista.length; index++) {
      const element = lista[index];

      if (element.codigo.toString() === valor.toString()) {
        descricao = element.descricao;
      }

    }

    return descricao;
  }

}
