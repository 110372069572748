import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from './../../shared/shared.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';

//DATEPICKER
import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';

// MAERSK
import { PortoRoutingModule } from './porto.routing.module';
import { PortoComponent } from './porto.component';
import { PortoDetalheComponent } from './porto-detalhe/porto-detalhe.component';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ModalModule.forRoot(),
        SharedModule,
        DatePickerModule,
        CurrencyMaskModule,
        TextMaskModule,

        // MAESRK
        PortoRoutingModule,
    ],
    exports: [],
    declarations: [
      PortoComponent,
      PortoDetalheComponent
    ],
    providers: [
        // PerfilService,
        // UsuarioService,
        // FuncionalidadeService
    ]
})
export class PortoModule { }
