import { Component, Input } from "@angular/core";
import { InfoSil } from "app/_models/info-sil";

@Component({
  selector: 'infos-sil',
  templateUrl: './infos-sil.component.html',
})
export class InfosSilComponent {

  @Input() infosSil: InfoSil[];
  @Input() tracking;

  _info;
  get info() : InfoSil{
    if(!this.infosSil)
      return;

    if(!this._info) {
      if(this.tracking.statusBooking === "Coletada")
        this._info = this.infosSil.find(e => e.evento === 'coleta')
      else
        this._info = this.infosSil.find(e => e.evento === 'entrega')
    }
    return this._info;
  }

}
