import { Component, OnInit, ViewChild } from '@angular/core';

import { PerfilService } from '../../_services/perfil.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from './../../_services/message.service';
import { UsuarioService } from './../../_services/usuario.service';
import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { Perfil } from 'app/_models/perfil';
import { UsuarioPesquisa } from './../../_models/usuario-pesquisa';
import { BaseComponent } from 'app/shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";

import { StatusUsuario } from 'app/_enums/status-usuario.enum';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { DatepickerService } from 'app/_services/datepicker.service';
import { DatePickerOptions, DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import * as moment from 'moment-timezone';
import { UsuarioPesquisaRequest } from 'app/_models/usuario-pesquisa-request';
import { SporadicTaxService } from 'app/_services/sporadic-tax.service';
import { SporadicTax } from 'app/_models/sporadic-tax';
import { DataService } from "./../../_services/data.service";
import { ListItem } from "./../../_models/listItem";
import { parse } from 'path';
import { BaseService } from 'app/_services/base.service';
import * as dayjs from 'dayjs';
import { TipoTaxaEsporadicaService } from 'app/_services/tipo-taxa-esporadica.service';
import { PortoService } from 'app/_services/porto.service';
import { PortoInstantPaymentRequest } from 'app/_models/PortoInstantPaymentRequest';
import { TipoTaxaEsporadica } from 'app/_models/tipo-taxa-esporadica';
import { letterSpacing } from 'html2canvas/dist/types/css/property-descriptors/letter-spacing';


@Component({
  selector: 'app-taxa-esporadica',
  templateUrl: './taxa-esporadica.component.html',
  styleUrls: ['./taxa-esporadica.component.scss']
})
export class TaxaEsporadicaComponent extends BaseComponent implements OnInit {

  @ViewChild(MessageComponent, { static: true }) mensagem: MessageComponent;
  @ViewChild(PaginacaoComponent, { static: true }) paginacao: PaginacaoComponent;

  filtro: UsuarioPesquisa = new UsuarioPesquisa();
  request: UsuarioPesquisaRequest = new UsuarioPesquisaRequest();

  public loadingSporadicTaxes: boolean = false;

  loading: boolean = false;
  comboStatus: FilterComboConfiguration;
  comboPerfis: FilterComboConfiguration;
  statusSelecionados: number[];
  perfisSelecionados: number[];
  perfis: Perfil[];
  downloading: boolean = false;

  regioesGeograficas: ListItem[] = new Array();
  tiposModais: ListItem[] = new Array();
  sporadicTaxesList: SporadicTax[] = new Array();
  tipoTaxaEsporadicasList: TipoTaxaEsporadica[] = new Array();

  statusesList: ListItem[] = new Array();
  sporadicTaxTypeId: number = 0;
  originRegionId: number = 0;
  originPortId: number = 0;
  originModalId: string = '';
  destinationRegionId: number = 0;
  destinationPortId: number = 0;
  destinationModalId: string = '';
  isEnabled: number = 0;

  portosLista: PortoInstantPaymentRequest[] = new Array();
  portosOrigemLista: PortoInstantPaymentRequest[] = new Array();
  portosDestinoLista: PortoInstantPaymentRequest[] = new Array();

  optionsInicio: DatePickerOptions = {
    format: 'DD-MM-YYYY',
    todayText: 'Hoje',
    style: 'big'
  };

  optionsFim: DatePickerOptions;
  dataInicio: DateModel;
  dataFim: DateModel;

  taxTypesFilterCboConfig: FilterComboConfiguration;
  statusesFilterCboConfig: FilterComboConfiguration;

  pageSize: number = 20;

  constructor(
    private baseService: BaseService,
    private _dataService: DataService,
    private _sharedService: SharedService,
    private _msgService: MessageService,
    private _usuarioService: UsuarioService,
    private _perfilService: PerfilService,
    private _genericOrderbyService: GenericOrderbyService,
    private _datepickerService: DatepickerService,
    private _tipoTaxaEsporadicaService: TipoTaxaEsporadicaService,
    private _portoService: PortoService,
    private _sporadicTaxService: SporadicTaxService) {

    super();



    this.taxTypesFilterCboConfig = new FilterComboConfiguration("Selecione...");
    this.taxTypesFilterCboConfig.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 1,
      enableSearch: false
    };

    this.statusesFilterCboConfig = new FilterComboConfiguration("Selecione...");
    this.statusesFilterCboConfig.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 1,
      enableSearch: false
    };

    this.optionsInicio = this._datepickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), -30));
    this.optionsFim = this._datepickerService.GenerateOptionsConfig(new Date());

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );


  }

  ngOnInit() {

    this.ObterDataFields();

    // Define o primeiro item como selecionado (índice 0)
    this.sporadicTaxTypeId = this.tipoTaxaEsporadicasList[0].id;
    this.originRegionId = parseInt(this.regioesGeograficas[0].codigo);


    this.OnChangeOriginRegion(this.originRegionId);

    this.originPortId = this.portosOrigemLista[0].id;
    this.originModalId =  this.tiposModais[0].codigo;
    this.destinationRegionId= parseInt(this.regioesGeograficas[0].codigo);

    this.OnChangeDestinationRegion(this.destinationRegionId);

    this.destinationPortId= this.portosDestinoLista[0].id;
    this.destinationModalId=  this.tiposModais[0].codigo;


    this.ObterSporadicTaxes(this.sporadicTaxTypeId, this.originRegionId, this.originPortId, this.originModalId, this.destinationRegionId, this.destinationPortId, this.destinationModalId, this.isEnabled);

    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
      this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Usuarios, Operacao.Consulta, this.filtro);
  }


  private ObterSporadicTaxes(sporadicTaxTypeId: number, originRegionId: number, originPortId: number, originModalId: string, destinationRegionId: number, destinationPortId: number, destinationModalId: string, isEnabled: number) {
    this.loading = true;
    var formato = "YYYY-MM-dd";



    this._sporadicTaxService.findAllSpec(sporadicTaxTypeId, originRegionId, originPortId, originModalId, destinationRegionId, destinationPortId, destinationModalId, isEnabled)
      .subscribe(lista => {

        //const distinto = lista.reduce(a {a.sporadicTaxTypeId, a.originRegionId, originPortId, originModalId, destinationRegionId, destinationPortId, destinationModalId});

        if (lista != null) {
        //preciso extrair uma lista contendo originRegionId, originModalId, destinationRegionId destinationModalId da lista
        const distinctList = lista.reduce((accumulator, current) => {
          const { sporadicTaxName, sporadicTaxTypeId, originRegionId, originPortId, originModalId, destinationRegionId, destinationPortId, destinationModalId, containerType20Value, containerType40Value, startDate, expirationDate, isEnabled } = current;
          const exists = accumulator.some(item => item.sporadicTaxName == sporadicTaxName &&
            item.sporadicTaxTypeId == sporadicTaxTypeId &&
            item.originRegionId === originRegionId &&
            item.originPortId === originPortId &&
            item.originModalId === originModalId &&
            item.destinationRegionId === destinationRegionId &&
            item.destinationPortId === destinationPortId &&
            item.destinationModalId === destinationModalId &&
            item.containerType20Value === containerType20Value &&
            item.containerType40Value === containerType40Value &&
          item.startDate == startDate &&
        item.expirationDate == expirationDate &&
      item.isEnabled === isEnabled);
          if (!exists) {
            accumulator.push({ sporadicTaxName, sporadicTaxTypeId, originRegionId, originPortId, originModalId, destinationRegionId, destinationPortId, destinationModalId, containerType20Value, containerType40Value, startDate, expirationDate, isEnabled });
          }
          return accumulator;
        }, []);

          // const uniqueAuthors = lista.reduce((accumulator, current) => {
          //   if (!accumulator.find((item) => item.sporadicTaxTypeId === current.sporadicTaxTypeId &&
          //                                   item.originModalId === current.originModalId &&
          //                                   item.destinationModalId === current.destinationModalId)) {
          //     accumulator.push(current);
          //   }
          //   return accumulator;
          // }, []);


          var hoje = new Date();
          hoje.setHours(0, 0, 0, 0);


          for (var a = 0; a < distinctList.length; a++) {
            // let found = lista.filter(item => item.sporadicTaxTypeId === distinctList[i].sporadicTaxTypeId &&
            //                          item.originModalId === distinctList[i].originModalId &&
            //                          item.destinationModalId === distinctList[i].destinationModalId);



            var tempo = new Date();
            tempo.setHours(0, 0, 0, 0);


            //for (var a = 0; a < found.length; a++) {

              //var dtVencimento = this.ConvertDateText(distinctList[a].expirationDate.toString());

              //if (dtVencimento.getTime() > tempo.getTime()) {
              //  tempo = dtVencimento;
              //}
            //}


            //for (var a = 0; a < found.length; a++) {
              //console.log(found[a]);
              // var dtInicio = this.ConvertDateText(distinctList[a].startDate.toString());
              // var dtVencimento = this.ConvertDateText(distinctList[a].expirationDate.toString());

              // distinctList[a].Acao = '';

              // if (tempo.getTime() === dtVencimento.getTime()) {

              //   if (hoje.getTime() >= dtInicio.getTime() && hoje.getTime() < dtVencimento.getTime()) {
              //     distinctList[a].Acao = 'EDITAR';
              //   }
              //   else {
              //     distinctList[a].Acao = 'EXCLUIR';
              //   }
              // }
            //}
          }

          this.sporadicTaxesList = distinctList;
          //console.log(this.sporadicTaxesList);
          this.paginacao.setAllData(this.sporadicTaxesList);
          this.paginacao.setPageSize(this.pageSize);
          this.paginacao.setPage(1);
        }
        this.loading = false;
      }, (err) => {
      });
  }


  DeletarTaxaEsporadica(id: number) {
    console.log("Excluindo registro de substituição tributária.");
    this._msgService.clearMessage();
    var msgErr: string[] = [];


    this._sporadicTaxService.delete(id)
      .subscribe(result => {

        if (result.IsValid) {

          this._msgService.addMessageRedirect("Taxa Esporádica", "Registro excluído sucesso.", "success");

          this.Pesquisar();

        }
        else {
          this.loading = false;
          result.Erros.forEach((element) => msgErr.push(element.Message));
        }


      });

  }


  Pesquisar() {
    //console.log(this.sporadicTaxTypeId);
    //console.log(this.isEnabled);

    if (this.sporadicTaxTypeId == undefined)
      this.sporadicTaxTypeId = 0;


    this.ObterSporadicTaxes(this.sporadicTaxTypeId, this.originRegionId, this.originPortId, this.originModalId, this.destinationRegionId, this.destinationPortId, this.destinationModalId, this.isEnabled);

  }

  public getClasse(status: StatusUsuario): string {
    return this._usuarioService.getClasse(status);
  }

  public getString(status: StatusUsuario): string {
    return this._usuarioService.getString(status);
  }

  getNomePerfilECargo(perfil: any): string {
    return this._usuarioService.getNomePerfilECargo(perfil);
  }

  setPageSize(value: number) {
    this.pageSize = value;
    this.paginacao.setAllData(this.sporadicTaxesList);
    this.paginacao.setPageSize(value);
    this.paginacao.setPage(1);
  }


  atribuiPeriodoInicialEFinal(): void {
    this.request.nome = this.filtro.nome;
    this.request.email = this.filtro.email;
    this.request.razaoSocial = this.filtro.razaoSocial;
    this.request.perfil = this.filtro.perfil;
    this.request.status = this.filtro.status;
    this.request.cnpj = this.filtro.cnpj;
    let dataInicio = this.filtro.cadastradosDe ? this._datepickerService.ObtemData(this.filtro.cadastradosDe, 'DD/MM/YYYY') : null;
    let dataFim = this.filtro.cadastradosAte ? this._datepickerService.ObtemData(this.filtro.cadastradosAte, 'DD/MM/YYYY') : null;
    this.request.cadastradosDe = dataInicio;
    this.request.cadastradosAte = dataFim
  }


  setTipoTaxa(tipoTaxaId: number): string {
    let item1 = this.tipoTaxaEsporadicasList.find(i => i.id === tipoTaxaId);
    return item1.name;
  }


  setRegiaoGeografica(regiaoGeograficaId: number): string {
    let item1 = this.regioesGeograficas.find(i => i.codigo === regiaoGeograficaId.toString());
    return item1.descricao;
  }


  setPorto(portoId: number): string {
    let item1;
    let descricao = '';

    if (portoId != undefined) {
      item1 = this.portosLista.find(i => i.id === portoId);
      descricao = item1?.description;

      if(item1 == undefined)
        console.log("Porto não encontrado: " + portoId);

    }
    else {
      descricao = '';
    }

    return descricao;
  }


  setModal(modalId: string): string {
    let item1 = this.tiposModais.find(i => i.codigo === modalId.toString());
    return item1.descricao;
  }


  ObterDataFields() {

    this._dataService.getRegiaoGeografica().subscribe((lista) => {
      this.regioesGeograficas = lista;
    });

    this._dataService.getTipoModal().subscribe((lista) => {
      this.tiposModais = lista;
    });


    this._tipoTaxaEsporadicaService.getAll().subscribe((lista) => {
      this.tipoTaxaEsporadicasList = lista;
    });

    this._dataService.getStatuses().subscribe((lista) => {
      this.statusesList = lista;
    });

    this._portoService.getAll().subscribe((lista) => {
      this.portosLista = lista;
    });
  }


  formatDate(data: Date): string {

    let dataTMP = data.toString().substring(0, 10);

    let day: string = dataTMP.substr(8, 2);
    let month: string = dataTMP.substr(5, 2);
    let year: string = dataTMP.substr(0, 4);

    let formattedDate: string = day + "/" + month.toString() + "/" + year.toString();
    dataTMP = formattedDate;
    return dataTMP;

  }

  ConvertDateText(data: string): Date {

    let year: number = parseInt(data.substr(0, 4));
    let month: number = parseInt(data.toString().substr(5, 2));
    let day: number = parseInt(data.toString().substr(8, 2));

    let novaData = new Date(year, month - 1, day);

    return novaData;
  }


  habilitaEdicao(_sporadicTax: SporadicTax): boolean {
    var data = _sporadicTax.expirationDate.toString();

    let year: number = parseInt(data.substr(0, 4));
    let month: number = parseInt(data.toString().substr(5, 2));
    let day: number = parseInt(data.toString().substr(8, 2));

    let _date = new Date(year, month - 1, day);

    if (_date <= new Date())
      return false;

    if (_sporadicTax.isEnabled != 1)
      return false;


    return true;
  }


  OnChangeOriginRegion(itemSelecionado: any) {

    this.portosOrigemLista = new Array();

    this._portoService.findByRegionId(itemSelecionado.target.value).subscribe((lista) => {
      this.portosOrigemLista = lista;
    });
  }


  OnChangeDestinationRegion(itemSelecionado: any) {

    this.portosDestinoLista = new Array();

    this._portoService.findByRegionId(itemSelecionado.target.value).subscribe((lista) => {
      this.portosDestinoLista = lista;
    });


  }


  OnChangePageSize(qtdLinhas: number) {

    this.setPageSize(qtdLinhas)

  }


  habilitaExclusao(_sporadicTax: SporadicTax): boolean {

    return _sporadicTax.acao == "EXC";
  }


  exportarRelatorioCSV() {
    this.downloading = true;

    const titulos: any[] = [
      "NOME",
      "TIPO",
      "REGÃO DE ORIGEM",
      "PORTO DE ORIGEM",
      "MODAL DE ORIGEM",
      "REGIÃO DE DESTINO",
      "PORTO DE DESTINO",
      "MODAL DE DESTINO",
      "VLR. TIPO CONTEINER 20",
      "VLR. TIPO CONTEINER 40",
      "INI. PERIODO",
      "FIM PERIODO",
      "STATUS"];

    const data: any[] = [];


    this.sporadicTaxesList.forEach(x => {
      let sporadicTaxTypeId: string = this.setTipoTaxa(x.sporadicTaxTypeId);
      let originRegionId: string = this.setRegiaoGeografica(x.originRegionId);
      let originPortId: string = this.setPorto(x.originPortId);
      let originModalId: string = this.setModal(x.originModalId);
      let destinationRegionId: string = this.setRegiaoGeografica(x.destinationRegionId);
      let destinationPortId: string = this.setPorto(x.destinationPortId);
      let destinationModalId: string = this.setModal(x.destinationModalId);
      let isEnabled: string = (x.isEnabled == 1) ? 'Ativo' : 'Inativo'


      data.push([
        x.sporadicTaxName,
        sporadicTaxTypeId,
        originRegionId,
        originPortId,
        originModalId,
        destinationRegionId,
        destinationPortId,
        destinationModalId,
        x.containerType20Value,
        x.containerType40Value,
        x.startDate.toString().substr(0, 10),
        x.expirationDate.toString().substr(0, 10),
        //x.createdOn.toString().substr(0, 10),
        isEnabled
      ]);
    });


    this.downloading = false;
    this.baseService.DownloadXLSX(data, titulos, 'TaxasEsporadicas.xlsx');

  }

}
