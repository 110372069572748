import { Component, Input, Output, EventEmitter, OnInit, ViewChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from "app/_services/shared.service";
import { MessageService } from "../../_services/message.service";
import { BaseComponent } from "app/shared/base.component";
import { MessageComponent } from "../../shared/message/message.component";
import { Operacao } from "app/_enums/operacao";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import * as moment from "moment-timezone";
import { UsuarioPesquisaRequest } from "app/_models/usuario-pesquisa-request";
import { DataService } from "../../_services/data.service";
import { IcmsService } from "app/_services/icms.service";
// Models
import { UF } from "app/_models/uf";
import { Icms } from "app/_models/icms";
import { ValidationError } from "../../_models/validation-error";
import { debug, Console } from "console";
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'app/_services/app.service';
import swal from 'sweetalert2';
import { Parser } from '@angular/compiler';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";

import { Injectable } from '@angular/core';

import { CustomerInfo } from '../../_models/customer-info';
import { CustomerInfoService } from 'app/_services/customer-info.service';

@Component({
  selector: "app-customer-info-import",
  templateUrl: "./customer-info-import.component.html",
  styleUrls: ["./customer-info-import.component.scss"],
})
export class CustomerInfoImportComponent extends BaseComponent implements OnInit {
  @ViewChild("modalResumo", { static: true }) modalResumo;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

  loading: boolean = false;
  private pageSize: number = 50;

  name = 'This is XLSX TO JSON CONVERTER';
  willDownload = false;
  customerInfo: CustomerInfo;
   registros: CustomerInfo[] =  new Array();
   customerInfoLista: CustomerInfo[] =  new Array();
   novaCarga: boolean = false;



  constructor(
    private _msgService: MessageService,
    private _customerInfoService: CustomerInfoService
  ) {
    super();
  }

  ngOnInit() {
    this.novaCarga = false;
this.findAll();
  }

  public changeListener(files: FileList){

    this.novaCarga = true;

    console.log(files);
    if(files && files.length > 0) {
       let file : File = files.item(0);
         let reader: FileReader = new FileReader();
         reader.readAsText(file);
         reader.onload = (e) => {
            let csv: string = reader.result as string;
            let allTextLines = csv.split(/\r?\n|\r/);
            let headers = allTextLines[0].split('|');
            let lines = [];

            for ( let i = 0; i < allTextLines.length; i++) {

              if(i>2){

                let customerInfo = new CustomerInfo();
                let data = allTextLines[i].split('|');
                if (data.length === headers.length) {
                  customerInfo.id = i+1;
                  customerInfo.corporationId = data[0].trim(); // Grupo Econmico.
                  customerInfo.name = data[2].trim();          // Cliente
                  customerInfo.ssn = data[3].trim();           // CPF e CNPJ
                  customerInfo.TipoPrazo = data[4].trim();     // Tipo Prazo

                  if(customerInfo.name.length<1)
                  {
                    customerInfo.Status = 'ERRO na linha ' + i.toString() + ': Razão social não informada.';
                  }


                  customerInfo.ssn = customerInfo.ssn.replace('.','').replace('.','').replace('/','').replace('-', '');

                  if(customerInfo.ssn.length != 11)
                  {
                     if(customerInfo.ssn.length != 14)
                     {
                       customerInfo.Status = 'ERRO na linha ' + i.toString() + ': Cnpj diferente de 14 caracteres.';
                     }
                  }


                  // if(customerInfo.UF.length != 2)
                  // {
                  //   customerInfo.Status = 'ERRO na linha ' + i.toString() + ': UF incorreta.';
                  // }

                  this.registros.push(customerInfo);

                    let tarr = [];
                    for ( let j = 0; j < headers.length; j++) {
                        tarr.push(data[j]);
                    }

                    console.log(tarr);
                    lines.push(tarr);
                }
                else
                {
                  customerInfo.Status = 'ERRO na linha ' + i.toString() + ': Esperado 8 colunas.';
                  this.registros.push(customerInfo);
                }
              }
            }
            return lines;
              }, // this.extractData,
              err => this.handleError(err)
         }
      }


      private handleError (error: any) {
        let errMsg = (error.message) ? error.message :
          error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg);
        return errMsg;
      }


      onSave() {
        const onSaveButton = document.getElementById('onSaveButtonBTN') as HTMLButtonElement;
        onSaveButton.disabled = true;

        this.loading = true;
        this._customerInfoService.salvarCustomerInfo(this.registros)
              .subscribe(result => {
                this.loading = false;
                if (result.IsValid) {
                  this._msgService.addMessage('Customer Info', result.Message, "success");
                  this.novaCarga = false;
                  this.findAll();
                }
                else{
                  var msgErr: string[] = [];
                  result.Erros.forEach(element => msgErr.push(element.Message));
                  this._msgService.addMessageArray("Navios", msgErr, "error", LogFrontFuncionalidade.CustomerInfo, Operacao.Inclusao, this.registros);
                }

                onSaveButton.disabled = false;
              });


      }


      findAll() {
        this.loading = true;

        this._customerInfoService.findAll().subscribe(
          (lista) => {
            this.customerInfoLista = lista;
            this.paginacao.setAllData(this.customerInfoLista);
            this.paginacao.setPage(1);
         },
          (err) => {}
        );

        this.loading = false;

      }


  // onFileChange(ev) {
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev.target.files[0];
  //   reader.onload = (event) => {
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: 'binary' });
  //     jsonData = workBook.SheetNames.reduce((initial, name) => {
  //       const sheet = workBook.Sheets[name];
  //       initial[name] = XLSX.utils.sheet_to_json(sheet);
  //       return initial;
  //     }, {});

  //     const obj = JSON.parse(jsonData);

  //     obj.array.forEach(element => {

  //       console.log(element.GrupoEconomico);
  //     });


  //     const dataString = JSON.stringify(jsonData);
  //     document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
  //     this.setDownload(dataString);
  //   }
  //   reader.readAsBinaryString(file);
  // }


  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
      el.setAttribute("download", 'xlsxtojson.json');
    }, 1000)
  }

  // onCustomerSelect()
  // {
  //   this._customerInfoService.getCustomerInfo("02003329000131")
  //   .subscribe(result => {
  //     this.customerInfo = result;
  //     // if (result.IsValid) {
  //     //   this._msgService.addMessage('Customer Info', result.Message, "success");
  //     //   //this.ObterNavios();
  //     // }
  //     // else{
  //     //   var msgErr: string[] = [];
  //     //   result.Erros.forEach(element => msgErr.push(element.Message));
  //     //   this._msgService.addMessageArray("Navios", msgErr, "error", LogFrontFuncionalidade.CustomerInfo, Operacao.Inclusao, this.registros);
  //     // }
  //   });
  // }

}
