
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';


import { Schedule } from './../_models/schedule';
import { ScheduleRequest } from './../_models/schedule-request';
import { ValidationResult } from './../_models/validation-result';

import { BaseService } from './base.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';

import { Operacao } from '../_enums/operacao';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';

@Injectable({
    providedIn: 'root'
})
export class ProgramacaoNaviosService {

    constructor(private baseService: BaseService,
                private _sharedService: SharedService,
                private _msgService: MessageService) { }

    // ---- Schedule -------------------------------------------------
    getSchedule(filtro: ScheduleRequest): Observable<Schedule[]> {
        return this.baseService.Post('schedule/priorizado', filtro).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Schedule[];
                else {
                    var erros: string[] = [];
                    erros.push("Erro ao consultar Programação de Navios! Contate o administrador!");
                    retorno.Erros.forEach(element => {
                        erros.push(element.ErrorCode + " - " + element.Message);
                    });
                    this._msgService.addMessageArray('Programação de Navios', erros, "error", LogFrontFuncionalidade.ProgramacaoDeNavios, Operacao.Consulta, filtro);
                    return null;
                }
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }
    // -------------------------------------------------------------

    DownloadCSV(programacao: any[]) {
        if (programacao == undefined || programacao == null)
            return;

        var data: any[] = [];
        var titulos: any[] = ["Origem", "Saida - Previsto", "Saida - Realizado", "Destino", "Chegada - Previsto",
                              "Chegada - Realizado", "Navio/Viagem", "Transit Time", "Deadline",
                              "Navio Transbordo 1", "Porto Transbordo 1", "Transit Time Transbordo 1", "Chegada Transbordo - Previsto 1", "Chegada Transbordo - Realizado 1",
                              "Navio Transbordo 2", "Porto Transbordo 2", "Transit Time Transbordo 2", "Chegada Transbordo - Previsto 2", "Chegada Transbordo - Realizado 2",
                              "Navio Transbordo 3", "Porto Transbordo 3", "Transit Time Transbordo 3", "Chegada Transbordo - Previsto 3", "Chegada Transbordo - Realizado 3",
                              "Navio Transbordo 4", "Porto Transbordo 4", "Transit Time Transbordo 4", "Chegada Transbordo - Previsto 4", "Chegada Transbordo - Realizado 4"];

        for (let i = 0; i < programacao.length; i++) {
            var item: Schedule = programacao[i];

            data.push([item.descPortoOrigem.trim() + ' - ' + item.municPortoOrigem.trim() + ' / ' + item.ufPortoOrigem,
            this._sharedService.formataDataCSV(item.dhPrevSaidaPortoOrigem),
            this._sharedService.formataDataCSV(item.dhConfSaidaPortoOrigem),
            item.descPortoDestino.trim() + ' - ' + item.municPortoDestino.trim() + ' / ' + item.ufPortoDestino,
            this._sharedService.formataDataCSV(item.dhPrevChegadaPortoDestino),
            this._sharedService.formataDataCSV(item.dhConfChegadaPortoDestino),
            item.descNavioViagem,
            this.formataTransitTime(item.transitTime, false),
            this._sharedService.formataDataCSV(item.dhDeadlineDocumentacao),
            item.Transbordos ? (item.Transbordos.length >=1 ? item.Transbordos[0].descViagemTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=1 ? item.Transbordos[0].nomePortoTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=1 ? this.formataTransitTime(item.Transbordos[0].transitTimeMultTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=1 ? this._sharedService.formataDataCSV(item.Transbordos[0].dataPrevChegadaTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=1 ? this._sharedService.formataDataCSV(item.Transbordos[0].dataRealChegadaTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=2 ? item.Transbordos[1].descViagemTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=2 ? item.Transbordos[1].nomePortoTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=2 ? this.formataTransitTime(item.Transbordos[1].transitTimeMultTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=2 ? this._sharedService.formataDataCSV(item.Transbordos[1].dataPrevChegadaTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=2 ? this._sharedService.formataDataCSV(item.Transbordos[1].dataRealChegadaTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=3 ? item.Transbordos[2].descViagemTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=3 ? item.Transbordos[2].nomePortoTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=3 ? this.formataTransitTime(item.Transbordos[2].transitTimeMultTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=3 ? this._sharedService.formataDataCSV(item.Transbordos[2].dataPrevChegadaTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=3 ? this._sharedService.formataDataCSV(item.Transbordos[2].dataRealChegadaTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=4 ? item.Transbordos[3].descViagemTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=4 ? item.Transbordos[3].nomePortoTransbordo : '') : '',
            item.Transbordos ? (item.Transbordos.length >=4 ? this.formataTransitTime(item.Transbordos[3].transitTimeMultTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=4 ? this._sharedService.formataDataCSV(item.Transbordos[3].dataPrevChegadaTransbordo) : '') : '',
            item.Transbordos ? (item.Transbordos.length >=4 ? this._sharedService.formataDataCSV(item.Transbordos[3].dataRealChegadaTransbordo) : '') : '']);
        }

        this.baseService.DownloadCSV(data, titulos, "Programacao de Navios.csv");
    }
    private formataTransitTime(transitTime: number, transbordo: boolean = true): string {
      let texto: string = (!transbordo) ? " dia" : " hora";

      if (!transitTime || transitTime == null || transitTime == 0)
        return "";

      return (transitTime == 1) ? transitTime.toString().concat(texto) : transitTime.toString().concat(texto, "s");
    }
}
