import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { BaseComponent } from './../../../../shared/base.component';

@Component({
  selector: 'controlesteps',
  templateUrl: './controle-steps.component.html',
  styleUrls: ['./controle-steps.component.scss']
})
export class ControleStepsComponent extends BaseComponent {

  @Output() clickProximoStep: EventEmitter<any> = new EventEmitter();
  @Output() clickStepAnterior: EventEmitter<any> = new EventEmitter();
  @Input() habilitaBotoesAbas: boolean;
  
  public proximoStep() {
    this.clickProximoStep.emit(null);
  }

  public stepAnterior() {
    this.clickStepAnterior.emit(null);
  }
}
