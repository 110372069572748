import { Component, OnInit, Input } from '@angular/core';

import { Endereco } from 'app/_models/endereco';

@Component({
  selector: 'endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss']
})
export class EnderecoComponent implements OnInit {

  @Input() permiteEditarEndereco: boolean;

  mascaraCep = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/];
  endereco: Endereco = new Endereco();
  enderecos: Endereco[] = [];
  enderecoSelecionado: Endereco;
  index: number;
  indexSelecionado: number = -1;
  maisDeUm: boolean = false;
  novo: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  carregaEnderecos(end: Endereco, lista: Endereco[]){
    this.endereco = new Endereco();
    this.enderecos = [];
    if (lista && lista.length == 1 && !end) {
      this.enderecoSelecionado = lista[0];
      this.endereco = lista[0];
    }
    else if (lista && lista.length > 1) {
      this.novo = false;
      this.enderecos = lista.filter(f => f.IdMunicipio == end.IdMunicipio);

      if (this.enderecos && this.enderecos.length == 0) {
        this.endereco = end;
      }
      else if (this.enderecos && this.enderecos.length == 1) {
        this.endereco = this.enderecos[0];
      }
      else if (this.enderecos && this.enderecos.length > 1) {
        this.enderecoSelecionado = end;
        this.enderecos.forEach(e => {
          var i: number = 0;
          if (e.CEP == end.CEP && e.Bairro == end.Bairro && 
              (e.Complemento == end.Complemento || (!e.Complemento && !end.Complemento)) &&
              e.IdMunicipio == end.IdMunicipio && e.Logradouro == end.Logradouro && e.Municipio == end.Municipio && 
              e.Numero == end.Numero && e.UF == end.UF) {
            this.indexSelecionado = i;
            return;
          }
          i++;
        });
      }
    }
    else if (!lista && !end) {
      //nenhum endereço cadastrado
      this.novo = true;
      this.permiteEditarEndereco = true;
    }
    else {
      this.endereco = end;
    }

    this.maisDeUm = (!this.enderecos) ? false : (this.enderecos.length > 1);
  }

  formataEndereco(end: Endereco): string{
    if(!end)
      return "";

    //13340-730 - EST ANGELA TREVISAN CICILIATO, 200 - JD OLIVEIRA CAMARGO - SERRA/ES
    return ((end.CEP) ? end.CEP.trim() : "") + " - " +
           ((end.Logradouro) ? end.Logradouro.trim() : "") + ", " +
           ((end.Numero) ? end.Numero.trim() : "") + " " +
           ((end.Complemento) ? end.Complemento.trim() : "") + " - " +
           ((end.Bairro) ? end.Bairro.trim() : "") + " - " +
           ((end.Municipio) ? end.Municipio.trim() : "") + "/" +
           ((end.UF) ? end.UF.trim() : "");
  }

  formataMunicipio() {
    return (!this.endereco || !this.endereco.Municipio) ? "" : this.endereco.Municipio.trim() + "/" + this.endereco.UF;
  }

  selecionaEndereco(i: number) {
    this.indexSelecionado = i;
    this.enderecoSelecionado = this.enderecos[i];
  }

  obtemEndereco(): Endereco {
    if (this.novo) {
      return this.endereco;
    }
    else if (this.maisDeUm) {
      return this.enderecoSelecionado;
    }
    else {
      return this.endereco;
    }
  }

  copiaObjetoEndereco(de: Endereco): Endereco {
    var para = new Endereco();
    para.Bairro = de.Bairro;
    para.CEP = de.CEP;
    para.Complemento = de.Complemento;
    para.IdMunicipio = de.IdMunicipio;
    para.Logradouro = de.Logradouro;
    para.Municipio = de.Municipio;
    para.Numero = de.Numero;
    para.UF = de.UF;
    return para;
  }
}
