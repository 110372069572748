import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { TrackingCteNf } from '../../../../_models/tracking-cte-nf';

import { BaseComponent } from './../../../../shared/base.component';
import { OkEntregaService } from 'app/_services/ok-entrega.service';
import { ImagemNF } from 'app/_models/imagemNF';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { AppService } from 'app/_services/app.service';
import { BaseService } from 'app/_services/base.service';
import { ConfigViaGreen } from 'app/_models/config-portal';
import { MessageService } from 'app/_services/message.service';


@Component({
  selector: 'lista-docs',
  templateUrl: './lista-docs.component.html',
  styleUrls: ['./lista-docs.component.scss']
})
export class ListaDocsComponent extends BaseComponent implements OnInit {
  @Input() listaDocs: TrackingCteNf[];
  @Input() linksCertificados: string[];
  @Input() tracking: any;
  @Input() CanhotosDisponiveis: ImagemNF[];
  @Output() executaDownloadDoc: EventEmitter<TrackingCteNf> = new EventEmitter<TrackingCteNf>();
  @Input() co2: number;
  @Input() indicEcoFrete: number;
  @Input() idViagreen: string;

  xmlsExpandidos = false;
  downloading = false;

  constructor(private _okEntregaService: OkEntregaService, private _baseService: BaseService, private appService: AppService, private _msgService: MessageService) {
    super();
  }

  ngOnInit(): void {
  }

  GetCo2(){
    return this.co2 != undefined ?  parseFloat(""+this.co2).toFixed(2).replace('.', ',') : (0).toFixed(2);
  }

  getLinklinkCo2() {
    return this.idViagreen && this.idViagreen.length ?
     this.appService.configViaGreen.apiUrlDownloadCertificado + "/" + this.idViagreen.split(";")[0] + "?accessToken=" + this.appService.configViaGreen.token : "https://www.alianca.com.br/ecofrete";
   }


  botaoCo2(){
    if(this.indicEcoFrete && this.idViagreen)
      this.downloadCertificado();
    else if(!this.idViagreen && !this.indicEcoFrete)
      this.abrirPaginaEcofrete();
  }

  downloadCertificado() {
      this._baseService.downloadFileByUrl(this.getLinklinkCo2(), "certificado-co2.pdf").subscribe(() => {return},
       () => this._msgService.addMessage("Rastreamento", "Não foi possível baixar o certficado! Tente novamente mais tarde.", "error")
      );
  }

  abrirPaginaEcofrete() {
    this._baseService.OpenNewTab(this.getLinklinkCo2());
  }

  GetTooltipCo2Text(){
    return this.indicEcoFrete ? this.idViagreen != null &&  this.idViagreen != "" ? "Baixar certificado de compensação" : "O Certificado de Compensação estará disponível ao final do transporte"
    : "você quer saber como compensar o CO2 emitido no seu transporte? clique aqui!";
  }

  GetLabelCo2() {
    return this.indicEcoFrete ? "Compensação" : "Emissão";
  }

  temCanhotosDisponiveis(): boolean {
    return this.CanhotosDisponiveis != null && this.CanhotosDisponiveis.some(res => res.TipoImagem === 'PDF')
  }
  apenasUmItemNaLista(): boolean {
    return (!this.listaDocs || this.listaDocs.length < 2);
  }

  GetColorCo2(){
    return this.indicEcoFrete? "" : "red";
  }

  downloadCanhoto() {
      this.downloading = true;
      this._okEntregaService.getPDFCanhotos(this.CanhotosDisponiveis).subscribe(res => {
        if(res.length > 1) {
          let names = res.map((r,index) => `canhoto-nf-${this.CanhotosDisponiveis[index].NumNF}.pdf`);
          this._baseService.zipAndDownload(res, names, "canhotos.zip");
          this.downloading = false;
        } else {
          this._baseService.download(res[0], `canhoto-nf-${this.CanhotosDisponiveis[0].NumNF}.pdf`);
          this.downloading = false;
        }
      });
  }

  expandirDivDocumentosXml(expandir: boolean): boolean {
    return this.xmlsExpandidos = !expandir;
  }

  habilitaDivDocumentoXml(indice: number) {
    return indice != 0 && !this.xmlsExpandidos;
  }

  downloadDoc(cteNf: TrackingCteNf) {
    this.executaDownloadDoc.emit(cteNf);
  }
}
