
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';


import { BaseService } from './base.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';

import { Usuario } from './../_models/usuario';
import { Municipio } from 'app/_models/municipio';
import { UsuarioCNPJ } from './../_models/usuario-cnpj';
import { UsuarioPesquisa } from 'app/_models/usuario-pesquisa';
import { ValidationResult } from './../_models/validation-result';

import { Operacao } from '../_enums/operacao';
import { StatusUsuario } from 'app/_enums/status-usuario.enum';
import { PerfilUsuario } from 'app/_enums/perfil-usuario.enum';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { UsuarioPesquisaRequest } from 'app/_models/usuario-pesquisa-request';
import { AuthenticationService } from './authentication.service';
import { AlteracaoSenha } from '../_models/ultima-alteracao-senha';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    constructor(private baseService: BaseService,
                private _msgService: MessageService,
                private _sharedService: SharedService,
                private _authService: AuthenticationService) { }

    salvarUsuario(usuario: Usuario): Observable<ValidationResult> {
        if (!usuario.UsuarioID || usuario.UsuarioID == 0)
            return this.baseService.Post('usuario', usuario).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
        else
            return this.baseService.Put('usuario/' + usuario.UsuarioID, usuario).pipe(
                map((response: ValidationResult) => {
                    let retorno: ValidationResult = response;
                    return retorno;
                }),catchError(e => {
                    return this.baseService.handleError(e);
                }),);
    }
/*
    deletarUsuario(id: number): Observable<ValidationResult> {
        return this.baseService.Delete('usuario/' + id)
            .map((response: Response) => {
                let retorno: ValidationResult = response.json();
                return retorno;
            }).catch(e => {
                return this.baseService.handleError(e);
            });
    }
*/
    getUsuarios(): Observable<Usuario[]> {
        return this.baseService.Get('usuario').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Usuario[];
                else
                    return null;
            }),
            catchError(e => {
                return this.baseService.handleError(e);
            }),)
            ;
    }

    getUsuariosByFiltro(filtro: UsuarioPesquisaRequest): Observable<Usuario[]> {
        return this.baseService.Post('usuario/filtro', filtro).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Usuario[];
                else {
                    var erros: string[] = [];
                    erros.push("Erro ao consultar Usuarios! Contate o administrador!");
                    retorno.Erros.forEach(element => {
                        erros.push(element.ErrorCode + " - " + element.Message);
                    });
                    this._msgService.addMessageArray('Usuarios', erros, "error", LogFrontFuncionalidade.Sobre, Operacao.Consulta, filtro);
                    return null;
                }
            }),
            catchError(e => {
                return this.baseService.handleError(e);
            }),)
            ;
    }

    getCNPJs(): Observable<UsuarioCNPJ[]> {
        return this.baseService.Get('usuario/getcnpjs').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as UsuarioCNPJ[];
                else
                    return null;
            }),
            catchError(e => { return this.baseService.handleError(e); }),)
            ;
    }

    getCsv(): Observable<Usuario[]> {
        return this.baseService.Get('usuario/csv').pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Usuario[];
                else
                    return null;
            }),
            catchError(e => { return this.baseService.handleError(e); }),)
            ;
    }

    getUsuario(id: number): Observable<Usuario> {
        return this.baseService.Get('usuario/' + id).pipe(
            map((response: ValidationResult) => {
                let retorno: ValidationResult = response;

                if (retorno.IsValid)
                    return retorno.Data as Usuario;
                else
                    return null;
            }),catchError(e => {
                return this.baseService.handleError(e);
            }),);
    }

    public getClasse(status: StatusUsuario): string {
        switch (status) {
            case StatusUsuario.AguardandoAprovacao:
                return "label label-info";
            case StatusUsuario.Aprovado:
                return "label label-success";
            case StatusUsuario.Inativado:
                return "label label-warning";
            case StatusUsuario.Reprovado:
                return "label label-danger";
        }
    }

    public getString(status: StatusUsuario): string {
        switch (status) {
            case StatusUsuario.AguardandoAprovacao:
                return "Aguardando Aprovacao";
            case StatusUsuario.Aprovado:
                return "Aprovado";
            case StatusUsuario.Inativado:
                return "Inativado";
            case StatusUsuario.Reprovado:
                return "Reprovado";
        }
    }

    exportarRelatorioCSV(usuarios: Usuario[]): Observable<any> {
        if (!usuarios || usuarios.length === 0) {
          return;
        };

        var municipios: Municipio[];
        var alteracoesSenha: AlteracaoSenha[];

        const data: any[] = [];
        const titulos: any[] = [
          'ID',
          'E-mail',
          'Nome',
          'Razão Social',
          'CNPJ',
          'Nome Fantasia',
          'Inscrição Estadual',
          'Logradouro',
          'Número',
          'Complemento',
          'Bairro',
          'UF',
          'Município',
          'CEP',
          'Telefone',
          'Perfil Portal',
          'Perfil E-cargo',
          'CNPJ(s) Cadastrados',
          'Status do Usuário',
          'Data de Solicitação',
          'Data de Aprovação',
          'Data de Reprovação',
          'Data Última Alteração de Senha',
          'Situação Senha Login'];

      this._authService.GetUltimasAlteracoesSenha().subscribe( res => {
        if(res.IsValid)
          alteracoesSenha = res.Data.Data
        else
          return;
      });

      var observable = this._sharedService.getMunicipios();
      observable.subscribe(m => {
            municipios = m;
            var nowDate = new Date();
            nowDate.setMonth(nowDate.getMonth() - 1)
            usuarios.forEach(x => {

                var municUser = municipios.find(z => z.Codigo.toString() == x.Municipio);
                var alteraUser = alteracoesSenha.find(altera => altera.Email === x.Email);
                data.push([
                x.UsuarioID,
                x.Email,
                x.Nome,
                x.RazaoSocial,
                x.CNPJ,
                x.NomeFantasia,
                x.InscricaoEstadual,
                x.Logradouro,
                x.Numero,
                x.Complemento,
                x.Bairro,
                x.UF,
                (municUser) ? municUser.Descricao : '', //x.Municipio,
                '0'.repeat(8 - x.CEP.length) +x.CEP,
                x.Telefone,
                (x.Perfil) ? x.Perfil.Descricao : '',
                this.getNomePerfilECargo(x.PerfilUsuario),
                x.ListaCNPJs,
                this.getString(x.Status),
                x.strDataCadastro,
                x.strDataAprovacao,
                x.strDataReprovacao,
                alteraUser != null && alteraUser.strData != null? alteraUser.strData : null,
                (alteraUser!= null && alteraUser.Data != null) ? (new Date(alteraUser.Data).getTime() <= nowDate.getTime()? "Expirado": "Senha Ativa")
                : "Nunca Logado"
                ]);
            });

            this.baseService.DownloadXLSX(data, titulos, 'Usuarios.xlsx');
      });

      return observable;
    }

    getNomePerfilECargo(perfil: any): string {
        var str = "";
        switch (perfil) {
            case PerfilUsuario.Administrador:
                str = "Administrador"
                break;
            case PerfilUsuario.Cliente:
                str = "Cliente"
                break;
            case PerfilUsuario.Provedor:
                str = "Provedor"
                break;
            case PerfilUsuario.Ambos:
                str = "Cliente e Provedor"
                break;
        }
        return str;
    }

}
