export class ParametroPorto{
    portoID: number;
    nomePorto: string;
    qtdHrEntrega: number;
    qtdHrAgendEntrega: number;
    qtdHrAgendColetaPA: number;
    qtdHrAgendColetaPO: number;
    qtdHrMinimasAgendColeta: number;
    qtdHrMinimasAgendEntrega: number;

    constructor(){
        this.portoID = 0;
    }
}