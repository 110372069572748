import { Component, OnInit, ViewChild } from '@angular/core';

import { ListaComponent } from './lista/lista.component';
import { MessageComponent } from 'app/shared/message/message.component';
import { BaseComponent } from 'app/shared/base.component';

import { PrioridadeService } from 'app/_services/prioridade.service';

import { Prioridade } from 'app/_models/prioridade';
import { PaginaPrioridades } from 'app/_models/pagina-prioridades';
import { MessageService } from 'app/_services/message.service';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { Operacao } from 'app/_enums/operacao';

@Component({
  selector: 'prioridades',
  templateUrl: './prioridades.component.html',
  styleUrls: ['./prioridades.component.scss']
})
export class PrioridadesComponent extends BaseComponent implements OnInit {

  @ViewChild(ListaComponent, {static: true}) lista: ListaComponent;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  loading: boolean = false;
  paginaPrioridade: PaginaPrioridades;
  prioridades: Prioridade[];
  mensagens: string[];

  constructor(private _msgService: MessageService,
              private _prioridadeService: PrioridadeService) {
    super();

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );

  }

  ngOnInit() {
    this.lista.atualizaLista.subscribe(
      (a: any) => {
        this.obtemPrioridades();
    });

    this.obtemPrioridades();
  }

  obtemPrioridades() {
    this.loading = true;
    this.prioridades = [];
    this._prioridadeService.getPaginaPrioridades()
      .subscribe(p => {
        if (p == null) {
          this.loading = false;
          return;
        }

        this.paginaPrioridade = p;
        this.prioridades = p.ListaPrioridades;
        this.AjustaPortosENaviosIDs();
        this.lista.prioridades = this.prioridades;
        this.lista.portos = p.ListaPortos;
        this.lista.navios = p.ListaNavios;
        this.lista.populaCombos();
        this.loading = false;
      });
  }

  private AjustaPortosENaviosIDs(){
    //Converte o ID do navio e do porto para o Combo Multiselect funcionar
    if (this.prioridades != null){
      this.prioridades.forEach(prio => {

        prio.NaviosID = [];
        prio.PortosOrigemID = [];
        prio.PortosDestinoID = [];

        if (prio.Navios != undefined){
          prio.Navios.forEach(navio => {
            prio.NaviosID.push(Number(navio.Codigo));
          });
        }

        if (prio.PortoOrigem != undefined){
          prio.PortosOrigemID.push(prio.PortoOrigem.Codigo) ;
        }

        if (prio.PortoDestino != undefined){
          prio.PortosDestinoID.push(prio.PortoDestino.Codigo) ;
        }
      });
    }
  }

  private AjustaPortosENavios() {

    this.prioridades.forEach(prioridade => {
      prioridade.Navios = [];
      if (prioridade.NaviosID != undefined) {
        prioridade.NaviosID.forEach(navioID => {
          var navio = this.lista.navios.find(f => f.Codigo == navioID.toString());
          if (navio)
            prioridade.Navios.push(navio);
        });
      }

      if (prioridade.PortosOrigemID != undefined) {
        prioridade.PortosOrigemID.forEach(portoID => {
          var porto = this.lista.portos.find(f => f.Codigo == portoID);
          if (porto)
            prioridade.PortoOrigem = porto;
        });
      }

      if (prioridade.PortosDestinoID != undefined) {
        prioridade.PortosDestinoID.forEach(portoID => {
          var porto = this.lista.portos.find(f => f.Codigo == portoID);
          if (porto)
            prioridade.PortoDestino = porto;
        });
      }
    });

  }

  AdicionaLinhaPrioridade() {
    var prio = new Prioridade();
    this.prioridades.push(prio);
  }

  Salvar() {
    this.loading = true;
    this.AjustaPortosENavios();

    if(!this.ValidaPrioridade()){
      this.loading = false;
      this._msgService.addMessageArray('Prioridades', this.mensagens, 'error', LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.lista.prioridades);
      return;
    }

    this._prioridadeService.salvarPrioridades(this.prioridades)
          .subscribe(result => {
            this.loading = false;
            if (result.IsValid) {
              this._msgService.addMessage('Prioridades', result.Message, "success");
              this.obtemPrioridades();
            }
            else{
              var msgErr: string[] = [];
              result.Erros.forEach(element => msgErr.push(element.Message));
              this._msgService.addMessageArray("Prioridades", msgErr, "error", LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.lista.prioridades);
            }
          });
  }

  ValidaPrioridade(): boolean {

    this.mensagens = [];

    this.lista.prioridades.forEach(prio => {

      if (!prio.PortoOrigem)
        this.mensagens.push("Obrigatório informar o Porto de Origem");

      if (!prio.PortoDestino)
        this.mensagens.push("Obrigatório informar o Porto de Destino");

      if (prio.PortoOrigem && prio.PortoDestino && prio.PortoOrigem.Codigo == prio.PortoDestino.Codigo)
        this.mensagens.push("Porto de Origem e Porto de Destino não podem ser iguais");

      if (!prio.Navios || prio.Navios.length == 0)
        this.mensagens.push("Obrigatório informar ao menos um Navio");

      if (this.mensagens.length == 0) {
        var filtrados = this.lista.prioridades.filter(f => f.PortoDestino.Codigo == prio.PortoDestino.Codigo &&
                        f.PortoOrigem.Codigo == prio.PortoOrigem.Codigo &&
                        ((prio.PrioridadeID == 0 && (f != prio)) || (prio.PrioridadeID != 0 && prio.PrioridadeID != f.PrioridadeID)));

        if (filtrados && filtrados.length >= 1)
          this.mensagens.push("Não é permitido o cadastro em duplicidade de priorização no mesmo Porto Origem e Destino");
      }

      if (this.mensagens.length > 0)
        return false;
    });

    return (this.mensagens.length == 0);
  }

}
