export class ArquivoUpload {
    idCotacao: number;
    idBooking: number;
    uploadJaRealizado: boolean;
    arquivo: any;

    get caminhoArquivo(): string {
        if (!this.arquivo)
            return null;
        return this.arquivo.name;
    }
}