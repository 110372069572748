
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { BaseService } from './base.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';
import { Icms } from '../_models/icms';
import { ValidationResult } from '../_models/validation-result';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IcmsService {

  urlPath:string;

  constructor(
    private baseService: BaseService,
    private _msgService: MessageService) { }

    obterListaIcms(): Observable<Icms[]> {
      return this.baseService.Get('icms').pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            this._msgService.addMessageValidationError('Icms', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Consulta, null);
          }

          return retorno.Data as Icms[];
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }


    obterListaIcmsByLocalication(departureUF: string, destinationUf: string): Observable<Icms[]> {

      if(departureUF == undefined)
        departureUF = departureUF + "";

        if(destinationUf == undefined)
          destinationUf=destinationUf+"";

      //this.urlPath =  string.concat('icms/GetData?departureUf=', departureUF,  '&destinationUf=' , destinationUf);
      this.urlPath =  'icms/GetData?departureUf=' + departureUF + '&destinationUf=' + destinationUf;

      return this.baseService.Get(this.urlPath).pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            this._msgService.addMessageValidationError('Icms', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Consulta, null);
          }

          return retorno.Data as Icms[];
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }


  salvar(icms: Icms): Observable<ValidationResult> {
    console.log("salvar(icms: Icms):", icms);
    return this.baseService.Post('icms', icms).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Icms - Salvar', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Inclusao, icms);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  atualizar(icms: Icms): Observable<ValidationResult> {
    console.log("atualizar(icms: Icms):", icms);

    return this.baseService.Post('icms', icms).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('icms', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Alteracao, icms);
        }

        return retorno;
      }),catchError(e => {
        console.log(e);
        return this.baseService.handleError(e);
      }),);
  }

  excluir(icmsId: number): Observable<ValidationResult> {
    return this.baseService.Delete('icms/' + icmsId).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('icms - Exclusão', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Exclusao, icmsId);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

}
