
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { Prioridade } from 'app/_models/prioridade';
import { ValidationResult } from 'app/_models/validation-result';
import { PaginaPrioridades } from 'app/_models/pagina-prioridades';

@Injectable({
    providedIn: 'root'
})
export class PrioridadeService {

  constructor(private baseService: BaseService) { }

  getPaginaPrioridades(): Observable<PaginaPrioridades> {
    return this.baseService.Get('prioridade').pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;

            if (retorno.IsValid)
                return retorno.Data as PaginaPrioridades;
            else
                return null;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
  }

  salvarPrioridades(prioridades: Prioridade[]): Observable<ValidationResult> {
    return this.baseService.Post('prioridade/', prioridades).pipe(
    map((response: ValidationResult) => {
        let retorno: ValidationResult = response;
        return retorno;
    }),catchError(e => {
        return this.baseService.handleError(e);
    }),);
  }

  excluirPrioridade(prioridadeId: number): Observable<ValidationResult> {
    return this.baseService.Delete('prioridade/' + prioridadeId).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;
            return retorno;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
  }

}
