import { IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from './../shared/multiselect-dropdown/multiselect-dropdown.component';

export class FilterComboConfiguration {

    private defaultTitle: string;
    public options: IMultiSelectOption[];
    public settings: IMultiSelectSettings;
    public textsFeminino: IMultiSelectTexts;
    public textsMasculino: IMultiSelectTexts;

    constructor(defaultTitle: string) {
        this.defaultTitle = defaultTitle;
        this.options = [];

        this.textsFeminino = this.generateTexts('a');
        this.textsMasculino = this.generateTexts('o');
    }

    private generateTexts(letter: string): IMultiSelectTexts{
        return {
            checkAll: 'Marcar Tod' + letter + 's',
            uncheckAll: 'Desmarcar Tod' + letter + 's',
            checked: 'marcad' + letter,
            checkedPlural: 'marcad' + letter + 's',
            searchPlaceholder: 'Pesquisar...',
            defaultTitle: this.defaultTitle,
            allSelected: 'Todos selecionad' + letter + 's'
        };
    }
}