import { Component, OnInit, Input } from '@angular/core';

import { Endereco } from 'app/_models/endereco';

@Component({
  selector: 'endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss']
})
export class EnderecoComponent implements OnInit {

  @Input() permiteEditarEndereco: boolean;

  mascaraCep = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/];
  endereco: Endereco = new Endereco();
  enderecos: Endereco[] = [];
  enderecoSelecionado: Endereco;
  index: number;
  indexSelecionado: number = -1;
  maisDeUm: boolean = false;
  novo: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  carregaEnderecos(end: Endereco, lista: Endereco[]){
    this.endereco = new Endereco();
    this.enderecos = [];
    if (lista && lista.length == 1 && !end) {
      this.enderecoSelecionado = lista[0];
      this.endereco = lista[0];
    }
    else if (lista && lista.length > 1) {
      this.novo = false;
      this.enderecos = lista.filter(f => f.idMunicipio == end.idMunicipio);

      if (this.enderecos && this.enderecos.length == 0) {
        this.endereco = end;
      }
      else if (this.enderecos && this.enderecos.length == 1) {
        this.endereco = this.enderecos[0];
      }
      else if (this.enderecos && this.enderecos.length > 1) {
        this.enderecoSelecionado = end;
        this.enderecos.forEach(e => {
          var i: number = 0;
          if (e.cep == end.cep && e.bairro == end.bairro && 
              (e.complemento == end.complemento || (!e.complemento && !end.complemento)) &&
              e.idMunicipio == end.idMunicipio && e.logradouro == end.logradouro && e.municipio == end.municipio && 
              e.numero == end.numero && e.uf == end.uf) {
            this.indexSelecionado = i;
            return;
          }
          i++;
        });
      }
    }
    else if (!lista && !end) {
      //nenhum endereço cadastrado
      this.novo = true;
      this.permiteEditarEndereco = true;
    }
    else {
      this.endereco = end;
    }

    this.maisDeUm = (!this.enderecos) ? false : (this.enderecos.length > 1);
  }

  formataEndereco(end: Endereco): string{
    if(!end)
      return "";

    //13340-730 - EST ANGELA TREVISAN CICILIATO, 200 - JD OLIVEIRA CAMARGO - SERRA/ES
    return ((end.cep) ? end.cep.trim() : "") + " - " +
           ((end.logradouro) ? end.logradouro.trim() : "") + ", " +
           ((end.numero) ? end.numero.trim() : "") + " " +
           ((end.complemento) ? end.complemento.trim() : "") + " - " +
           ((end.bairro) ? end.bairro.trim() : "") + " - " +
           ((end.municipio) ? end.municipio.trim() : "") + "/" +
           ((end.uf) ? end.uf.trim() : "");
  }

  formataMunicipio() {
    return (!this.endereco || !this.endereco.municipio) ? "" : this.endereco.municipio.trim() + "/" + this.endereco.uf;
  }

  selecionaEndereco(i: number) {
    this.indexSelecionado = i;
    this.enderecoSelecionado = this.enderecos[i];
  }

  obtemEndereco(): Endereco {
    if (this.novo) {
      return this.endereco;
    }
    else if (this.maisDeUm) {
      return this.enderecoSelecionado;
    }
    else {
      return this.endereco;
    }
  }

  copiaObjetoEndereco(de: Endereco): Endereco {
    var para = new Endereco();
    para.bairro = de.bairro;
    para.cep = de.cep;
    para.complemento = de.complemento;
    para.idMunicipio = de.idMunicipio;
    para.logradouro = de.logradouro;
    para.municipio = de.municipio;
    para.numero = de.numero;
    para.uf = de.uf;
    return para;
  }
}
