import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RouterModule } from "@angular/router";

import { ProgramacaoNaviosComponent } from './programacao-navios.component';
import { MultiselectDropdownModule } from './../../shared/multiselect-dropdown/multiselect-dropdown.module';
import { ProgramacaoNaviosRoutingModule } from './programacao-navios.routing.module';
import { ProgramacaoNaviosService } from 'app/_services/programacao-navios.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { ParametroService } from 'app/_services/parametro.service';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        MultiselectDropdownModule,
        DatePickerModule,
        PopoverModule,
        SharedModule,
        ProgramacaoNaviosRoutingModule
    ],
    exports: [],
    declarations: [
        ProgramacaoNaviosComponent
    ],
    providers: [
        ProgramacaoNaviosService, 
        DatepickerService,
        ParametroService
    ],
})
export class ProgramacaoNaviosModule {


}
