import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { Porto } from 'app/_models/porto';
import { Cotacao } from 'app/_models/cotacao';
import { Municipio } from './../../_models/municipio';
import { TipoContainer } from 'app/_models/tipo-container';
import { CotacaoRequest } from 'app/_models/cotacao-request';
import { MinhasCotacoes } from 'app/_models/minhas-cotacoes';
import { FiltrosLaterais } from 'app/_models/filtros-laterais';
import { MunicipioRequest } from './../../_models/municipio-request';
import { FilterComboConfiguration } from 'app/_models/filterComboConfiguration';

import { AppService } from 'app/_services/app.service';
import { CotacaoService } from './../../_services/cotacao.service';
import { MessageService } from './../../_services/message.service';
import { SharedService } from 'app/_services/shared.service';
import { MunicipioService } from './../../_services/municipio.service';
import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { FiltrosLateraisService } from 'app/_services/filtros-laterais.service';

import { BaseComponent } from './../../shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';
import { PaginacaoComponent } from './../../shared/paginacao/paginacao.component';
import { CnpjDropdownComponent } from 'app/shared/cnpj-dropdown/cnpj-dropdown.component';
import { FiltrosLateraisComponent } from './../filtros-laterais/filtros-laterais.component';
import { DetalhesContainerComponent } from '../../shared/detalhes-container/detalhes-container.component';

import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from '../../_enums/analytics-tela';
import { AnalyticsDownload } from '../../_enums/analytics-download';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { GrupoEconomicoService } from 'app/_services/grupo-economico.service';

@Component({
  selector: 'app-cotacao',
  templateUrl: './cotacao.component.html',
  styleUrls: ['./cotacao.component.scss']
})
export class CotacaoComponent extends BaseComponent implements OnInit, AfterViewInit {

  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(FiltrosLateraisComponent, {static: true}) filtroslaterais : FiltrosLateraisComponent;
  @ViewChild(CnpjDropdownComponent, {static: true}) cnpjdropdown: CnpjDropdownComponent;
  @ViewChild(DetalhesContainerComponent, {static: true}) detalhesContainer : DetalhesContainerComponent;

  constructor(private _cotacaoService: CotacaoService,
      private _sharedService: SharedService,
      private _municipioService: MunicipioService,
      private _grupoService: GrupoEconomicoService,
      private _appService: AppService,
      private _msgService: MessageService,
      private _genericOrderbyService: GenericOrderbyService,
      private _filtroslateraisService: FiltrosLateraisService,
      private _analyticsService: AnalyticsService) {

    super();

    this.comboCidades = new FilterComboConfiguration('Cidade(s)...');
    this.comboCidades.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      enableSearch: true
    };

    this.comboPortos = new FilterComboConfiguration('Porto(s)...');
    this.comboPortos.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      showCheckAll: true,
      showUncheckAll: true
    };

    // Inicializa ViewModel de MinhasCotacoes
    this.minhasCotacoes = new MinhasCotacoes(new FiltrosLaterais(), []);
    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      });
  }

  ngAfterViewInit(): void {
    this._grupoService.grupoEconomicoSelecionadoEvent.subscribe(cnpjs => {
        this.Pesquisar();
      });
  }

  minhasCotacoes: MinhasCotacoes;
  filtrosLaterais = new FiltrosLaterais();
  consulta: CotacaoRequest = new CotacaoRequest();
  downloading: boolean = false;
  loading: boolean = false;
  loadingPortos: boolean = false;
  loadingMunicipios: boolean = false;
  empty: boolean = true;
  noresult: boolean = false;
  cssClassAdmin = '';
  pageSize: number = 10;
  ordenarpor: string = 'numProposta';
  eAdmin: boolean = this._appService.isAdmin();
  eCliente: boolean = this._appService.isCliente();  
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();
  filtroMunicipio: MunicipioRequest = new MunicipioRequest();
  listaStatusCotacao: string[] = [];

  optionsModel: number[] = [];

  portos: Porto[];
  municipios: Municipio[];
  municipiosOrigem: number[];
  portosOrigem: number[];
  municipiosDestino: number[];
  portosDestino: number[];

  comboCidades: FilterComboConfiguration;
  comboPortos: FilterComboConfiguration;

  ngOnInit() {

    this.cssClassAdmin = this.eAdmin || (this.eCliente && this.maisDeUmCNPJ)  ? "" : " customer-profile";
    this.cnpjdropdown.SelecionaMultiplos = true;
    this.cnpjdropdown.modoEdicao = false;

    this.ObterPortos();

    if (!this.eAdmin){
      this.ObterLista();
      this.ObterMunicipios(null);
    }
    else {
      this.cnpjdropdown.cnpjsGrupos = localStorage.getItem('cnpjGrupos');
      if (this.cnpjdropdown.cnpjsGrupos)
        this.Pesquisar();
      else
        this.zeraPesquisa();
    }

    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
    this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Cotacao, Operacao.Consulta, this.consulta);

    this._analyticsService.RegistrarAcesso(AnalyticsTela.Cotacao);
  }

  Pesquisar(registrarAnalytics: boolean = true) {
    this._msgService.clearMessage();
    if (this.eAdmin && ((this.cnpjdropdown.CNPJDigitado == undefined) || (this.cnpjdropdown.CNPJDigitado == '')) && (!this.cnpjdropdown.cnpjsGrupos)) {
      this._msgService.addMessage('Minhas Cotações', 'Informe o CNPJ do Cliente ou selecione ao menos um Grupo Econômico.', 'error', 
           LogFrontFuncionalidade.Cotacao, Operacao.Consulta);
      this.zeraPesquisa();
      return;
    }
    else {
      if (this.eAdmin && this.cnpjdropdown.cnpjsGrupos && this.cnpjdropdown.CNPJDigitado) {
        if (!this.cnpjdropdown.validaCNPJnoGrupo()) {
          this._msgService.addMessage('Minhas Cotações', 'CNPJ informado não pertence ao(s) Grupo(s) Econômico(s) selecionado(s)!', 'error', 
             LogFrontFuncionalidade.Cotacao, Operacao.Consulta);
          this.zeraPesquisa();
          return;
        }
      }

      //limpa mensagens de erros
      this._msgService.clearMessage();
      this.consulta.status = '';
      this.ObterLista(registrarAnalytics);
    }
  }

  public ObterPortos() {
    this.loadingPortos = true;
    this._sharedService.getPortos()
      .subscribe(m => {
        this.portos = m;
        if (this.portos != null) {
          this.portos.forEach(porto => {
            this.comboPortos.options.push({ id: porto.Codigo, name: porto.Descricao });
            //this.comboPortos.options.push({ id: porto.Codigo, name: porto.Descricao + ' - ' + porto.Municipio + '/' + porto.UF });
          });
        }
        this.loadingPortos = false;
      });
  }

  public ObterMunicipios(cnpj: string) {
    this.loadingMunicipios = true;
    this.filtroMunicipio.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
    this.filtroMunicipio.tipoConsulta = '1';
    this.filtroMunicipio.cnpjCli = cnpj;
    this.municipios = [];
    //this.comboCidades.options = [];
    this._municipioService.getMunicipiosEspecial(this.filtroMunicipio)
      .subscribe(m => {
        this.municipios = m;
        this.comboCidades.options = [];
        if (this.municipios != null) {
          this.municipios.forEach(municipio => {
            this.comboCidades.options.push({ id: municipio.Codigo, name: municipio.Descricao + ' / ' + municipio.UF });
          });
        }
        this.loadingMunicipios = false;
      });
  }

  cnpjAlterado(cnpj: string){
    this.ObterMunicipios(cnpj);
    this.Pesquisar();
  }

  zeraPesquisa() {
    this.empty = true;
    this.noresult = false;
    this.minhasCotacoes = new MinhasCotacoes(new FiltrosLaterais(), []);
    this.paginacao.pagedItems = [];
    this.paginacao.pager = {};
    this.consulta.status = ''; 
  }

  ObterLista(registrarAnalytics: boolean = false) {
    this.filtrosLaterais = new FiltrosLaterais();
    this.loading = true;

    // Converte Array em String Delimitado por Ponto e Vírgula (formato recebido na procedure)
    this.consulta.UsuarioID = this._appService.UsuarioLogado.UsuarioIDeCargo;
    this.consulta.idsMunicOri = this.municipiosOrigem ? this.municipiosOrigem.join(';') : '';
    this.consulta.idsPortoOri = this.portosOrigem ? this.portosOrigem.join(';') : '';
    this.consulta.idsMunicDes = this.municipiosDestino ? this.municipiosDestino.join(';') : '';
    this.consulta.idsPortoDes = this.portosDestino ? this.portosDestino.join(';') : '';
    this.consulta.cnpjCli = this.cnpjdropdown.CNPJs ? this.cnpjdropdown.CNPJs.join(';') :
                            this.cnpjdropdown.CNPJDigitado ? this.cnpjdropdown.CNPJDigitado : this.cnpjdropdown.cnpjsGrupos ? this.cnpjdropdown.cnpjsGrupos : '';
    this.ObterMunicipios(this.consulta.cnpjCli);
    if (registrarAnalytics)
      this._analyticsService.RegistrarFiltroCotacao(this.consulta);
    this._cotacaoService.getCotacoes(this.consulta)
      .subscribe(minhasCotacoes => {
        this.minhasCotacoes = minhasCotacoes;
        this.empty = false;
        this.noresult = ((this.minhasCotacoes == undefined) || (this.minhasCotacoes.Cotacoes.length == 0));
        this.ordernarListaPorCampo(this.ordenarpor, true);
        this.ObterListaStatus();
        this.loading = false;
      });
  }

  ObterDetalhesDaCotacao(cotacao: Cotacao) {
    cotacao.loading = true;

    var consulta: CotacaoRequest = new CotacaoRequest();
    consulta.CotacaoID = cotacao.numProposta;
    consulta.IdTrecho = cotacao.IdTrecho;
    consulta.UsuarioID = this._appService.UsuarioLogado.UsuarioIDeCargo;

    this._cotacaoService.getCotacao(consulta)
      .subscribe(cotacaoRetorno => {
        let detalhe: Cotacao = new Cotacao();
        this._sharedService.copyProperties(cotacaoRetorno, detalhe);
        let tp: TipoContainer = new TipoContainer();
        this._sharedService.copyProperties(cotacaoRetorno.tipoDeContainer, tp);
        cotacao.detalheDaCotacao = detalhe;
        cotacao.detalheDaCotacao.tipoDeContainer = tp;
        cotacao.loading = false;
      });
  }

  obtemStatus(status: string): string {
    if ((status != undefined) && (status.toUpperCase() == 'AGUARDANDO APROVAÇÃO DO CLIENTE'))
      return 'Aguardando Aprovação';
    return status;
  }

  obtemClasseDoStatus(status: string): string {
    status = status.toLowerCase();

    if ((status.indexOf('ativa') !== -1) || status.indexOf('aceita') !== -1)
      return 'quotation-status active';
    else if ((status.indexOf('processo') !== -1) || (status.indexOf('aguardando') !== -1))
      return 'quotation-status waiting';
    return 'quotation-status expired';
  }

  ordernarListaPorCampo(campo : string, desc: boolean): void {
    this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(this.minhasCotacoes.Cotacoes, campo, desc));
  }

  filtrarPorStatus(): void{
    let listaFiltrada = this._filtroslateraisService.filtrarDados(this.minhasCotacoes.Cotacoes, this.consulta);
    this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(listaFiltrada, this.ordenarpor));
  }

  ObterListaStatus(): string[] {
    this.listaStatusCotacao = [];
    this.listaStatusCotacao.push('');
    this.minhasCotacoes.Cotacoes.forEach(cotacao => {
        var status = this.listaStatusCotacao.find(s => s == cotacao.Status);
        if (status == null || status == undefined)
          this.listaStatusCotacao.push(cotacao.Status);
      });
    
    this.listaStatusCotacao.sort((a: string, b: string) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    return this.listaStatusCotacao;
  }

  exportarRelatorioCSV() {
    this.downloading = true;
    this._analyticsService.RegistrarDownload(AnalyticsTela.Cotacao, AnalyticsDownload.ExportacaoCSV);
    // Obtem listagem de cotacoes específica para download
    this._cotacaoService.getCotacoesDownload(this.consulta)
      .subscribe(x => {
        if (x != null) {
          let listaFiltrada = this._filtroslateraisService.filtrarDados(x, this.filtrosLaterais);
          this._cotacaoService.exportarRelatorioCSV(listaFiltrada);
        }
          this.downloading = false;
      });
  }
}
