import { Component, OnInit, Input } from '@angular/core';
import { ParametroPorto } from 'app/_models/parametro-porto';
import { BaseParametrosHoras } from 'app/admin/parametro/horas/base.parametros.horas';

@Component({
  selector: 'parametro-porto-edit',
  templateUrl: './parametro-porto-edit.component.html',
  styleUrls: ['./parametro-porto-edit.component.scss']
})
export class ParametroPortoEditComponent extends BaseParametrosHoras implements OnInit {

  constructor() {
    super();
  }
  @Input() parametro: ParametroPorto;
  
  ngOnInit() {
  }

}
