import { Component, Input, Output, EventEmitter, OnInit, ViewChild, NgModule } from '@angular/core';


import { SharedService } from "app/_services/shared.service";
import { MessageService } from "./../../_services/message.service";
import { BaseComponent } from "app/shared/base.component";
import { MessageComponent } from "./../../shared/message/message.component";
import { Operacao } from "app/_enums/operacao";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import * as moment from "moment-timezone";
import { UsuarioPesquisaRequest } from "app/_models/usuario-pesquisa-request";
import { DataService } from "./../../_services/data.service";
import { IcmsService } from "app/_services/icms.service";
// Models
import { UF } from "app/_models/uf";
import { Icms } from "app/_models/icms";
import { ValidationError } from "../../_models/validation-error";
import { debug, Console } from "console";
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'app/_services/app.service';
import swal from 'sweetalert2';
import { Parser } from '@angular/compiler';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


import { GenericOrderbyService } from 'app/_services/generic-orderby.service';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";
import { BaseService } from 'app/_services/base.service';
import { UsuarioService } from 'app/_services/usuario.service';
import { NgForm } from '@angular/forms';



@Component({
  selector: "app-icms",
  templateUrl: "./icms.component.html",
  styleUrls: ["./icms.component.scss"],
})
export class IcmsComponent extends BaseComponent implements OnInit {
  @ViewChild("modalResumo", { static: true }) modalResumo;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

    request: UsuarioPesquisaRequest = new UsuarioPesquisaRequest();
  icmsList: Icms[];
  loading: boolean = false;
  loadingPerfis: boolean = false;
  ufs: UF[] = new Array();

  departureUf: string = "";
  destinationUf: string = "";

  pageSize: number = 50;
  downloading: boolean = false;
  public loadingSporadicTaxes: boolean = false;
  icmsItem: Icms;

  percCif: number;
  percCifNonTaxPayer: number;
  percFob: number;
  percFobNonTaxPayer: number = 0;
  isNovoReg: boolean = true;
  isEditing: boolean = false;
  isRequired: boolean = false;
  @ViewChild('icmsFrm') icmsFrm: NgForm;

  constructor(
    private baseService: BaseService,
    private _sharedService: SharedService,
    private _msgService: MessageService,
    private _dataService: DataService,
    private _genericOrderbyService: GenericOrderbyService,
    private _icmsService: IcmsService,
    private _appService: AppService
  ) {
    super();

    this._msgService.emitirMensagem.subscribe(
      msg => {
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
  );
  }

  ngOnInit() {
    this.loadingPerfis = true;
    this.isEditing= false;
    this.ObterDataFields();

    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
      this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.AneisERotas, Operacao.Alteracao);
  }

  OnChangeDepartureUfCmb(value: any) {
      this.departureUf = value.target.value;
  }

  OnChangeDestinationUfCmb(value: any) {
      this.destinationUf = value.target.value;
  }

  setPageSize(value: number) {
    this.pageSize = value;
    this.paginacao.setAllData(this.icmsList);
    this.paginacao.setPageSize(this.pageSize);
    this.paginacao.setPage(1);
  }


  atualizarValores() {

    this.isEditing = false;

    for (let icmsItem of this.icmsList) {
      icmsItem.percCif = this.percCif,
       icmsItem.percCifNonTaxPayer = this.percCifNonTaxPayer,
       icmsItem.percFob = this.percFob,
       icmsItem.percFobNonTaxPayer = this.percFobNonTaxPayer,
       icmsItem.isEnabled = true;
       icmsItem.updatedBy = this._appService.UsuarioLogado.Nome.substring(0,15);
    }

  }

  cancelaEdicao()
  {
    this.ObterListaIcms();
    this.isEditing = true;
  }


  IcmsSalvar() {

    this.modalResumo.hide();

    for (let icmsItem of this.icmsList) {
      this._icmsService.atualizar(icmsItem).subscribe((result) => {

        this.IcmsPesquisar();
        this.isEditing=false;

        console.log("sucesso");
      });
    }

    this.percCif = 0;
    this.percCifNonTaxPayer = 0;
    this.percFob = 0;
    this.percFobNonTaxPayer = 0;
    this.isEditing = false;

  }

  public IcmsPesquisar() {
    this.isEditing = true;
    this.ObterListaIcms();
  }

  private ObterListaIcms() {
    this.loading = true;

    if (this.departureUf == "" && this.destinationUf == "") {
      this._icmsService.obterListaIcms().subscribe(
        (lista) => {
          this.icmsList = lista;
          this.paginacao.setAllData(this.icmsList);
          this.paginacao.setPage(1);
       },
        (err) => {}
      );
    } else {
      this.loading = true;
      this._icmsService
        .obterListaIcmsByLocalication(this.departureUf, this.destinationUf)
        .subscribe(
          (lista) => {
            this.icmsList = lista;
            this.paginacao.setAllData(this.icmsList);
            this.paginacao.setPage(1);
          },
          (err) => {}
        );
    }

    this.loading = false;

  }



  ObterDataFields() {
    this._dataService.getUFs().subscribe((lista) => {
      this.ufs = lista;
    });
  }

  exportarRelatorioCSV() {
    this.downloading = true;

    const titulos: any[] = [
      "CODIGO",
      "UF ORIGEM",
      "UF DESTINO",
      "% CIF",
      "% CIF - NÃO CONTRIBUINTE",
      "% FOB",
      "% FOB - NÃO CONTRIBUINTE",
      "CRIADO POR",
      "CRIADO EM",
      "ATUALIZADO POR",
      "ATUALIZADO EM"];


      const data: any[] = [];

      this.icmsList.forEach(x => {
        data.push([
          x.codeIcms,
          x.departureUf,
          x.destinationUf,
          x.percCif,
          x.percCifNonTaxPayer,
          x.percFob,
          x.percFobNonTaxPayer,
          x.createdBy,
          x.createdOn,
          x.updatedBy,
          x.updatedOn
        ]);
      });

    this.downloading = false;
    this.baseService.DownloadXLSX(data, titulos, 'ICMS.xlsx');

  }
}
