
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Response, ResponseType } from '@angular/http';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';

import swal from 'sweetalert2';

import { Assunto } from './../../_models/assunto';
import { FaleConosco } from './../../_models/faleconosco';
import { ValidationResult } from './../../_models/validation-result';

import { AppService } from './../../_services/app.service';
import { BaseService } from './../../_services/base.service';
import { MessageService } from './../../_services/message.service';
import { SharedService } from 'app/_services/shared.service';

import { BaseComponent } from './../../shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss'],
  providers: [BaseService, MessageService]
})

export class FaleConoscoComponent extends BaseComponent implements OnInit {

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  faleConosco: FaleConosco = new FaleConosco();
  //public assunto: Assunto;
  assuntoSelecionado: number;
  assuntos: Assunto[];
  emailfaleconosco: String;
  result: ValidationResult;
  error = '';
  loading: boolean = false;
  loadingAssuntos: boolean = false;

  constructor(private router: Router,
    private baseService: BaseService,
    private msgService: MessageService,
    private sharedService: SharedService,
    private appService: AppService) {

    super ();

    this.msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ngOnInit() {
    //Preenche combo com assuntos
    this.ObterAssuntos();
    this.ObterEmailFaleCom();

    //Se tem usuário logado, preenche campos de identificação
    if (this.appService.isAuthenticated()) {
      this.faleConosco.Nome = this.appService.UsuarioLogado.nome;
      this.faleConosco.Email = this.appService.UsuarioLogado.email;
      this.faleConosco.Telefone = this.appService.UsuarioLogado.telefone;
    }
  }

  public ObterAssuntos() {
    this.loadingAssuntos = true;
    //Busca Assuntos na API e preenche combo com assuntos cadastradas da base
    this.baseService.Get('faleconosco/assuntos').pipe(map((response: ValidationResult) => {
      let retorno: ValidationResult = response;
      if (retorno.IsValid) {
        this.assuntos = retorno.Data as Assunto[];
        this.loadingAssuntos = false;
      }
    })).subscribe();
  }

  public ObterEmailFaleCom() {
     let retorno = this.baseService.Get('faleconosco/emailfalecom').pipe(map((response: String) => {
       this.emailfaleconosco = response})).subscribe();
  }

  public IncluirFaleConosco(myForm: NgForm) {
    this.msgService.clearMessage();

    if ((this.assuntoSelecionado == 0) || (this.assuntoSelecionado == undefined)) {
      this.msgService.addMessage('Fale Conosco', "Informe o Assunto!", 'error', LogFrontFuncionalidade.FaleConosco, Operacao.Inclusao, this.assuntoSelecionado);
      return;
    }

    if (!this.sharedService.validaTelefone(this.faleConosco.Telefone)) {
      this.msgService.addMessage('Fale Conosco', 'Telefone inválido', 'danger', LogFrontFuncionalidade.FaleConosco, Operacao.Inclusao, this.faleConosco.Telefone);
      return;
    }

    //Chama API para inclusão dos dados na base e envio do email
    this.loading = true;
    this.baseService.Post('faleconosco', {
      Nome: this.faleConosco.Nome,
      Email: this.faleConosco.Email,
      Telefone: this.faleConosco.Telefone.toString(),
      Mensagem: this.faleConosco.Mensagem,
      Assunto: { AssuntoID: this.assuntoSelecionado }
    })
      .subscribe(result => {
        this.error = '';
        let retorno: ValidationResult = result;
        if (retorno.IsValid) {
          if (!this.appService.isAuthenticated()) {
            this.faleConosco = new FaleConosco();
            myForm.controls["telefone"].setValue("");
          }

          //this.assunto = null;
          this.assuntoSelecionado = null;
          this.faleConosco.Mensagem = "";

          myForm.form.markAsPristine();
          myForm.form.markAsUntouched();

          swal({
            title: 'Sucesso',
            text: "Mensagem enviada com sucesso!",
            type: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'OK'
          }).then(success => {
            //this._router.navigate(['/home']);
          }, dismiss => {
            // console.log(dismiss);
          });
        }
        else {
          var erros: string[] = [];
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this.msgService.addMessageArray('Fale Conosco', erros, 'error', LogFrontFuncionalidade.FaleConosco, Operacao.Inclusao,
                {
                  Nome: this.faleConosco.Nome,
                  Email: this.faleConosco.Email,
                  Telefone: this.faleConosco.Telefone.toString(),
                  Mensagem: this.faleConosco.Mensagem,
                  Assunto: { AssuntoID: this.assuntoSelecionado }
                });
        }

        this.loading = false;
      },
      err => {
        this.error = err;
        this.loading = false;
      });
  }
}
