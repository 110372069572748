import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

import { Porto } from 'app/_models/porto';
import { Municipio } from 'app/_models/municipio';
import { NavioViagem } from 'app/_models/navioViagem';
import { BaseFiltroTop } from 'app/_models/base-filtro-top';
import { MunicipioRequest } from 'app/_models/municipio-request';
import { NavioViagemRequest } from 'app/_models/navioViagem-request';
import { TrackingListaRequest } from 'app/_models/trackinglista-request';
import { FilterComboConfiguration } from "app/_models/filterComboConfiguration";

import { AppService } from 'app/_services/app.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';
import { MunicipioService } from 'app/_services/municipio.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { NavioViagemService } from 'app/_services/navio-viagem.service';

import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { BaseComponent } from 'app/shared/base.component';
import { CnpjDropdownComponent } from 'app/shared/cnpj-dropdown/cnpj-dropdown.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AnalyticsService } from 'app/_services/analytics.service';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'busca-rastreamento',
  templateUrl: './busca-rastreamento.component.html',
  styleUrls: ['./busca-rastreamento.component.scss']
})
export class BuscaRastreamentoComponent extends BaseComponent implements OnInit {

  @ViewChild(CnpjDropdownComponent, {static: true}) cnpjdropdown: CnpjDropdownComponent;
  @Output() carregaFiltrosDeRastreamento: EventEmitter<TrackingListaRequest> = new EventEmitter();
  @Output() zeraPesquisa: EventEmitter<any> = new EventEmitter();
  //@Output() listaMunicipiosCarregada: EventEmitter<Municipio[]>  = new EventEmitter<Municipio[]>();
   // EventEmitter<IMultiSelectOption[]> = new EventEmitter<IMultiSelectOption[]>();

  // loadings
  loading = false;
  loadingPortos = false;
  loadingMunicipios = false;
  loadingNavioViagem = false;
  loadingDetalhes = false;

  filtroMunicipio: MunicipioRequest = new MunicipioRequest();
  filtroTrackingLista: TrackingListaRequest = new TrackingListaRequest();
  filtroTop: BaseFiltroTop = new BaseFiltroTop();

	navioViagemSelecionado: NavioViagem;
	navioViagens: NavioViagem[] = [];

  // Default selection
  optionsModel: number[] = [];

  portos: Porto[] = [];
  municipios: Municipio[] = [];

  cnpjDigitado: string;

  eAdmin = this._appService.isAdmin();
  eCliente = this._appService.isCliente();
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();
  cssClassAdmin = '';
  cssClassPeriodo = 'select-group t-t-filter-periodo ';

  optionsInicio: DatePickerOptions;

  comboCidades: FilterComboConfiguration;
  comboPortos: FilterComboConfiguration;

  constructor(private _appService: AppService,
              private _municipioService: MunicipioService,
              private _datepickerService: DatepickerService,
              private _msgService: MessageService,
              private _sharedService: SharedService,
              private _navioViagemService: NavioViagemService,
              private _sanitizer: DomSanitizer,
              private _analyticsService: AnalyticsService) {
    super();

    this.comboCidades = new FilterComboConfiguration('Cidade(s)...');
    this.comboCidades.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      enableSearch: true
    };

    this.comboPortos = new FilterComboConfiguration('Porto(s)...');
    this.comboPortos.settings = {
      checkedStyle: 'glyphicon',
      showCheckAll: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 2
    };
  }

  ngOnInit() {
    this.cssClassAdmin = this.eAdmin || (this.eCliente && this.maisDeUmCNPJ)  ? '' : ' customer-profile';
    this.filtroTop.tipoFiltroPeriodo = 1; // Default
    this.optionsInicio = this._datepickerService.GenerateOptionsConfig(new Date());

    this.cnpjdropdown.SelecionaMultiplos = true;
    this.cnpjdropdown.modoEdicao = false;

    this.ObterPortos();

    if (!this._appService.isAdmin()){
      this.ObterMunicipios(null);

      this.cnpjAlterado(this._appService.UsuarioLogado.cnpjs.join(";"));
    }
    else {
      this.cnpjdropdown.cnpjsGrupos = localStorage.getItem('cnpjGrupos');
      if (this.cnpjdropdown.cnpjsGrupos)
        this.cnpjAlterado(this.cnpjdropdown.cnpjsGrupos);
    }
  }

  exibePeriodo(value) {
    this.cssClassAdmin = this.eAdmin || (this.eCliente && this.maisDeUmCNPJ)  ? '' : ' customer-profile';
    this.cssClassAdmin = (value == '3') ? this.cssClassAdmin + ' has-period' : '';
    this.atribuiPeriodoInicialEFinal();
  }

  formataDataFim(): string {
    return (!this.filtroTrackingLista.dataFim) ? '' :
          this._sharedService.formataDataHoraBR(this.filtroTrackingLista.dataFim, false);
  }

  configuraPeriodo() {
    //Se filtro genérico ou navio viagem estão preenchido, o período deve ser vazio, caso contrário, o mesmo é obrigatório
    if ((this.filtroTop.filtroGenerico2 && this.filtroTop.filtroGenerico2.trim().length > 0) ||
        (this.navioViagemSelecionado && this.navioViagemSelecionado.idViagemNavio != 0)) {
          this.filtroTop.tipoFiltroPeriodo = 0;
          this.cssClassPeriodo = 'select-group t-t-filter-periodo disabled';
    }
    else {
          this.cssClassPeriodo = 'select-group t-t-filter-periodo';
          if (this.filtroTop.tipoFiltroPeriodo == 0)
            this.filtroTop.tipoFiltroPeriodo = 1;
    }
    this.exibePeriodo(this.filtroTop.tipoFiltroPeriodo);
  }

  public ObterPortos() {
    this.loadingPortos = true;
    this._sharedService.getPortos()
      .subscribe(m => {
        this.portos = m;
        if (this.portos != null) {
          this.portos.forEach(porto => {
            this.comboPortos.options.push({ id: porto.codigo, name: porto.descricao });
          });
        }
        this.loadingPortos = false;
      });
  }

	obterNaviosViagens(consulta: NavioViagemRequest) {
		this.loadingNavioViagem = true;
		this._navioViagemService.getNavioViagem(consulta)
			.subscribe(viagens => {
				this.loadingNavioViagem = false;
        this.navioViagens = []; //(viagens == null) ? [] : viagens;

        if (viagens)
          viagens.forEach(viagem => {
              var str = viagem.descViagemNavio.split('/');
              var navio = this._sharedService.toCamelCase(str[0]) + '/' + str[1];
              this.navioViagens.push({ idViagemNavio: viagem.idViagemNavio, descViagemNavio: navio, descViagemNavioResim: '', sentido: '', numViagemNavio: '' });
          });

			});
  }

  autocompleListFormatter = (data: any) => {
		let html = `<span>${data.descViagemNavio}</span>`;
		return this._sanitizer.bypassSecurityTrustHtml(html);
		//return html;
	}

  cnpjAlterado(cnpj: string){
    var request = new NavioViagemRequest();
    request.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    request.dataIni = this.filtroTrackingLista.dataIni;
    request.dataFim = this.filtroTrackingLista.dataFim;
    request.CNPJCliente = cnpj;
    request.tipo = "TODOS";
    this.obterNaviosViagens(request);

    this.cnpjDigitado = cnpj;
    this.ObterMunicipios(cnpj);
    this.Pesquisar(false, false);
  }

  public ObterMunicipios(cnpj: string) {
    this.loadingMunicipios = true;
    this.filtroMunicipio.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
    this.filtroMunicipio.tipoConsulta = '2';  // Rastreamento - Cenário 7: tipoConsulta = 2
    this.filtroMunicipio.cnpjCli = cnpj;
    this.filtroMunicipio.dataIni = this.filtroTrackingLista.dataIni;
    this.filtroMunicipio.datafim = this.filtroTrackingLista.dataFim;
    //this._municipioService.getMunicipios('') //.getMunicipiosEspecial(this.filtroMunicipio)
    this.municipios = [];
    this.comboCidades.options = [];
    this._municipioService.getMunicipiosEspecial(this.filtroMunicipio)
      .subscribe(m => {
        this.municipios = m;
        if (this.municipios != null) {
          this.comboCidades.options = [];
          this.municipios.forEach(municipio => {
            this.comboCidades.options.push({ id: municipio.codigo, name: municipio.descricao + ' / ' + municipio.uf });
          });
        }
        //this.listaMunicipiosCarregada.emit(this.municipios); // comboCidades.options);
        this.loadingMunicipios = false;
      });
  }

  public Pesquisar(loadFilter: boolean = true, registrarAnalytics: boolean = true) {
    this._msgService.clearMessage();
    if (this.eAdmin && ((this.cnpjdropdown.CNPJDigitado == undefined) || (this.cnpjdropdown.CNPJDigitado == "")) && (!this.cnpjdropdown.cnpjsGrupos)) {
      this._msgService.addMessage('Rastreamento', 'Informe o CNPJ do Cliente ou selecione ao menos um Grupo Econômico.', 'danger',
         LogFrontFuncionalidade.Rastreamento, Operacao.Consulta, this.filtroTop);
      this.zeraPesquisa.emit();
      return;
    }
    else {
      if (this.eAdmin && this.cnpjdropdown.cnpjsGrupos && this.cnpjdropdown.CNPJDigitado) {
        if (!this.cnpjdropdown.validaCNPJnoGrupo()) {
          this._msgService.addMessage('Rastreamento', 'CNPJ informado não pertence ao(s) Grupo(s) Econômico(s) selecionado(s)!', 'error',
              LogFrontFuncionalidade.Rastreamento, Operacao.Consulta, this.filtroTop);
          this.zeraPesquisa.emit();
          return;
        }
      }

      //limpa mensagens de erros
      this._msgService.clearMessage();

      var cnpjSemFormatacao = "";
      if (this.cnpjdropdown.CNPJDigitado != undefined) {
        cnpjSemFormatacao = this._sharedService.replaceAll(this.cnpjdropdown.CNPJDigitado, '.', '');
        cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '/', '');
        cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '-', '');
      }

      this.filtroTrackingLista.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
      this.filtroTrackingLista.filtroGenerico = this.filtroTop.filtroGenerico;
      this.filtroTrackingLista.filtroGenerico2 = this.filtroTop.filtroGenerico2;
      this.filtroTrackingLista.idsMunicOri = this.filtroTop.municipiosOrigem ? this.filtroTop.municipiosOrigem.join(';') : '';
      this.filtroTrackingLista.idsPortoOri = this.filtroTop.portosOrigem ? this.filtroTop.portosOrigem.join(';') : '';
      this.filtroTrackingLista.idsMunicDes = this.filtroTop.municipiosDestino ? this.filtroTop.municipiosDestino.join(';') : '';
      this.filtroTrackingLista.idsPortoDes = this.filtroTop.portosDestino ? this.filtroTop.portosDestino.join(';') : '';
      this.filtroTrackingLista.cnpjCli = this.cnpjdropdown.CNPJs ? this.cnpjdropdown.CNPJs.join(';') :
                                          this.cnpjdropdown.CNPJDigitado ? cnpjSemFormatacao :
                                          this.cnpjdropdown.cnpjsGrupos ? this.cnpjdropdown.cnpjsGrupos : '';
      this.filtroTrackingLista.idNavioViagem = (this.navioViagemSelecionado) ? this.navioViagemSelecionado.idViagemNavio : null;
      this.atribuiPeriodoInicialEFinal();
      if ((this.filtroTrackingLista.dataIni && this.filtroTrackingLista.dataIni.toString() == "Invalid Date") ||
          (this.filtroTrackingLista.dataFim && this.filtroTrackingLista.dataFim.toString() == "Invalid Date")) {
        this._msgService.addMessage('Rastreamento', '    ', 'danger', LogFrontFuncionalidade.Rastreamento, Operacao.Consulta, this.filtroTrackingLista);
        return;
      }

      if (registrarAnalytics)
        this._analyticsService.RegistrarFiltroRastreamento(this.filtroTrackingLista, this.filtroTop.tipoFiltroPeriodo);

      this.filtroTrackingLista.status = this.filtroTop.tipoFiltroPeriodo == 1;
      this.carregaFiltrosDeRastreamento.emit(this.filtroTrackingLista);

      if(loadFilter)
      {
        this.ObterMunicipios(this._appService.isAdmin() ? this.cnpjDigitado : null);

        var request = new NavioViagemRequest();
        request.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;
        request.dataIni = this.filtroTrackingLista.dataIni;
        request.dataFim = this.filtroTrackingLista.dataFim;
        request.CNPJCliente = this._appService.isAdmin() ? this.cnpjDigitado : this.cnpjdropdown.CNPJFiltro;
        request.tipo = "TODOS";
        this.obterNaviosViagens(request);
      }
    }
  }

  atribuiPeriodoInicialEFinal(): void {
    // A partir de...
    if (this.filtroTop.tipoFiltroPeriodo == 3) {
      let dataInicio = this._datepickerService.ObtemData(this.filtroTop.dataInicio, 'DD/MM/YYYY');
      this.filtroTrackingLista.dataFim = dataInicio; // Inicializa Data Final

      // Atribui período conforme opção selecionada
      this.filtroTrackingLista.dataIni = new Date(dataInicio);
      this.filtroTrackingLista.dataFim.setDate(dataInicio.getDate() + 30);
    }else if(this.filtroTop.tipoFiltroPeriodo == 2) {
      this.filtroTrackingLista.dataFim = new Date();   // defini data atual - 10 dias
      this.filtroTrackingLista.dataIni = this._sharedService.addDays(this.filtroTrackingLista.dataFim, -10);
    } else {
      this.filtroTrackingLista.dataFim = null;   // Default: cargas em trânsito
      this.filtroTrackingLista.dataIni = null;
    }
  }
}
