import { Component, OnInit, Input } from '@angular/core';
import { PortoRota } from '../../../../_models/porto-rota';

@Component({
  selector: 'porto-rota',
  templateUrl: './porto-rota.component.html',
  styleUrls: ['./porto-rota.component.scss']
})
export class PortoRotaComponent implements OnInit {

  @Input() portoRota: PortoRota;

  constructor() { }

  ngOnInit() {
  }

  get textoPrevisto(): string{
    return this.portoRota.isCidadeOrigem ? "Solicitado" : "Chegada Prevista";
  }

  get textoSaida(): string{
    return this.portoRota.isCidadeOrigem ? "Saída do Cliente" : "Saída Realizada";
  }

  get dataSaida(): string{
    return this.portoRota.isCidadeOrigem ? 
    this.portoRota.strDataSaidaCliente : 
    this.portoRota.strDataRealizadaSaida;
  }

  get portoLinha(): string {
    return this.portoRota.FoiVisitado ? "line ok" : (this.portoRota.ProximoPorto ? "line proximo" : "line");
  }

  get portoCirculo(): string {
    return this.portoRota.FoiVisitado ? "circle-success" : (this.portoRota.ProximoPorto ? "circle-proximo" : "circle");
  }

  get portoTexto(): string {
    return this.portoRota.FoiVisitado ? "title" : (this.portoRota.ProximoPorto ? "title proximo" : "title nao-visitado");
  }

  isAtrasado(): string {
    if(this.portoRota.DataRealizada)
      return this.portoRota.DataPrevista >= this.portoRota.DataRealizada  ? "realizado" : "atrasado";
    }
}
