import { Injectable } from '@angular/core';
import { PropostaComercial } from "app/_models/proposta-comercial";

@Injectable({
  providedIn: 'root'
})
export class FiltrosPropostasComerciaisService {

  constructor() { }

  filtrar(propostasComerciais: PropostaComercial[], campoOrigemFiltroNumProposta = false, filtros: any[]): PropostaComercial[] {

    if (!propostasComerciais || propostasComerciais.length == 0) { return propostasComerciais };

    const [numProposta, idTrecho, idMunicOrigem, idMunicDestino, idPortoOrigem, idPortoDestino,
      idFamiliaProduto, tipoOperacaoComercial, tipoDeContainer, modal] = filtros;

    const tipoOper = tipoOperacaoComercial == "" || !tipoOperacaoComercial ? 0 : tipoOperacaoComercial;

    if (campoOrigemFiltroNumProposta) {
      if (numProposta > 0 ) {
        return propostasComerciais.filter(x => x.numProposta == numProposta 
                                            && x.idTrecho == idTrecho);
      }
      return propostasComerciais;
    }

    return propostasComerciais.filter(x =>
        (
          (idMunicOrigem == 0 || (x.idMunicOrigem == idMunicOrigem)) && 
          (idMunicDestino == 0 || (x.idMunicDestino == idMunicDestino)) &&          
          (idPortoOrigem == 0 || (modal.startsWith("PO") && x.idPortolOrigem == idPortoOrigem)) &&
          (idPortoDestino == 0 || (modal.endsWith("PO") && x.idPortoDestino == idPortoDestino)) &&      
          (idFamiliaProduto == 0 || (x.idFamiliaProduto == idFamiliaProduto)) &&
          (tipoOper == 0 || (x.tipoOperacaoComercial && x.tipoOperacaoComercial.toUpperCase() == tipoOper)) &&
          ((tipoDeContainer.idContainer == "0|0") || 
          (tipoDeContainer.idContainer.trim() == (x.tipoDeContainer.codigo + "|" + x.tipoDeContainer.tamanho).trim()))
        )
      );
  }
}
