import { Component, ViewChild, QueryList, Input } from '@angular/core';

import { AgendamentoColeta } from '../../../../../_models/agendamento-coleta';
import { Booking } from '../../../../../_models/booking';
import { PropostaComercial } from '../../../../../_models/proposta-comercial';
import { ItemAgendamentoComponent } from './item-agendamento/item-agendamento.component';
import { SharedService } from '../../../../../_services/shared.service';
import { DateExtensions } from 'app/shared/extensions/extensions.module';

@Component({
  selector: 'lista-agendamento',
  templateUrl: './lista-agendamento.component.html',
  styleUrls: ['./lista-agendamento.component.scss']
})
export class ListaAgendamentoComponent {

  constructor(private _sharedService: SharedService) {
    this.booking = new Booking();
    this.booking.AgendamentosDeColeta = [];
   }

  qtdeAgendamentosCadastrados: number;
  booking: Booking;
  proposta: PropostaComercial;
  copiar: boolean = true;

  @Input() edita: boolean;
  @Input() erroBooking: boolean = false;
  @Input() dataMinima: Date;
  @ViewChild(ItemAgendamentoComponent, {static: true}) itensAgendamento: QueryList<ItemAgendamentoComponent>;
  
  public ConfiguraProposta(booking: Booking, proposta: PropostaComercial, mantemAgendamentos: boolean){
    this.booking = booking;
    this.proposta = proposta;

    //Se a lista de agendamentos estiver vazia,
    //é a primeira vez que entrou nessa tela
    if (booking.AgendamentosDeColeta == undefined)
      booking.AgendamentosDeColeta = [];
    
    //Se a lista de agendamentos for maior que a quantidade de
    //containers, quer dizer que o usuário voltou para o step 1 e
    //removeu contêiners
    if (booking.AgendamentosDeColeta.length > booking.qtdeContainer){
      //Remove os contêiners do fim da lista
      var quantidadeRemover = booking.AgendamentosDeColeta.length - booking.qtdeContainer;
      booking.AgendamentosDeColeta.splice(booking.AgendamentosDeColeta.length - quantidadeRemover, quantidadeRemover);
    }

    //Limpa os dados digitados dos agendamentos prévios se
    //foi informado no parâmetro que os dados não devem ser mantidos
    if (!mantemAgendamentos){
      booking
      .AgendamentosDeColeta
      .filter(agendamento => agendamento.numAgendamento == 0)
      .forEach(agendamento => {
        agendamento.peso = (proposta) ? proposta.pesoContainer : 0;
        agendamento.valMercadoria = (proposta) ? proposta.valMercadoria : 0;
        agendamento.numRefCliente = "";
        agendamento.horaColeta = "";
        if (agendamento.dataSelecionada != undefined)
          agendamento.dataSelecionada = DateExtensions.toDateModel(this.dataMinima, "DD/MM/YYYY"); //new Date().toLocaleDateString('pt-br');
      });
    }
    
    //Armazena a quantidade de agendamentos pré-existentes
    this.qtdeAgendamentosCadastrados = booking.AgendamentosDeColeta.filter(a => a.numAgendamento != 0).length;
    
    //Se a lista de agendamentos estiver vazia e não houver
    //agendamentos pré-existentes, preenche a lista inteiramente
    //com agendamentos vazios. Se já houverem agendamentos na lista
    //apenas completa a lista com a quantidade de contêineres
    //adicionais informado pelo usuário no step 1.
    if (booking.qtdeContainer > this.qtdeAgendamentosCadastrados)
      for(var i = booking.AgendamentosDeColeta.length; i < booking.qtdeContainer; i++){
        var agendamento: AgendamentoColeta = new AgendamentoColeta();
        agendamento.numAgendamento = 0;
        agendamento.peso = proposta ? proposta.pesoContainer : 0;
        agendamento.valMercadoria = proposta ? proposta.valMercadoria : 0;
        booking.AgendamentosDeColeta.push(agendamento);
      }
    
    proposta.tipoDeContainer.Desc_resumida = booking.tipoContainer
    proposta.tipoDeContainer.Descricao = booking.categoriaContainer
  }

  RepetirValoresAgendamento(index: number){
    for(var i = index + 1; i < this.booking.AgendamentosDeColeta.length; i++){
      var agendamento = this.booking.AgendamentosDeColeta[i];
      var agendamentoBase = this.booking.AgendamentosDeColeta[index];
      agendamento.peso = agendamentoBase.peso;
      agendamento.valMercadoria = agendamentoBase.valMercadoria;
      agendamento.numRefCliente = agendamentoBase.numRefCliente;
      agendamento.dataColeta = agendamentoBase.dataColeta;
      agendamento.horaColeta = agendamentoBase.horaColeta;
      agendamento.dataSelecionada = this._sharedService.clonaDateModel(agendamentoBase.dataSelecionada);
    }
  }

  public ObterModalBooking(): string{
    return (this.booking.oriPorto ? "PO" : "PA") + "/" + (this.booking.desPorto ? "PO" : "PA");
  }

}
