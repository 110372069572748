
import {catchError, map} from 'rxjs/operators';
import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { MessageService } from './message.service';

import { Operacao } from '../_enums/operacao';
import { DoctoFatura } from '../_models/doctoFatura';
import { DownloadArquivo } from '../_models/downloadArquivo';
import { EnvioAnexoEmail } from '../_models/envioAnexoEmail';
import { ValidationResult } from "app/_models/validation-result";
import { DoctoFaturaArquivo } from "app/_models/doctoFaturaArquivo";
import { DoctoFaturaRequest } from '../_models/doctoFatura-request';
import { DocumentosSelecionados } from "app/_models/documentos-selecionados";
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { DoctoFaturaArquivoRequest } from '../_models/doctoFaturaArquivo-request';

@Injectable({
  providedIn: 'root'
})
export class DocumentacaoService {

  getLinksCertificados(idsViaGreen): Observable<string[]> {
    return this.baseService.Get("linksCertificadosCo2").pipe(map((response: ValidationResult) => {
      let retorno: ValidationResult = response;
      if(retorno.IsValid)
        return retorno.Data as string[];
      else{
        const erros = [];
        erros.push("Erro ao consultar os certificado de compensação de Co2! Contate o administrador!");
        this._msgService.addMessageArray('Documentação', erros, "error", LogFrontFuncionalidade.Documentacao, Operacao.Consulta, idsViaGreen);
      }
    }),catchError(e => {
      const erros = [];
      erros.push("Erro ao consultar os certificados de compensação de Co2! Contate o administrador!");
      this._msgService.addMessageArray('Documentação', erros, "error", LogFrontFuncionalidade.Documentacao, Operacao.Consulta, idsViaGreen);
      return this.baseService.handleError(e);
    }),);
  }

  constructor(private baseService: BaseService, private _msgService: MessageService, private _appService: AppService) { }

  getListaDocumentacao(consulta: DoctoFaturaRequest): Observable<DoctoFatura[]> {
    return this.baseService.Post('doctofatura', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as DoctoFatura[];
        else {
          var erros: string[] = [];
          erros.push("Erro ao consultar Documentos! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          if (erros.join(".").indexOf("Pesquisa não encontrou nenhuma informação") > 0)
            return [];
          this._msgService.addMessageArray('Documentação', erros, "error", LogFrontFuncionalidade.Documentacao, Operacao.Consulta, consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getListaArquivos(consulta: DoctoFaturaArquivoRequest): Observable<DoctoFaturaArquivo[]> {
    return this.baseService.Post('doctofatura/arquivos', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as DoctoFaturaArquivo[];
        else {
          var erros: string[] = [];
          erros.push("Erro ao consultar Arquivos do Documento! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Documentação', erros, "error", LogFrontFuncionalidade.Documentacao, Operacao.Consulta, consulta);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  downloadArquivo(arquivo: DoctoFaturaArquivo): Observable<DownloadArquivo> {
    arquivo.idUsuario = this._appService.UsuarioLogado.usuarioID;
    return this.download(arquivo, 'doctofatura/download');
  }

  downloadTodos(arquivos: DoctoFaturaArquivo[]): Observable<DownloadArquivo> {
    arquivos.forEach(arq => arq.idUsuario = this._appService.UsuarioLogado.usuarioID)
    return this.download(arquivos, 'doctofatura/download/todos');
  }

  downloadSelecionados(documentos: DoctoFatura[]): Observable<DownloadArquivo> {
    var selecionados = new DocumentosSelecionados();
    selecionados.UsuarioId = this._appService.UsuarioLogado.usuarioID;
    selecionados.UsuarioIDeCargo = this._appService.UsuarioLogado.usuarioIDeCargo;
    selecionados.Documentos = documentos;
    return this.download(selecionados, 'doctofatura/download/selecionados');
  }

  private download(arquivo: any, url: string): Observable<DownloadArquivo>{
    return this.baseService.Post(url, arquivo).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return retorno.Data as DownloadArquivo;
        else {
          var erros: string[] = [];
          erros.push("Erro ao realizar download do arquivo! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Documentação', erros, "error", LogFrontFuncionalidade.Documentacao, Operacao.Download, arquivo);
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  enviaEmailSelecionados(documentos: DoctoFatura[]): Observable<boolean> {
    var selecionados = new DocumentosSelecionados();
    selecionados.UsuarioId = this._appService.UsuarioLogado.usuarioID;
    selecionados.UsuarioIDeCargo = this._appService.UsuarioLogado.usuarioIDeCargo;
    selecionados.Documentos = documentos;
    return this.enviaEmail(selecionados, 'doctofatura/email/selecionados');
  }

  enviaEmailArquivos(arquivos: DoctoFaturaArquivo[]): Observable<boolean> {
    var consulta = new EnvioAnexoEmail();
    consulta.UsuarioId = this._appService.UsuarioLogado.usuarioID;
    consulta.Arquivos = arquivos;
    return this.enviaEmail(consulta, 'doctofatura/email');
  }

  enviaEmail(consulta: any, url:string): Observable<boolean> {
    return this.baseService.Post(url, consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return true;
        else {
          var erros: string[] = [];
          erros.push("Erro ao enviar Arquivos do Documento por e-mail! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Documentação', erros, "error", LogFrontFuncionalidade.Documentacao, Operacao.Email, consulta);
          return false;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }
}
