import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from './../../shared/shared.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';

//DATEPICKER
import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';

// MAERSK
import { TipoTaxaEsporadicaRoutingModule } from './tipo-taxa-esporadica.routing.module';
import { TipoTaxaEsporadicaComponent } from './tipo-taxa-esporadica.component';
import { TipoTaxaEsporadicaDetalheComponent } from './tipo-taxa-esporadica-detalhe/tipo-taxa-esporadica-detalhe.component';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        ModalModule.forRoot(),
        SharedModule,
        DatePickerModule,
        CurrencyMaskModule,
        TextMaskModule,

        // MAESRK
        TipoTaxaEsporadicaRoutingModule,
    ],
    exports: [],
    declarations: [
      TipoTaxaEsporadicaComponent,
      TipoTaxaEsporadicaDetalheComponent
    ],
    providers: [
        // PerfilService,
        // UsuarioService,
        // FuncionalidadeService
    ]
})
export class TipoTaxaEsporadicaModule { }
