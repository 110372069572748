
import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
//import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';


//import { AppService } from './app.service';
import { BaseService } from './base.service';

import { Login } from './../_models/login';
import { AlterarSenha } from './../_models/alterar-senha';
import { UsuarioLogado } from './../_models/usuariologado';
import { ValidationResult } from './../_models/validation-result';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    configInfo: any;

    constructor(private baseService: BaseService) {
    }

    emitirLogin = new EventEmitter<boolean>();

    login(login: Login): Observable<ValidationResult> {
        //return this.http.post(this.appService.apiUrl + 'account', JSON.stringify({ username: login.username, password: login.password }), this.getOptionsArgs())
        return this.baseService.Post('account',{ username: login.username, password: login.password }).pipe(
            map((response: ValidationResult) => {
                // login successful if there's a jwt token in the response
                let retorno: ValidationResult = response;
                this.baseService.limpaStorage();
                console.log(retorno);
                //validar se está expirado mas é vlaido
                if(retorno.Message == "")
                {
                    if (retorno.IsValid) {
                        // store username and jwt token in local storage to keep user logged in between page refreshes
                        //this.appService.UsuarioLogado = retorno.Data as UsuarioLogado;
                        //localStorage.setItem('currentUser', JSON.stringify(this.appService.UsuarioLogado));
                        this.baseService.setUsuarioLogado(retorno.Data as UsuarioLogado);
                        this.emitirLogin.emit(true);
                    }
                    else {
                        this.emitirLogin.emit(false);
                    }
                }
                //this.tokenExpirado = false;
                console.log(retorno);
                return retorno;
            }),
            catchError(e => {
                console.log("erro front");
                return this.baseService.handleError(e);
            }),);
        ;
    }

    logout(callModal = false): void {
        //this.appService.UsuarioLogado = null;
        this.baseService.setUsuarioLogado(null);
        this.baseService.limpaStorage();
        this.emitirLogin.emit(callModal);
    }

    emitirModal = new EventEmitter<boolean>();

    public showModal(): void {
        this.emitirModal.emit(true);
    }

    public hideModal(): void {
        this.emitirModal.emit(false);
    }

    ResetSenha(email: string): Observable<ValidationResult> {
        return this.baseService.Post('account/resetsenha', { email: email }).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;
            return retorno;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
    }

    CadastrarSenha(viewmodel: AlterarSenha): Observable<ValidationResult> {
        return this.baseService.Post('account/cadastrarsenha', viewmodel).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;
            return retorno;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
    }

    ObterEmail(viewmodel: AlterarSenha): Observable<ValidationResult> {
        return this.baseService.Post('account/email', viewmodel).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;
            return retorno;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
    }

    GetUltimasAlteracoesSenha():Observable<ValidationResult> {
      return this.baseService.Get('account/ultimasAltercoesSenha').pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;
            return retorno;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
    }

}
