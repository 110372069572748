import { Incidente } from "app/_models/incidente";

export class AtualizarOrdemServico {
    idUsuario: number;
    idTipoContainer: number;
    solicitContainerId: number;
    tamanhoContainer: number;
    idOs: number;
    tara: number;
    numOs: string;
    dhRetiradaTerminal: Date;
    dhChegadaCliente: Date;
    dhSaidaCliente: Date;
    dhChegadaTerminal: Date;
    numContainer: string;
    lacre1: string;
    lacre2: string;
    lacre3: string;
    placa: string;
    observacao: string;
    idTipoServico: number;
    indicAvulsa: boolean;

    justificativas: Incidente[];
}
