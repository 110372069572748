import { Injectable } from "@angular/core";
import { Data } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class InfoPgtoAdicional {
    idInfoPgtoAdicional: number;
    idBooking: string;
    idProposta: number;
    idTipoPagto: number = 0;
    idClassificacaoCli: number = 0;
    idTipoServico: number  = 0;
    vlrTotalNota: number  = 0;
    vlrFreteNegociado: number  = 0;
    percAdvalorem: number  = 0;
    percAliquotaIcms: number  = 0;
    vlrAdValorem: number  = 0;
    vlrBaf: number  = 0;
    vlrTaxaAdicional: number = 0;
    vlrIcms: number = 0;
    vlrDesconto: number  = 0;
    cnpjDeposito: string = '';
    bancoDeposito: string = '';
    agenciaDeposito: string = '';
    contaDeposito: string = '';
    descIndicador: string = '';
    ufPagadorFrete:  string = '';
    origemModalId: string = '';
    destinoModalId: string = '';

    qrCode: string;

    paidAt: string | null;
    paymentStatus: string = '';

    customerName: string = '';
    cpfCnpj: string = '';
    qdtConteiner: number = 0;
    containerType: number = 0;
    vlrFreteTotal: number = 0;

    createdBy         : string;
    createdOn: Date;
    updatedBy         : string;

    dataBaseCalculo: string;

    totalPaymentAmount: number = 0;

    amountReceived: number = 0;

    getTotalPix(): number {
      return (this.vlrFreteNegociado +
        this.vlrBaf +
        this.vlrAdValorem +
        this.vlrIcms +
        this.vlrTaxaAdicional) - this.vlrDesconto;
   }
}


const add: Function = (a: number, b: number): number => a + b;


