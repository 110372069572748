import { SentidoRota } from '../_enums/sentido-rota.enum';

export class AnelRota {
    AnelRotaID: number; 
    Ordem: number;
    Sentido: SentidoRota;
    CodigoPortoOrigem: number;
    CodigoPortoDestino: number;
    DescricaoPortoOrigem: string;
    DescricaoPortoDestino: string;
    AnelID: number;
    Novo: boolean;
    Ultimo: boolean;
}