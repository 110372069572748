import { Component, OnInit, ViewChild } from '@angular/core';

import { Anel } from '../../../_models/anel';
import { Navio } from './../../../_models/navio';
import { FilterComboConfiguration } from 'app/_models/filterComboConfiguration';

import { MessageService } from './../../../_services/message.service';
import { AnelRotaService } from '../../../_services/anel-rota.service';
import { ParametroService } from './../../../_services/parametro.service';

import { BaseComponent } from './../../../shared/base.component';
import { MessageComponent } from './../../../shared/message/message.component';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'app-navios',
  templateUrl: './navios.component.html',
  styleUrls: ['./navios.component.scss']
})
export class NaviosComponent extends BaseComponent implements OnInit {
  
  public aneis: Anel[];
  navios: Navio[];
  loading: boolean = false;
  loadingAneis: boolean = false;

  comboAneis: FilterComboConfiguration;

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  
  constructor(private _msgService: MessageService,
              private _anelRotaService: AnelRotaService,
              private _parametroService: ParametroService) { 
    super();

    this.comboAneis = new FilterComboConfiguration('Rota(s)...');
    this.comboAneis.settings = {
      checkedStyle: 'glyphicon',
      selectionLimit: 1, //REMOVER QUANDO A ATIVIDADE ESTIVER LIBERADA, E DESCOMENTAR OS 'true' ABAIXO
      showCheckAll: false, //true,
      showUncheckAll: false, //true,
      dynamicTitleMaxItems: 2,
      enableSearch: false
    };

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );

  }

  ngOnInit() {
     this.ObterNavios();
     this.ObterAneis();
  }

  ObterNavios() {
    this._msgService.clearMessage(); 
    this.loading = true;
    this._parametroService.getNaviosAdmin()
      .subscribe(n => {
        //Converte o ID do anel para Array para o Combo Multiselect funcionar
        if (n != null){
          n.forEach(navio => {
            navio.aneisID = [];
            if (navio.aneis != undefined){
              navio.aneis.forEach(anel => {
                navio.aneisID.push(anel.anelID);
              });
            }
          });
        }
        this.navios = n;
        this.loading = false;
      });
  }

  public ObterAneis() {
    this.loadingAneis = true;
    this.aneis = [];
    this._anelRotaService.getAneis()
      .subscribe(p => {
        this.comboAneis.options = [];
        this.aneis = p;
        if (this.aneis != null){
          this.aneis.forEach(anel => {
            this.comboAneis.options.push({ id: anel.anelID, name: anel.nome });
          });
        }
        this.loadingAneis = false;
      }
      );
  }

  Salvar() {
    this.AjustaAneisNavios();

    if (!this.ValidaNaviosRotas()) {
      this._msgService.addMessage('Navios', 'Os Navios marcados como "Exibe Portal" devem conter ao menos uma rota.', "error", 
               LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.navios);
      return;
    }

    if (!this.ValidaCodigoIMO()) {
      this._msgService.addMessage('Navios', 'Os Navios marcados como "Exibe Portal" devem ter Código IMO.', "error",
               LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.navios);
      return;
    }


    if (!this.ValidaValorCodigoIMO()) {
      this._msgService.addMessage('Navios', 'O Código IMO deve ser um número entre 0 e 9999999.', "error",
               LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.navios);
      return;
    }

    this.loading = true;
    this._parametroService.salvarNavio(this.navios)
          .subscribe(result => {
            this.loading = false;
            if (result.IsValid) {
              this._msgService.addMessage('Navios', result.Message, "success");
              this.ObterNavios();
            }
            else{
              var msgErr: string[] = [];
              result.Erros.forEach(element => msgErr.push(element.Message));             
              this._msgService.addMessageArray("Navios", msgErr, "error", LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.navios);   
            }
          });
  }

  private AjustaAneisNavios(){
    this.navios.forEach(navio => {
      navio.aneis = [];
      if (navio.aneisID != undefined) {
        navio.aneisID.forEach(anelID => {
          var anel = this.aneis.find(f => f.anelID == anelID);
          if (anel)
            navio.aneis.push(anel);
        });
      }
    });
  }

  private ValidaNaviosRotas(): boolean {
    var erro = true;
    this.navios.forEach(navio => {
      if (navio.exibePortal && (!navio.aneis || navio.aneis.length == 0))
        erro = false;
    });
    return erro;
  }

  private ValidaCodigoIMO(): boolean {
    var erro = true;
    this.navios.forEach(navio => {
      if (navio.exibePortal && (!navio.codigoIMO))
        erro = false;
    });
    return erro;
  }

  private ValidaValorCodigoIMO(): boolean {
    var erro = true;
    this.navios.forEach(navio => {
      if (navio.exibePortal && navio.codigoIMO && (navio.codigoIMO < 0) || (navio.codigoIMO > 9999999))
        erro = false;
    });
    return erro;
  }
}
