import { Component, OnInit, ViewChild } from '@angular/core';

import { DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import { Incidente } from 'app/_models/incidente';
import { OrdemServico } from './../../_models/ordemServico';
import { TipoContainer } from './../../_models/tipo-container';
import { FiltroListaItem } from './../../_models/filtro-lista-item';
import { IncidenteRequest } from './../../_models/incidente-request';
import { OrdemServicoRequest } from 'app/_models/ordemServicoRequest';
import { AtualizarOrdemServico } from 'app/_models/atualizar-ordem-servico';

import { BaseComponent } from 'app/shared/base.component';
import { MessageComponent } from 'app/shared/message/message.component';
import { DatePickerOptions } from './../../shared/ng2-datepicker/ng2-datepicker.component';
import { FiltroTopComponent } from 'app/provedor/filtro-top/filtro-top.component';
import { IncidenteComponent } from './incidente/incidente.component';
import { FiltroListaComponent } from 'app/provedor/filtro-lista/filtro-lista.component';
import { ListaProvedorComponent } from 'app/provedor/lista-provedor/lista-provedor.component';
import { MensagemModalComponent } from './../../shared/mensagem-modal/mensagem-modal.component';
import { OrdenacaoProvedorComponent } from 'app/provedor/ordenacao-provedor/ordenacao-provedor.component';

import { LogService } from 'app/_services/log.service';
import { AppService } from 'app/_services/app.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';
import { DatepickerService } from './../../_services/datepicker.service';
import { OrdemServicoService } from 'app/_services/ordem-servico.service';

import { DateExtensions } from "app/shared/extensions/extensions.module";
import { AnalyticsTela } from '../../_enums/analytics-tela';
import { AnalyticsService } from 'app/_services/analytics.service';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { DataService } from 'app/_services/data.service';

@Component({
  selector: 'ordem-servico',
  templateUrl: './ordem-servico.component.html',
  styleUrls: ['./ordem-servico.component.scss']
})

export class OrdemServicoComponent extends BaseComponent implements OnInit {
  @ViewChild('botaoCancelarOS', {static: true}) botaoCancelarOS;
  @ViewChild('botaoCancelarNota', {static: true}) botaoCancelarNota;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  @ViewChild(FiltroTopComponent, {static: true}) filtroTop: FiltroTopComponent;
  @ViewChild(IncidenteComponent, {static: true}) incidente: IncidenteComponent;
  @ViewChild(FiltroListaComponent, {static: true}) filtroLista: FiltroListaComponent;
  @ViewChild(ListaProvedorComponent, {static: true}) listaProvedor: ListaProvedorComponent;
  @ViewChild(OrdenacaoProvedorComponent, {static: true}) ordenacaoProvedor: OrdenacaoProvedorComponent;
  @ViewChild(MensagemModalComponent, {static: true}) mensagemModal: MensagemModalComponent;


  mascaraHoraRetirada: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];
  mascaraHoraInicio: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];
  mascaraHoraFim: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];
  mascaraHoraEntrega: Array<string | RegExp> = [/[0-2]/,/[0-9]/, ':', /[0-5]/, /[0-9]/];

  downloading: boolean = false;
  empty: boolean = true;
  noresult: boolean = false;
  loading: boolean = false;
  loadingOSs: boolean = false;
  loadingSalvar: boolean = false;
  loadingContainers: boolean = false;
  loadingIndicadores: boolean = false;

  filtroPesquisa: OrdemServicoRequest;
  totais: FiltroListaItem[];

  eAdmin: boolean = this._appService.isAdmin();
  ordemServico: OrdemServico = new OrdemServico();

  idContainerSelecionado: string = '';
  bloqueiaCamposModal: boolean = false;
  tipoContainers: TipoContainer[] = [];
  incidentesBlocked: Incidente[] = [];
  options: DatePickerOptions;
  mensagemErro: string = '';

  exibirMensagemModal: boolean = false;
  textoMensagemModal: string;
  classeTipoMensagem: string;

  exibirMensagemModalOSs: boolean = false;
  textoMensagemModalOSs: string;
  classeTipoMensagemModalOSs: string;

  exibirReplicarParaOSs: boolean = true;

  OSs: OrdemServico[] = [];
  OSsSelecionadas: OrdemServico[] = [];
  campoOrdenarPor: string = "dhProgramacao";
  camposOrdenacao = [
    { descricao: "Ordem de Serviço", id: "numOs" },
    { descricao: "Tipo de Serviço", id: "descTipoServico" },
    { descricao: "Navio / Viagem", id: "descNavioViagem" },
    { descricao: "Porto", id: "portoOriDes" },
    { descricao: "Emb / Dest", id: "nomeEmbDest" },
    { descricao: "Município", id: "municOriDes" },
    { descricao: "Data da Programação", id: "dhProgramacao" },
    { descricao: "Contêiner", id: "numContainer" }
  ];

  constructor(public _datePickerService: DatepickerService,
    private _ordemServicoService: OrdemServicoService,
    private _messageService: MessageService,
    private _sharedService: SharedService,
    private _appService: AppService,
    private _logService: LogService,
    private _analyticsService: AnalyticsService,
    private _dataService: DataService) {
    super();

    this.ordemServico.incidentes = [];

    this.totais = [
      FiltroListaItem.Create('step-a-vincular', 'icon-clock', 'aberta', 'Aberta(s)', 0),
      FiltroListaItem.Create('step-vinculadas', 'icon-check', 'fechada', 'Fechadas(s)', 0),
      FiltroListaItem.Create('step-ctes-emitidos', 'icon-dollar', 'liberada para pagamento', 'Liberada(s) para Pagamento', 0),
    ];
  }

  changeRegex(input_time, mascara){
    if(input_time.charAt(0) == '2'){
       mascara[1]=new RegExp('[0-3]')
    }else{
       mascara[1]=new RegExp('[0-9]')
    }
  }

  ngOnInit() {
    this.options = this._datePickerService.GenerateOptionsConfig(null);
    this.filtroTop.classePrincipal = "minhas-os";
    this.filtroLista.classePrincipal = "minhas-os";
    this.listaProvedor.classePrincipal = "minhas-os";

    if (!this.eAdmin) {
      this.pesquisaInicial();
    }

    this.filtroTop.carregarFiltros.subscribe((filtro: OrdemServicoRequest) => {
      if (filtro) {
        this.pesquisar(filtro);
      }
    });

    this.listaProvedor.carregarFiltros.subscribe((filtro: OrdemServicoRequest) => {
      if (this.filtroPesquisa) {
        this.pesquisar(this.filtroPesquisa);
      }
      else {
        this.pesquisaInicial();
      }
    });

    this.ordenacaoProvedor.exportarArquivo.subscribe((exportar: boolean) => {
      if (exportar) {
        this.exportarRelatorioCSV();
      }
    });

    this.ordenacaoProvedor.quantidadePorPagina.subscribe((quantidadePorPagina: number) => {
      this.listaProvedor.setQuantidadeRegistrosPorPagina(quantidadePorPagina);
    });

    this._messageService.emitirMensagem.subscribe(msg => {
      this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
    });

    this._analyticsService.RegistrarAcesso(AnalyticsTela.OrdemServico);
    //Obtém incidentes que devam ficar bloqueados
    this._dataService.getIncidentesBlocked()
    .subscribe(lista => {
      this.incidentesBlocked = lista;
    });
  }

  pesquisaInicial(): void {
    const filtro: OrdemServicoRequest = new OrdemServicoRequest();
    filtro.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
    filtro.cnpjProvedor = this._appService.UsuarioLogado.CNPJs.join(";");
    filtro.dataFim = new Date();
    filtro.dataIni = this._sharedService.addDays(filtro.dataFim, -14);
		this._sharedService.setTimeZero(filtro.dataIni);
		this._sharedService.setTimeZero(filtro.dataFim);
    this.pesquisar(filtro);
  }

  pesquisar(consulta: OrdemServicoRequest): void {
    var diasDiff = Math.floor((consulta.dataFim.valueOf() - consulta.dataIni.valueOf()) / (1000 * 60 * 60 * 24)) + 1;
    if (diasDiff > 31) {
      this._messageService.addMessage("Ordens de Serviço", "O filtro por período não deve ser superior à 31 dias.", "error",
         LogFrontFuncionalidade.OrdemDeServico, Operacao.Consulta, consulta);
      return;
    }

    consulta.vincularNF = false;

    this.loading = true;
    this.filtroPesquisa = consulta;
    this.filtroTop.loading = true;
    this.filtroLista.zerarQuantidades();

    this._ordemServicoService.obtemOrdensDeServico(consulta, AnalyticsTela.OrdemServico).subscribe(result => {
      if (!result || !result.OSs)
        return;

      this.OSs = result.OSs;
      this.filtroTop.preencheServicos(result.TiposServicosOS);
      this.filtroTop.preencheTipos(result.TiposOS);
      this.filtroTop.preenchePortos(result.OSs);
      this.filtroTop.preencheNavioViagens(result.OSs);
      this.listaProvedor.carregaListaOrdens(result.OSs, this.campoOrdenarPor);
      this.listaProvedor.setTiposEventos(result.tiposEventosOS);
      this.filtroLista.carregaDadosEmComponente(result.tiposEventosOS);
      this.aplicarFiltro(this.filtroLista.stepAtivo);

      this.empty = false;
      this.noresult = (!this.OSs || (this.OSs.length === 0));
    }, (errors) => {
      console.log('erro 1');
      console.log(errors);
      this.filtroTop.loading = false;
      this.loading = false;
    }, () => {
      this.loading = false;
      this.filtroTop.loading = false;
    });
  }

  exportarRelatorioCSV() {
    this.downloading = true;
    this._ordemServicoService.obtemOrdensDeServicoCSV(this.filtroPesquisa).subscribe(ordens => {
      this.downloading = false;
      this._ordemServicoService.exportarRelatorioCSV(ordens);
    });
  }

  aplicarFiltro(tipoEvento: string) {
    this.listaProvedor.carregaListaOrdens(
      this._ordemServicoService.filtrarPorStep(this.OSs, tipoEvento), this.campoOrdenarPor
    );
  }

  ordenarPor(campoDeOrdenacao: string) {
    this.campoOrdenarPor = campoDeOrdenacao;
    this.listaProvedor.obtemListaOrdensOrdenadaPorCampo(campoDeOrdenacao);
  }

  fecharOS(OS: OrdemServico) {
    this.loadingSalvar = true;

    this.mensagemModal.ocultarMensagem();
    this.ordemServico = new OrdemServico();

    Object.assign(this.ordemServico, OS);

    this.ordemServico.dataRetiradaTerminal = this.ordemServico.dtRetiradaTerminal == null ? new DateModel() : this.atribuiData(this.ordemServico.dtRetiradaTerminal);
    this.ordemServico.dataInicioExecucao = this.ordemServico.dtInicioExecucao == null ? new DateModel() : this.atribuiData(this.ordemServico.dtInicioExecucao);
    this.ordemServico.dataFimExecucao = this.ordemServico.dtFimExecucao == null ? new DateModel() : this.atribuiData(this.ordemServico.dtFimExecucao);
    this.ordemServico.dataEntregaTerminal = this.ordemServico.dtEntregaTerminal == null ? new DateModel() : this.atribuiData(this.ordemServico.dtEntregaTerminal);

    this.ordemServico.dtRetiradaTerminal = (this.ordemServico.dtRetiradaTerminal != undefined) ? this._sharedService.corrigeData(this.ordemServico.dtRetiradaTerminal) : null;
    this.ordemServico.dtInicioExecucao = (this.ordemServico.dtInicioExecucao != undefined) ? this._sharedService.corrigeData(this.ordemServico.dtInicioExecucao) : null;
    this.ordemServico.dtFimExecucao = (this.ordemServico.dtFimExecucao != undefined) ? this._sharedService.corrigeData(this.ordemServico.dtFimExecucao) : null;
    this.ordemServico.dtEntregaTerminal = (this.ordemServico.dtEntregaTerminal != undefined) ? this._sharedService.corrigeData(this.ordemServico.dtEntregaTerminal) : null;

    this.bloqueiaCamposModal = (OS.tipo != 'Coleta' && OS.tipo != 'Exportação');
    this.obterIndicadores(OS);
    this.obterTipoContainers();

    this.loadingSalvar = false;
  }

  atribuiData(dataBD: Date) {
    if (!dataBD) {
      return null;
    }
    else {
      var dataCalendar = new DateModel();
      dataCalendar.formatted = this._sharedService.formataDataHoraBR(this._sharedService.corrigeData(dataBD), false);

      return dataCalendar;
    }
  }

  fecharOSsSelecionadas(OS: OrdemServico) {
    this.loadingOSs = true;
    this.ocultarMensagemOSs();

    this.OSsSelecionadas = this.OSs.filter(x => x.selecionado);
    this.exibirReplicarParaOSs = this.OSsSelecionadas && this.OSsSelecionadas.length > 1;

    if (this.OSsSelecionadas && this.OSsSelecionadas.length) {
      this.OSsSelecionadas.forEach(element => {
        element.dataInicioExecucao = null;
        element.dataFimExecucao = null;

        element.dataInicioExecucao = DateExtensions.toDateModel(element.dtInicioExecucao, "DD/MM/YYYY");
        element.dataFimExecucao = DateExtensions.toDateModel(element.dtFimExecucao, "DD/MM/YYYY");

        element.dtInicioExecucao = (element.dtInicioExecucao != undefined) ? this._sharedService.corrigeData(element.dtInicioExecucao) : null;
        element.dtFimExecucao = (element.dtFimExecucao != undefined) ? this._sharedService.corrigeData(element.dtFimExecucao) : null;
      });
    }

    this.loadingOSs = false;
  }

  replicarDadosParaOSs() {
    this.loadingOSs = true;

    let OS = this.OSsSelecionadas[0];
    OS.dtInicioExecucao = this.ConfigurarData(OS.dataInicioExecucao, OS.dtInicioExecucao, this.limpaHora(OS.hrInicioExecucao) || '0');
    OS.dtFimExecucao = this.ConfigurarData(OS.dataFimExecucao, OS.dtFimExecucao, this.limpaHora(OS.hrFimExecucao) || '0');

    if (OS && this.OSsSelecionadas.indexOf(OS) === 0) {
      this.OSsSelecionadas.forEach(element => {
        if (element.idOs === OS.idOs) {
          return;
        }

        element.dataInicioExecucao = DateExtensions.toDateModel(OS.dtInicioExecucao, "DD/MM/YYYY");
        element.dataFimExecucao = DateExtensions.toDateModel(OS.dtFimExecucao, "DD/MM/YYYY");

        element.dtInicioExecucao = OS.dtInicioExecucao;
        element.hrInicioExecucao = OS.hrInicioExecucao;
        element.dtFimExecucao = OS.dtFimExecucao;
        element.hrFimExecucao = OS.hrFimExecucao;

        element.dtInicioExecucao = this.ConfigurarData(element.dataInicioExecucao, element.dtInicioExecucao, this.limpaHora(element.hrInicioExecucao));
        element.dtFimExecucao = this.ConfigurarData(element.dataFimExecucao, element.dtFimExecucao, this.limpaHora(element.hrFimExecucao));
      });
    }

    this.loadingOSs = false;
  }

  ocultarReplicar() {
    this.exibirReplicarParaOSs = false;
  }

  selecionarTodasOSs() {
    var totalSelecionados = this.OSs.filter(o => o.selecionado == true).length;
    var total = this.OSs.filter(o => o.descTipoServico !== "Transporte Rodoviário" && o.statusOs.toLowerCase() === "aberta").length;

    this.OSs.forEach(ordem => {
      if (ordem.selecionado && typeof ordem.selecionado === 'boolean') {
        ordem.selecionado = ((totalSelecionados > 0) && (totalSelecionados < total)) ? true : !ordem.selecionado;
        let ordemIndex = this.OSsSelecionadas.indexOf(ordem);
        if (ordemIndex > -1) {
          this.OSsSelecionadas.splice(ordemIndex, 1);
        }
      }
      else {
        if (ordem.descTipoServico !== "Transporte Rodoviário" && ordem.statusOs.toLowerCase() === "aberta") {
          ordem.selecionado = true;
          this.OSsSelecionadas.push(ordem);
        }
      }
    });
  }

  salvarOSs() {
    this.loadingOSs = true;
    this.ocultarMensagemOSs();

    if (this.OSsSelecionadas && this.OSsSelecionadas.length > 0) {
      let temErros = false;
      let ordensParaAtualizar: AtualizarOrdemServico[] = [];
      this.OSsSelecionadas.forEach(OS => {
        if (temErros) {
          return;
        }

        let indexLinha = this.OSsSelecionadas.indexOf(OS);

        var hrIni = this.limpaHora(OS.hrInicioExecucao);
        var hrFim = this.limpaHora(OS.hrFimExecucao);

        OS.dtInicioExecucao = this.ConfigurarData(OS.dataInicioExecucao, OS.dtInicioExecucao, hrIni);
        OS.dtFimExecucao = this.ConfigurarData(OS.dataFimExecucao, OS.dtFimExecucao, hrFim);

        if ((!OS.dataInicioExecucao && !OS.hrInicioExecucao)
          || (!OS.dataInicioExecucao && OS.hrInicioExecucao)
          || (OS.dataInicioExecucao && (!OS.dataInicioExecucao.formatted || !OS.hrInicioExecucao))) {
          this.exibirMensagemOSs('A Data/Hora Início é obrigatória');
          this.loadingOSs = false;
          temErros = true;
          return;
        }
        else if ((OS.dataInicioExecucao && OS.dataInicioExecucao.formatted && OS.hrInicioExecucao)
          && ((OS.dataFimExecucao && !OS.dataFimExecucao.formatted && OS.hrFimExecucao) || (OS.dataFimExecucao && OS.dataFimExecucao.formatted && !OS.hrFimExecucao) || (!OS.dataFimExecucao && OS.hrFimExecucao))) {
          this.exibirMensagemOSs('Informar a Data/Hora Fim completa');
          this.loadingOSs = false;
          temErros = true;
          return;
        }
        else if (OS.dtInicioExecucao && OS.dtFimExecucao && OS.dtInicioExecucao > OS.dtFimExecucao) {
          this.exibirMensagemOSs('A Data/Hora Início deve ser menor que a Data/hora Fim');
          this.loadingOSs = false;
          temErros = true;
          return;
        }

        if (OS.dataInicioExecucao && OS.dataInicioExecucao.formatted && OS.dtInicioExecucao == null) {
          this.exibirMensagemOSs('Data de Chegada no Cliente inválida!');
          this.loadingOSs = false;
          temErros = true;
          return;
        }

        if (OS.hrInicioExecucao && !this.verificaHoraValida(hrIni)) {
          this.exibirMensagemOSs('Hora de Chegada no Cliente inválida!');
          this.loadingOSs = false;
          temErros = true;
          return;
        }

        if (OS.dataFimExecucao && OS.dataFimExecucao.formatted && OS.dtFimExecucao == null) {
          this.exibirMensagemOSs('Data de Saída do Cliente inválida!');
          this.loadingOSs = false;
          temErros = true;
          return;
        }

        if (OS.hrFimExecucao && !this.verificaHoraValida(hrFim)) {
          this.exibirMensagemOSs('Hora de Saída do Cliente inválida!');
          this.loadingOSs = false;
          temErros = true;
          return;
        }

        let ordemParaAtualizar: AtualizarOrdemServico = {
          idUsuario: this._appService.UsuarioLogado.UsuarioIDeCargo,
          idTipoContainer: OS.idTipoContainer,
          solicitContainerId: OS.solicitContainerId,
          tamanhoContainer: OS.tamanhoContainer,
          idOs: OS.idOs,
          idTipoServico: OS.idTipoServico,
          tara: null,
          numOs: OS.numOs,
          dhRetiradaTerminal: null,
          dhChegadaCliente: OS.dtInicioExecucao,
          dhSaidaCliente: OS.dtFimExecucao,
          dhChegadaTerminal: null,
          numContainer: OS.numContainer,
          lacre1: null,
          lacre2: null,
          lacre3: null,
          placa: null,
          observacao: null,
          indicAvulsa: OS.indicAvulsa,
          justificativas: []
        };

        ordensParaAtualizar.push(ordemParaAtualizar);
      });

      if (!temErros) {
        this._analyticsService.RegistrarFechamentosOS(ordensParaAtualizar);
        this._ordemServicoService.atualizarOrdensDeServico(ordensParaAtualizar).subscribe(result => {
          var acaoFecharModal = document.getElementById('botaoCancelarOSs');
          if (acaoFecharModal) {
            acaoFecharModal.click();
            if (this.filtroPesquisa) {
              this.pesquisar(this.filtroPesquisa);
            }
          }

          this.loadingOSs = false;
        }, (errors) => {
          if (errors && errors.length > 0) {
            let mensagemAgrupada = '';
            let mensagemAnterior = null;
            errors.forEach(element => {
              if (mensagemAnterior === element.Message) {
                return;
              }

              mensagemAgrupada += element.Message;
              mensagemAnterior = element.Message;
            });

            this.exibirMensagemOSs(mensagemAgrupada);
          }

          this.loadingOSs = false;
        });
      }
    }
  }

  exibirMensagemOSs(texto: string, tipo: string = null) {
    if (texto && texto !== undefined && texto !== null && texto !== '') {
      this.textoMensagemModalOSs = texto;
      this.exibirMensagemModalOSs = true;
      this.classeTipoMensagemModalOSs = tipo || 'danger';

      if (texto && texto != '' && texto != undefined && tipo != "success")
            this._logService.logFront(null, LogFrontFuncionalidade.OrdemDeServico, texto, Operacao.Alteracao, "Ordem de Serviço");

      var element = document.getElementById('mensagemModalOSs');
      if (element) {
        setTimeout(() => { element.scrollIntoView(); });
      }
    }
  }

  ocultarMensagemOSs() {
    if (this.exibirMensagemModalOSs) {
      this.textoMensagemModalOSs = null;
      this.exibirMensagemModalOSs = false;
    }
  }

  obterIndicadores(OS: OrdemServico) {
    this.loadingIndicadores = true;
    var filtro: IncidenteRequest = new IncidenteRequest();
    filtro.idOs = OS.idOs;
    filtro.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
    this._ordemServicoService.obtemIncidentes(filtro).subscribe(
      x => {

        this.loadingIndicadores = false;
        if (x == null)
          return;

        this.ordemServico.incidentes = [];
        x.forEach(item => {
          if (!(item.descIndicador == 'Devolução' && OS.tipo != 'Entrega' && OS.tipo != 'Importação')) {
            item.idOs = OS.idOs;
            item.numOs = OS.numOs;
            item.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
            item.checked = (item.justificativa != undefined && item.justificativa != null && item.justificativa.length > 0);
            item.blocked = (item.checked && this.incidentesBlocked.length > 0 && this.incidentesBlocked.filter(f => f.idIndicador == item.idIndicador).length > 0);
            this.ordemServico.incidentes.push(item as Incidente);
          }
        });
      });
  }

  obterTipoContainers() {
    this.loadingContainers = true;
    this._ordemServicoService.getTipoContainersOS(0)
      .subscribe(items => {
        this.tipoContainers = [];
        items.forEach(item => {
          let tp: TipoContainer = new TipoContainer();
          this._sharedService.copyProperties(item, tp);
          this.tipoContainers.push(tp);
        });

        this.idContainerSelecionado = this.ordemServico.idTipoContainer + '|' + this.ordemServico.tamanhoContainer;
        this.loadingContainers = false;
      });
  }

  validaPreenchimento() {
    var erros: string[] = [];

    if (this.ordemServico.indicAvulsa) {
      this.ordemServico.dataRetiradaTerminal = null;
      this.ordemServico.hrRetiradaTerminal = null;
      this.ordemServico.dataEntregaTerminal = null;
      this.ordemServico.hrEntregaTerminal = null;
    }

    if (this.ordemServico.dataRetiradaTerminal != undefined && this.ordemServico.dataRetiradaTerminal.formatted != null
      && this.ordemServico.dataRetiradaTerminal.formatted != '') {

      var hora = this.limpaHora(this.ordemServico.hrRetiradaTerminal);

      this.ordemServico.dtRetiradaTerminal = this.ConfigurarData(this.ordemServico.dataRetiradaTerminal, this.ordemServico.dtRetiradaTerminal, hora);

      if (this.ordemServico.dtRetiradaTerminal == null)
        erros.push('<li>Data de Retirada do Terminal inválida!</li>');

      if (!this.ordemServico.hrRetiradaTerminal)
        erros.push('<li>Informe a Hora de Retirada do Terminal!</li>');
      else if (this.ordemServico.hrRetiradaTerminal && !this.verificaHoraValida(hora)) {
        erros.push('<li>Hora de Retirada do Terminal inválida!</li>');
      }
    }

    if (this.ordemServico.dataInicioExecucao != undefined && this.ordemServico.dataInicioExecucao.formatted != null
      && this.ordemServico.dataInicioExecucao.formatted != '') {

      var hora = this.limpaHora(this.ordemServico.hrInicioExecucao);

      this.ordemServico.dtInicioExecucao = this.ConfigurarData(this.ordemServico.dataInicioExecucao, this.ordemServico.dtInicioExecucao, hora);

      if (this.ordemServico.dtInicioExecucao == null)
        erros.push('<li>Data de Chegada no Cliente inválida!</li>');

      if (!this.ordemServico.hrInicioExecucao)
        erros.push('<li>Informe a Hora de Chegada no Cliente!</li>');
      else if (this.ordemServico.hrInicioExecucao && !this.verificaHoraValida(hora)) {
        erros.push('<li>Hora de Chegada no Cliente inválida!</li>');
      }
    }

    if (this.ordemServico.dataFimExecucao != undefined && this.ordemServico.dataFimExecucao.formatted != null
      && this.ordemServico.dataFimExecucao.formatted != '') {

      var hora = this.limpaHora(this.ordemServico.hrFimExecucao);

      this.ordemServico.dtFimExecucao = this.ConfigurarData(this.ordemServico.dataFimExecucao, this.ordemServico.dtFimExecucao, hora);

      if (this.ordemServico.dtFimExecucao == null)
        erros.push('<li>Data de Saída do Cliente inválida!</li>');

      if (!this.ordemServico.hrFimExecucao)
        erros.push('<li>Informe a Hora de Saída do Cliente!</li>');
      else if (this.ordemServico.hrFimExecucao && !this.verificaHoraValida(hora)) {
        erros.push('<li>Hora de Saída do Cliente inválida!</li>');
      }
    }

    if (this.ordemServico.dataEntregaTerminal != undefined && this.ordemServico.dataEntregaTerminal.formatted != null
      && this.ordemServico.dataEntregaTerminal.formatted != '') {

      var hora = this.limpaHora(this.ordemServico.hrEntregaTerminal);

      this.ordemServico.dtEntregaTerminal = this.ConfigurarData(this.ordemServico.dataEntregaTerminal, this.ordemServico.dtEntregaTerminal, hora);

      if (this.ordemServico.dtEntregaTerminal == null)
        erros.push('<li>Data de Entrega no Terminal inválida!</li>');

      if (!this.ordemServico.hrEntregaTerminal)
        erros.push('<li>Informe a Hora de Entrega no Terminal!</li>');
      else if (this.ordemServico.hrEntregaTerminal && !this.verificaHoraValida(hora)) {
        erros.push('<li>Hora de Entrega no Terminal inválida!</li>');
      }
    }

    //verifica se preencheu alguma das informações do contêiner
    var temInfoContainer: boolean = ((this.ordemServico.numContainer != null && this.ordemServico.numContainer != undefined && this.ordemServico.numContainer != '') ||
      (this.idContainerSelecionado != undefined && this.idContainerSelecionado != null && this.idContainerSelecionado != '0') ||
      (this.ordemServico.lacre1 != null && this.ordemServico.lacre1 != undefined && this.ordemServico.lacre1 != '') ||
      (this.ordemServico.lacre2 != null && this.ordemServico.lacre2 != undefined && this.ordemServico.lacre2 != '') ||
      (this.ordemServico.lacre3 != null && this.ordemServico.lacre3 != undefined && this.ordemServico.lacre3 != '') ||
      (this.ordemServico.tara != null && this.ordemServico.tara != undefined && this.ordemServico.tara != 0) ||
      (this.ordemServico.placa != null && this.ordemServico.placa != undefined && this.ordemServico.placa != ''));

    if (temInfoContainer) {
      if (this.ordemServico.numContainer == null || this.ordemServico.numContainer == undefined || this.ordemServico.numContainer.trim() == '')
        erros.push('<li>Informe o número do Contêiner!</li>');
      else {
        if (!this._sharedService.validaDigitoContainer(this.ordemServico.numContainer))
          erros.push('<li>Número do Contêiner inválido!</li>');
      }

      if (this.idContainerSelecionado == undefined || this.idContainerSelecionado == null || this.idContainerSelecionado == '0')
        erros.push('<li>Informe o tipo do Contêiner!</li>');
      else {
        var str = this.idContainerSelecionado.split('|');
        this.ordemServico.idTipoContainer = Number(str[0]);
        this.ordemServico.tamanhoContainer = Number(str[1]);
      }

      if ((this.ordemServico.lacre1 == null || this.ordemServico.lacre1 == undefined || this.ordemServico.lacre1.trim() == '') &&
        (this.ordemServico.lacre2 == null || this.ordemServico.lacre2 == undefined || this.ordemServico.lacre2.trim() == '') &&
        (this.ordemServico.lacre3 == null || this.ordemServico.lacre3 == undefined || this.ordemServico.lacre3.trim() == ''))
        erros.push('<li>Informe ao menos um Lacre!</li>');

      if (this.ordemServico.tara == null || this.ordemServico.tara == undefined || this.ordemServico.tara == 0)
        erros.push('<li>Informe a Tara!</li>');

      if (this.ordemServico.placa == null || this.ordemServico.placa == undefined || this.ordemServico.placa.trim() == '')
        erros.push('<li>Informe a Placa do Cavalo!</li>');
    }

    var temAlgumaData: boolean = ((this.ordemServico.dtRetiradaTerminal != null && this.ordemServico.dtRetiradaTerminal != undefined) ||
      (this.ordemServico.dtInicioExecucao != null && this.ordemServico.dtInicioExecucao != undefined) ||
      (this.ordemServico.dtFimExecucao != null && this.ordemServico.dtFimExecucao != undefined) ||
      (this.ordemServico.dtEntregaTerminal != null && this.ordemServico.dtEntregaTerminal != undefined));

    //Se dados do contêiner não informados, não pode informar datas
    if (!temInfoContainer && temAlgumaData)
      erros.push('<li>Os dados do contêiner devem ser informados antes de informar os eventos!</li>');

    //Verifica se datas preenchidas estão em ordem coerente
    if (temAlgumaData) {
      if (!this.ordemServico.indicAvulsa && !this.validaSeDataMenor(this.ordemServico.dtRetiradaTerminal, this.ordemServico.dtInicioExecucao))
        erros.push('<li>A data/hora de Retirada do Terminal deve ser menor que a data/hora de Chegada no Cliente!</li>');

      if (!this.ordemServico.indicAvulsa && !this.validaSeDataMenor(this.ordemServico.dtRetiradaTerminal, this.ordemServico.dtFimExecucao))
        erros.push('<li>A data/hora de Retirada do Terminal deve ser menor que a data/hora de Saída do Cliente!</li>');

      if (!this.ordemServico.indicAvulsa && !this.validaSeDataMenor(this.ordemServico.dtRetiradaTerminal, this.ordemServico.dtEntregaTerminal))
        erros.push('<li>A data/hora de Retirada do Terminal deve ser menor que a data/hora de Entrega no Terminal!</li>');

      if (!this.validaSeDataMenor(this.ordemServico.dtInicioExecucao, this.ordemServico.dtFimExecucao))
        erros.push('<li>A data/hora de Chegada no Cliente deve ser menor que a data/hora de Saída do Cliente!</li>');

      if (!this.ordemServico.indicAvulsa && !this.validaSeDataMenor(this.ordemServico.dtInicioExecucao, this.ordemServico.dtEntregaTerminal))
        erros.push('<li>A data/hora de Chegada no Cliente deve ser menor que a data/hora de Entrega no Terminal!</li>');

      if (!this.ordemServico.indicAvulsa && !this.validaSeDataMenor(this.ordemServico.dtFimExecucao, this.ordemServico.dtEntregaTerminal))
        erros.push('<li>A data/hora de Saída do Cliente deve ser menor que a data/hora de Entrega no Terminal!</li>');
    }

    if (this.ordemServico.incidentes) {
      //Se checado algum incidente, deve informar justificativa
      this.ordemServico.incidentes.forEach(item => {
        if (item.checked && (item.justificativa == undefined || item.justificativa == null || item.justificativa.trim().length == 0))
          erros.push("<li>Informe a justificativa para o incidente '" + item.descIndicador + "'!</li>");
      });
    }

    this.mensagemErro = "";

    if (erros.length > 0) {
      this.mensagemErro = '<ul>' + erros.join("") + '</ul>';
      this.mensagemModal.exibeMensagemErro(this.mensagemErro, LogFrontFuncionalidade.OrdemDeServico, Operacao.Alteracao, "Fechar OS");
    }
    else
      this.salvar();
  }

  validaSeDataMenor(dataMenor: Date, dataMaior: Date) {
    //se não tem alguma das datas, retorna true
    if ((dataMenor == null || dataMenor == undefined) && (dataMaior == null || dataMaior == undefined))
      return true;

    if ((dataMenor != null && dataMenor != undefined) && (dataMaior == null || dataMaior == undefined))
      return true;

    if ((dataMenor == null || dataMenor == undefined) && (dataMaior != null && dataMaior != undefined))
      return false;

    return (dataMenor.getTime() < dataMaior.getTime());
  }

  exibirMensagem(texto: string, tipo: string = null) {
    if (texto && texto !== undefined && texto !== null && texto !== '') {
      this.textoMensagemModal = texto;
      this.exibirMensagemModal = true;
      this.classeTipoMensagem = tipo || 'danger';

      if (texto && texto != '' && texto != undefined && tipo != "success")
            this._logService.logFront(null, LogFrontFuncionalidade.OrdemDeServico, texto, Operacao.Alteracao, "Ordem de Serviço");

      var element = document.getElementById('mensagemModal');
      if (element) {
        element.focus();
      }
    }
  }

  ocultarMensagem() {
    if (this.exibirMensagemModal) {
      this.textoMensagemModal = null;
      this.exibirMensagemModal = false;
    }
  }

  public ConfigurarData(dataCalendar: DateModel, data: Date, hora: string): Date {
    // Se a data selecionada for nula ou inválida, retorna nulo
    if ((dataCalendar == undefined) || (dataCalendar.formatted == '') || (dataCalendar.formatted == null) ||
      (!this._datePickerService.IsValid(dataCalendar)) || (!hora))
      return null;

    // Configura o campo baseado na data selecionada
    // no DatePicker e na hora informada no input
    return this._datePickerService.ObtemDataHora(dataCalendar, hora);
  }

  salvar() {
    this.loadingSalvar = true;
    var lista: AtualizarOrdemServico[] = [];
    var obj: AtualizarOrdemServico = new AtualizarOrdemServico();

    obj.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
    obj.idTipoContainer = this.ordemServico.idTipoContainer;
    obj.solicitContainerId = this.ordemServico.solicitContainerId;
    obj.tamanhoContainer = this.ordemServico.tamanhoContainer;
    obj.idOs = this.ordemServico.idOs;
    obj.tara = this.ordemServico.tara;
    obj.numOs = this.ordemServico.numOs;
    obj.dhRetiradaTerminal = this.ordemServico.dtRetiradaTerminal;
    obj.dhChegadaCliente = this.ordemServico.dtInicioExecucao;
    obj.dhSaidaCliente = this.ordemServico.dtFimExecucao;
    obj.dhChegadaTerminal = this.ordemServico.dtEntregaTerminal;
    obj.numContainer = this.ordemServico.numContainer;
    obj.idTipoServico = this.ordemServico.idTipoServico;
    obj.lacre1 = this.ordemServico.lacre1;
    obj.lacre2 = this.ordemServico.lacre2;
    obj.lacre3 = this.ordemServico.lacre3;
    obj.placa = this.ordemServico.placa;
    obj.indicAvulsa = this.ordemServico.indicAvulsa;
    obj.observacao = this.ordemServico.observacao;
    //obj.justificativas = this.ordemServico.incidentes;
    obj.justificativas = [];
    this.ordemServico.incidentes.forEach(item => {
      if (item.checked)
        obj.justificativas.push(item);
    });

    lista.push(obj);
    this._analyticsService.RegistrarFechamentosOS(lista);
    this._ordemServicoService.atualizarOrdensDeServico(lista).subscribe(result => {
      this.loadingSalvar = false;
      if (result) {
        var acaoFecharModal = document.getElementById('botaoCancelarNota');
        if (acaoFecharModal) {
          acaoFecharModal.click();
          if (this.filtroPesquisa) {
            this.pesquisar(this.filtroPesquisa);
          }
        }
      }
    }, (errors) => {
      if (errors && errors.length > 0) {
        let mensagemAgrupada = '';
        errors.forEach(element => {
          mensagemAgrupada += '<li>' + element.Message + '</li>';
        });

        this.mensagemModal.exibeMensagemErro('<ul>' + mensagemAgrupada + '</ul>', LogFrontFuncionalidade.OrdemDeServico, Operacao.Alteracao, "Fechar OS");
      }

      this.loadingSalvar = false;
    });
  }

  limpaHora(hora: string): string {
    if (hora == undefined || hora == null || hora == '')
      return undefined;

    return hora.substr(0, 5).replace(':', '');
  }

  verificaHoraValida(hora: string): boolean {
    if (hora == undefined || hora == null || hora == '')
      return false;

    var hr: number = Number(hora.substr(0, 2));
    var min: number = Number(hora.substr(2, 2));

    return (hr < 24) && (min < 60);
  }

  validaDigito(ctnr: string){
    if(!this._sharedService.validaDigitoContainer(ctnr))
      this.mensagemModal.exibeMensagemErro('<ul>Número do Contêiner inválido!</ul>', LogFrontFuncionalidade.OrdemDeServico, Operacao.Alteracao, "Fechar OS");
    else
      this.mensagemModal.ocultarMensagem();
  }
}
