import { Injectable } from '@angular/core';
import { BaseService } from 'app/_services/base.service';
import { AnalyticsTela } from '../_enums/analytics-tela';
import { AnalyticsFiltro } from '../_enums/analytics-filtro';
import { AnalyticsData } from '../_models/analytics-data';
import { AnalyticsDimensoes } from '../_enums/analytics-dimensoes';
import { UsuarioLogado } from '../_models/usuariologado';
import { AnalyticsConfig } from '../_models/analytics-config';
import { AnalyticsMetricas } from '../_enums/analytics-metricas';
import { PerfilUsuario } from 'app/_enums/perfil-usuario.enum';
import { AnalyticsDownload } from '../_enums/analytics-download';
import { AnalyticsOperacao } from '../_enums/analytics-operacao';
import { AnalyticsEmail } from '../_enums/analytics-email';
import { BookingRequest } from '../_models/booking-request';
import { CotacaoRequest } from '../_models/cotacao-request';
import { TrackingListaRequest } from '../_models/trackinglista-request';
import { OrdemServicoRequest } from '../_models/ordemServicoRequest';
import { DoctoFaturaRequest } from '../_models/doctoFatura-request';
import { DoctoFaturaArquivo } from 'app/_models/doctoFaturaArquivo';
import { ValidationResult } from '../_models/validation-result';
import { ScheduleRequest } from 'app/_models/schedule-request';
import { AtualizarOrdemServico } from 'app/_models/atualizar-ordem-servico';
import { CtmNf } from 'app/_models/ctmNf';

declare var analyticsJS: any;
declare var generateAnalyticsContent: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private baseService: BaseService) { }

  public RegistrarOperacao(tela: AnalyticsTela, operacao: AnalyticsOperacao){
    this.RegistrarBase(tela, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Operacao, operacao);
  }

  public RegistrarDownload(tela: AnalyticsTela, download: AnalyticsDownload){
    this.RegistrarBase(tela, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Download, download);
  }

  public RegistrarEmail(tela: AnalyticsTela, email: AnalyticsEmail){
    this.RegistrarBase(tela, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Email, email);
  }

  public RegistrarErro(tela: AnalyticsTela, result: ValidationResult){
    if (result.IsValid)
      return;

    result.Erros.forEach(erro => {
      this.RegistrarBase(tela, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Erro, erro.Message);
    });
  }

  public RegistrarAcesso(tela: AnalyticsTela){
    this.RegistrarBase(tela, AnalyticsMetricas.Acessos);
  }

  public RegistrarFiltroBooking(consulta: BookingRequest){ 
    var filtros: AnalyticsFiltro[] = [];
    if (consulta.cnpjCli)
      filtros.push(AnalyticsFiltro.CNPJ);
    if (consulta.filtroGenerico)
      filtros.push(AnalyticsFiltro.FiltroGenerico);
    if (consulta.idsMunicOrigem)
      filtros.push(AnalyticsFiltro.Origem);
    if (consulta.idsPortoOrigem)
      filtros.push(AnalyticsFiltro.Embarque);
    if (consulta.idsMunicDestino)
      filtros.push(AnalyticsFiltro.Destino);
    if (consulta.idsPortoDestino)
      filtros.push(AnalyticsFiltro.Desembarque);
    
    this.RegistrarFiltro(AnalyticsTela.Booking, filtros);
  }

  public RegistrarFiltroCotacao(consulta: CotacaoRequest){ 
    var filtros: AnalyticsFiltro[] = [];
    if (consulta.cnpjCli)
      filtros.push(AnalyticsFiltro.CNPJ);

    this.AdicionaFiltrosPortosMunicipios(consulta, filtros);
    this.RegistrarFiltro(AnalyticsTela.Cotacao, filtros);
  }

  public RegistrarFiltroRastreamento(consulta: TrackingListaRequest, tipoPeriodo: number){ 
    var filtros: AnalyticsFiltro[] = [];
    if (consulta.cnpjCli)
      filtros.push(AnalyticsFiltro.CNPJ);
    if (consulta.filtroGenerico)
      filtros.push(AnalyticsFiltro.FiltroGenerico);
    if (consulta.filtroGenerico2)
      filtros.push(AnalyticsFiltro.FiltroGenerico2);

    this.AdicionaFiltrosPortosMunicipios(consulta, filtros);

    if (consulta.idNavioViagem)
      filtros.push(AnalyticsFiltro.NavioViagem);
    if (tipoPeriodo == 1)
      filtros.push(AnalyticsFiltro.Periodo10dias);
    if (tipoPeriodo == 2)
      filtros.push(AnalyticsFiltro.PeriodoDeAte);
    
    this.RegistrarFiltro(AnalyticsTela.Rastreamento, filtros);
  }

  public RegistrarFiltroProvedor(consulta: OrdemServicoRequest, tela: AnalyticsTela){ 
    var filtros: AnalyticsFiltro[] = [];
    if (consulta.cnpjProvedor)
      filtros.push(AnalyticsFiltro.CNPJ);
    if (consulta.filtroGenerico)
      filtros.push(AnalyticsFiltro.FiltroGenerico);
    if (consulta.strPortoOriDes)
      filtros.push(AnalyticsFiltro.Porto);
    if (consulta.idNavioViagem)
      filtros.push(AnalyticsFiltro.NavioViagem);
    if (consulta.tipo)
      filtros.push(AnalyticsFiltro.TipoOS);
    if (consulta.strTipoServico)
      filtros.push(AnalyticsFiltro.TipoServico);
    if (consulta.tipoFiltroPeriodo == 1)
      filtros.push(AnalyticsFiltro.Periodo15dias);
    if (consulta.tipoFiltroPeriodo == 2)
      filtros.push(AnalyticsFiltro.Periodo30dias);
    if (consulta.tipoFiltroPeriodo == 3)
      filtros.push(AnalyticsFiltro.PeriodoDeAte);
    
    this.RegistrarFiltro(tela, filtros);
  }

  public RegistrarFiltroDocumentacao(consulta: DoctoFaturaRequest){ 
    var filtros: AnalyticsFiltro[] = [];
    if (consulta.cnpjCli)
      filtros.push(AnalyticsFiltro.CNPJ);
    if (consulta.filtroGenerico)
      filtros.push(AnalyticsFiltro.FiltroGenerico);
    if (consulta.idNavioViagem)
      filtros.push(AnalyticsFiltro.NavioViagem);

    this.RegistrarFiltro(AnalyticsTela.Documentacao, filtros);
  }

  public RegistrarFiltroSchedule(consulta: ScheduleRequest){ 
    var filtros: AnalyticsFiltro[] = [];
    if (consulta.idsPortoOrigem)
      filtros.push(AnalyticsFiltro.Embarque);
    if (consulta.idsPortoDestino)
      filtros.push(AnalyticsFiltro.Desembarque);
    if (consulta.tipoPeriodo == "embarque")
      filtros.push(AnalyticsFiltro.PeriodoAPartirDe);
    if (consulta.tipoPeriodo == "desembarque")
      filtros.push(AnalyticsFiltro.PeriodoChegadaAte);

    this.RegistrarFiltro(AnalyticsTela.ProgramacaoNavios, filtros);
  }

  public RegistrarFiltro(tela: AnalyticsTela, filtros: AnalyticsFiltro[]){
    filtros.forEach(filtro => {
      this.RegistrarBase(tela, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Filtro, filtro);
    });
  }

  public RegistrarDownloadDocumentacao(arquivo: DoctoFaturaArquivo){
    this.RegistrarDownload(AnalyticsTela.Documentacao, this.ObterTipoDownload(arquivo));
  }

  public RegistrarDownloadDocumentacaoTodos(){
    this.RegistrarDownload(AnalyticsTela.Documentacao, AnalyticsDownload.Zip);
  }

  public RegistrarEmailDocumentacao(arquivo: DoctoFaturaArquivo){
    this.RegistrarEmail(AnalyticsTela.Documentacao, this.ObterTipoAnexoEmail(arquivo));
  }

  public RegistrarEmailDocumentacaoTodos(){
    this.RegistrarEmail(AnalyticsTela.Documentacao, AnalyticsEmail.Zip);
  }

  public RegistrarAgendamentosTracking(quantidade: number){
    if (quantidade > 0)
      this.RegistrarBase(AnalyticsTela.Rastreamento, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Operacao, AnalyticsOperacao.AgendamentoRealizado, quantidade);
  }

  public RegistrarFechamentosOS(lista: AtualizarOrdemServico[]){
    lista.forEach(os => {
      var fechamento: AnalyticsOperacao = 
        (os.dhChegadaCliente && os.dhChegadaTerminal && os.dhRetiradaTerminal && os.dhSaidaCliente) ?
        AnalyticsOperacao.FechamentoTotal : AnalyticsOperacao.FechamentoParcial;
      this.RegistrarBase(AnalyticsTela.OrdemServico, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Operacao, fechamento);
    });
  }

  public RegistrarNFsVinculadas(lista: CtmNf[]){
    var quantidade = lista.filter(n => n.acao === 1).length;
    if (quantidade > 0)
      this.RegistrarBase(AnalyticsTela.VincularNF, AnalyticsMetricas.Quantidade, AnalyticsDimensoes.Operacao, AnalyticsOperacao.NFsVinculadas, quantidade);
  }

  public RegistrarAcessoLinkHome(numeroLink: number){
    var link: AnalyticsOperacao;
    if (numeroLink == 1)
      link = AnalyticsOperacao.AcessoLink1;
    if (numeroLink == 2)
      link = AnalyticsOperacao.AcessoLink2;
    if (numeroLink == 3)
      link = AnalyticsOperacao.AcessoLink3;
    
    this.RegistrarOperacao(AnalyticsTela.Home, link);
  }

  private ObterTipoDownload(arquivo: DoctoFaturaArquivo): AnalyticsDownload {
    var tipo = (arquivo.tipoArquivo == undefined) ? "" : arquivo.tipoArquivo.toLowerCase();
    switch(tipo){
      case "pdf fatura boleto":
      case "pdf boleto":
      return AnalyticsDownload.BoletoFatura;
      case "xml cte":
      return AnalyticsDownload.CTeXML;
      case "pdf cte":
      return AnalyticsDownload.CTePDF;
      case "pdf rps":
        return AnalyticsDownload.RPS;
      case "canhoto":
        return AnalyticsDownload.Canhoto;
      default:
        return AnalyticsDownload.Zip;
    }
  }

  private ObterTipoAnexoEmail(arquivo: DoctoFaturaArquivo): AnalyticsEmail {
    var tipo = (arquivo.tipoArquivo == undefined) ? "" : arquivo.tipoArquivo.toLowerCase();
    switch(tipo){
      case "pdf fatura boleto":
      case "pdf boleto":
      return AnalyticsEmail.BoletoFatura;
      case "xml cte":
      return AnalyticsEmail.CTeXML;
      case "pdf cte":
      return AnalyticsEmail.CTePDF;
      case "pdf rps":
        return AnalyticsEmail.RPS;
      case "canhoto":
        return AnalyticsEmail.Canhoto;
      default:
        return AnalyticsEmail.Zip;
    }
  }

  private AdicionaFiltrosPortosMunicipios(consulta: any, filtros: AnalyticsFiltro[]) {
    if (consulta.idsMunicOri)
      filtros.push(AnalyticsFiltro.Origem);
    if (consulta.idsPortoOri)
      filtros.push(AnalyticsFiltro.Embarque);
    if (consulta.idsMunicDes)
      filtros.push(AnalyticsFiltro.Destino);
    if (consulta.idsPortoDes)
      filtros.push(AnalyticsFiltro.Desembarque);
  }

  private RegistrarBase(tela: AnalyticsTela, metrica: AnalyticsMetricas, dimensao: AnalyticsDimensoes = null, value: any = null, quantidadeMetrica: number = 1){
    //Obtém as dimensões básicas
    var dimensoes = this.InicializaDimensoesBasicas(tela);

    //Se foi informada uma dimensão, adiciona a dimensão e o valor
    if (dimensao)
      dimensoes.push(this.GetData(dimensao, value, this.Dimensoes));

    //Envia as dimensões e métricas para o Google Analytics
    this.EnviaAnalytics(dimensoes, metrica, quantidadeMetrica);
  }

  private EnviaAnalytics(dimensoes: AnalyticsData[], metrica: AnalyticsMetricas, quantidadeMetrica: number){
    var cfg = this.Config;

    //Verifica se o Analytics está habilitado
    if (!cfg.Habilitado)
      return;

    //Ordena as dimensões a serem enviadas pelo índice da dimensão
    dimensoes = this.SortAnalyticsData(dimensoes);
    //Ordena as métricas a serem enviadas pelo índice da métrica
    var metricas = this.SortAnalyticsData(this.CriaMetrica(metrica, quantidadeMetrica));

    try {
      //Envia as dimensões e a métrica para o Google Analtics
      analyticsJS(this.Config.GtagID, dimensoes, metricas);
    }
    catch (e) {}

    //Obtém o conteúdo enviado para o Google Analytics
    var content = generateAnalyticsContent(dimensoes, metricas);
    //Envia para API do Portal Cabotagem para registrar o hit enviado para o GA
    this.baseService.Post('analytics', content).subscribe();
  }

  private InicializaDimensoesBasicas(tela: AnalyticsTela): AnalyticsData[]{
    //Cria o array de dimensões
    var dimensoes: AnalyticsData[] = [];

    //Adiciona as dimensões de tela de se o usuário está autenticado
    dimensoes.push(this.GetData(AnalyticsDimensoes.Tela, tela, this.Dimensoes));
    dimensoes.push(this.GetData(AnalyticsDimensoes.Autenticado, (this.Usuario) ? "Sim" : "Não", this.Dimensoes));
    
    //Se houver usuário logado, armazena a empresa e perfil do usuário
    if (this.Usuario){
      dimensoes.push(this.GetData(AnalyticsDimensoes.Empresa, this.Usuario.RazaoSocial, this.Dimensoes));
      dimensoes.push(this.GetData(AnalyticsDimensoes.Classificacao, this.GetNomePerfil(), this.Dimensoes));
    }

    //Retorna as dimensões criadas acima
    return dimensoes;
  }

  private CriaMetrica(metrica: AnalyticsMetricas, quantidadeMetrica: number): AnalyticsData[]{
    //Cria o array de métricas
    var metricas: AnalyticsData[] = [];
    //Adiciona no array de métricas a métrica passada como parâmetro, informando 1 hit
    metricas.push(this.GetData(metrica, quantidadeMetrica, this.Metricas));
    //Retorna a métrica criada
    return metricas;
  }

  private get Usuario(): UsuarioLogado{
    return this.baseService.UsuarioLogado;
  }

  private get Dimensoes(): AnalyticsData[]{
    //Retorna as dimensões carregadas no arquivo de configurações (_config\google-config.json)
    return this.Config.Dimensoes;
  }

  private get Metricas(): AnalyticsData[]{
    //Retorna as métricas carregadas no arquivo de configurações (_config\google-config.json)
    return this.Config.Metricas;
  }

  private get Config(): AnalyticsConfig{
    //Retorna as configurações do Analytics carregadas (_config\google-config.json)
    return this.baseService.ConfigAnalytics;
  }

  private GetData(key: string, value: any, data: AnalyticsData[]): AnalyticsData {
    //Método para gerar um registro para o Analytics
    //baseado na dimensão ou métrica informada.
    return new AnalyticsData(this.GetIndex(key, data), value);
  }

  private GetIndex(key: string, data: AnalyticsData[]){
    //Encontra a dimensão ou métrica dentro da lista e retorna o índice
    return data.find(a => a.Value == key).Index;
  }

  private SortAnalyticsData(data: AnalyticsData[]): AnalyticsData[]{
    //Ordena as dimensões ou métricas
    return data.sort((a, b) => a.Index - b.Index);
  }

  private GetNomePerfil(): string {
    var nomePerfil = "";
    var perfilUsuario = this.Usuario.PerfilUsuario.toString().charCodeAt(0);
    switch (perfilUsuario) {
        case PerfilUsuario.Administrador:
            nomePerfil = "Administrador"
            break;
        case PerfilUsuario.Cliente:
            nomePerfil = "Cliente"
            break;
        case PerfilUsuario.Provedor:
            nomePerfil = "Provedor"
            break;
        case PerfilUsuario.Ambos:
            nomePerfil = "Cliente e Provedor"
            break;
    }
    return nomePerfil;
  }

}
