import { PerfilUsuario } from "app/_enums/perfil-usuario.enum";

export class UsuarioRegistrar {
    nome: string;
    razaoSocial: string;
    nomeFantasia: string;
    cnpj: string;
    inscricaoEstadual: string;
    logradouro: string;
    complemento: string;
    numero: string;
    bairro: string;
    municipio: string;
    uf: string;
    cep: string;
    ddd: string;
    telefone: string;
    email: string;
    cliente: boolean;
    provedor: boolean;
    perfilUsuario: PerfilUsuario;
    dataAceiteTermo: Date;
}