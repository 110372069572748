import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Porto } from 'app/_models/porto';
import { TipoServico } from './../../_models/tipo-servico';
import { NavioViagem } from './../../_models/navioViagem';
import { OrdemServico } from 'app/_models/ordemServico';
import { NavioViagemRequest } from './../../_models/navioViagem-request';
import { OrdemServicoRequest } from 'app/_models/ordemServicoRequest';
import { FilterComboConfiguration } from 'app/_models/filterComboConfiguration';

import { DateModel } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import { BaseComponent } from 'app/shared/base.component';
import { CnpjDropdownComponent } from 'app/shared/cnpj-dropdown/cnpj-dropdown.component';

import { DatePickerOptions } from 'app/shared/ng2-datepicker/ng2-datepicker.component';
import { DateExtensions } from 'app/shared/extensions/extensions.module';

import { AppService } from 'app/_services/app.service';
import { SharedService } from 'app/_services/shared.service';
import { MessageService } from 'app/_services/message.service';
import { DatepickerService } from 'app/_services/datepicker.service';
import { GenericOrderbyService } from './../../_services/generic-orderby.service';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from '../../_enums/analytics-tela';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
	selector: 'filtro-top',
	templateUrl: './filtro-top.component.html',
	styleUrls: ['./filtro-top.component.scss']
})
export class FiltroTopComponent extends BaseComponent implements OnInit {
	@ViewChild(CnpjDropdownComponent, {static: true}) cnpjdropdown: CnpjDropdownComponent;
	@Input() titulo: string;
	@Input() exibeFiltroServico: boolean;

	@Output() classePrincipal: string;
	@Output() carregarFiltros: EventEmitter<OrdemServicoRequest> = new EventEmitter();

	loading = false;
	loadingPortos = false;
	loadingNavioViagem = false;

	eAdmin = this._appService.isAdmin();
	eCliente = this._appService.isCliente();
	maisDeUmCNPJ = this._appService.maisDeUmCNPJ();
	cssClassAdmin = '';
	cnpjs: string;
	cnpjDigitado: string;

	portos: Porto[] = [];
	portosSelecionados: Porto[] = [];
	filtroPesquisa: OrdemServicoRequest = new OrdemServicoRequest();
	navioViagemSelecionado: NavioViagem;
	navioViagens: NavioViagem[] = [];
	navioViagensFiltrados: NavioViagem[] = [];
	dataFim: DateModel;
	dataInicio: DateModel;
	optionsInicio: DatePickerOptions;
	optionsFim: DatePickerOptions;

	comboPortos: FilterComboConfiguration;
	comboTiposOS: FilterComboConfiguration;
	comboServicos: FilterComboConfiguration;

	tipoOSsSelecionado: string[] = [];
	tipoServicosSelecionado: TipoServico[] = [];

	funcionalidade: LogFrontFuncionalidade;

	constructor(private _appService: AppService,
		private _datepickerService: DatepickerService,
		private _msgService: MessageService,
		private _sharedService: SharedService,
		private _orderByService: GenericOrderbyService,
		private _sanitizer: DomSanitizer,
		private _analyticsService: AnalyticsService) {
		super();

		this.optionsInicio = this._datepickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), -14));
		this.optionsFim = this._datepickerService.GenerateOptionsConfig(new Date());

		this.comboPortos = new FilterComboConfiguration('Porto(s)...');
		this.comboPortos.settings = {
			checkedStyle: 'glyphicon',
			dynamicTitleMaxItems: 1,
			showCheckAll: true,
			showUncheckAll: true,
			enableSearch: true
		};

		this.comboTiposOS = new FilterComboConfiguration('Tipo(s) OS...');
		this.comboTiposOS.options = [];
		this.comboTiposOS.settings = {
			checkedStyle: 'glyphicon',
			showCheckAll: true,
			showUncheckAll: true,
			dynamicTitleMaxItems: 1,
			enableSearch: true
		};

		this.comboServicos = new FilterComboConfiguration('Serviços...');
		this.comboServicos.options = [];
		this.comboServicos.settings = {
			checkedStyle: 'glyphicon',
			dynamicTitleMaxItems: 1,
			showCheckAll: true,
			showUncheckAll: true,
			enableSearch: true
		};
	}

	ngOnInit() {
		this.funcionalidade = (this.titulo.toLowerCase().indexOf('vincular') >= 0) ? LogFrontFuncionalidade.VincularNF : LogFrontFuncionalidade.OrdemDeServico;

		this.cssClassAdmin = this.eAdmin || (!this.eAdmin && this.maisDeUmCNPJ) ? '' : ' customer-profile';
		this.filtroPesquisa.tipoFiltroPeriodo = 1;
		this.comboPortos.options = [];

		if (!this.eAdmin) {
			this.cnpjs = this._appService.UsuarioLogado.CNPJs.join(";");
			this.cnpjAlterado(this.cnpjs);
		}
	}

	autocompleListFormatter = (data: any) => {
		let html = `<span>${data.descViagemNavio}</span>`;
		return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	exibePeriodo(value) {
		this.cssClassAdmin = this.eAdmin || (!this.eAdmin && this.maisDeUmCNPJ) ? '' : ' customer-profile';
		this.cssClassAdmin = (value === '3') ? this.cssClassAdmin + ' has-period' : this.cssClassAdmin;
	}

	cnpjAlterado(cnpj: string) {
		this.cnpjs = cnpj;
		var consulta = new NavioViagemRequest();
		consulta.CNPJCliente = cnpj;
		consulta.CNPJProvedor = cnpj;
		consulta.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
		consulta.tipo = 'OS';
		this.atribuiPeriodoInicialEFinal();
		consulta.dataIni = this.filtroPesquisa.dataIni;
		consulta.dataFim = this.filtroPesquisa.dataFim;

		//this.obterNaviosViagens(consulta);
	}

  preencheNavioViagens(lista: OrdemServico[]) {
    if (!lista)
      return;

    this.navioViagensFiltrados = [];

    lista.forEach(f => {
      if (f.idNavioViagem != null && this.navioViagensFiltrados.filter(x =>
          x.idViagemNavio == f.idNavioViagem).length <= 0)
      {
        if (f.descNavioViagem != null && f.descNavioViagem.length > 0) {
          let str = f.descNavioViagem.split('/');
          let navio = this._sharedService.toCamelCase(str[0]) + '/' + str[1];
          this.navioViagensFiltrados.push({
                idViagemNavio: f.idNavioViagem,
                descViagemNavio: navio,
                descViagemNavioResim: '',
                sentido: '',
                numViagemNavio: ''
              });
        }
      }
    });
  }

	preencheServicos(servicos: TipoServico[]) {
		servicos = this._orderByService.ordenarPorCampo(servicos, 'Descricao', false);
		this.comboServicos.options = [];
		if (servicos != null) {
			servicos.forEach(s => {
				this.comboServicos.options.push({ id: s.Codigo, name: this._sharedService.toCamelCase(s.Descricao) });
			});
		}
	}

	preencheTipos(tipos: string[]) {
		this.comboTiposOS.options = [];
		if (tipos != null) {
			tipos.forEach(s => {
				this.comboTiposOS.options.push({ id: s, name: s });
			});
		}
		this.comboTiposOS.options = this._orderByService.ordenarPorCampo(this.comboTiposOS.options, 'name', false);
	}

	preenchePortos(lista: OrdemServico[]) {
		if (!lista)
			return;

		this.comboPortos.options = [];

		lista.forEach(f => {
			if (f.idPortoOriDes != null && this.comboPortos.options.filter(x => x.id == f.idPortoOriDes).length <= 0) {
				this.comboPortos.options.push({ id: f.idPortoOriDes, name: f.portoOriDes });
			}
		});
	}

	Pesquisar() {
		if (this.eAdmin && ((this.cnpjdropdown.CNPJDigitado == undefined) || (this.cnpjdropdown.CNPJDigitado == ""))) {
			this._msgService.addMessage(this.titulo, 'Informe o CNPJ do Cliente', 'danger', this.funcionalidade, Operacao.Consulta);
		}
		else {
			this._msgService.clearMessage();

			var cnpjSemFormatacao = "";
			if (this.cnpjdropdown.CNPJDigitado != undefined) {
				cnpjSemFormatacao = this._sharedService.replaceAll(this.cnpjdropdown.CNPJDigitado, '.', '');
				cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '/', '');
				cnpjSemFormatacao = this._sharedService.replaceAll(cnpjSemFormatacao, '-', '');
			}
			this.filtroPesquisa.idUsuario = this._appService.UsuarioLogado.UsuarioIDeCargo;
			this.filtroPesquisa.cnpjProvedor = this.cnpjdropdown.CNPJs ? this.cnpjdropdown.CNPJs.join(';') : this.cnpjdropdown.CNPJDigitado ? cnpjSemFormatacao : '';

			this.filtroPesquisa.strPortoOriDes = this.portosSelecionados != undefined && this.portosSelecionados.length > 0 ? this.portosSelecionados.join(';') : null;
			this.filtroPesquisa.tipo = this.tipoOSsSelecionado && this.tipoOSsSelecionado.length > 0 ? this.tipoOSsSelecionado.join(';') : null;
			this.filtroPesquisa.idNavioViagem = (this.navioViagemSelecionado) ? this.navioViagemSelecionado.idViagemNavio : null;

			if (this.exibeFiltroServico && this.tipoServicosSelecionado && this.tipoServicosSelecionado.length > 0)
				this.filtroPesquisa.strTipoServico = this.tipoServicosSelecionado.join(';');
			else
				this.filtroPesquisa.strTipoServico = null;

			this.cnpjAlterado(this.cnpjs);
			this.atribuiPeriodoInicialEFinal();

			if (this.filtroPesquisa.tipoFiltroPeriodo.toString() == "3") {
				if (this.filtroPesquisa.dataIni == null || this.filtroPesquisa.dataFim == null ||
					this.filtroPesquisa.dataIni.toString() == 'Invalid Date' || this.filtroPesquisa.dataFim.toString() == 'Invalid Date') {
				  this._msgService.addMessage(this.titulo, 'Data Início ou Fim Inválida! As datas devem estar no formato dd/mm/aaaa.', 'danger',
				     this.funcionalidade, Operacao.Consulta, this.filtroPesquisa);
				  return;
				}
			}

			var tela = (this.titulo.includes('Vincular')) ? AnalyticsTela.VincularNF : AnalyticsTela.OrdemServico;
			this._analyticsService.RegistrarFiltroProvedor(this.filtroPesquisa, tela);

			this.carregarFiltros.emit(this.filtroPesquisa);
		}
	}


	private atribuiPeriodoInicialEFinal(): void {
		switch (this.filtroPesquisa.tipoFiltroPeriodo.toString()) {
			case "1":
				this.filtroPesquisa.dataIni = this._sharedService.addDays(new Date(), -14);
				this.filtroPesquisa.dataFim = new Date();
				this.setTimeZero();

				this.dataInicio = DateExtensions.toDateModel(this.filtroPesquisa.dataIni, "DD/MM/YYYY");
				this.dataFim = DateExtensions.toDateModel(this.filtroPesquisa.dataFim, "DD/MM/YYYY");
				break;
			case "2":
				this.filtroPesquisa.dataIni = this._sharedService.addDays(new Date(), -29);
				this.filtroPesquisa.dataFim = new Date();
				this.setTimeZero();

				this.dataInicio = DateExtensions.toDateModel(this.filtroPesquisa.dataIni, "DD/MM/YYYY");
				this.dataFim = DateExtensions.toDateModel(this.filtroPesquisa.dataFim, "DD/MM/YYYY");
				break;
			case "3":
					this.filtroPesquisa.dataIni = this.ObterData(this.dataInicio, 0);
					this.filtroPesquisa.dataFim = this.ObterData(this.dataFim, 0);
					this.setTimeZero();
				break;
			default:
				this.filtroPesquisa.dataIni = null;
				this.filtroPesquisa.dataFim = null;
		}
	}

	private ObterData(dateModel: DateModel, dateOffset: number) {
		return (dateModel != undefined) ?
			this._datepickerService.ObtemData(dateModel) :
			this._sharedService.addDays(new Date(), dateOffset);
	}

	setTimeZero() {
		this._sharedService.setTimeZero(this.filtroPesquisa.dataIni);
		this._sharedService.setTimeZero(this.filtroPesquisa.dataFim);
	}
}
