export class filtrosLaterais{
    tamanho20Pes: boolean = false; 
    tamanho40Pes: boolean = false;
    flagCargaPerigosa: boolean = false;  
    flagCargaRefrigerada: boolean = false; 
    flagCargaOver: boolean = false;  
    flagOvaDesova: boolean = false;
    flagTpContainerDryDC_HC: boolean = false;
    flagTpContainerRH: boolean = false;
    flagTpContainerEspecial: boolean = false;
    flagModalPoPo: boolean = false;
    flagModalPaPa: boolean = false;
    flagModalPaPo: boolean = false;  
    flagModalPoPa: boolean = false;       
    flagConferente: boolean = false; 
    flagCargaSeca: boolean = false;
    status: string = ""; 
}