export class MunicipioRequest
{
    Descricao: string;
    idProposta: number;
    idCotacao: number;
    cnpjCli: string;
    dataIni: Date;
    datafim: Date;
    tipoConsulta: string;
    idUsuario: number;
}