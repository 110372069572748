
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { BaseService } from './base.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';
import { BookingShippingRequest } from '../_models/booking-shipping-request';
import { ValidationResult } from '../_models/validation-result';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BookingShippingService {

  urlPath:string = "booking-shipping/";

  constructor(
    private baseService: BaseService,
    private _msgService: MessageService) { }


    obterBookingShippingResponse(bookingId: number): Observable<BookingShippingRequest[]> {

      this.urlPath =  this.urlPath + 'get-data?departureUf=' + bookingId;

      return this.baseService.Get(this.urlPath).pipe(
        map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (!retorno.IsValid) {
            this._msgService.addMessageValidationError('bookingShippingResponse', retorno.Erros, 'error', LogFrontFuncionalidade.Icms, Operacao.Consulta, null);
          }

          return retorno.Data as BookingShippingRequest[];
        }),catchError(e => {
          return this.baseService.handleError(e);
        }),);
    }



    calculate(bookingShippingRequest: BookingShippingRequest): Observable<ValidationResult> {

          return this.baseService.Post(this.urlPath + 'calculate', bookingShippingRequest).pipe(
              map((response: ValidationResult) => {
                  let retorno: ValidationResult = response;
                  return retorno;
              }),catchError(e => {
                  return this.baseService.handleError(e);
              }),);

  }

}
