import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { PortoRotaComponent } from './porto-rota/porto-rota.component';
import { NavioViagensRotas } from 'app/_models/navio-viagens-rotas';
import { SharedService } from 'app/_services/shared.service';

@Component({
  selector: 'navio-rota',
  templateUrl: './navio-rota.component.html',
  styleUrls: ['./navio-rota.component.scss']
})
export class NavioRotaComponent implements OnInit {

  @ViewChild(PortoRotaComponent, {static: true}) portoRota : PortoRotaComponent;
  @Input() naviosRota: NavioViagensRotas[];

  constructor(private _sharedService: SharedService) { }

  ngOnInit() {
  }

  textoFormatado(str: string): string {
    return this._sharedService.toCamelCase(str);
  }
}
