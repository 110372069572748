import { Component, Input, OnInit, ViewChild, NgModule } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MessageService } from "./../../../_services/message.service";
import { MessageComponent } from "./../../../shared/message/message.component";
import { DataService } from "./../../../_services/data.service";
import { SharedService } from "./../../../_services/shared.service";
import { BaseComponent } from "./../../../shared/base.component";

import { Observable } from "rxjs";
import { Subscription } from "rxjs";

//DATEPICKER
import { DatepickerService } from "app/_services/datepicker.service";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateExtensions } from 'app/shared/extensions/extensions.module';


// MAERSK
import { ListItem } from "./../../../_models/listItem";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import { Operacao } from "app/_enums/operacao";
import { PortoService } from "app/_services/porto.service";
import { PortoInstantPaymentRequest } from "app/_models/PortoInstantPaymentRequest";
import { BaseService } from "app/_services/base.service";
import { UsuarioLogado } from '../../../_models/usuariologado';
import { UF } from "app/_models/uf";


@Component({
  selector: "porto-detalhe",
  templateUrl: "./porto-detalhe.component.html",
  styleUrls: ["./porto-detalhe.component.scss"],
})
export class PortoDetalheComponent extends BaseComponent
  implements OnInit {
  inscricaoGet: Subscription;
  @ViewChild(MessageComponent, { static: true }) mensagem: MessageComponent;

  titulo: string = "Novo Porto";
  // //listItem: ListItem;
  regioesGeograficas: ListItem[] = new Array();
  portoInstantPaymentRequest: PortoInstantPaymentRequest = new PortoInstantPaymentRequest();
  portoInstantPaymentResponse: PortoInstantPaymentRequest = new PortoInstantPaymentRequest();
  loading: boolean = false;
  ufs: UF[] = new Array();

  // MAERSK
  id: number = 0;


  constructor(
    private _baseService: BaseService,
    private _dataService: DataService,
    private _route: ActivatedRoute,
    private _msgService: MessageService,
    private _sharedService: SharedService,
    private _router: Router,
    private _datepickerService: DatepickerService,
    private route: ActivatedRoute,
    private _portoService : PortoService
  ) {
    super();

    this._msgService.emitirMensagem.subscribe((msg) => {
      this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
    });


    this.ObterDataFields();

  }

  ngOnInit(): void {

    this.inscricaoGet = this._route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.titulo = "Editar Porto";
        this.loading = true;
        this.getPorto(this.id);
        this.portoInstantPaymentRequest.isNovo = false;
      }
      else {
        this.titulo = "Novo Porto";
        this.portoInstantPaymentRequest = new PortoInstantPaymentRequest();
        this.portoInstantPaymentRequest.isNovo = true;
      }
    });
  }



  // Metodos auxiliares
  ObterDataFields() {
    this._dataService.getUFs().subscribe((lista) => {
      this.ufs = lista;
    });

    this._dataService.getRegiaoGeografica().subscribe((lista) => {
      this.regioesGeograficas = lista;
    });


  }

  private ObterData(dateModel: DateModel, dateOffset: number) {
    return dateModel != undefined
      ? this._datepickerService.ObtemData(dateModel)
      : this._sharedService.addDays(new Date(), dateOffset);
  }

  onSalvaPorto() {
    this._msgService.clearMessage();

    this.portoInstantPaymentRequest.city = this.portoInstantPaymentResponse.city;
    this.portoInstantPaymentRequest.code = this.portoInstantPaymentResponse.code;
    this.portoInstantPaymentRequest.description = this.portoInstantPaymentResponse.description;
    this.portoInstantPaymentRequest.id = this.portoInstantPaymentResponse.id;
    this.portoInstantPaymentRequest.isEnabled = true;
    this.portoInstantPaymentRequest.region = this.portoInstantPaymentResponse.region;
    this.portoInstantPaymentRequest.state = this.portoInstantPaymentResponse.state;


    if(this.portoInstantPaymentRequest.isNovo)
    {
      this.portoInstantPaymentRequest.createdBy = this._baseService.UsuarioLogado.nome;
    }
    else
    {
      this.portoInstantPaymentRequest.updatedBy = this._baseService.UsuarioLogado.nome;
      this.portoInstantPaymentRequest.createdBy = this.portoInstantPaymentResponse.createdBy;
    }

    this._portoService.salvar(this.portoInstantPaymentRequest).subscribe((result) => {
      this.loading = false;
      if (result.IsValid) {
        this._router.navigate(["porto"]);
        this._msgService.addMessageRedirect(
          "Porto",
          result.Message,
          "success"
        );
      } else {
        var msgErr: string[] = [];
        result.Erros.forEach((element) => msgErr.push(element.Message));
        this._msgService.addMessageArray(
          "Porto",
          msgErr,
          "error",
          LogFrontFuncionalidade.Porto,
          Operacao.Alteracao,
          this.portoInstantPaymentRequest
        );
        this.loading = false;
      }
    });
  }


  getPorto(id: number) {
    this._portoService.getById(this.id)
      .subscribe((porto) => {

        this.portoInstantPaymentResponse = porto;
        this.portoInstantPaymentRequest.isNovo = false;

        this.loading = false;

      });
  }
}
