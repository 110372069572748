export class TipoContainer {
    codigo: number = 0;
    tamanho: number = 0;
    descricao: string = "";
    desc_resumida: string = "";
    flag_PES: string = "";
    flag_Reefer: string = "";
    flag_HighCube: string = "";
    flag_Dry: string = "";
    
    private _idContainer: string = "";
    get idContainer(): string {
        if (this._idContainer == "") {
            this._idContainer = (this.codigo != null) ? this.codigo.toString() + "|" + this.tamanho.toString() : undefined;
        }
        return this._idContainer;
    }
    set idContainer(value) {
        this._idContainer = value;
    }
}
