import { Operacao } from './../_enums/operacao';
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";

export class LogFront {
    LogFrontID: number;
    NomeUsuario: string;
    UsuarioID: number;
    Funcionalidade: LogFrontFuncionalidade;
    Tela: string;
    Mensagem: string;
    Dados: string;
    Operacao: Operacao;
    VersaoPortal: string;
    Browser: string;
    Data: Date;
    strData: string;
}
