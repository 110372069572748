import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { FiltroListaItem } from 'app/_models/filtro-lista-item';
import { OrdemServico } from 'app/_models/ordemServico';
import { OrdemServicoVM } from 'app/_models/ordemServicoVM';

import { BaseComponent } from 'app/shared/base.component';

import { SharedService } from 'app/_services/shared.service';
import { TipoEventoTracking } from 'app/_models/tipoEventoTracking';

@Component({
	selector: 'filtro-lista',
	templateUrl: './filtro-lista.component.html',
	styleUrls: ['./filtro-lista.component.scss']
})

export class FiltroListaComponent extends BaseComponent implements OnInit {
	@Input() filtros: FiltroListaItem[];
	@Input() tituloTotal: string;

	@Output() classePrincipal: string;
	@Output() stepAlterado: EventEmitter<string> = new EventEmitter<string>();

	quantidadeAberta: number = 0;
	quantidadeFechada: number = 0;
	quantidadeLiberadaPagamento: number = 0;
	quantidadeVincular: number = 0;
	quantidadeVinculadas: number = 0;
	quantidadeCtesEmitidos: number = 0;
	quantidadePendentes: number = 0;
	quantidadeTotal: number = 0;

	stepAtivo: string = 'total';

	constructor(private _sharedService: SharedService) {
		super();

		if (!this.tituloTotal) {
			this.tituloTotal = "Todos os Itens";
		}
	}

	ngOnInit() { }

	formataQuantidade(quantidade: number): string {
		if ((quantidade == undefined) || (quantidade == 0)) {
			return '0';
		}

		return (quantidade < 10 ? ('0' + quantidade).slice(-2) : quantidade.toString());
	}

	styleLinkStep(quantidade: number) {
		return (quantidade > 0) ? 'auto' : 'none';
	}

	possuiItens(quantidade: number) {
		return (quantidade > 0) ? ' possui-itens' : '';
	}

	stepEstaAtivo(step: string): string {
		return (step == this.stepAtivo) ? 'active' : '';
	}

	stepClick(step: string) {
		this.stepAtivo = step;
		this.stepAlterado.emit(step);
	}

	atribuiQuantidadePorTipoEvento(tiposEventos: TipoEventoTracking[]): void {
		if (tiposEventos) {
			this.quantidadeTotal = 0;
			tiposEventos.forEach(x => {
				if (x.Descricao) {
					let descricao: string = x.Descricao.toLowerCase().trim();

					var filtro = this.filtros.find(x => x.status === descricao);
					if (filtro != null) {
						filtro.valor = x.Quantidade;
						this.quantidadeTotal += x.Quantidade;
					}

					switch (descricao) {
						case 'liberada para pagamento':
							this.quantidadeLiberadaPagamento = x.Quantidade;
							break;
						case 'fechada':
							this.quantidadeFechada = x.Quantidade;
							break;
						case 'aberta':
							this.quantidadeAberta = x.Quantidade;
							break;
						case 'vinculada':
							this.quantidadeVinculadas = x.Quantidade;
							break;
						case 'a vincular':
							this.quantidadeVincular = x.Quantidade;
							break;
						case 'enviado':
							break;
						case 'pendente':
							this.quantidadePendentes = x.Quantidade;
							break;
						case 'ct-e emitido':
							this.quantidadeCtesEmitidos = x.Quantidade;
							break;
						default:
							break;
					}
				}
			});
		}
	}

	carregaDadosEmComponente(tiposEventos: TipoEventoTracking[]): void {
		this.atribuiQuantidadePorTipoEvento(tiposEventos);
	}

	zerarQuantidades() {
		this.quantidadeAberta = 0;
		this.quantidadeFechada = 0;
		this.quantidadeLiberadaPagamento = 0;
		this.quantidadeVincular = 0;
		this.quantidadeVinculadas = 0;
		this.quantidadeCtesEmitidos = 0;
		this.quantidadePendentes = 0;
		this.quantidadeTotal = 0;
		this.stepAtivo = 'total';

		if (this.filtros) {
			this.filtros.forEach(f => {
				f.valor = 0;
			});
		}
	}
}
