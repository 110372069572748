import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { ItemAgendamentoComponent } from './item-agendamento.component';
import { MultiselectDropdownModule } from './../../../../../../shared/multiselect-dropdown/multiselect-dropdown.module';
import { SharedModule } from "app/shared/shared.module";
import { CurrencyMaskModule } from 'ng2-currency-mask';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TextMaskModule,
        CurrencyMaskModule,
        MultiselectDropdownModule,
        DatePickerModule,
        SharedModule,
    ],
    exports: [ItemAgendamentoComponent],
    declarations: [ItemAgendamentoComponent],
    providers: [],
})
export class ItemAgendamentoModule {

}
