

export class CustomerInfo{
    ssn: string;
    corporationId: string;
    name: string;
    Atividade: string;
    Telefone: string;
    UF: string;
    TipoPrazo: string;
    Substituicao: string;
    Status: string = '';
    id: number = 0;
}
