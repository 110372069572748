import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { AppService } from 'app/_services/app.service';

import { DatepickerService } from "app/_services/datepicker.service";
import { GenericOrderbyService } from "app/_services/generic-orderby.service";
import { FiltrosLateraisService } from 'app/_services/filtros-laterais.service';



import { Booking } from 'app/_models/booking';
import { MeusBookings } from "app/_models/meus-bookings";

import { FiltrosLaterais } from "app/_models/filtros-laterais";


import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";


import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
import { GrupoEconomicoService } from 'app/_services/grupo-economico.service';
import { InfoPgtoAdicionalService } from 'app/_services/info-pgto-adicional.service';
import { AnalyticsDownload } from 'app/_enums/analytics-download';
import { AnalyticsTela } from 'app/_enums/analytics-tela';
import { BookingRequest } from 'app/_models/booking-request';
import { FilterComboConfiguration } from 'app/_models/filterComboConfiguration';
import { Municipio } from 'app/_models/municipio';
import { MunicipioRequest } from 'app/_models/municipio-request';
import { Porto } from 'app/_models/porto';
import { PropostaComercial } from 'app/_models/proposta-comercial';
import { ServicoProposta } from 'app/_models/servico-proposta';
import { AnalyticsService } from 'app/_services/analytics.service';
import { BookingService } from 'app/_services/booking.service';
import { MessageService } from 'app/_services/message.service';
import { MunicipioService } from 'app/_services/municipio.service';
import { PropostaComercialService } from 'app/_services/proposta-comercial.service';
import { SharedService } from 'app/_services/shared.service';
import { FiltrosLateraisComponent } from 'app/cliente/filtros-laterais/filtros-laterais.component';
import { BaseComponent } from 'app/shared/base.component';
import { CnpjDropdownComponent } from 'app/shared/cnpj-dropdown/cnpj-dropdown.component';
import { DetalhesContainerComponent } from 'app/shared/detalhes-container/detalhes-container.component';
import { MessageComponent } from 'app/shared/message/message.component';
import { PaginacaoComponent } from 'app/shared/paginacao/paginacao.component';


@Component({
  selector: 'relatoriopix',
  templateUrl: './relatoriopix.component.html',
  styleUrls: ['./relatoriopix.component.scss']
})
export class RelatorioPixComponent extends BaseComponent implements OnInit, AfterViewInit {

  
  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;
  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  
  loading: boolean = false;
  downloading: boolean = false;
  loadingPortos: boolean = false;
  loadingMunicipios: boolean = false;
  empty: boolean = true;
  noresult: boolean = false;
  listaStatus: string[];
  statusSelecionado: string;

  filtroMunicipio: MunicipioRequest = new MunicipioRequest();

  filtrosLaterais = new FiltrosLaterais();
  consulta: BookingRequest = new BookingRequest();
  meusBookings: MeusBookings = new MeusBookings(new FiltrosLaterais(), []);
  listaBookingsOriginal: Booking[];
  pageSize: number = 10;
  optionsInicio: DatePickerOptions;
  optionsFim: DatePickerOptions;
  dataInicio: DateModel;
  dataFim: DateModel;

  filtroGenerico: string = "";
  portos: Porto[] = new Array();
  municipios: Municipio[] = new Array();

  municipiosOrigem: number[];
  portosOrigem: number[];
  municipiosDestino: number[];
  portosDestino: number[];

  ordenarpor: string = 'idBooking';
  eAdmin: boolean = this._appService.isAdmin();
  eCliente: boolean = this._appService.isCliente();
  maisDeUmCNPJ = this._appService.maisDeUmCNPJ();
  cssClassAdmin = '';

  comboCidades: FilterComboConfiguration;
  comboPortos: FilterComboConfiguration;
  selecao: number[];
  dhInstantPayment: string = '';

  constructor(
    private _bookingService: BookingService,
    private _grupoService: GrupoEconomicoService,
    private _sharedService: SharedService,
    private _propostaService: PropostaComercialService,
    private _municipioService: MunicipioService,
    private _appService: AppService,
    private _msgService: MessageService,
    private _genericOrderbyService: GenericOrderbyService,
    private _datepickerService: DatepickerService,
    private _filtroslateraisService: FiltrosLateraisService,
    private _analyticsService: AnalyticsService,
    private _infoPgtoAdicionalService: InfoPgtoAdicionalService
  ) {

    super();

    this.optionsInicio = this._datepickerService.GenerateOptionsConfig(this._sharedService.addDays(new Date(), -30));
    this.optionsFim = this._datepickerService.GenerateOptionsConfig(new Date());

    this.comboCidades = new FilterComboConfiguration("Cidade(s)...");
    this.comboCidades.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      enableSearch: true
    };

    this.comboPortos = new FilterComboConfiguration("Porto(s)...");
    this.comboPortos.settings = {
      checkedStyle: 'glyphicon',
      dynamicTitleMaxItems: 2,
      showCheckAll: true,
      showUncheckAll: true
    };

    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      });
  }

  ngAfterViewInit(): void {
    this._grupoService.grupoEconomicoSelecionadoEvent.subscribe((cnpjs: string) => {
        if (cnpjs)
          this.Pesquisar();
        else
          this.zeraPesquisa();
      });
  }

  ngOnInit() {
    this.cssClassAdmin = this.eAdmin || (this.eCliente && this.maisDeUmCNPJ)  ? "" : " customer-profile";

  

    let msg = this._msgService.getMessageRedirect();
    if (msg.show)
    this._msgService.addMessage(msg.titulo, msg.mensagem, msg.type, LogFrontFuncionalidade.Booking, Operacao.Consulta, this.consulta);

    this._analyticsService.RegistrarAcesso(AnalyticsTela.Booking);
  }

  zeraPesquisa() {
    this.empty = true;
    this.noresult = false;
    this.statusSelecionado = "";
    this.meusBookings = new MeusBookings(new FiltrosLaterais(), []);
    this.paginacao.pagedItems = [];
    this.paginacao.pager = {};
  }

  Pesquisar(registrarAnalytics: boolean = true) {
   
  }

	private ObterData(dateModel: DateModel, dateOffset: number) {
		return (dateModel != undefined) ?
			this._datepickerService.ObtemData(dateModel) :
			this._sharedService.addDays(new Date(), dateOffset);
	}


 

  FiltrarPorStatus(status: string){
    if (!status)
      this.meusBookings.Bookings = this.listaBookingsOriginal;
    else
      this.meusBookings.Bookings = this.listaBookingsOriginal.filter(b => b.statusBooking == status);

    this.ordernarListaPorCampo(this.ordenarpor, true);
  }





  getServicosBooking(book: Booking, tipo: string): ServicoProposta[] {
    return book.detalheDoBooking.proposta.servicosDaProposta.filter(f => f.indicOriDes == tipo);
  }

  exibeServicosBooking(book: Booking, tipo: string): boolean {
    var servicosDaProposta = book.detalheDoBooking.proposta.servicosDaProposta;
    if (!servicosDaProposta)
      return false;

    if (servicosDaProposta.length == 0)
      return false;

    return (servicosDaProposta.filter(f => f.indicOriDes == tipo).length > 0);
  }

  ordernarListaPorCampo(campo: string, desc: boolean): void {
    this.paginacao.setAllData(this._genericOrderbyService.ordenarPorCampo(this.meusBookings.Bookings, campo, desc));
  }

  obtemClasseDoStatus(status: string): string {
    status = status.toLowerCase();

    if (status.indexOf('emitido') !== -1)
      return 'quotation-status active';
    else if (status.indexOf('pendente') !== -1)
      return 'quotation-status waiting';
    else if (status.indexOf('ativo') !== -1)
      return 'quotation-status info';
    return 'quotation-status expired';
  }

  obtemTextoEmbarque(book: Booking) {
    if (book.modal.startsWith('PO'))
      return (book.portoOri + ' - ' + book.ufPortoOri).toUpperCase();
    else
      return (book.municOri + ' - ' + book.ufMunicOri).toUpperCase();
  }

  obtemTextoDesembarque(book: Booking) {
    if (book.modal.endsWith('PO'))
      return (book.portoDes + ' - ' + book.ufPortoDes).toUpperCase();
    else
      return (book.municDes + ' - ' + book.ufDes).toUpperCase();
  }

  exportarRelatorioCSV() {
    this.downloading = true;
    let listaFiltrada = this._filtroslateraisService.filtrarDados(this.meusBookings.Bookings, this.filtrosLaterais);
    this._bookingService.exportarRelatorioCSV(listaFiltrada);
    this._analyticsService.RegistrarDownload(AnalyticsTela.Booking, AnalyticsDownload.ExportacaoCSV);
    this.downloading = false;
  }

  handleDetail(book: any) {
    console.log('book,', book)
  }


  isInstantPaymentCreditado(numBooking: string) {

    if(numBooking == undefined || numBooking.trim().length == 0)
      return;


    this._infoPgtoAdicionalService.getInfoPgtoAdicionalByNumBooking(numBooking)
      .subscribe(infoPgtoAdicional => {

        if (infoPgtoAdicional !=   null)
        {
          const bookingUpd =  this.meusBookings.Bookings.find(item=> item.numBooking === numBooking );
          bookingUpd.isInstantPaymentCreditado = infoPgtoAdicional.paymentStatus == 'COMPLETED';
          let dtTmp: string = infoPgtoAdicional.paidAt.toString().substr(0,10);
          let ano: string = dtTmp.substr(0, 4);
          let mes: string = dtTmp.substr(5, 2);;
          let dia:string = dtTmp.substr(8, 2);;

          bookingUpd.dhInstantPayment = dia + '/' + mes + '/' + ano;

        }
        else
        {
          this.meusBookings.Bookings.find(item=> item.numBooking === numBooking ).isInstantPaymentCreditado = false;
        }

      });
  }

}
