
export enum LogFrontFuncionalidade
    {
        Icms,
        Invalida,
        Login,
        ResetSenha,
        CadastreSe,
        ProgramacaoDeNavios,
        Cotacao,
        Booking,
        Rastreamento,
        Documentacao,
        OrdemDeServico,
        VincularNF,
        Usuarios,
        PerfisDeAcesso,
        Parametros,
        CaixaDeEntrada,
        FaleConosco,
        AneisERotas,
        ConsultaDeLogs,
        Sobre,
        GrupoEconomico,
        OkEntregaConsulta,
        TaxaEsporadica,
        TipoTaxaEsporadica,
        CustomerInfo,
        Porto,
        InfoPgtoAdicional
    }
