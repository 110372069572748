import { Component, OnInit } from '@angular/core';

import { BaseComponent } from './../../shared/base.component';

import { AppService } from 'app/_services/app.service';
import { SharedService } from 'app/_services/shared.service';
import { ConfigPortal } from 'app/_models/config-portal';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent extends BaseComponent implements OnInit {

  versaoWebPortal: string = "";
  versaoAPIPortal: string = "";
  versaoAPIeCargo: string = "";
  versaoAPIAuth: string = "";
  configInfo: ConfigPortal;

  constructor(private _sharedService: SharedService, private _appService: AppService) {
    super();
   }

  ngOnInit() {
    this.getVersaoAPI("portal");
    this.getVersaoAPI("ecargo");
    this.getVersaoAPI("auth");
    this.versaoWebPortal = this._appService.getVersaoWebPortal();

    if (localStorage.getItem('apiUrl')) {
      this.configInfo = JSON.parse(localStorage.getItem('apiUrl')); 
    }
  }

  loading: boolean = false;
  loading2: boolean = false;

  getVersaoAPI(filtro: string) {
    this.loading = true;
    this._sharedService.getVersaoAPI(filtro)
      .subscribe(p => {
        this.loading = false;
        if (filtro == "portal")
          this.versaoAPIPortal = p;
        else if (filtro == "ecargo")
          this.versaoAPIeCargo = p;
        else
          this.versaoAPIAuth = p;

      });

  }
}
