import { Component, OnInit, ViewChild } from '@angular/core';

import { Assunto } from './../../../_models/assunto';
import { FaleConosco } from './../../../_models/faleconosco';
import { CaixaEntradaService } from './../../../_services/caixa-entrada.service';

@Component({
  selector: 'app-caixa-entrada-detalhe',
  templateUrl: './caixa-entrada-detalhe.component.html',
  styleUrls: ['./caixa-entrada-detalhe.component.scss'],
  exportAs: 'child'
})
export class CaixaEntradaDetalheComponent implements OnInit {

  loading: boolean = false;
  email: FaleConosco = new FaleConosco();

  constructor(private _caixaEntradaService: CaixaEntradaService) { }

  ngOnInit() {
    this.email.Assunto = new Assunto();

    this._caixaEntradaService.emitirModal.subscribe(
      modal  => this.showModal(modal)
    );
  }

  @ViewChild('cxModal', {static: true}) cxModal; 

  public showModal(modal):void {
    if(modal)
      this.cxModal.show();
    else
      this.cxModal.hide();
  }

}
