import { Perfil } from './perfil';
import { UsuarioCNPJ } from './usuario-cnpj';
import { PerfilUsuario } from 'app/_enums/perfil-usuario.enum';
import { StatusUsuario } from 'app/_enums/status-usuario.enum';

export class Usuario{
    usuarioID: number;
    email: string;
    nome: string;
    razaoSocial: string;
    nomeFantasia: string;
    cnpj: string;
    inscricaoEstadual: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    municipio: string;
    uf: string;
    cep: string;
    telefone: string;
    perfilUsuario: PerfilUsuario;
    vendedor: string;
    escritorioResponsavel: string;
    status: StatusUsuario;
    motivoRecusa: string;
    dataCadastro: Date;
    dataAprovacao: Date;
    dataReprovacao: Date;

    perfilID: number;
    perfil: Perfil;
    cnpjs: UsuarioCNPJ[];
    usuarioIDeCargo: string;
    strDataCadastro: string;
    strDataAprovacao: string;
    strDataReprovacao: string;
    listaCNPJs: string;
}
