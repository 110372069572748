import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ListaComponent } from './lista/lista.component';
import { PrioridadesComponent } from './prioridades.component';
import { PrioridadesRoutingModule } from './prioridades.routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { PrioridadeService } from 'app/_services/prioridade.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    PrioridadesRoutingModule
  ],
  declarations: [
    PrioridadesComponent,
    ListaComponent
  ],
  providers: [
    PrioridadeService
  ]
})
export class PrioridadesModule { }
