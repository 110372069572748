export class FiltroListaItem {
	classeCssFundo: string;
	classeCssIcone: string;
	status: string;
	titulo: string;
	valor: number;

	public static Create(classeCssFundo: string = null, classeCssIcone: string = null, status: string = null, titulo: string = null, valor: number = 0): FiltroListaItem {
		return {
			classeCssFundo: classeCssFundo,
			classeCssIcone: classeCssIcone,
			titulo: titulo,
			status: status,
			valor: valor
		};
	}
}
