import { SubTributaria } from './../../_models/sub-tributaria';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SubTributariaCompoent } from './sub-tributaria.component';
import { SubTributariaDetalheComponent } from './sub-tributaria-detalhe/sub-tributaria-detalhe.component';
import { AuthGuard } from 'app/_guards/auth.guard';

const usuariosRoutes: Routes = [
    { path: '', component: SubTributariaCompoent, canActivate: [AuthGuard] },
    // { path: 'detalhe', component: UsuarioDetalheComponent, canActivate: [AuthGuard] },
    // { path: 'detalhe/:id', component: UsuarioDetalheComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(usuariosRoutes)],
    exports: [RouterModule]
})
export class SubTributariaModuleRoutingModule {}
