
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';

import { ServicoProposta } from './../_models/servico-proposta';
import { ValidationResult } from './../_models/validation-result';
import { PropostaComercial } from './../_models/proposta-comercial';
import { PropostaComercialRequest } from './../_models/proposta-comercial-request';

import { BaseService } from './base.service';
import { MessageService } from './message.service';

import { Operacao } from '../_enums/operacao';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';

@Injectable({
  providedIn: 'root'
})
export class PropostaComercialService {

  constructor(private baseService: BaseService, private _msgService: MessageService) { }

  getPropostas(consulta: PropostaComercialRequest): Observable<PropostaComercial[]> {
    return this.baseService.Post('propostas', consulta).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;
          if (retorno.IsValid)
            return (retorno.Data as PropostaComercial[]);
          else {
            var erros: string[] = [];
            erros.push("Erro ao consultar Propostas! Contate o administrador!");
            retorno.Erros.forEach(element => {
              erros.push(element.ErrorCode + " - " + element.Message);
            });
            this._msgService.addMessageArray('Propostas', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, consulta);
            return null;
          }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getProposta({ numProposta, usuarioID, idTrecho, cnpjCli, ieCli }): Observable<PropostaComercial> {
    return this.baseService.Post('propostas', { numProposta: numProposta, idTrecho: idTrecho,
      idUsuario: usuarioID, cnpjCli: cnpjCli, ieCli: ieCli }).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return (retorno.Data as PropostaComercial);
        else {
          var erros: string[] = [];
          erros.push("Erro ao consultar Proposta! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Propostas', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, { numProposta: numProposta, idTrecho: idTrecho,
            idUsuario: usuarioID, cnpjCli: cnpjCli, ieCli: ieCli });
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

  getServicos({ propostaID, usuarioID }): Observable<ServicoProposta[]> {
    return this.baseService.Post('propostas/servicos', { idProposta: propostaID, idUsuario: usuarioID }).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (retorno.IsValid)
          return (retorno.Data as ServicoProposta[])
        else {
          var erros: string[] = [];
          erros.push("Erro ao consultar Propostas! Contate o administrador!");
          retorno.Erros.forEach(element => {
            erros.push(element.ErrorCode + " - " + element.Message);
          });
          this._msgService.addMessageArray('Proposta', erros, "error", LogFrontFuncionalidade.Booking, Operacao.Consulta, { idProposta: propostaID, idUsuario: usuarioID });
          return null;
        }
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }

}
