import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MessageService } from './../../_services/message.service';
import { AuthenticationService } from './../../_services/authentication.service';

import { BaseComponent } from './../../shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';

import  swal  from 'sweetalert2';
import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';
declare var removeSwal: any;

@Component({
  selector: 'app-reset-senha',
  templateUrl: './reset-senha.component.html',
  styleUrls: ['./reset-senha.component.scss']
})
export class ResetSenhaComponent extends BaseComponent implements OnInit {

  public emailUsuario: string = '';
  loading: boolean = false;
  //public mensagem: string = "";

  @ViewChild(MessageComponent, {static: true}) alerta: MessageComponent;

  constructor(private _authenticationService: AuthenticationService,
              private _msgService: MessageService,
              protected _router: Router) {

    super();
    
    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.alerta.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ngOnInit() {
  }

  Enviar(form: FormGroup) {
    this.loading = true;
    this._authenticationService.ResetSenha(this.emailUsuario)
      .subscribe(result => {
        this.loading = false;
        if (result.IsValid) {
          this._msgService.clearMessage();
          removeSwal();
          swal({
                title: 'Sucesso',
                text: 'Procedimento para alteração de senha enviado por email!',
                type: 'success',
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: 'OK'
              }).then(success => {
                  this.emailUsuario = '';
                  form.reset();
                  this._router.navigate(['/home']);
              }, dismiss => {
                // console.log(dismiss);
              });
        }
        else {
          var msgErr: string[] = [];
          result.Erros.forEach(element => msgErr.push(element.Message));             
          this._msgService.addMessageArray("Reset Senha", msgErr, "error", LogFrontFuncionalidade.ResetSenha, Operacao.Inclusao, this.emailUsuario);   
        }
      });
  }
}
