import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseComponent } from './../../../shared/base.component';
import { MessageComponent } from './../../../shared/message/message.component';

import { Perfil } from './../../../_models/perfil';
import { Funcionalidade } from './../../../_models/funcionalidade';

import { PerfilService } from './../../../_services/perfil.service';
import { MessageService } from './../../../_services/message.service';
import { FuncionalidadeService } from './../../../_services/funcionalidade.service';

import { Operacao } from 'app/_enums/operacao';
import { PerfilUsuario } from "app/_enums/perfil-usuario.enum";
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';


@Component({
  selector: 'app-perfil-detalhe',
  templateUrl: './perfil-detalhe.component.html',
  styleUrls: ['./perfil-detalhe.component.scss']
})
export class PerfilDetalheComponent extends BaseComponent implements OnInit {

  inscricaoGet: Subscription;
  inscricaoFuncs: Subscription;
  inscricaoPost: Subscription;

  public titulo: string = 'Novo Perfil de Acesso';
  public loading: boolean = false;
  public perfil: Perfil = new Perfil();
  public funcionalidades: Funcionalidade[] = new Array<Funcionalidade>();

  public id: number = 0;

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  constructor(
    private _route: ActivatedRoute,
    private _perfilService: PerfilService,
    private _funcionalidadeService: FuncionalidadeService,
    private _msgService: MessageService,
    private _router: Router) {

    super();
    
    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ngOnInit(): void {
    this.inscricaoGet = this._route.params.subscribe(
      (params: any) => {
        this.id = params['id'];
        if (this.id) {
          this.titulo = 'Editar Perfil de Acesso';
          this.loading = true;
          this._perfilService.getPerfil(this.id)
            .subscribe(perfil => {
              this.perfil = perfil;
              this.loading = false;
              this.AtualizaListaFuncionalidades();
            });
        }
        else {
          this.titulo = 'Novo Perfil de Acesso';
          this.perfil = new Perfil();
          this.perfil.Ativo = true;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.inscricaoGet)
      this.inscricaoGet.unsubscribe();
    if (this.inscricaoPost)
      this.inscricaoPost.unsubscribe();
    if (this.inscricaoFuncs)
      this.inscricaoFuncs.unsubscribe();
  }

  AtualizaListaFuncionalidades() {
    this.inscricaoFuncs = this._funcionalidadeService.getFuncionalidades()
      .subscribe(lista => {
        let cli = lista.filter(function (itm) { return itm.PerfilUsuario == PerfilUsuario.Cliente });
        let pro = lista.filter(function (itm) { return itm.PerfilUsuario == PerfilUsuario.Provedor });
        let adm = lista.filter(function (itm) { return itm.PerfilUsuario == PerfilUsuario.Administrador });

        this.funcionalidades = new Array<Funcionalidade>();

        if (this.perfil.Cliente)
          this.funcionalidades = this.funcionalidades.concat(cli);
        if (this.perfil.Provedor)
          this.funcionalidades = this.funcionalidades.concat(pro);
        if (this.perfil.Admin)
          this.funcionalidades = this.funcionalidades.concat(adm);

        if (this.funcionalidades.length > 0)
          this.funcionalidades.forEach(func => {
            if (this.perfil.Funcionalidades)
              func.Checked = !this.perfil.Funcionalidades || this.perfil.Funcionalidades.filter(function (itm) { return itm.FuncionalidadeID == func.FuncionalidadeID }).length > 0;
          });

        this.loading = false;
      });
  }

  SalvarPerfil() {
    this.perfil.Funcionalidades = this.funcionalidades;
    this.loading = true;
    this._perfilService.salvarPerfil(this.perfil)
      .subscribe(result => {
        this.loading = false;

        if (result.IsValid) {
          this._msgService.addMessageRedirect('Perfil de Acesso', result.Message, "success");
          this._router.navigate(['perfil']);
        }
        else {
          var msgErr: string[] = [];
          result.Erros.forEach(element => msgErr.push(element.Message));             
          this._msgService.addMessageArray("Perfil de Acesso", msgErr, "error", LogFrontFuncionalidade.PerfisDeAcesso, Operacao.Alteracao, this.perfil);   
        }
      });
  }
}
