
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { AlteracoesArquivoUpload } from '../_models/alteracoes-arquivo-upload';
import { ValidationResult } from '../_models/validation-result';
import { AppService } from './app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadArquivoService {

  constructor(
    private http: HttpClient,
    private appService: AppService) { }

  private getOptionsArgs(): any {
    // let headers = new Headers();

    // if (this.appService.UsuarioLogado != null) {
    //   headers = new Headers({ 'Authorization': 'Bearer ' + this.appService.UsuarioLogado.Token });
    // }

    // return new RequestOptions({ headers: headers });
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.appService.UsuarioLogado.token, 'Content-Type': 'application/json' });

    let options = {
      headers: headers
    };
    return options;
  }

  salvarAlteracoesUpload(alteracoes: AlteracoesArquivoUpload, upload: string, tipo: string, id: number) {
    if (alteracoes.arquivosParaUpload.length > 0 && alteracoes.arquivosParaExcluir.length == 0)
      return this.gravarUpload(alteracoes, upload, tipo, id);
    if (alteracoes.arquivosParaExcluir.length > 0 && alteracoes.arquivosParaUpload.length == 0)
      return this.apagarUpload(alteracoes, upload, tipo);

    this.gravarUpload(alteracoes, upload, tipo, id)
      .subscribe(result => {
        return this.apagarUpload(alteracoes, upload, tipo);
      });
  }

  private gravarUpload(alteracoes: AlteracoesArquivoUpload, upload: string, tipo: string, id: number) {
    let formData: FormData = new FormData();
    for (var j = 0; j < alteracoes.arquivosParaUpload.length; j++) {
      formData.append("file[]", alteracoes.arquivosParaUpload[j].arquivo, alteracoes.arquivosParaUpload[j].arquivo.name);
    }
    return this.http.post(this.obterUrlUpload(upload, tipo, id), formData, this.getOptionsArgs()).pipe(
      map((response: any) => {
        let retorno: ValidationResult = response;
        return retorno;
      }),
      catchError(e => {
        let erro = e && e.error_description;
        return observableThrowError(erro);
      }));
  }

  private apagarUpload(alteracoes: AlteracoesArquivoUpload, upload: string, tipo: string) {
    var ids: number[] = [];
    alteracoes.arquivosParaExcluir.forEach(arquivo => {
      if (tipo == 'cotacao')
        ids.push(arquivo.idCotacao);
      else
        ids.push(arquivo.idBooking);
    });

    ids.forEach(id => {
      this.http.delete(this.obterUrlUpload(upload, tipo, id)).pipe(
        map((response: any) => { }));
    });

    return null;
  }

  private obterUrlUpload(upload: string, tipo: string, id: number) {
    return this.appService.apiUrl + 'upload/' + upload + '/' + tipo + '/' + id
  }

}
