export class ParametroPorto{
    PortoID: number;
    NomePorto: string;
    QtdHrEntrega: number;
    QtdHrAgendEntrega: number;
    QtdHrAgendColetaPA: number;
    QtdHrAgendColetaPO: number;
    QtdHrMinimasAgendColeta: number;
    QtdHrMinimasAgendEntrega: number;

    constructor(){
        this.PortoID = 0;
    }
}