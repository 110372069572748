export class ClienteRequest
{
    cnpj: string;
    inscricaoEstadual: string;

    idProposta: number;
    idCotacao: number;
    dataIni: Date;
    datafim: Date;
    tipoConsulta: number;
    idUsuario: number;
    idMunicipio: number;
}
