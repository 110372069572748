import { FiltrosLaterais } from './filtros-laterais';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CotacaoRequest extends FiltrosLaterais {
    Status: string;
    CotacaoID: number;    
    UsuarioID: number;
    IdTrecho: number;
    idsMunicDes: string; 
    idsMunicOri: string; 
    idsPortoOri: string; 
    idsPortoDes: string;
    cnpjCli: string;
}