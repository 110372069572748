import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageComponent } from '../../../shared/message/message.component';
import { MessageService } from '../../../_services/message.service';
import { BaseComponent } from '../../../shared/base.component';
import { Terminal } from '../../../_models/terminal';
import { Porto } from '../../../_models/porto';
import { SharedService } from '../../../_services/shared.service';
import { TerminaisService } from '../../../_services/terminais.service';
import { LogFrontFuncionalidade } from '../../../_enums/log-front-funcionalidade';
import { Operacao } from '../../../_enums/operacao';

@Component({
  selector: 'app-terminais',
  templateUrl: './terminais.component.html',
  styleUrls: ['./terminais.component.scss']
})
export class TerminaisComponent extends BaseComponent implements OnInit {

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;
  
  loading: boolean = false;
  terminais: Terminal[];

  //* VARIÁVEIS QUE SERÃO UTILIZADAS SOMENTE QUANDO HOUVER TERMINAIS ALÉM DO TERMINAL PADRÃO */
  loadingPortos: boolean = false;
  loadingTerminais: boolean = false;
  mensagemErroModal: string;
  novoTerminal: Terminal = new Terminal();
  listaTerminais: Terminal[] = [];
  portos: Porto[] = [];
  //*************************************/

  constructor(
    private _msgService: MessageService,
    private _terminalService: TerminaisService,
    private _sharedService: SharedService) {

    super();

    this._msgService.clearMessage();
    this.ObterTerminais();
   }

  ngOnInit() {
    this._msgService.emitirMensagem.subscribe(
      msg => {
        this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ObterTerminais(){
    this.loading = true;
    this._terminalService.obterTerminais()
      .subscribe(result => {
        this.loading = false;
        this.terminais = result;
      });
  }

  AtualizarTerminais(){
    var terminalInvalido = this.terminais.find(t => !t.latitude || !t.longitude);
    if (terminalInvalido){
      this._msgService.addMessage('Terminal', 'Obrigatório informar Latitude e Longitude para todos os terminais', 'error');
      return;
    }

    this.loading = true;
    this._terminalService.atualizarTerminais(this.terminais)
      .subscribe(result => {
        this.loading = false;
        if (result.IsValid) {
          this._msgService.addMessage('Terminal', 'Terminais atualizados com sucesso', "success");
          this.ObterTerminais();
        }
        else {
          var msgErr: string[] = [];
          result.Erros.forEach(element => msgErr.push(element.Message));
          this._msgService.addMessageArray("Terminal", msgErr, "error", LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.terminais);
        }
      });
  }

  /** DAQUI PRA BAIXO, MÉTODOS QUE SERÃO UTILIZADOS QUANDO HOUVER CADASTRO DE NOVOS TERMINAIS */

  public ObterPortos() {
    this.loadingPortos = true;
    this._sharedService.getPortosTodos()
      .subscribe(p => {
        this.portos = p;
        this.loadingPortos = false;
      });
  }

  CancelarNovoTerminal(){
    this._msgService.clearMessage();
    this.novoTerminal = new Terminal();
  }

  IncluirTerminal(){
    this.loading = true;
    this._terminalService.salvarTerminal(this.novoTerminal)
      .subscribe(result => {
        this.loading = false;
        if (result.IsValid) {
          this._msgService.addMessageRedirect('Terminal', result.Message, "success");
          this.novoTerminal = new Terminal();
          this.ObterTerminais();
        }
        else {
          var msgErr: string[] = [];
          result.Erros.forEach(element => msgErr.push(element.Message));
          this._msgService.addMessageArray("Terminal", msgErr, "error", LogFrontFuncionalidade.Parametros, Operacao.Alteracao, this.terminais);
        }
      });
  }

}
