import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FiltrosLateraisComponent } from './filtros-laterais.component';
import { FiltrosLateraisService } from '../../_services/filtros-laterais.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RastreamentoService } from 'app/_services/rastreamento.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TooltipModule
    ],
    exports: [
        FiltrosLateraisComponent
    ],
    declarations: [
        FiltrosLateraisComponent
    ],
    providers: [
        FiltrosLateraisService,
        RastreamentoService
    ],
})
export class FiltrosLateraisModule {


}
