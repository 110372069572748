import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';
import { LogFrontFuncionalidade } from '../_enums/log-front-funcionalidade';
import { Operacao } from '../_enums/operacao';
import { SporadicTax } from '../_models/sporadic-tax';
import { ValidationResult } from '../_models/validation-result';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class SporadicTaxService {
  urlPath: string;

  constructor(
    private baseService: BaseService,
    private _msgService: MessageService) { }


  salvar(sporadicTax: SporadicTax): Observable<ValidationResult> {
    return this.baseService.Post('sporadicTax', sporadicTax).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Taxa adicional - Salvar', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Inclusao, sporadicTax);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


  saveAll(sporadicTax: SporadicTax[]): Observable<ValidationResult> {
    return this.baseService.Post('sporadicTax/saveAll', sporadicTax).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Taxa adicional - Salvar', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Inclusao, sporadicTax);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


  getSporadicTax(id: number): Observable<SporadicTax> {
    return this.baseService.Get('sporadicTax/' + id).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;

            if (retorno.IsValid)
                return retorno.Data as SporadicTax;
            else
                return null;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
}

  update(sporadicTax: SporadicTax[]): Observable<ValidationResult> {
    return this.baseService.Put('sporadicTax', sporadicTax).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Taxa Adicional - Atualização', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Alteracao, sporadicTax);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


  delete(id: number): Observable<ValidationResult> {
    return this.baseService.Delete('sporadicTax/delete?id=' + id).pipe(
      map((response: ValidationResult) => {
        let retorno: ValidationResult = response;

        if (!retorno.IsValid) {
          this._msgService.addMessageValidationError('Taxa adicional - Exclusão', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Exclusao, id);
        }

        return retorno;
      }),catchError(e => {
        return this.baseService.handleError(e);
      }),);
  }


  findLastExpirationDate(taxTypeId: number, originRegionId: number, originModalId: string, destinationRegionId: number, destinationModalId: string): Observable<SporadicTax> {

    this.urlPath = "sporadicTax/findLastExpirationDate?taxTypeId=" + taxTypeId + "&originRegionId=" + originRegionId + "&originModalId=" + originModalId + "&destinationRegionId=" +  destinationRegionId + "&destinationModalId=" + destinationModalId;

    return this.baseService.Get(this.urlPath).pipe(
        map((response: ValidationResult) => {
            let retorno: ValidationResult = response;

            if (retorno.IsValid)
                return retorno.Data as SporadicTax;
            else
                return null;
        }),catchError(e => {
            return this.baseService.handleError(e);
        }),);
}


findByTypeIdStatus(sporadicTaxTypeId: number, isEnabled: number): Observable<SporadicTax[]> {

  this.urlPath = "sporadicTax/findByTypeIdStatus?sporadicTaxTypeId=" + sporadicTaxTypeId + "&isEnabled=" + isEnabled;

  return this.baseService.Get(this.urlPath).pipe(
      map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (retorno.IsValid)
              return retorno.Data as SporadicTax[];
          else
              return null;
      }),catchError(e => {
          return this.baseService.handleError(e);
      }),);
}


findTaxesByOriginRegionAndDestinationRegion(sporadicTaxTypeId: number,
                                            originRegionId: number,
                                            destinationRegionId: number): Observable<SporadicTax[]> {

  this.urlPath = "sporadicTax/findTaxesByOriginRegionAndDestinationRegion?sporadicTaxTypeId=" + sporadicTaxTypeId +
                                                                          "&originRegionId=" + originRegionId +
                                                                          "&destinationRegionId=" + destinationRegionId;

  return this.baseService.Get(this.urlPath).pipe(
      map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (retorno.IsValid)
              return retorno.Data as SporadicTax[];
          else
              return null;
      }),catchError(e => {
          return this.baseService.handleError(e);
      }),);
}




findTaxesByOriginRegionAndDestinationRegionPort(sporadicTaxTypeId: number,
                                                originRegionId: number,
                                                destinationRegionId: number,
                                                destinationPortId: number): Observable<SporadicTax[]> {

this.urlPath = "sporadicTax/findByOriginRegionAndDestinationRegionAndPort?sporadicTaxTypeId=" + sporadicTaxTypeId +
         "&originRegionId=" + originRegionId +
         "&destinationRegionId=" + destinationRegionId
         "&destinationPortId=" + destinationPortId;

return this.baseService.Get(this.urlPath).pipe(
map((response: ValidationResult) => {
let retorno: ValidationResult = response;

if (retorno.IsValid)
return retorno.Data as SporadicTax[];
else
return null;
}),catchError(e => {
return this.baseService.handleError(e);
}),);
}




findTaxesByOriginRegionPortAndDestinationRegion(sporadicTaxTypeId: number, originRegionId: number, originPortId: number, destinationRegionId: number): Observable<SporadicTax[]> {

  this.urlPath = "sporadicTax/findTaxesByOriginRegionPortAndDestinationRegion?sporadicTaxTypeId=" + sporadicTaxTypeId + "&originRegionId=" + originRegionId + "&originPortId=" + originPortId + "&destinationRegionId=" + destinationRegionId;

  return this.baseService.Get(this.urlPath).pipe(
      map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (retorno.IsValid)
              return retorno.Data as SporadicTax[];
          else
              return null;
      }),catchError(e => {
          return this.baseService.handleError(e);
      }),);
}


// Busca por Tipo de taxa, Regiao de Origem, Porto de Origem, Modal de Origem, Regiao de Destino, Porto de Destino, Modal de Destino e status
findAllSpec(sporadicTaxTypeId: number, originRegionId: number, originPortId: number, originModalId: string, destinationRegionId: number, destinationPortId: number, destinationModalId: string, isEnabled: number): Observable<SporadicTax[]> {

  let specification: string = '';


  
  if(sporadicTaxTypeId > 0)
  {
    specification = specification + "sporadicTaxTypeId=" + sporadicTaxTypeId;
  }


  if(originRegionId > 0) 
  {
    if(specification.length > 0)
    {
      specification = specification + '&';
    }
      
    specification = specification + "originRegionId=" + originRegionId;

  }


  if(originPortId > 0) 
  {
    if(specification.length > 0)
    {
      specification = specification + '&';
    }
      
    specification = specification + "originPortId=" + originPortId;

  }  


  if(originModalId != '') 
  {
    if(specification.length > 0)
    {
      specification = specification + '&';
    }
      
    specification = specification + "originModalId=" + originModalId;

  }    


  // destinationRegionId
  if(destinationRegionId > 0) 
  {
    if(specification.length > 0)
    {
      specification = specification + '&';
    }
      
    specification = specification + "destinationRegionId=" + destinationRegionId;

  }


  //destinationPortId
  if(destinationPortId > 0) 
  {
    if(specification.length > 0)
    {
      specification = specification + '&';
    }
      
    specification = specification + "destinationPortId=" + destinationPortId;

  }  


  if(destinationModalId != '') 
  {
    if(specification.length > 0)
    {
      specification = specification + '&';
    }
      
    specification = specification + "destinationModalId=" + destinationModalId;

  }   



    if(specification.length > 0)
    {
      specification = specification + '&';
    }
      
    specification = specification + "isEnabled=" + isEnabled;



  // this.urlPath = "sporadicTax/findAllSpec?sporadicTaxTypeId="    + sporadicTaxTypeId +
  //                                         "&originRegionId="      + originRegionId +
  //                                         "&originPortId="        + originPortId +
  //                                         "&originModalId="       + originModalId +
  //                                         "&destinationRegionId=" + destinationRegionId +
  //                                         "&destinationPortId="   + destinationPortId +
  //                                         "&destinationModalId="  + destinationModalId +
  //                                         "&isEnabled="           + isEnabled;

  this.urlPath = "sporadicTax/findAllSpec?" + specification;


  return this.baseService.Get(this.urlPath).pipe(
      map((response: ValidationResult) => {
          let retorno: ValidationResult = response;

          if (retorno.IsValid)
              return retorno.Data as SporadicTax[];
          else
              return null;
      }),catchError(e => {
          return this.baseService.handleError(e);
      }),);
}




findTaxesByOriginRegionPortAndDestinationRegionPort(sporadicTaxTypeId: number,
                                                    originRegionId: number,
                                                    originPortId: number,
                                                    destinationRegionId: number,
                                                    destinationPortId: number): Observable<SporadicTax[]> {

this.urlPath = "sporadicTax/findTaxesByOriginRegionPortAndDestinationRegionPort?sporadicTaxTypeId=" + sporadicTaxTypeId +
                                                                               "&originRegionId=" + originRegionId +
                                                                               "&originPortId=" + originPortId +
                                                                               "&destinationRegionId=" + destinationRegionId +
                                                                               "&destinationPortId=" + destinationPortId;
return this.baseService.Get(this.urlPath).pipe(
map((response: ValidationResult) => {
let retorno: ValidationResult = response;

if (retorno.IsValid)
return retorno.Data as SporadicTax[];
else
return null;
}),catchError(e => {
return this.baseService.handleError(e);
}),);
}



obterSporadicTaxes(): Observable<SporadicTax[]> {
  return this.baseService.Get('sporadicTax').pipe(
    map((response: ValidationResult) => {
      let retorno: ValidationResult = response;

      if (!retorno.IsValid) {
        this._msgService.addMessageValidationError('Taxas esporádicas', retorno.Erros, 'error', LogFrontFuncionalidade.Parametros, Operacao.Consulta, null);
      }

      return retorno.Data as SporadicTax[];

    }),catchError(e => {
      return this.baseService.handleError(e);
    }),);
}


exportarRelatorioCSV(sporadicTaxes: SporadicTax[]): Observable<any> {
  if (!sporadicTaxes || sporadicTaxes.length === 0) {
    return;
  };


  const titulos: any[] = [
    'ID',
    'E-mail',
    'Nome',
    'Razão Social',
    'CNPJ',
    'Nome Fantasia',
    'Inscrição Estadual',
    'Logradouro',
    'Número',
    'Complemento',
    'Bairro',
    'UF',
    'Município',
    'CEP',
    'Telefone',
    'Perfil Portal',
    'Perfil E-cargo',
    'CNPJ(s) Cadastrados',
    'Status do Usuário',
    'Data de Solicitação',
    'Data de Aprovação',
    'Data de Reprovação',
    'Data Última Alteração de Senha',
    'Situação Senha Login'];

      this.baseService.DownloadXLSX(sporadicTaxes, titulos, 'TaxasEsporadicas.xlsx');
};

}
