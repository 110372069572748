import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from "@angular/router";
import {
  Component,
  Compiler,
  ViewChild,
  ElementRef,
  OnInit,
} from "@angular/core";
import swal from "sweetalert2";
import * as moment from "moment/moment";

import { AppService } from "./_services/app.service";
import { ConfigService } from "./_services/config.service";
import { GrupoEconomicoService } from "./_services/grupo-economico.service";
import { AuthenticationService } from "./_services/authentication.service";

import { GrupoEconomico } from "app/_models/grupo-economico";
import { GrupoEconomicoRequest } from "./_models/grupo-economico-request";
import { FilterComboConfiguration } from "./_models/filterComboConfiguration";
import { AnalyticsService } from "app/_services/analytics.service";
import { AnalyticsTela } from "./_enums/analytics-tela";
import { AnalyticsOperacao } from "./_enums/analytics-operacao";
import { GoogleConfig } from "./_models/google-config";
import { LoginForgeRockService } from "./_services/login-forgerock.service";
import { Login } from "./_models/login";
import { BaseService } from "./_services/base.service";
import { catchError } from "rxjs/operators";
import { pipe } from "rxjs";

declare var ocultarControleMenu: any;
declare var ocultarSwalOverlay: any;
declare var removeSwal: any;
declare var isIe: boolean;
declare var loadMaps: any;
declare var loadAnalytics: any;
declare var browser_versao: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("closeModal", { static: true }) private closeModal: ElementRef;
  comboGrupoEconomico: FilterComboConfiguration;
  mensagemErro: string;
  username: string = "";
  logado: boolean = false;
  loadingGrupos: boolean = false;
  grupos: GrupoEconomico[] = [];
  gruposSelecionados: number[];
  labelGrupo: string;
  eAdmin: boolean = this._appService.isAdmin();
  cssClassAdmin = "";
  loadingRouter: boolean = true;
  error = "";
  dropdownVisibleMenuLogin = false;
  dropdownVisibleMenuRegister = false;
  hideTimeout: any;
  isDropdownOpen: boolean = false;

  public login: Login = new Login();

  constructor(
    public _router: Router,
    public _authenticationService: AuthenticationService,
    public _appService: AppService,
    private _compiler: Compiler,
    private _configService: ConfigService,
    private _grupoService: GrupoEconomicoService,
    private _loginForgeRockService: LoginForgeRockService,
    private _baseService: BaseService,
    private _analyticsService: AnalyticsService
  ) {
    _loginForgeRockService.SetConfig(_configService);

    this._compiler.clearCache();

    Date.prototype.toJSON = function () {
      return moment(this).format();
    };

    this.loadGoogleConfig(_configService._googleConfig);
    this._appService.configInfo = _configService._config;
    this._appService.apiUrl = this._appService.configInfo.apiUrl;
    localStorage.setItem("browser_versao", JSON.stringify(browser_versao));
    localStorage.setItem("apiUrl", JSON.stringify(_configService._config));

    this.PopularUsuario();

    this._authenticationService.emitirLogin.subscribe((modal) => {
      this.PopularUsuario();
    });

    this._appService.emitirLogin.subscribe((modal) => {
      this.PopularUsuario();
      if (modal) {
        this._appService.limpaStorage();
      }
    });

    this._appService.emitirLogout.subscribe((value) => {
      swal({
        title: "Alerta",
        text: "Sua sessão expirou. Faça o login novamente para prosseguir.",
        type: "warning",
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: "OK",
        allowEnterKey: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((response) => {
        this._router.navigate(["/"]);
        localStorage.removeItem("dataExpiracaoSessa");
        this._authenticationService.logout(value);
        this._loginForgeRockService.logout();
      });
    });

    this.loadingRouter = true;

    setTimeout(() => {
      this.verificaLoginForgeRockEfetivado();
    }, 1000);

    this.comboGrupoEconomico = new FilterComboConfiguration(
      "Grupo(s) Econômico(s)..."
    );
    this.comboGrupoEconomico.settings = {
      checkedStyle: "glyphicon",
      showCheckAll: false,
      showUncheckAll: true,
      dynamicTitleMaxItems: 0,
      enableSearch: true,
    };

    if (isIe) {
      removeSwal();

      swal({
        title: "Atenção",
        text: "Este site é melhor visualizado no navegador Google Chrome",
        type: "warning",
      }).then(
        (success) => {
          ocultarControleMenu();
          ocultarSwalOverlay();
        },
        (dismiss) => {
          removeSwal();
        }
      );
    }
  }

  toggleDropdown(): void {
    if (!this.logado) {
      // Só alterna o dropdown se não estiver logado
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }

  showDropdownMenuLogin() {
    this.dropdownVisibleMenuLogin = true;
  }

  showDropdownMenuRegister() {
    this.dropdownVisibleMenuRegister = true;
  }

  showDropdownMenuMobile(menu: string) {
    this[menu] = !this[menu];
  }

  scheduleHideDropdownMenuLogin() {
    this.hideTimeout = setTimeout(() => {
      this.dropdownVisibleMenuLogin = false;
    }, 300); // Pequeno delay para evitar fechamento inesperado
  }

  scheduleHideDropdownMenuRegister() {
    this.hideTimeout = setTimeout(() => {
      this.dropdownVisibleMenuRegister = false;
    }, 300); // Pequeno delay para evitar fechamento inesperado
  }

  cancelHideDropdown() {
    clearTimeout(this.hideTimeout);
  }

  private verificaLoginForgeRockEfetivado() {
    const locationSearch = window.location.search;
    const searchParams = String(locationSearch)
      .substring(1, locationSearch?.length)
      .split(`&`);

    let queryParams: string[] = [];
    searchParams?.map((search) => {
      if (search.includes(`code=`)) queryParams.push(`code`);
      if (search.includes(`iss=`)) queryParams.push(`iss`);
      if (search.includes(`client_id=`)) queryParams.push(`client_id`);
    });

    if (
      queryParams.includes(`code`) &&
      queryParams.includes(`iss`) &&
      queryParams.includes(`client_id`)
    ) {
      var retorno = this._loginForgeRockService.getTokenRaw();

      if (!retorno) {
        setTimeout(() => {
          retorno = this._loginForgeRockService.getTokenRaw();

          if (retorno) {
            this.efetuaLogin(retorno);
          } else {
            this._baseService.limpaStorage();
            this._router["/home"];
          }
        }, 1000);
      }

      if (retorno) {
        this.efetuaLogin(retorno);
      }
    } else {
      this.loadingRouter = false;
    }
  }

  efetuaLogin(retorno) {
    this._authenticationService
      .LoginForgeRock(retorno)
      .pipe(
        catchError((error) => {
          this._router["/home"];
          this.loadingRouter = false;
          this.logado = false;
          swal({
            title: "Erro Login!",
            text: "Não foi possível realizar o seu login! Tente novamente mais tarde!",
            type: "warning",
            confirmButtonText: "Ok",
            showConfirmButton: true,
          });
          localStorage.removeItem("dataExpiracaoSessao");
          this._baseService.limpaStorage();
          this._loginForgeRockService.limpaStorage();

          this._router.navigate(["/"]);

          throw error;
        })
      )
      .subscribe((result) => {
        this.error = "";
        if (result.IsValid) {
          this.logado = true;
          this.login = new Login();
          let usuario = this._appService.UsuarioLogado;
          this.logado = true;

          this.PopularUsuario();

          let descricaoPerfis = [
            "Cliente Master",
            "Cliente Tracking",
            "Cliente Booking e Rastreamento",
            "Cliente Financeiro Operacional",
          ];

          if (
            descricaoPerfis.some(
              (perfil) => perfil === usuario.perfil.descricao
            )
          )
            this._router.navigate(["/rastreamento"]);
          else {
            this._router.navigate(["/home"]);
            // setTimeout(() => {
            //   window.location.reload();
            // }, 200);
          }

          this._appService.tokenExpirado = false;
          this.loadingRouter = false;
        } else {
          this.error =
            result.Erros[0].ErrorCode + " " + result.Erros[0].Message;
          this._appService.tokenExpirado = true;
          this.loadingRouter = false;
          this.logado = false;

          var erroMsg =
            "Você não tem cadastro no Portal Cabotagem. Deseja se cadastrar agora?";
          var tituloErro = "Usuário não localizado!";

          if (result.Erros[0].Message.includes("não autorizado")) {
            tituloErro = "Usuário não autorizado!";
            erroMsg = result.Erros[0].Message;

            swal({
              title: tituloErro,
              text: erroMsg,
              type: "warning",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "OK",
            }).then(
              (success) => {
                this._baseService.limpaStorage();
                this.redirectToRegisterForge();

                ocultarControleMenu();
                ocultarSwalOverlay();
              },
              (dismiss) => {
                removeSwal();
              }
            );
          } else {
            swal({
              title: tituloErro,
              text: erroMsg,
              type: "question",
              showCancelButton: true,
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
            }).then(
              (success) => {
                this._baseService.limpaStorage();
                this.redirectToRegisterForge();

                ocultarControleMenu();
                ocultarSwalOverlay();
              },
              (dismiss) => {
                removeSwal();
              }
            );
          }
        }
      });
  }

  private loadGoogleConfig(cfgGoogle: GoogleConfig) {
    var cfgAnalytics = cfgGoogle.Analytics;
    this._appService.configAnalytics = cfgAnalytics;
    this._appService.configMaps = cfgGoogle.Maps;
    //localStorage.setItem('google-config', JSON.stringify(cfgGoogle));
    loadMaps(cfgGoogle.Maps.ApiKey);

    if (cfgAnalytics.Habilitado) loadAnalytics(cfgAnalytics.GtagID);
  }

  ngOnInit(): void {
    this.labelGrupo = this._appService.getLocalStorage("labelGrupo"); //this._appService.getLocalStorage('cnpjGrupos');
    if (this.labelGrupo) this.gruposSelecionados = this.getGrupoStorage();

    this.cssClassAdmin = this.eAdmin ? "peril-admin" : "";
    this._router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouter = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouter = false;
      }
    });
  }

  PopularUsuario(): void {
    if (this._appService.UsuarioLogado != null) {
      this.username = this._appService.UsuarioLogado.nome;
      this.logado = true;
      this._analyticsService.RegistrarOperacao(
        AnalyticsTela.Home,
        AnalyticsOperacao.LoginRealizado
      );
    } else {
      this.username = "";
      this.logado = false;
      this.labelGrupo = "";
      //this._appService.tokenExpirado = true;
    }
  }

  public showModal(): void {
    this._authenticationService.showModal();
  }

  public redirectToLoginForge(): void {
    if (
      localStorage.getItem(
        `al.${this._configService._config.forgeRockAuth.urlAuth}.alianca001.id_token`
      ) != undefined &&
      localStorage.getItem(
        `al.${this._configService._config.forgeRockAuth.urlAuth}.alianca001.id_token`
      ) != null
    ) {
      this._loginForgeRockService.logout();
    } else {
      this._loginForgeRockService.loginRedirect();
    }
  }

  public logout(): void {
    removeSwal();

    swal({
      title: "Sair",
      text: "Deseja realmente sair do sistema?",
      type: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(
      (success) => {
        this.labelGrupo = "";
        this.gruposSelecionados = [];
        this._authenticationService.logout();
        this._appService.tokenExpirado = false;
        this._loginForgeRockService.logout();
        this._router.navigate(["/home"]);

        ocultarControleMenu();
        ocultarSwalOverlay();
      },
      (dismiss) => {
        removeSwal();
      }
    );
  }

  ObterGrupos() {
    if (!this._appService.UsuarioLogado || this.grupos.length > 0) return;

    this.loadingGrupos = true;
    var request: GrupoEconomicoRequest = new GrupoEconomicoRequest();
    request.idUsuario = this._appService.UsuarioLogado.usuarioIDeCargo;

    this._grupoService.getGruposEconomicos(request).subscribe((m) => {
      this.grupos = m;

      if (this.grupos != null) {
        this.comboGrupoEconomico.options = [];
        this.grupos.forEach((grp) => {
          this.comboGrupoEconomico.options.push({
            id: grp.idGrupo,
            name: grp.descGrupo,
          });
        });
      } // em caso de erro (ex.: sessão expirada)
      else this.closeModal.nativeElement.click();
      this.loadingGrupos = false;
      setTimeout(() => {
        this.gruposSelecionados = this.getGrupoStorage();
      }, 10);
    });
  }

  ocultarMensagem() {
    this.mensagemErro = "";
  }

  aplicarGrupo() {
    var cnpjs = "";
    var label = "";
    var total = 0;
    if (this.gruposSelecionados) {
      this.gruposSelecionados.forEach((g) => {
        var grp: GrupoEconomico = this.grupos.find((f) => f.idGrupo == g);
        cnpjs += grp.listaCNPJCliente + ";";
        if (label == "") {
          label =
            grp.descGrupo.indexOf(" ") > 0
              ? ": " + grp.descGrupo.substring(0, grp.descGrupo.indexOf(" "))
              : ": " + grp.descGrupo;
        }
        total += 1;
      });
    }
    if (label && total > 1) label += " + " + (total - 1).toString();

    if (cnpjs) cnpjs = cnpjs.substring(0, cnpjs.length - 1);

    if (cnpjs.length > 4000) {
      this.mensagemErro =
        "Muitos CNPJs selecionados. Selecionar no máximo 255 CNPJs.";
      return;
    }

    this.labelGrupo = label;
    label = "";
    this._appService.setLocalStorage("labelGrupo", this.labelGrupo);
    this._appService.setLocalStorage("cnpjGrupos", cnpjs);
    this._appService.setLocalStorageJson(
      "idsGruposSelecionados",
      this.gruposSelecionados
    );
    this._grupoService.grupoEconomicoSelecionadoEvent.emit(cnpjs);
    this.closeModal.nativeElement.click();
  }

  cancelarGrupo() {
    this.gruposSelecionados = this.getGrupoStorage();
    this.closeModal.nativeElement.click();
  }

  getGrupoStorage(): any {
    return this._appService.getLocalStorageJson("idsGruposSelecionados");
  }

  navigateToVendorSite(event: Event): void {
    event.preventDefault(); // Prevent default anchor behavior
    const url = this._appService.configInfo.novoSiteVendor;

    if (url) {
      window.location.href = url; // Redirect to the specified URL
    } else {
      console.error("Vendor login URL is not defined");
    }
  }

  navigateToRegisterVendorSite(event: Event): void {
    event.preventDefault(); // Prevent default anchor behavior
    const url = this._appService.configInfo.novoSiteVendorRegistrar;

    if (url) {
      window.location.href = url; // Redirect to the specified URL
    } else {
      console.error("Vendor register URL is not defined");
    }
  }

  public redirectToRegisterForge(event?: Event): void {
    event?.preventDefault(); // Prevent default anchor behavior
    const url = this._appService.configInfo.clientRegisterUrl;

    if (url) {
      window.location.href = url; // Redirect to the specified URL
    } else {
      console.error("Client register URL is not defined");
    }
  }

  redirectToNovoSiteCotacao(event: Event): void {
    event.preventDefault(); // Prevent default anchor behavior
    const url = this._appService.configInfo.novoSiteCotacao;

    if (url) {
      window.location.href = url; // Redirect to the specified URL
    } else {
      console.error("Cotação register URL is not defined");
    }
  }
}
