import { Injectable } from '@angular/core';

import { FiltrosLaterais } from "app/_models/filtros-laterais";

@Injectable({
    providedIn: 'root'
})
export class FiltrosLateraisService {

  constructor() { }

  public filtrarDados(dados: any, filtros: FiltrosLaterais): any {
    var modal = "|" + (filtros.flagModalPoPo ? "PO/PO" : "") + "|"
                            + (filtros.flagModalPoPa ? "PO/PA" : "") + "|"
                            + (filtros.flagModalPaPa ? "PA/PA" : "") + "|"
                            + (filtros.flagModalPaPo ? "PA/PO" : "") + "|";

    var container = "|" + (filtros.flagTpContainerDryDC_HC ? "HC|DC" : "") + "|"
                    + (filtros.flagTpContainerEspecial ? "OT|OH|FR|FH|TK" : "") + "|"
                    + (filtros.flagTpContainerRH ? "RH|RF" : "") + "|";
	return dados.filter
		(x =>
		 x.tamanhoContainer == 
			(
				((filtros.tamanho20Pes && filtros.tamanho40Pes) || (!filtros.tamanho20Pes && !filtros.tamanho40Pes)) ? 
					x.tamanhoContainer : (filtros.tamanho20Pes && !filtros.tamanho40Pes ? 20 : 40)
			)
			&& (!filtros.status || x.Status.trim().toLowerCase() == filtros.status.trim().toLowerCase())			
			&& ((modal.indexOf("|" + x.modal + "|") >= 0) || modal == "|||||")
			&& (container == "||||" || (x.tipoDeContainer != null && 
										x.tipoDeContainer.Desc_resumida != null && 
										container.indexOf("|" + x.tipoDeContainer.Desc_resumida.substr(3, 2) + "|") != -1))
			&& (( x.flagCargaOver == (filtros.flagCargaOver ? "S" : x.flagCargaOver)
			&& x.flagCargaPerigosa == (filtros.flagCargaPerigosa ? "S" : x.flagCargaPerigosa)
			&& x.flagCargaRefrigerada == (filtros.flagCargaRefrigerada ? "S" : x.flagCargaRefrigerada) )||  
			  (filtros.flagCargaSeca &&
				(
					x.flagCargaOver == "N"
					&& x.flagCargaOver == x.flagCargaRefrigerada
					&& x.flagCargaOver == x.flagCargaPerigosa
				)
			))
		);
  }
}
