import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'app/shared/base.component';
import { ArquivoUpload } from 'app/_models/arquivo-upload';
import { AlteracoesArquivoUpload } from 'app/_models/alteracoes-arquivo-upload';
import { AppService } from 'app/_services/app.service';

@Component({
  selector: 'uploadarquivos',
  templateUrl: './uploadarquivos.component.html',
  styleUrls: ['./uploadarquivos.component.scss']
})
export class UploadArquivosComponent extends BaseComponent implements OnInit {
  
    arquivos: ArquivoUpload[] = [];
    private arquivosParaExcluir: ArquivoUpload[] = [];
    errors: Array<string> = [];
    dragAreaClass: string = 'clearfix';
    @Input() qtdeMaximaUpload: number;
    @Input() titulo: string;
    @Input() subTitulo: string
    @Output() salvarAlteracoes = new EventEmitter<AlteracoesArquivoUpload>();

  constructor(private appService: AppService) {
    super();
   }

  ngOnInit() {
  }

  public get maxFiles(): number{
    return (this.qtdeMaximaUpload) ? this.qtdeMaximaUpload : this.appService.configUpload.quantidadeMaxArquivos;
  }

  public get maxSize(): number{
    return this.appService.configUpload.limiteTamanhoMB;
  }

  public get fileExt(): string{
    return this.appService.configUpload.extensoesSuportadas;
  }

  public configuraArquivos(arquivos: ArquivoUpload[]) {
    if (!arquivos)
      return;
    arquivos.forEach(arquivo => {
      arquivo.uploadJaRealizado = true;
    });

    this.arquivos = arquivos;
  }

  public obterAlteracoesArquivos(): AlteracoesArquivoUpload{
    var alteracoes = new AlteracoesArquivoUpload();
    alteracoes.arquivosParaUpload = this.arquivos.filter(a => !a.uploadJaRealizado);
    alteracoes.arquivosParaExcluir = this.arquivosParaExcluir;
    return alteracoes;
  }

  public marcarAlteracoesComoSalvas(){
    this.arquivosParaExcluir = [];
    this.arquivos.forEach(arquivo => {
      arquivo.uploadJaRealizado = true;
    });
  }

  private salvarAlteracoesClick(){
    this.salvarAlteracoes.emit(this.obterAlteracoesArquivos());
  }

  private get habilitaBotaoSalvar(): boolean {
    return (this.arquivosParaExcluir.length > 0) ||
           ((this.arquivos.filter(a => !a.uploadJaRealizado).length > 0) &&
            (this.arquivos.filter(a => a.uploadJaRealizado).length > 0));
  }
  
  onFileChange(event){
    this.adicionaArquivosValidos(event.target.files);
  }

  private get cssDropArea(): string {
    return (this.arquivos.length < this.maxFiles ?
      "droparea" : "droparea-error") + " clearfix";
  }

  @HostListener('dragover', ['$event']) onDragOver(event) {
       this.dragAreaClass = this.cssDropArea;
       event.preventDefault();
  }
   
  @HostListener('dragenter', ['$event']) onDragEnter(event) {
       this.dragAreaClass = this.cssDropArea;
       event.preventDefault();
  }
  
  @HostListener('dragend', ['$event']) onDragEnd(event) {
       this.dragAreaClass = "clearfix";
       event.preventDefault();
  }
   
  @HostListener('dragleave', ['$event']) onDragLeave(event) {
       this.dragAreaClass = "clearfix";
       event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event) {   
       this.dragAreaClass = "clearfix";           
       event.preventDefault();
       event.stopPropagation();
       this.adicionaArquivosValidos(event.dataTransfer.files);
  }

  limpaErros(){
    this.errors = [];
  }

  removerArquivo(index: number){
    this.limpaErros();
    var arquivo = this.arquivos.splice(index, 1)[0];
    if (arquivo.uploadJaRealizado)
      this.arquivosParaExcluir.push(arquivo);
  }
   
  private adicionaArquivosValidos(files: FileList){
    this.limpaErros();
    for (var i = 0; i < files.length; i++) {
      if (this.arquivos.length >= this.maxFiles) {
        this.errors.push("Número máximo de arquivos atingido (" + this.maxFiles + "). Arquivo não adicionado: " + files[i].name);
        continue;
      } 
      if (!this.isExtensaoValida(files[i])){
        this.errors.push("Extensão não suportada: " + files[i].name);
        continue;
      }
      if (!this.isTamanhoValido(files[i])){
        this.errors.push("Tamanho do arquivo excede o limite máximo (" + this.maxSize + "mb): " + files[i].name);
        continue;
      }
      if (this.arquivos.find(a => a.arquivo.name == files[i].name)){
        this.errors.push("Arquivo duplicado não adicionado: " + files[i].name);
        continue;
      }
      
      var arquivo = new ArquivoUpload();
      arquivo.arquivo = files[i];
      this.arquivos.push(arquivo);
    }
  }

  private isExtensaoValida(file): boolean {
    // Make array of file extensions
      var extensions = (this.fileExt.split(','))
                      .map(function (x) { return x.toLocaleUpperCase().trim() });

      // Get file extension
      var ext = file.name.toUpperCase().split('.').pop() || file.name;

      // Check the extension exists
      return extensions.includes(ext);
  }
  
  
  private isTamanhoValido(file): boolean {
         var fileSizeinMB = file.size / (1024 * 1000);
         var size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
         return (size <= this.maxSize)
  }
}
