import { PaginacaoService } from './paginacao.service';
import { Component } from '@angular/core';

@Component({
  selector: 'paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.scss']
})
export class PaginacaoComponent {



  constructor(private _paginacaoService: PaginacaoService) { }

  pager: any = {};
  pagedItems: any[];

  allItems: any[];

  private pageSize: number = 10

  public setAllData(dados: any[]) {
    this.allItems = dados;
    this.setPageSize(this.pageSize);
    this.setPage(1);
  }

  public setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.setPage(1);
  }

  public setPage(page: number) {
    if (page < 1 || (this.pager && page > this.pager.totalPages && this.pager.totalPages > 0)) {
      return;
    }

    this.pager = this._paginacaoService.getPager(this.allItems.length, page, this.pageSize);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }



}
