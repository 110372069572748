import { Injectable, EventEmitter } from '@angular/core';
//import { Subject, Observable, Subscription } from 'rxjs/Rx';
import { ValidationError } from './../_models/validation-error';
import { LogService } from './log.service';
import { Operacao } from '../_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    public tela: string;

    constructor(private _logService: LogService) { }

    redirect: any = {
        show: false,
        tela: '',
        type: '',
        mensagem: '',
        mensagemArray: []
    };

    emitirMensagem = new EventEmitter<any>();
    private timeout = 80000;

    clearMessage(){
        this.addMessage(null, null, null);
    }

    addMessage(tela: string, msg: string, type: string = 'default', func: LogFrontFuncionalidade = LogFrontFuncionalidade.Invalida,
               operacao: Operacao = Operacao.Invalida, dados: any = null) {
        if (type == "error") type = "danger";

        this.emitirMensagem.emit({ tela: tela, mensagem: msg, tipo: type });

        if (msg != '' && msg != undefined && type != "success")
            this._logService.logFront(dados, func, msg, operacao, tela);
    }

    addMessageArray(tela: string, msg: string[], type: string = 'default', func: LogFrontFuncionalidade = LogFrontFuncionalidade.Invalida,
                    operacao: Operacao = Operacao.Invalida, dados: any = null) {
        if (type == "error") type = "danger";

        this.emitirMensagem.emit({ tela: tela, mensagem: msg, tipo: type });

        if (msg != undefined && msg.length > 0 && type != "success")
            this._logService.logFront(dados, func, msg.join(';'), operacao, tela);
    }

    addMessageValidationError(tela: string, msgs: ValidationError[], type: string = 'default', func: LogFrontFuncionalidade = LogFrontFuncionalidade.Invalida,
                  operacao: Operacao = Operacao.Invalida, dados: any = null) {
        if (type == "error") type = "danger";

        let msgString: string[] = [];
        msgs.forEach(x => msgString.push(x.Message));

        this.emitirMensagem.emit({ tela: tela, mensagem: msgString, tipo: type });

        if (msgString != undefined && msgString.length > 0 && type != "success")
            this._logService.logFront(dados, func, msgString.join(';'), operacao, tela);
    }

    addMessageRedirect(tela: string, msg: string, type: string = 'default', func: LogFrontFuncionalidade = LogFrontFuncionalidade.Invalida,
                       operacao: Operacao = Operacao.Invalida, dados: any = null) {
        if (type == "error") type = "danger";

        this.redirect.show = true;
        this.redirect.type = type;
        this.redirect.mensagem = msg;
        this.redirect.tela = tela;

        if (msg != '' && msg != undefined && type != "success")
            this._logService.logFront(dados, func, msg, operacao, tela);
    }

    addMessageRedirectArray(tela: string, msg: string[], type: string = 'default', func: LogFrontFuncionalidade = LogFrontFuncionalidade.Invalida,
               operacao: Operacao = Operacao.Invalida, dados: any = null) {
        if (type == "error") type = "danger";

        this.redirect.show = true;
        this.redirect.type = type;
        this.redirect.mensagem = msg;
        this.redirect.titulo = tela;

        if (msg != undefined && msg.length > 0 && type != "success")
            this._logService.logFront(dados, func, msg.join(';'), operacao, tela);
    }

    getMessageRedirect(): any {
        var retorno: any = {
            show: false,
            tela: '',
            type: '',
            mensagem: '',
            mensagemArray: []
        };

        if (this.redirect == undefined)
            return null;

        retorno.show = this.redirect.show;
        retorno.titulo = this.redirect.tela;
        retorno.type = this.redirect.type;

        if (this.redirect.mensagem instanceof Array)
            retorno.mensagemArray = this.redirect.mensagemArray;
        else
            retorno.mensagem = this.redirect.mensagem;

        this.redirect.show = false;
        this.redirect.type = '';
        this.redirect.mensagem = '';
        this.redirect.mensagemArray = [];
        this.redirect.tela = '';

        return retorno;
    }
}
