import { Component, Input, OnInit, ViewChild, NgModule } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MessageService } from "../../../_services/message.service";
import { MessageComponent } from "../../../shared/message/message.component";
import { DataService } from "../../../_services/data.service";
import { SharedService } from "../../../_services/shared.service";
import { BaseComponent } from "../../../shared/base.component";



import { Observable } from "rxjs";
import { Subscription } from "rxjs";


//DATEPICKER
import { DatepickerService } from "app/_services/datepicker.service";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DateExtensions } from 'app/shared/extensions/extensions.module';


// MAERSK
import { ListItem } from "../../../_models/listItem";
import { LogFrontFuncionalidade } from "app/_enums/log-front-funcionalidade";
import { Operacao } from "app/_enums/operacao";
import { SporadicTaxService } from "../../../_services/sporadic-tax.service";
import { SporadicTax } from "../../../_models/sporadic-tax";
import { IDateModel } from '../../../shared/ng2-datepicker/ng2-datepicker.component';
import { TipoTaxaEsporadica } from "app/_models/tipo-taxa-esporadica";
import { TipoTaxaEsporadicaService } from "app/_services/tipo-taxa-esporadica.service";
import { BaseService } from "app/_services/base.service";


@Component({
  selector: "tipo-taxa-esporadica-detalhe",
  templateUrl: "./tipo-taxa-esporadica-detalhe.component.html",
  styleUrls: ["./tipo-taxa-esporadica-detalhe.component.scss"],
})
export class TipoTaxaEsporadicaDetalheComponent extends BaseComponent
  implements OnInit {
  inscricaoGet: Subscription;
  @ViewChild(MessageComponent, { static: true }) mensagem: MessageComponent;

  titulo: string = "Nova Tipo Taxa Esporádica";
  // //listItem: ListItem;
  isNovaTaxa: boolean = true;

  tipoTaxaEsporadica: TipoTaxaEsporadica = new TipoTaxaEsporadica();

  loading: boolean = false;
  id: number = 0;

    constructor(
    private baseService: BaseService,
    private _dataService: DataService,
    private _route: ActivatedRoute,
    private _msgService: MessageService,
    private _sharedService: SharedService,
    private _router: Router,
    private _datepickerService: DatepickerService,
    private _tipoTaxaEsporadicaService: TipoTaxaEsporadicaService,
    private route: ActivatedRoute
  ) {
    super();

    this._msgService.emitirMensagem.subscribe((msg) => {
      this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
    });


    this.ObterDataFields();

  }

  ngOnInit(): void {

    this.inscricaoGet = this._route.params.subscribe((params: any) => {
      this.id = params["id"];
      if (this.id) {
        this.titulo = "Editar Tipo de Taxa Esporádica";
        this.loading = true;

        this.isNovaTaxa = false;
        this.getTipoTaxaEsporadica(this.id);

      }
      else {
        this.titulo = "Nova Tipo de Taxa Esporádica";
        this.isNovaTaxa = true;
      }
    });
  }


  // Metodos auxiliares
  ObterDataFields() {

  }

  private ObterData(dateModel: DateModel, dateOffset: number) {
    return dateModel != undefined
      ? this._datepickerService.ObtemData(dateModel)
      : this._sharedService.addDays(new Date(), dateOffset);
  }

  onSaveTipoTaxaEsporadica() {
    this._msgService.clearMessage();

    this.tipoTaxaEsporadica.isEnabled = true;
    this.tipoTaxaEsporadica.createdOn = null;
    this.tipoTaxaEsporadica.updatedOn = null;

    if(this.tipoTaxaEsporadica.id == 0){
      this.tipoTaxaEsporadica.createdBy = this.baseService.UsuarioLogado.nome;
    }
    else
    {
      this.tipoTaxaEsporadica.updatedBy = this.baseService.UsuarioLogado.nome;
    }

    this._tipoTaxaEsporadicaService.salvar(this.tipoTaxaEsporadica).subscribe((result) => {
      this.loading = false;

      if (result.IsValid) {
        this._router.navigate(["tipo-taxa-esporadica"]);
        this._msgService.addMessageRedirect(
          "Tipo Taxa Esporádica",
          result.Message,
          "success"
        );
      } else {
        var msgErr: string[] = [];
        result.Erros.forEach((element) => msgErr.push(element.Message));
        this._msgService.addMessageArray(
          "Tipo Taxa Esporádica",
          msgErr,
          "error",
          LogFrontFuncionalidade.TipoTaxaEsporadica,
          Operacao.Alteracao,
          this.tipoTaxaEsporadica
        );
        this.loading = false;
      }
    });
  }


  formatDate(data: Date): string
  {

    let dataTMP = data.toString().substring(0,10);

    let day: string = dataTMP.substr(8, 2);
    let month: string  = dataTMP.substr(5,2);
    let year: string  = dataTMP.substr(0,4);

    let formattedDate: string = day + "/" + month.toString() + "/" + year.toString();
    dataTMP = formattedDate;
    return dataTMP;

  }


  getTipoTaxaEsporadica(id: number) {
    this._tipoTaxaEsporadicaService
      .getTipoTaxaEsporadica(id)
      .subscribe((tipoTaxaEsporadica) => {

        this.tipoTaxaEsporadica = tipoTaxaEsporadica;

        this.loading = false;

      });
  }

}
