import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { FiltrosLateraisService } from '../../_services/filtros-laterais.service';
import { PaginacaoComponent } from '../../shared/paginacao/paginacao.component';
import { FiltrosLaterais } from './../../_models/filtros-laterais';
import { Co2Total } from 'app/_models/co2-total';
import { RastreamentoService } from 'app/_services/rastreamento.service';
import { Co2TotalRequest } from 'app/_models/co2-total-request';
import { AppService } from 'app/_services/app.service';
import { BaseService } from 'app/_services/base.service';

@Component({
  selector: 'filtroslaterais',
  templateUrl: './filtros-laterais.component.html',
  styleUrls: ['./filtros-laterais.component.scss']
})
export class FiltrosLateraisComponent implements OnInit {

  constructor(
    private _filtroslateraisService: FiltrosLateraisService,
    private _trackingService: RastreamentoService,
    private _appService: AppService,
    private _baseService: BaseService) { }

  ngOnInit(): void {
  }

  loadingCo2: boolean = false;
  @Input() dados: any;
  @Input() pageSize: number;
  @Input() consulta: FiltrosLaterais;
  @Input() paginacao: PaginacaoComponent;
  @Input() filtrosLateraisAtivos: FiltrosLaterais;
  @Input() co2Total: Co2Total;
  @Output() filtroAplicado: EventEmitter<any> = new EventEmitter();

  public filtrar() {
    let listaFiltrada = this._filtroslateraisService.filtrarDados(this.dados, this.consulta);

    this.paginacao.setAllData(listaFiltrada);
    this.paginacao.setPageSize(this.pageSize);
    this.paginacao.setPage(1);

    this.filtroAplicado.emit(listaFiltrada);
  }

  GetColorCo2(){
    return this.co2Total && this.co2Total.indicEcofrete? "" : "red";
  }

  public setPageSize(value: number) {
    this.pageSize = value;

    // Defeito #5638: Meus bookings - Paginação é perdida quando muda qtde de
    // registros por página (resolução de problema)
    // this.paginacao.setAllData(this.dados);
    this.paginacao.setPageSize(this.pageSize);
    this.paginacao.setPage(1);
  }

  openEcofretePage(){
    this._baseService.OpenNewTab("https://www.alianca.com.br/ecofrete")
  }

  GetLabelCo2() {
    return this.co2Total && this.co2Total.indicEcofrete ? "Compensação" : "Emissão";
  }

  GetCo2(){
    return this.co2Total && this.co2Total.co2 != undefined ?  parseFloat(""+this.co2Total.co2).toFixed(2).replace('.', ',') : (0).toFixed(2);
  }
}
