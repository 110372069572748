import { Component, OnInit, ViewChild } from '@angular/core';

import { Assunto } from './../../_models/assunto';
import { FaleConosco } from './../../_models/faleconosco';

import { SharedService } from './../../_services/shared.service';
import { CaixaEntradaService } from './../../_services/caixa-entrada.service';

import { BaseComponent } from './../../shared/base.component';
import { PaginacaoComponent } from './../../shared/paginacao/paginacao.component';
import { CaixaEntradaDetalheComponent } from './caixa-entrada-detalhe/caixa-entrada-detalhe.component';

@Component({
  selector: 'caixa-entrada',
  templateUrl: './caixa-entrada.component.html',
  styleUrls: ['./caixa-entrada.component.scss']
})
export class CaixaEntradaComponent extends BaseComponent implements OnInit {

  emails: FaleConosco[] = [];
  filtroNome: string = "";
  filtroAssunto: number = 0;
  assuntos: Assunto[];
  loading: boolean = false;
  pageSize: number = 20;

  @ViewChild(PaginacaoComponent, { static: true} ) paginacao: PaginacaoComponent;
  @ViewChild(CaixaEntradaDetalheComponent , { static: true}) detalhe: CaixaEntradaDetalheComponent;

  constructor(private _caixaEntradaService: CaixaEntradaService,
              private _sharedService: SharedService) { 
    super();
  }

  ngOnInit() {
    this.ObtemAssuntos();
    this.BuscaEmails("");
  }

  BuscaEmails(filtro: string) {
    this.loading = true;
    this._caixaEntradaService.getEmails(filtro)
      .subscribe(p => {
        this.emails = p;
        this.setOrder("Data", false);
        this.loading = false;
      });
  }

  ObtemAssuntos() {
    this.loading = true;
    this._caixaEntradaService.getAssuntos()
      .subscribe(p => {
        this.assuntos = p;
        this.loading = false;
      });
  }

  FiltrarAssunto(selecionado: any) {
    this.filtroAssunto = selecionado;
    this.BuscaEmails(this.filtroAssunto + ';' + this.filtroNome);
  }

  FiltrarNome() {
    this.BuscaEmails(this.filtroAssunto + ';' + this.filtroNome);
  }

  setPageSize(value: number) {
    this.pageSize = value;
    this.paginacao.setAllData(this.emails);
    this.paginacao.setPageSize(this.pageSize);
    this.paginacao.setPage(1);
  }

  setOrder(value: string, asc: boolean = true) {
    var array = this.emails.sort(function (a, b) {
      if (asc) {
        if (!value) {
          return a > b ? 1 : -1;
        }
        return a[value] > b[value] ? 1 : -1;
      }
      else {
        if (!value) {
          return a < b ? 1 : -1;
        }
        return a[value] < b[value] ? 1 : -1;
      }
    });

    this.emails = array;
    this.paginacao.setAllData(this.emails);
    this.paginacao.setPageSize(this.pageSize);
    this.paginacao.setPage(1);
  }

  public showModal(item: FaleConosco): void {
    this.detalhe.email = item;
    this._caixaEntradaService.showModal();
  }


  
}
