import { NgModule } from '@angular/core';
import { SlimScrollDirective } from './slimscroll.directive';

export * from './slimscroll-options.class';

@NgModule({
  declarations: [
    SlimScrollDirective
  ],
  exports: [
    SlimScrollDirective
  ]
})
export class SlimScrollModule { }