import { Component, OnInit, Input } from '@angular/core';

import { Incidente } from './../../../_models/incidente';

@Component({
  selector: 'incidente',
  templateUrl: './incidente.component.html',
  styleUrls: ['./incidente.component.scss']
})
export class IncidenteComponent implements OnInit {

  @Input() incidente: Incidente;
  @Input() index: number;
  
  constructor() { }

  ngOnInit() {
  }

  limpaJustificativa() {
    this.incidente.checked = !this.incidente.checked;
    this.incidente.justificativa = (!this.incidente.checked) ? '' : this.incidente.justificativa;
  }
}
