import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { BaseComponent } from '../base.component';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent extends BaseComponent implements OnInit {

  tipo: string = "";
  textos: string[];
  classesCss: {};
  ocultarMensagem: boolean = true;
  private currentTimeout: any;
  @ViewChild('painelMensagem', {static: true}) private pnMsg: ElementRef;

  constructor() {
    super();
   }

  ngOnInit() {
  }

  public mostrarMensagem(tipoMensagem: string, mensagem: string): void {
    var arr = (mensagem) ? [mensagem] : null;
    this.mostrarMensagemInterno(tipoMensagem, arr, 10000);
  }

  public mostrarMensagemArray(tipoMensagem: string, mensagens: string[]): void {
    this.mostrarMensagemInterno(tipoMensagem, mensagens, 3000);
  }

  private mostrarMensagemInterno(tipoMensagem: string, mensagens: string[], timeout: number){
    this.ocultarMensagem = false;
    this.textos = mensagens;
    this.tipo = tipoMensagem;

    this.montarClasses(this.tipo);
     this.setMessageTimeout(timeout);

    if (this.textos && this.textos.length > 0)
      setTimeout(() => { this.pnMsg.nativeElement.scrollIntoView(); });
  }

  private montarClasses(tipo: string): void {
    this.classesCss = { 'alert': true };
    this.classesCss['alert alert-dismissible alert-' + tipo] = true; // alert-success
  }

  public Fechar(): void{
    this.ocultarMensagem = true;
  }

  private setMessageTimeout(timeout: number){
     if ((this.tipo == 'danger') || (this.tipo == 'error') || (this.tipo == ''))
      return;

    if (this.currentTimeout)
      clearTimeout(this.currentTimeout);

    this.currentTimeout = 
      setTimeout(() => {
        this.textos = null; 
      }, timeout); 
  }
}
