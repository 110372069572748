import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmModule } from './../../shared/confirm/confirm.module';

import { PerfilRoutingModule } from './perfil.routing.module';
import { PerfilComponent } from './perfil.component';
import { PerfilDetalheComponent } from './perfil-detalhe/perfil-detalhe.component';
import { PerfilService } from './../../_services/perfil.service';

import { FuncionalidadeService } from './../../_services/funcionalidade.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        PerfilRoutingModule,
        ModalModule.forRoot(),
        ConfirmModule,
        SharedModule
    ],
    exports: [],
    declarations: [
        PerfilComponent,
        PerfilDetalheComponent
    ],
    providers: [
        PerfilService,
        FuncionalidadeService
    ]
})
export class PerfilModule { }
