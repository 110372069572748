
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmModule } from './../../shared/confirm/confirm.module';
import { SharedModule } from './../../shared/shared.module';

import { IcmsModuleRoutingModule } from './icms.routing.module';
import { IcmsComponent } from './icms.component';
import { UsuarioService } from './../../_services/usuario.service';

import { FuncionalidadeService } from './../../_services/funcionalidade.service';
import { MascaraModule } from "app/shared/mascara/mascara.module";
import { PerfilService } from 'app/_services/perfil.service';
import { DatePickerModule } from 'app/shared/ng2-datepicker/ng2-datepicker.module';
import { HttpClientModule } from '@angular/common/http';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { RouterModule } from '@angular/router';




@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        IcmsModuleRoutingModule,
        ModalModule.forRoot(),
        ConfirmModule,
        SharedModule,
        MascaraModule,
        DatePickerModule,
        CurrencyMaskModule
    ],
    exports: [],
    declarations: [
      IcmsComponent
    ],
    providers: [
        PerfilService,
        UsuarioService,
        FuncionalidadeService
    ]
})
export class IcmsModule { }
