import { BookingComponent } from './booking.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SolicitarBookingComponent } from "app/cliente/booking/solicitar-booking/solicitar-booking.component";
import { AuthGuard } from 'app/_guards/auth.guard';


const bookingRoutes: Routes = [
    // { path: '', component: BookingComponent, canActivate: [AuthGuard] },
    // { path: 'solicitar', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    // { path: 'solicitar/:id', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    // { path: 'solicitar/programacao/', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    // { path: 'solicitar/:id/:duplicar', component: SolicitarBookingComponent, canActivate: [AuthGuard] },
    // { path: 'solicitar/cotacao/:numProposta/:idTrecho/:cnpjCli/:ieCli', component: SolicitarBookingComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(bookingRoutes)],
    exports: [RouterModule]
})
export class BookingRoutingModule {}