import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { LogService } from 'app/_services/log.service';

import { Operacao } from 'app/_enums/operacao';
import { LogFrontFuncionalidade } from 'app/_enums/log-front-funcionalidade';

@Component({
  selector: 'mensagem-modal',
  templateUrl: './mensagem-modal.component.html',
  styleUrls: ['./mensagem-modal.component.scss']
})
export class MensagemModalComponent implements OnInit {

  @Input() mensagemErro: string;
  @ViewChild('topo', {static: true}) private topo: ElementRef;

  constructor(private _logService: LogService) { }

  ngOnInit() {
  }

  ocultarMensagem() {
    this.mensagemErro = ''; //this.exibeMensagemErro('');
  }

  exibeMensagemErro(msg: string, func: LogFrontFuncionalidade, oper: Operacao, tela: string) {
    this.mensagemErro = msg;

    if (msg && msg != '' && msg != undefined)
      this._logService.logFront(null, LogFrontFuncionalidade.OrdemDeServico, msg, Operacao.Alteracao, tela);

    if (this.mensagemErro && this.mensagemErro.length > 0)
      setTimeout(() => { this.topo.nativeElement.scrollIntoView(); });
  }
}
