import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetSenhaComponent } from './reset-senha.component';

const resetSenhaRoutes: Routes = [
    { path: '', component: ResetSenhaComponent }
];

@NgModule({
    imports: [RouterModule.forChild(resetSenhaRoutes)],
    exports: [RouterModule]
})
export class ResetSenhaRoutingModule {}