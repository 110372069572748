import { Injectable } from "@angular/core";
import { UserAgentPublicApp } from "@maersk-global/iam-al";
import { environment } from "environments/environment";
import { IToken } from "app/_models/types";
import jwtDecode from "jwt-decode";
import * as dayjs from "dayjs";
import { ConfigService } from "./config.service";

Injectable({
  providedIn: "root",
});

export class LoginForgeRockService {
  constructor() {}

  _configService: ConfigService;

  SetConfig(conf: ConfigService) {
    this._configService = conf;
  }

  loginRedirect(redirectUri?: string) {
    try {
      const instance = new UserAgentPublicApp({
        authority: this._configService._config.forgeRockAuth.urlAuth,
        client_id: this._configService._config.forgeRockAuth.client_id,
        storage: {
          type: "localStorage",
        },
        redirect_uri: this._configService._config.forgeRockAuth.urlCallBack,
        post_logout_uri: this._configService._config.forgeRockAuth.urlCallBack,
      });

      if (!!redirectUri) {
        try {
          localStorage.setItem(`redirect_uri`, redirectUri);
        } catch (e) {}
      }

      instance.loginRedirect({
        scopes: ["openid", "profile", "email", "customer_code.any", "roles"],
        response_type: "code",
      });
    } catch (err) {}
  }

  getTokenRaw(): any | null {
    try {
      const instance = new UserAgentPublicApp({
        authority: this._configService._config.forgeRockAuth.urlAuth,
        client_id: this._configService._config.forgeRockAuth.client_id,
        storage: {
          type: "localStorage",
        },
        redirect_uri: this._configService._config.forgeRockAuth.urlCallBack,
        post_logout_uri: this._configService._config.forgeRockAuth.urlCallBack,
      });

      const token = instance?.storage?.id_token;

      if (!token) {
        return null;
      }

      const decodedToken: IToken = jwtDecode(token);
      const userEmail = decodedToken.email;
      const expiration = decodedToken?.exp * 1000;
      const dueExpiration = dayjs(expiration).diff(dayjs(), "minute");

      localStorage.setItem("dataExpiracaoSessao", expiration.toString());

      let retorno = {
        userEmail: userEmail,
        token: token,
      };

      if (dueExpiration > 5) {
        return retorno;
      } else {
        this.limpaStorage();
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  logout() {
    const instance = new UserAgentPublicApp({
      authority: this._configService._config.forgeRockAuth.urlAuth,
      client_id: this._configService._config.forgeRockAuth.client_id,
      storage: {
        type: "localStorage",
      },
      redirect_uri: this._configService._config.forgeRockAuth.urlCallBack,
      post_logout_uri: this._configService._config.forgeRockAuth.urlCallBack,
    });

    this.limpaStorage();
  }

  limpaStorage() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/home";
  }
}
