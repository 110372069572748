import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import 'moment/locale/pt-br';
import { DateModel } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { DatePickerOptions } from "app/shared/ng2-datepicker/ng2-datepicker.component";
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from './../../shared/multiselect-dropdown/multiselect-dropdown.component';

import { Porto } from './../../_models/porto';
import { Schedule } from './../../_models/schedule';
import { Transbordo } from './../../_models/transbordo';
import { ScheduleRequest } from './../../_models/schedule-request';
import { AnalyticsDownload } from './../../_enums/analytics-download';

import { BaseComponent } from './../../shared/base.component';
import { MessageComponent } from './../../shared/message/message.component';
import { PaginacaoComponent } from './../../shared/paginacao/paginacao.component';

import { SharedService } from './../../_services/shared.service';
import { MessageService } from './../../_services/message.service';
import { ParametroService } from 'app/_services/parametro.service';
import { DatepickerService } from '../../_services/datepicker.service';
import { ProgramacaoNaviosService } from './../../_services/programacao-navios.service';
import { AnalyticsService } from 'app/_services/analytics.service';
import { AnalyticsTela } from 'app/_enums/analytics-tela';

@Component({
  selector: 'app-programacao-navios',
  templateUrl: './programacao-navios.component.html',
  styleUrls: ['./programacao-navios.component.scss']
})
export class ProgramacaoNaviosComponent extends BaseComponent implements OnInit {

  programacao: Schedule[] = [];
  optionsModel: number[];
  tipoPeriodo: string;
  portosOrigem: number[];
  portosDestino: number[];
  dataFiltro: Date;
  dataInicio: Date;
  dataFim: Date;
  selectedDate: DateModel;
  options: DatePickerOptions;
  portos: Porto[];
  loading: boolean = false;
  loadingPortos: boolean = false;
  empty: boolean = true;
  noresult: boolean = false;
  pageSize: number = 20;
  qtdeHorasAgendamentoPO: number = 0;
  urlDownloadSchedulePDF: string = "";

  @ViewChild(PaginacaoComponent, {static: true}) paginacao: PaginacaoComponent;

  myTexts: IMultiSelectTexts = {
    checkAll: 'Marcar Todas',
    uncheckAll: 'Desmarcar Todas',
    checked: 'marcado',
    checkedPlural: 'marcados',
    searchPlaceholder: 'Pesquisar...',
    defaultTitle: 'Selecione',
    allSelected: 'Todos selecionados',
  };

  //Labels
  myOptions: IMultiSelectOption[] = [];

  mySettings: IMultiSelectSettings = {
    pullRight: false,
    enableSearch: false,
    checkedStyle: 'glyphicon',
    buttonClasses: 'btn btn-default btn-secondary',
    selectionLimit: 0,
    closeOnSelect: false,
    autoUnselect: false,
    showCheckAll: true,
    showUncheckAll: true,
    fixedTitle: false,
    dynamicTitleMaxItems: 2,
    maxHeight: '300px',
  };

  @ViewChild(MessageComponent, {static: true}) mensagem: MessageComponent;

  constructor(private _programacaoService: ProgramacaoNaviosService,
              protected _router: Router,
              private _sharedService: SharedService,
              private _datePickerService: DatepickerService,
              private _msgService: MessageService,
              private _parametroService: ParametroService,
              private _analyticsService: AnalyticsService) {

    super();

    this.tipoPeriodo = 'embarque';
    this.options = _datePickerService.GenerateOptionsConfig(new Date());

    this._msgService.emitirMensagem.subscribe(
      msg => {
        if (msg.mensagem instanceof Array)
          this.mensagem.mostrarMensagemArray(msg.tipo, msg.mensagem);
        else
          this.mensagem.mostrarMensagem(msg.tipo, msg.mensagem);
      }
    );
  }

  ngOnInit() {
    this.ObterPortos();
    this.ObterParametroQtdeHoras();
    this.ObterPDF();
    this._analyticsService.RegistrarAcesso(AnalyticsTela.ProgramacaoNavios);
   }

  private ObterPDF(){
    this._parametroService.obtemParametroPorCodigo("PDF_SCHEDULE")
      .subscribe(m => {
        this.urlDownloadSchedulePDF = m.Valor;
      });
  }

  public ObterPortos() {
    this.loadingPortos = true;
    this._sharedService.getPortos()
      .subscribe(m => {
        this.portos = m;
        if (this.portos != null) {
          this.portos.forEach(porto => {
            this.myOptions.push({ id: porto.codigo, name: porto.descricao });
          });
        }
        this.loadingPortos = false;
      });
  }

  ObterParametroQtdeHoras() {
    //this.loadingParametro = true;
    this._parametroService.obterParametrosPortoPadrao()
      .subscribe(p => {
    //    this.loadingParametro = false;
        this.qtdeHorasAgendamentoPO = p.qtdHrAgendColetaPO;
      });
  }

  BuscarProgramacao() {
    this._msgService.clearMessage();

    let filtro: ScheduleRequest;
    filtro = new ScheduleRequest();
    filtro.servico = 'ALCT';

    if (this.portosOrigem != undefined && this.portosOrigem.length > 0)
      filtro.idsPortoOrigem = this.portosOrigem.join(';');
    if (this.portosDestino != undefined && this.portosDestino.length > 0)
      filtro.idsPortoDestino = this.portosDestino.join(';');

    this.dataFiltro = this._datePickerService.ObtemData(this.selectedDate);

    filtro.tipoPeriodo = this.tipoPeriodo.toUpperCase();
    //filtro.dataInicio = (filtro.tipoPeriodo == "EMBARQUE") ? this.dataFiltro : undefined;
    //filtro.dataFim = (filtro.tipoPeriodo == "DESEMBARQUE") ? this.dataFiltro : undefined;
    //filtro.dataFim = this._sharedService.addDays(this.dataFiltro, 30);

    //Chegada Até (desembarque)
    if (this.tipoPeriodo == "desembarque") {
      filtro.dataInicio = this._sharedService.addDays(this.dataFiltro, -30);
      filtro.dataFim = this.dataFiltro;
    }
    //A partir (embarque)
    else {
      filtro.dataInicio = this.dataFiltro;
      filtro.dataFim = this._sharedService.addDays(this.dataFiltro, 30);
    }

    this.loading = true;
    this._analyticsService.RegistrarFiltroSchedule(filtro);
    this._programacaoService.getSchedule(filtro)
      .subscribe(p => {
        this.programacao = p;
        this.setOrder('dhPrevSaidaPortoOrigem');
        this.loading = false;
        this.empty = false;
      });
  }

  obterTransbordosSchedule(schedule: Schedule): Transbordo[]{
    return (schedule.Transbordos && schedule.Transbordos.length > 4) ? schedule.Transbordos.slice(0, 4) : schedule.Transbordos;
  }

  setPageSize(value: number) {
    this.pageSize = value;
    //if (this.paginacao != )
    this.paginacao.setAllData(this.programacao);
    this.paginacao.setPageSize(this.pageSize);
    this.paginacao.setPage(1);
  }

  setOrder(value: string) {
    if ((this.programacao != null) && (this.programacao != undefined)) {
      var array = this.programacao.sort(function (a, b) {
        if (!value) {
          return a > b ? 1 : -1;
        }
        return a[value] > b[value] ? 1 : -1;
      });

      this.programacao = array;
      this.paginacao.setAllData(this.programacao);
      this.paginacao.setPageSize(this.pageSize);
      this.paginacao.setPage(1);
      this.noresult = (this.programacao.length == 0);
    }
    else
      this.noresult = true;
  }

  downloadProgramacaoCompleta(){
    this._analyticsService.RegistrarDownload(AnalyticsTela.ProgramacaoNavios, AnalyticsDownload.ProgramacaoCompleta);
  }

  GerarCSV() {
    this._analyticsService.RegistrarDownload(AnalyticsTela.ProgramacaoNavios, AnalyticsDownload.ExportacaoCSV);
    this._programacaoService.DownloadCSV(this.programacao);
  }

  exibeSolicitarBooking(dataDeadline: Date) {
    var dataCorrigida: Date = this._sharedService.corrigeData(dataDeadline);
    var dataRef = this._sharedService.addHours(new Date(), this.qtdeHorasAgendamentoPO);
    return (dataRef <= dataCorrigida);
  }
}
